import { formatPrice } from "library/utils/formatter";

export const extractOldPriceAndQuantity = (arr) => {
  const finalArr = arr.map(
    ({ itemPrice = "", originalQuantity = "", quantity = "1" }) => ({
      itemPrice: itemPrice,
      quantity: originalQuantity || quantity,
    })
  );
  return finalArr;
};

export const extractNewPriceAndQuantity = (arr) => {
  const finalArr = arr.map(
    ({
      itemPrice = "",
      newPrice = "",
      originalQuantity = "1",
      quantity = "",
      isRemoved = false,
    }) => ({
      itemPrice: !isRemoved
        ? newPrice
          ? parseFloat(newPrice)
          : parseFloat(itemPrice)
        : 0,
      quantity: quantity || originalQuantity,
    })
  );
  return finalArr;
};

export const getLineItemsTotal = (lineItemsArray = []) => {
  const total = lineItemsArray.reduce((total, item) => {
    const itemPrice = parseFloat(item?.itemPrice);
    const quantity = parseFloat(item?.quantity);

    // If itemPrice or quantity is NaN, treat them as 0
    const validItemPrice = !isNaN(itemPrice) ? itemPrice : 0.0;
    const validQuantity = !isNaN(quantity) ? quantity : 0;

    return total + validItemPrice * validQuantity;
  }, 0);

  return total;
};

export const isOrderItemTotalsMatching = (props) => {
  const { values = [] } = props;

  const prevValues = extractOldPriceAndQuantity(values);
  const newValues = extractNewPriceAndQuantity(values);

  const prevTotal = formatPrice(getLineItemsTotal(prevValues));
  const newTotal = formatPrice(getLineItemsTotal(newValues));

  const output = prevTotal === newTotal;

  return output;
};
