import React, { useEffect } from "react";
import { View, Platform, TouchableOpacity } from "react-native";
import { Text } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import isEmpty from "lodash/isEmpty";
//Adding a separator line to differentiate libraries to custom components
import {
  FormField,
  FormFieldPicker,
  FormFieldAutoComplete,
} from "components/elements/forms";
import Address from "components/elements/address";
import { request } from "library/utils/request";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import set from "lodash/set";
import useStateIfMounted from "library/utils/useStateIfMounted";
import {
  setOrderItemTitle,
  resetOrderItemTitle,
} from "library/sagas/views/home/drawer/create-order/slice";
import UserProfileStorage from "library/storage/userProfile";
import moment from "moment";
import Environment from "library/utils/environment";
import tw from "tailwind-rn";
import { fonts, shapes } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import AppSettingsContext from "library/contexts/appSettings";
import {
  selectIsEditOrder,
  selectIsSubscription,
  selectRushDeliveryFee,
} from "library/sagas/views/home/drawer/create-order/selector";
import { selectApiResponse } from "library/sagas/ongoing/order-details/selector";
import { canCallDeliveryFee } from "./helper";
import { isEligibleForRush } from "library/utils/createOrder";
import AddressVerificationIndicator from "components/views/drawer/order-details/delivery-info/component/address-indicator";
import {
  selectAddressVerificationInfo,
  selectRecalcTaxAndFees,
} from "library/sagas/ongoing/global-data/selector";
import { locationList } from "../config";
import { Entitlements, isMHQNonCoreMember } from "library/utils/entitlements";
import { phoneNumberFormatter } from "library/utils/formatter";

const RecipientInfo = ({
  isSmallScreen,
  index = 0,
  title,
  orderInfo,
  setSideCarOpen,
  isAutoFill,
  eligibleDeliveryMethods,
  sendingMemberCode: selectedMemberCode,
  canUpdateDeliveryMethod = true,
  canModifyOrder = true,
  isMultiOrderEligible,
}) => {
  const { setFieldValue, values, touched, setValues } = useFormikContext();
  const dispatch = useDispatch();
  const { messages, Localise } = React.useContext(I18NContext);
  const { permissions } = React.useContext(AppSettingsContext);
  const isEditOrder = useSelector(selectIsEditOrder);
  const orderResponse = useSelector(selectApiResponse);
  const rushDeliveryFee = useSelector(selectRushDeliveryFee);
  const addressVerificationInfo = useSelector(selectAddressVerificationInfo);
  const reCalculateDF = useSelector(selectRecalcTaxAndFees);
  const isSubscription = useSelector(selectIsSubscription);

  const shopLocations = UserProfileStorage.getAllShopLocation();
  const selectedShopPreferences =
    UserProfileStorage.getShopPreferences(selectedMemberCode);
  const shopLocation = shopLocations[selectedMemberCode];

  const selectedShopPermissions = get(permissions, selectedMemberCode, {});
  const hasFuneralLogEntitlement = selectedShopPermissions[
    Entitlements.CREATE_ORDER
  ]?.includes(Entitlements.CREATE_ORDER_PAGE.FUNERAL_LOG);
  const isMHQNonCoreUser = isMHQNonCoreMember(selectedMemberCode);
  const showFuneralLog =
    Environment.get("SHOW_FUNERAL_LOG", false) &&
    isMHQNonCoreUser &&
    hasFuneralLogEntitlement &&
    !isSubscription;
  const isAutoCompleteLocApiEnabled = Environment.get(
    "ENABLE_AUTO_COMPLETE_API",
    true
  );

  const {
    customerInfo: { addresses = [] },
  } = values;

  const shippingAddresses =
    addresses?.filter((x) => x.addressType?.toLowerCase() === "shipping") || [];

  const [recipientSuggestions, setRecipientSuggestions] = useStateIfMounted([]);
  const [locationSuggestions, setLocationSuggestions] = useStateIfMounted([]);
  const [abbreviationCodes, setAbbreviationCodes] = useStateIfMounted([]);
  const { firstName, addressLine1, city, state, zip, country } =
    orderInfo.recipientInfo;
  const ignoreZipValidation =
    isEditOrder && country === "US" && zip?.length === 10;
  const { deliveryMethod, deliveryDate } = orderInfo.deliveryInfo;

  const [loading, setLoading] = useStateIfMounted(false);

  const formIKParentPath = `orderItems.${index}`;
  const formIKPath = `orderItems.${index}.recipientInfo`;
  const deliveryFeeInfoPath = `orderItems.${index}.deliveryFeeInfo`;
  const deliveryFeePath = `orderItems.${index}.deliveryFee`;
  const locationTypeRef = React.useRef(null);
  const isFloristDeliveredEligible = eligibleDeliveryMethods.some(
    (obj) => obj.value === "FLORIST_DELIVERED"
  );
  const rushSubmitBy = get(selectedShopPreferences, "rush_submitted_by", "");
  const isDeliveryFeeOverridden = get(
    values,
    `orderItems.${index}.isDeliveryFeeOverridden`,
    false
  );

  const updateDeliveryMethod = async () => {
    if (!canUpdateDeliveryMethod) return;
    const isLocalDelivery = await checkIsFloristDelivered();
    if (isLocalDelivery === undefined) return;

    if (isLocalDelivery && deliveryMethod === "") {
      const defaultDeliveryMethod = "FLORIST_DELIVERED";
      setFieldValue(`${formIKPath}.deliveryMethod`, defaultDeliveryMethod);
      setFieldValue(
        `${formIKParentPath}.deliveryMethod`,
        defaultDeliveryMethod
      );
      setFieldValue(
        `${formIKParentPath}.deliveryInfo.deliveryMethod`,
        defaultDeliveryMethod
      );
    }
  };

  const getDeliveryFee = async (updateDeliveryMethod = false) => {
    if (
      !(
        city &&
        state &&
        country &&
        zip &&
        deliveryDate &&
        selectedMemberCode
      ) ||
      isDeliveryFeeOverridden
    )
      return;

    if (
      isEditOrder &&
      (isEmpty(touched) ||
        !canCallDeliveryFee(
          orderInfo,
          selectedMemberCode,
          index,
          values,
          setFieldValue,
          orderResponse,
          reCalculateDF
        ))
    )
      return;

    setFieldValue(
      `${formIKParentPath}.relayFee`,
      (deliveryMethod === "FLORIST_PARTNER" ||
        deliveryMethod === "PHONE_OUT") &&
        Environment.get("SHOW_RELAY_FEE", false)
        ? get(selectedShopPreferences, "relay_fee", "0.00")
        : 0
    );

    const requestParams = {
      zip,
      city: Platform.OS === "web" ? encodeURIComponent(city) : city,
      state,
      country,
      deliveryDate: moment(deliveryDate).utc().format("YYYY-MM-DD"),
      selectedMemberCode,
      streetAddress: addressLine1,
    };
    request("get-delivery-fee", requestParams)
      .then(async (resp) => {
        const { deliveryFee: { localFee = 0, outsideLocalFee = 0 } = {} } =
          resp;
        setFieldValue(deliveryFeeInfoPath, resp.deliveryFee);

        let delFee =
          deliveryMethod === "FLORIST_DELIVERED"
            ? localFee
            : deliveryMethod === "FLORIST_PARTNER" ||
              deliveryMethod === "PHONE_OUT"
            ? outsideLocalFee
            : 0;

        setFieldValue(
          deliveryFeePath,
          parseFloat(
            orderInfo.isRushOrder &&
              isEligibleForRush(deliveryDate, rushSubmitBy)
              ? Number(delFee) + Number(rushDeliveryFee)
              : delFee
          ).toFixed(2)
        );
        if (updateDeliveryMethod && canUpdateDeliveryMethod) {
          const isLocalDelivery = await checkIsFloristDelivered();
          if (isLocalDelivery && deliveryMethod === "") {
            const defaultDeliveryMethod = "FLORIST_DELIVERED";
            setFieldValue(
              `${formIKPath}.deliveryMethod`,
              defaultDeliveryMethod
            );
            setFieldValue(
              `${formIKParentPath}.deliveryMethod`,
              defaultDeliveryMethod
            );
            setFieldValue(
              `${formIKParentPath}.deliveryInfo.deliveryMethod`,
              defaultDeliveryMethod
            );
          }
        }
      })
      .catch((error) => {
        console.log("Failed to get delivery fee. Try again. ", error);
      });
  };

  const checkIsFloristDelivered = async () => {
    if (!(city && zip && selectedMemberCode)) return;

    return await request("get-cityzipcodes-fees", {
      city,
      countryId: country,
      state,
      shopCode: selectedMemberCode,
    })
      .then((resp) => {
        return resp.some((obj) =>
          obj?.countryId === "US"
            ? zip?.length <= 5
              ? obj.zipCode === zip && obj.status === "Y"
              : obj.zipCode === zip.split("-")[0] && obj.status === "Y"
            : obj.zipCode === zip && obj.status === "Y"
        );
      })
      .catch((error) => {
        console.log("Unable to verify the florist delivery", error);
        return false;
      });
  };

  useEffect(() => {
    request("get-card-messages", {}).then((res = []) => {
      setAbbreviationCodes(res);
    });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!firstName) {
      dispatch(setOrderItemTitle({ firstName, Localise, messages }));
    } else {
      dispatch(resetOrderItemTitle());
    }
  }, [firstName]);

  useEffect(() => {
    setRecipientSuggestions(shippingAddresses.slice(0, 5) || []);
  }, [addresses?.length]);

  useEffect(() => {
    // getDeliveryFee and UpdateDelivery fee if florist delivery eligible
    if (!isFloristDeliveredEligible) return;
    if (deliveryDate) getDeliveryFee(true);
    else updateDeliveryMethod();
  }, [city, zip, selectedMemberCode]);

  useEffect(() => {
    setFieldValue(
      `orderItems.${index}.retailDeliveryFee`,
      !["WALK_IN", "STORE_PICKUP"].includes(deliveryMethod) &&
        state === shopLocation?.state
        ? get(selectedShopPreferences, "retail_delivery_fee", "0.00")
        : "0.00"
    );
  }, [state]);

  useEffect(() => {
    isFloristDeliveredEligible && getDeliveryFee();
  }, [state, country, deliveryDate, isDeliveryFeeOverridden]);

  useEffect(() => {
    if (isEditOrder) {
      if (isEmpty(orderResponse)) return;
      const { deliveryMethod: actualDeliveryMethod } =
        orderResponse?.orderItems[index]?.deliveryInfo || {};
      if (actualDeliveryMethod !== deliveryMethod) {
        isFloristDeliveredEligible && getDeliveryFee();
      }
    }
  }, [deliveryMethod]);

  const getAutoCompleteLocation = (text) => {
    if (text.trim().length < 3) {
      setLocationSuggestions([]);
      return;
    }
    const recipientValues = values?.orderItems[0]?.recipientInfo;
    const locationType = recipientValues?.locationType;
    if (locationType?.length > 0 && locationType !== "Residence") {
      setLoading(true);
      const requestType = isAutoCompleteLocApiEnabled
        ? "autocomplete-location"
        : "textsearch";
      request(requestType, {
        input: text,
        country: recipientValues?.country,
        city: recipientValues?.city,
        state: recipientValues?.state,
        zip: recipientValues?.zip,
        locationType: ["Funeral"].includes(recipientValues?.locationType)
          ? recipientValues?.locationType
          : ``,
      })
        .then((res) => {
          const response = isAutoCompleteLocApiEnabled
            ? res?.predictions?.length
              ? res.predictions
              : []
            : res?.results?.length
            ? res?.results
            : [];
          setLocationSuggestions(response);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getLocationDetails = (text) => {
    setLoading(true);
    request("google-geocode", {
      place_id: text,
    })
      .then((response) => {
        const getValue = (value) => {
          const address_components = response?.results[0]?.address_components;
          return address_components?.find((obj) => obj.types[0] === value);
        };
        const streetNum =
          getValue("street_number")?.short_name ||
          getValue("street_number")?.long_name ||
          ``;
        const route =
          getValue("route")?.short_name || getValue("route")?.long_name || ``;

        const streetAddress = streetNum + ` ` + route;
        const city =
          getValue("locality")?.short_name ||
          getValue("locality")?.long_name ||
          ``;
        const state = getValue("administrative_area_level_1")?.short_name || ``;
        const postalcode = getValue("postal_code")?.long_name || ``;
        const country = getValue("country")?.short_name || ``;

        set(updatedValues, path, {
          ...get(updatedValues, path),
          addressLine1: streetAddress,
          city: city,
          zip: postalcode,
          state: state,
          country: country,
        });
        setValues(updatedValues);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const path = `orderItems.${index}.recipientInfo`;
  const updatedValues = cloneDeep(values);

  const findDiff = (str1, str2) => {
    let diff = "";
    str2.split("").forEach(function (val, i) {
      if (val != str1.charAt(i)) diff += val;
    });
    return diff;
  };

  // function for enter, space, Focus out, Tab
  const replaceAbbreviationCodes = (text) => {
    abbreviationCodes.map((abbreviation) => {
      const RegExBackward = new RegExp("\\" + abbreviation.code + "\\b", "gi");
      text = text.replace(RegExBackward, abbreviation.cardMessage);
    });
    return text;
  };

  const recipientEmail = get(
    updatedValues,
    `orderItems.0.recipientInfo.email`,
    ""
  );

  const {
    isAvsPerformed = "",
    isAvsSuggestedAddress = "",
    avsConfidence = "",
  } = addressVerificationInfo[index] || {};
  const isAvsVerified =
    isAvsPerformed === "Y" &&
    isAvsSuggestedAddress === "Y" &&
    avsConfidence === "HIGH";
  const recipientInfo = updatedValues?.orderItems[0]?.recipientInfo;
  return (
    <View
      testID="recipient-info"
      style={[
        {
          // ...shapes.sectionBorder,
          marginTop: 15,
        },
        !isMultiOrderEligible && { ...shapes.sectionBorder },
      ]}
    >
      <View style={[tw("flex flex-row pb-3"), { paddingHorizontal: 5 }]}>
        <Text style={[fonts.sectionHeading]}>{Localise(messages, title)}</Text>

        {showFuneralLog && (
          <TouchableOpacity
            style={{
              marginLeft: "auto",
            }}
            onPress={() => {
              setSideCarOpen(`funeral_log`, {});
            }}
            testID="funeral_log"
            accessibilityLabel="funeral_log"
          >
            <Text style={{ ...fonts.link1, fontWeight: "400" }}>
              {Localise(messages, "Funeral Log")}
            </Text>
          </TouchableOpacity>
        )}
      </View>

      <View style={[tw("flex flex-row flex-wrap")]}>
        {shippingAddresses.length > 0 && (
          <FormFieldAutoComplete
            autoCapitalize="none"
            autoCorrect={false}
            name="recipient"
            fsClass="fs-exclude"
            loading={false}
            placeholder={"Search Recipients"}
            label={""}
            labelStyle={{ fontWeight: "normal" }}
            path={path}
            data={recipientSuggestions}
            showOnFocus={true}
            onChangeText={(query = "") => {
              const text = query.toLowerCase();
              setRecipientSuggestions(
                text.length > 0
                  ? shippingAddresses
                      ?.filter(
                        (x) =>
                          x.firstName?.toLowerCase().includes(text) ||
                          x.lastName?.toLowerCase().includes(text) ||
                          x.addressLine1?.toLowerCase().includes(text)
                      )
                      ?.slice(0, 5) || []
                  : shippingAddresses?.slice(0, 5) || []
              );
            }}
            outerContainerStyle={{
              width: "100%",
              zIndex: 4,
            }}
            listDisplayValues={[
              "firstName",
              "lastName",
              "addressLine1",
              "city",
              "state",
              "zipcode",
            ]}
            updateOnBlur={true}
            focusTo={locationTypeRef}
            popperPlacement={"bottom"}
            onSelect={(selectedValue) => {
              const {
                addressId,
                firstName,
                lastName,
                addressLine1,
                addressLine2,
                city,
                state,
                zipcode,
                country,
                locationType,
                locationName,
                phones = [],
              } = selectedValue;
              setFieldValue(path, {
                recipient: "",
                addressId,
                firstName,
                lastName,
                addressLine1,
                suite: addressLine2,
                city,
                zip: zipcode,
                state,
                country,
                locationType,
                locationName,
                phone: phoneNumberFormatter(phones[0]) || "",
                email: recipientEmail,
                giftCardId: "",
              });
            }}
          />
        )}
        <FormField
          autoCapitalize="none"
          autoCorrect={false}
          autoComplete="new-password"
          name="firstName"
          placeholder={"First Name"}
          label={"First Name"}
          labelStyle={{ fontWeight: "normal" }}
          path={path}
          editable={isAutoFill ? false : true}
          containerStyle={{ width: isSmallScreen ? "100%" : "33.5%" }}
          testID="recipient_name"
          accessibilityLabel="recipient_name"
        />
        <FormField
          autoCapitalize="none"
          autoCorrect={false}
          autoComplete="new-password"
          name="lastName"
          placeholder={"Last Name"}
          label={"Last Name"}
          labelStyle={{ fontWeight: "normal" }}
          path={path}
          editable={isAutoFill ? false : true}
          containerStyle={{ width: isSmallScreen ? "100%" : "33.5%" }}
        />
        <FormField
          autoCapitalize="none"
          autoCorrect={false}
          autoComplete="new-password"
          name="phone"
          placeholder={"123-456-7890"}
          label={"Phone"}
          labelStyle={{ fontWeight: "normal" }}
          inputStyle={{ width: "100%" }}
          containerStyle={{ width: isSmallScreen ? "100%" : "33%" }}
          path={path}
          keyboardType="phone-pad"
          maxLength={18}
          fsClass="fs-exclude"
          testID="recipient_phone"
          accessibilityLabel="recipient_phone"
          transformText={(text = "") => {
            return phoneNumberFormatter(text);
          }}
        />

        <Address
          values={values}
          setValues={setValues}
          ignoreAVS={isEditOrder && isEmpty(touched)}
          isSmallScreen={isSmallScreen}
          index={index}
          focusTo={locationTypeRef}
          editable={!isAutoFill}
          formIKPath={formIKPath}
          updateCounty={true}
          canModifyOrder={canModifyOrder}
          stateLabel={["MX", "CA"].includes(country) ? "Province" : "State"}
          cityLabel={
            ["MX", "BR"].includes(country)
              ? "Locality"
              : ["CA"].includes(country)
              ? "Municipality"
              : "City"
          }
          ignoreZipValidation={ignoreZipValidation}
        />
        {!!(addressLine1 && city && state && country && zip) &&
          isAvsPerformed === "Y" && (
            <AddressVerificationIndicator
              containerStyle={{
                paddingLeft: 5,
                marginTop: 8,
                marginBottom: 16,
                zIndex: -5,
                width: "100%",
              }}
              isVerified={isAvsVerified}
              unVerfifiedText={"Address Not Verified"}
            />
          )}
        <FormFieldPicker
          containerStyle={{
            width: isSmallScreen ? "100%" : "33.5%",
            zIndex: -1,
          }}
          placeholder={{
            label: "Location Type",
          }}
          label={"Location Type"}
          labelStyle={{ fontWeight: "normal" }}
          data={locationList}
          name="locationType"
          path={path}
          pickerRef={locationTypeRef}
          disabledFieldTouch={true}
        />

        <FormFieldAutoComplete
          autoCapitalize="none"
          autoCorrect={false}
          loading={loading}
          name="locationName"
          fsClass="fs-exclude"
          placeholder={"Location or business name"}
          label={"Location Name"}
          labelStyle={{ fontWeight: "normal" }}
          path={path}
          data={locationSuggestions}
          onChangeText={(text) => {
            // Set Location Type to "Other" when locationType is not set
            if (!recipientInfo?.locationType) {
              setFieldValue(`${path}.locationType`, "Other");
            }
            getAutoCompleteLocation(text);
          }}
          outerContainerStyle={{
            width: isSmallScreen ? "100%" : "66.5%",
            zIndex: -1,
          }}
          listDisplayValues={
            isAutoCompleteLocApiEnabled
              ? ["description"]
              : ["name", "formatted_address"]
          }
          updateOnBlur={true}
          popperPlacement={"bottom"}
          onSelect={(selectedValue) => {
            getLocationDetails(selectedValue?.place_id);
            set(updatedValues, path, {
              ...get(updatedValues, path),
              locationName: isAutoCompleteLocApiEnabled
                ? selectedValue?.terms[0]?.value
                : selectedValue?.name,
            });
          }}
        />
      </View>

      <View style={{ zIndex: -1 }}>
        <View
          style={[
            tw("flex flex-row"),
            {
              marginTop: 5,
              paddingHorizontal: 5,
            },
          ]}
        >
          <Text style={[fonts.heading5, { fontWeight: "normal" }]}>
            {Localise(messages, "Card Message")}
          </Text>

          <TouchableOpacity
            style={{ marginLeft: "auto" }}
            onPress={() => {
              setSideCarOpen(`abbreviation_codes-${index}`, abbreviationCodes);
            }}
            testID="abbreviation_codes"
            accessibilityLabel="abbreviation_codes"
          >
            <Text style={[tw("mb-1"), fonts.link1, { fontWeight: "400" }]}>
              {Localise(messages, "Abbreviation Codes")}
            </Text>
          </TouchableOpacity>
        </View>

        <FormField
          autoCapitalize="none"
          autoCorrect={false}
          name="cardMessage"
          placeholder={
            isSmallScreen
              ? "Enter card message,\n\\hb = Happy Birthday"
              : "Enter card message, \\hb = Happy Birthday"
          }
          path={`orderItems.${index}.deliveryInfo`}
          handleOnBlur={() => {
            !isSmallScreen
              ? setSideCarOpen("additional_info")
              : setSideCarOpen("");
          }}
          transformText={(val, prev) => {
            return prev
              ? findDiff(prev, val).startsWith(" ") ||
                findDiff(prev, val) === "\n"
                ? replaceAbbreviationCodes(val)
                : val
              : replaceAbbreviationCodes(val);
          }}
          multiline={true}
          numberOfLines={3}
          maxNumberOfLines={5}
          spellCheck={true}
        />
      </View>
    </View>
  );
};

export default RecipientInfo;
