import React, { useEffect, useContext } from "react";
import { View, Platform, ActivityIndicator } from "react-native";
import { useSelector, useDispatch } from "react-redux";

import { Text, Button } from "react-native-elements";
import { useFormikContext } from "formik";
import filter from "lodash/filter";
import startCase from "lodash/startCase";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import moment from "moment";
import tw from "tailwind-rn";
import { fonts, colors, backgroundColors } from "styles/theme";
import { generateDetailContent } from "../helper";
import {
  isDeliveryService,
  isDoordashProvider,
  isFloristDelivered,
  isMOLDelivered,
  isWireOrder,
} from "./helper";
import { deliveryMethods, undeliverableActions } from "./config";
import { ToasterHandler, CustomModal } from "components/elements";
import { FormFieldPicker } from "components/elements/forms";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { request } from "library/utils/request";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import {
  selectRecordData,
  selectLoadingOnDSrequested,
} from "library/sagas/ongoing/current-orders/selector";
import { setLoadingOnDSrequested } from "library/sagas/ongoing/current-orders/slice";
import { setEditOrder } from "library/sagas/views/home/drawer/create-order/slice";
import UpsertDeliveryInstructions from "./upsert-delivery-instructions";
import DsForm from "./component/dsForm";
import {
  getActiveShipment,
  DSEstimatedFees,
  isBurqProvider,
} from "library/utils/deliveryService";
import { orderDsRequestRerouting } from "library/utils/orderListing";
import RecipientDateInfo from "./recipient-date-info";
import { getOrderStatus } from "components/views/drawer/order-details/order-head/order-breadcrumb/helper";

const DeliveryInfo = ({
  doNotFill,
  triggerRequest,
  triggerAction,
  sourceMemberCode,
  index,
  recordId,
  actionCallback,
  supportDateChange,
}) => {
  const orderDetailResponse = useSelector(selectRecordData);
  const orderDetails = orderDetailResponse.orderItems[index];

  const dispatch = useDispatch();
  const { isMobile, isDesktop } = useContext(DeviceContext);
  const { messages: locMessages, Localise } = useContext(I18NContext);
  const onDSrequestedLoading = useSelector(selectLoadingOnDSrequested);
  const { values, setFieldValue } = useFormikContext();
  const [isCancelDSModal, setShowCancelDSModal] = useStateIfMounted(false);
  const [rerouteCancelDSModal, setShowRerouteCancelDSModal] =
    useStateIfMounted(false);
  const [showCancelDSLoading, setShowCancelDSLoading] =
    useStateIfMounted(false);
  const [isShowErrorModal, setShowErrorModal] = useStateIfMounted(false);
  const [DSFulfillmentType, setDSFulfillmentType] = useStateIfMounted("");
  const [DSExpectedPickupTime, setDSExpectedPickupTime] = useStateIfMounted("");
  const deliveryInfoPath = `orderItems.${index}.deliveryInfo`;
  const deliveryInfo = get(values, deliveryInfoPath, {});

  const {
    deliveryDate,
    pickUpDateTime = "",
    deliveryDetailsToDS = "",
    undeliverableAction = "",
    deliveryMethod = "",
    isFloristSubmitted,
    isDSSubmitted,
    recipientInfo = {},
  } = deliveryInfo;

  const { locationType, locationName } = recipientInfo;

  const {
    messages = [],
    dsEligible = false,
    status: orderStatus,
    latestDSRequestedStatus = "",
    hasDSFulfillmentError = false,
    hasSettlementError = false,
    receivingMember: { timezone = "" } = {},
    orderItemId,
    direction,
    deliveryInfo: {
      deliveryMethod: originalDeliveryMethod = "",
      driver,
      operator,
    },
    orderSource = "",
    pickupInfo,
    autoSendToDS,
  } = orderDetails;
  const { storePickupDateTime = "" } = pickupInfo || {};

  const dsPickUpDate =
    pickUpDateTime.length > 0
      ? `${pickUpDateTime?.split("T")[0]}`
      : `${deliveryDate?.split("T")[0]}`;
  const dspickUpDateTime = pickUpDateTime
    ? `${moment.utc(pickUpDateTime).tz(timezone).format("YYYY-MM-DDTHH:mm:ss")}`
    : "";
  const dsPickUpTime = moment(dspickUpDateTime).format("hh:mm A");
  const floristTimeZone = moment.tz(timezone).format("z");

  const isSmallScreen = !isDesktop;

  const fillerMemberCode = get(orderDetails, "receivingMember.memberCode", "");
  const dsRerouteInProgress = orderDsRequestRerouting(
    pick(orderDetails, ["latestDSRequestedStatus"])
  );
  const DSMessages = filter(messages, (message) =>
    ["DeliveryService", "Delivery Service"].includes(message.operator)
  );
  let errorMessage = "";
  if (DSMessages && DSMessages.length > 0)
    errorMessage = DSMessages[DSMessages.length - 1].comments.split("\n")[0];

  const deliveryTypeChange = ({ deliveryMethod }) => {
    triggerAction({
      action: "preferred-delivery-type",
      preferredDeliveryType: [
        "FLORIST_DELIVERED",
        "FLORIST_PARTNER",
        "MOL_FLORIST_DELIVERED",
        "FOL_FLORIST_DELIVERED",
      ].includes(deliveryMethod)
        ? "FLORIST_FULFILLED"
        : deliveryMethod,
    });
  };

  const cancelDSRequest = ({ resolve = () => {} } = {}) => {
    setShowCancelDSLoading(true);
    const payload = {
      memberCode: fillerMemberCode,
      orderItemId: orderDetails.orderItemId,
      deliveryMethod: orderDetails.deliveryInfo.deliveryMethod,
    };
    request("delivery-service-cancel", payload)
      .then(() => {
        setShowCancelDSLoading(false);
        actionCallback(new Date().toString(), recordId);
        resolve();
      })
      .catch(({ errors, code }) => {
        setTimeout(() => {
          setShowCancelDSLoading(false);
        }, 5000);
        const errorMessage = get(
          errors,
          "0.message",
          "Something went wrong, please try again."
        );
        ToasterHandler("uh oh", Localise(locMessages, errorMessage));
        code === 400 && actionCallback(new Date().toString(), recordId);
      });
  };

  const CancelDSFeeModal = () => {
    const modalContent = {
      content: (
        <Text style={{ ...tw("p-4"), fontSize: 15, color: colors.highlighter }}>
          {Localise(
            locMessages,
            "Canceling this order may incur a fee. Do you want to continue?"
          )}
        </Text>
      ),
      buttons: [
        { type: "primary", title: Localise(locMessages, "Nevermind") },
        { type: "secondary", title: Localise(locMessages, "Continue") },
      ],
    };

    return (
      <CustomModal
        modalVisible={isCancelDSModal}
        modalContent={modalContent}
        primaryhandler={() => setShowCancelDSModal(false)}
        secondaryhandler={() => {
          setShowCancelDSModal(false);
          cancelDSRequest();
        }}
        contentStyle={[
          tw("border border-black p-4"),
          { backgroundColor: "white" },
        ]}
        modalStyle={
          Platform.OS !== "web"
            ? {
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#00000070",
                color: "#FFFFFF",
              }
            : {
                width: "35%",
              }
        }
      />
    );
  };

  const ShowErrorMessageModal = () => {
    const modalContent = {
      content: (
        <Text style={{ ...tw("p-4"), fontSize: 15, color: colors.highlighter }}>
          {Localise(
            locMessages,
            "Please cancel Delivery Service before requesting new date change, then resubmit the Delivery Service request."
          )}
        </Text>
      ),
      buttons: [{ type: "secondary", title: Localise(locMessages, "Close") }],
    };

    return (
      <CustomModal
        modalVisible={isShowErrorModal}
        modalContent={modalContent}
        secondaryhandler={() => setShowErrorModal(false)}
        contentStyle={[
          tw("border border-black p-4"),
          { backgroundColor: "white" },
        ]}
        modalStyle={
          Platform.OS !== "web"
            ? {
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#00000070",
                color: "#FFFFFF",
              }
            : {
                width: "35%",
              }
        }
      ></CustomModal>
    );
  };

  const RerouteCancelDSModal = () => {
    const modalContent = {
      content: (
        <Text
          style={{
            ...tw("p-4"),
            fontSize: 15,
            color: colors.highlighter,
            textAlign: "center",
          }}
        >
          {Localise(
            locMessages,
            "Are you sure you want to cancel the delivery service and switch to using a local driver?"
          )}
        </Text>
      ),
      buttons: [
        { type: "secondary", title: Localise(locMessages, "Cancel") },
        { type: "primary", title: Localise(locMessages, "Confirm") },
      ],
    };

    return (
      <CustomModal
        modalVisible={rerouteCancelDSModal}
        modalContent={modalContent}
        secondaryhandler={() => {
          setFieldValue(`${deliveryInfoPath}.deliveryMethod`, "");
          setShowRerouteCancelDSModal(false);
        }}
        primaryhandler={() => {
          setShowRerouteCancelDSModal(false);
          cancelDSRequest({ callback: deliveryMethodChange });
        }}
        contentStyle={[
          tw("border border-black p-4"),
          { backgroundColor: "white" },
        ]}
        modalStyle={
          Platform.OS !== "web"
            ? {
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#00000070",
                color: "#FFFFFF",
              }
            : {
                width: "35%",
              }
        }
      />
    );
  };

  const eligibileDeliveryMethods = [originalDeliveryMethod];

  let deliveryMethodOptions = deliveryMethods.filter((type) => {
    const DSDelveryMethod = "DELIVERY_SERVICE";
    let updatedDeliveryMethods = [...eligibileDeliveryMethods];
    if ((dsEligible || isDSSubmitted) && !dsRerouteInProgress) {
      // show Delivery Service delivery method option
      updatedDeliveryMethods.push(DSDelveryMethod);
    }
    return updatedDeliveryMethods.includes(type.value);
  });

  const isIncomingWireOrder =
    isWireOrder(originalDeliveryMethod ?? "") && direction === "INBOUND";

  //Show FLORIST_DELIVERED and hide WireOut for WireIn Orders for IncomingWireOrder
  if (isIncomingWireOrder) {
    deliveryMethodOptions = deliveryMethodOptions.filter(
      (type) => !eligibileDeliveryMethods.includes(type.value)
    );
    deliveryMethodOptions.unshift({
      label: "Florist Delivered",
      value: "FLORIST_PARTNER",
    });
  }

  const isStorePickupOrder = deliveryMethod === "STORE_PICKUP";
  const isWalkInOrder = deliveryMethod === "WALK_IN";
  const isPickUpOrder =
    ((orderSource === "DoorDash" || orderSource === "UberEats") &&
      !isEmpty(storePickupDateTime)) ||
    isStorePickupOrder ||
    isWalkInOrder ||
    (orderSource === "MOL" && deliveryMethod === "MOL_CUSTOMER_PICKUP");
  const internalStatus = getOrderStatus({ status: orderStatus, isPickUpOrder });

  useEffect(() => {
    if (onDSrequestedLoading) {
      setShowCancelDSLoading(true);
      setTimeout(() => {
        setShowCancelDSLoading(false);
        dispatch(setLoadingOnDSrequested(false));
      }, 5000); //temporary fix to delay Cancel Delivery Service display for 5 seconds
    }
    if (
      dsEligible &&
      !isDSSubmitted &&
      hasDSFulfillmentError &&
      !dsRerouteInProgress
    ) {
      autoSendToDS &&
        setFieldValue(`${deliveryInfoPath}.deliveryMethod`, "DELIVERY_SERVICE");
    }
  }, []);

  useEffect(() => {
    if (isDSSubmitted)
      request("get-delivery-tasks-by-referenceId", {
        memberCode: fillerMemberCode,
        referenceId: orderItemId,
      }).then((res) => {
        const {
          fulfillmentType = "",
          vendor: { name: deliveryProvider = "" } = {},
          pickup: { expectedTime } = {},
        } = getActiveShipment(res);

        if (isDoordashProvider(deliveryProvider.toLowerCase())) {
          setDSFulfillmentType(fulfillmentType);
        } else if (isBurqProvider(deliveryProvider)) {
          !!expectedTime &&
            setDSExpectedPickupTime(
              moment(moment.utc(expectedTime).toDate()).format("h:mm A")
            );
        }
      });
  }, [isDSSubmitted]);

  const shouldHaveOnPress = (errorMessage ?? "")
    .toLowerCase()
    .includes("phone number");

  const disableDeliveryMethod = isDSSubmitted && !dsRerouteInProgress;
  const showFloristDeliverySubmit =
    (dsEligible || !isFloristSubmitted || hasDSFulfillmentError) &&
    (isFloristDelivered(deliveryMethod ?? "") ||
      isWireOrder(deliveryMethod ?? "") ||
      isMOLDelivered(deliveryMethod ?? ""));

  const data = {
    DeliveryService: {
      label: "Delivery Service",
      value: `${moment(dsPickUpDate).format(
        "MM/DD/YYYY"
      )} at ${dsPickUpTime} ${floristTimeZone} ${startCase(
        DSFulfillmentType.toLowerCase()
      )}`,
    },
    ...(DSExpectedPickupTime && {
      DSExpectedPickupTime: {
        label: "Expected Pickup Time",
        value: DSExpectedPickupTime,
      },
    }),
    Deliveredby: {
      label: "Delivered by",
      value: !!driver && !!operator ? `${driver} via ${operator}` : "",
    },
    UndeliverableAction: {
      label: "Undeliverable Action",
      value: undeliverableAction,
    },
    DeliveryServiceInstructions: {
      label: "Delivery Service Instructions",
      value: deliveryDetailsToDS,
    },
  };

  const deliveryMethodChange = () => {
    deliveryTypeChange({ deliveryMethod: deliveryMethod ?? "" });
    if (hasDSFulfillmentError || dsRerouteInProgress) {
      //making read call
      triggerRequest({ requestType: "deliveryTypeChange" });
    }
  };

  const floristDeliverySubmit = () => {
    if (dsRerouteInProgress) {
      setShowRerouteCancelDSModal(true);
    } else {
      deliveryMethodChange();
    }
  };
  const handlePress = () => {
    dispatch(setEditOrder());
    triggerAction({
      action: "edit-and-lock-order",
    });
  };

  return (
    <View
      style={{
        zIndex: 1,
      }}
      pointerEvents={doNotFill ? "none" : "auto"}
    >
      <View style={tw("flex flex-col")}>
        <RecipientDateInfo
          {...{
            index,
            recordId,
            isSmallScreen,
            orderDetails,
            triggerRequest,
            triggerAction,
            supportDateChange,
          }}
        />

        {/* Delivery Service Display Start*/}
        {isDeliveryService(deliveryMethod ?? "") &&
          isDSSubmitted &&
          !hasDSFulfillmentError &&
          !dsRerouteInProgress &&
          generateDetailContent(data, true)}
        {/* Delivery Service Display End*/}

        {/* Delivery Method */}
        <View
          style={[
            tw("flex flex-row flex-wrap items-center my-2"),
            { zIndex: -1 },
          ]}
        >
          {((dsEligible && !isDSSubmitted) ||
            hasDSFulfillmentError ||
            dsRerouteInProgress) &&
            !(isIncomingWireOrder && internalStatus === "incoming") && (
              <View
                style={[
                  tw(
                    `flex flex-${
                      isMobile ? "col" : "row"
                    } flex-wrap w-full items-${isMobile ? "start" : "center"}`
                  ),
                ]}
              >
                <View style={[tw(`flex flex-row items-end`)]}>
                  <FormFieldPicker
                    name={"deliveryMethod"}
                    path={deliveryInfoPath}
                    label={Localise(locMessages, "Delivery Method")}
                    isRequired
                    placeholder={{
                      label: "Select Delivery Method",
                      value: "",
                    }}
                    labelStyle={{
                      fontSize: 14,
                      fontWeight: "normal",
                    }}
                    data={deliveryMethodOptions}
                    disabled={disableDeliveryMethod}
                    innerContainerStyle={{
                      backgroundColor: disableDeliveryMethod
                        ? backgroundColors.greyColor
                        : "transparent",
                    }}
                    containerStyle={{ width: 195, paddingLeft: 0 }}
                    testID="order_details_delivery_method"
                  />

                  {isDeliveryService(deliveryMethod ?? "") && (
                    <View
                      style={{
                        ...(isMobile
                          ? {
                              flex: 0,
                              marginBottom: 15,
                              marginHorizontal: 5,
                            }
                          : {
                              flex: 1,
                              marginHorizontal: 10,
                              marginBottom: 10,
                            }),
                        textTransform: "none",
                      }}
                    >
                      {isBurqProvider(values?.deliveryProvider) ? (
                        <DSEstimatedFees
                          {...{ Localise, messages: locMessages }}
                        />
                      ) : null}
                    </View>
                  )}
                  {showFloristDeliverySubmit && (
                    <Button
                      title={Localise(locMessages, "Submit")}
                      containerStyle={{ margin: 5 }}
                      testID="order_details_delivery_method_submit"
                      buttonStyle={{
                        paddingVertical: 8,
                        paddingHorizontal: 20,
                        marginBottom: 3,
                      }}
                      onPress={floristDeliverySubmit}
                    />
                  )}
                </View>
                {(hasDSFulfillmentError || dsRerouteInProgress) && (
                  <View
                    style={{
                      ...(isMobile
                        ? {
                            flex: 0,
                            marginBottom: 15,
                            marginHorizontal: 5,
                          }
                        : {
                            flex: 1,
                            marginHorizontal: 10,
                          }),
                      marginTop: 10,
                      textTransform: "none",
                    }}
                  >
                    <Text
                      onPress={shouldHaveOnPress ? handlePress : undefined}
                      style={{ color: colors.error }}
                      numberOfLines={10}
                    >
                      {errorMessage ||
                        Localise(locMessages, "Select Delivery Type")}
                    </Text>
                  </View>
                )}
              </View>
            )}
        </View>

        {/* DS Form */}
        {isDeliveryService(deliveryMethod ?? "") &&
          (!isDSSubmitted || hasDSFulfillmentError) && (
            <DsForm
              actionCallback={actionCallback}
              recordId={recordId}
              orderDetails={orderDetails}
              sourceMemberCode={sourceMemberCode}
              locationType={locationType}
              locationName={locationName}
              fillerMemberCode={fillerMemberCode}
              triggerAction={triggerAction}
              orderStatus={orderStatus}
              latestDSRequestedStatus={latestDSRequestedStatus}
              hasDSFulfillmentError={hasDSFulfillmentError}
              isDSSubmitted={isDSSubmitted}
              timezone={timezone}
              deliveryMethod={deliveryMethod}
              undeliverableActions={undeliverableActions}
              undeliverableAction={undeliverableAction}
              pickUpDateTime={pickUpDateTime}
              deliveryDate={deliveryDate}
              deliveryDetailsToDS={deliveryDetailsToDS}
            />
          )}

        <View style={tw("flex flex-row")}>
          {![
            "DELIVERED",
            "REJECTED",
            "CANCELLED",
            "ERROR",
            "FORFEITED",
          ].includes(orderStatus) &&
            (isDSSubmitted || dsRerouteInProgress) && (
              <>
                {showCancelDSLoading ? (
                  <ActivityIndicator
                    size="small"
                    color={colors.activityIndicator}
                    style={tw("pl-2")}
                  />
                ) : (
                  <View>
                    {!dsRerouteInProgress ? (
                      <Text
                        style={{
                          ...fonts.success,
                          textAlign: "left",
                        }}
                      >
                        {Localise(
                          locMessages,
                          "Delivery Service info has been submitted"
                        )}
                      </Text>
                    ) : null}
                    <Button
                      title={Localise(locMessages, "Cancel Delivery Service")}
                      onPress={() => setShowCancelDSModal(true)}
                      testID={"cancel_DS"}
                      accessibilityLabel={"cancel_DS"}
                      containerStyle={{
                        borderColor: "#4b7c8b",
                        borderWidth: 1,
                        marginHorizontal: 0,
                        marginVertical: 10,
                      }}
                      buttonStyle={{
                        backgroundColor: "transparent",
                      }}
                      titleStyle={{ color: "#4b7c8b" }}
                    />
                  </View>
                )}
              </>
            )}
        </View>
        {/* Delivery Instructions Start */}
        <UpsertDeliveryInstructions
          index={index}
          triggerAction={triggerAction}
          hasSettlementError={hasSettlementError}
        />
        {/* Delivery Instructions End */}

        {/* Modals */}
        <CancelDSFeeModal />
        <ShowErrorMessageModal />
        <RerouteCancelDSModal />
      </View>
    </View>
  );
};

export default DeliveryInfo;
