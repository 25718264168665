import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text } from "react-native";
import ContactInformationActions from "../shop-details/contact-information";
import ShopLogo from "../shop-details/shop-logo";

import {
  ToasterHandler,
  Spinner,
  AccordionWithBorder,
} from "components/elements";
import { Form } from "components/elements/forms";
import { SaveCancelButtons } from "components/wrappers";

import I18NContext from "library/contexts/i18N";
import { saveShopHours } from "library/sagas/views/home/drawer/shop-settings/common/slice";
import { selectShopHours } from "library/sagas/views/home/drawer/shop-settings/common/selector";

import { theme } from "styles/theme";
import tw from "tailwind-rn";

import ShopHoursInput from "./input";
import { getValidationSchema } from "./yup";
import { DeviceContext } from "library/contexts/appSettings";
import { Divider as RNDivider } from "react-native-elements";
import { fonts } from "styles/theme";
import { memberEntitlements } from "library/utils/entitlements";
import { selectShopDetails } from "library/sagas/views/home/drawer/shop-settings/common/selector";

const ShopHours = ({ saveSettings }) => {
  const dispatch = useDispatch();
  const { content, error } = useSelector(selectShopHours);
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);
  const shopDetails = useSelector(selectShopDetails);
  const isMOLOnlyUser =
    shopDetails.memberEntitlements?.memberEntitlementName ===
    memberEntitlements.MERCURY_ONLINE;

  const validationSchema = getValidationSchema(Localise, messages);

  const isEntitlementExpired =
    shopDetails?.memberEntitlements?.message?.includes("expired") ?? false;

  const entitlement = shopDetails?.memberEntitlements?.memberEntitlementName;

  const hideShopHours =
    entitlement === memberEntitlements.MHQ_CORE ||
    entitlement === memberEntitlements.MHQ_CONN ||
    isEntitlementExpired;

  if (!content && !error)
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );

  const onSubmit = (values, formikBag) => {
    formikBag.setSubmitting(true);
    dispatch(
      saveShopHours({
        params: values,
        resolve: () => {
          ToasterHandler(
            "excellent",
            Localise(messages, "Your Shop Hours have been updated.")
          );
          formikBag.setSubmitting(false);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
          formikBag.setSubmitting(false);
        },
      })
    );
  };

  return (
    <AccordionWithBorder
      title={Localise(messages, "Shop Information")}
      overrideZindex={1}
    >
      {!hideShopHours && (
        <View>
          <Form
            initialValues={content}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            render={() => {
              return (
                <View style={tw("flex flex-col")}>
                  <Text style={[fonts.heading3, tw("mb-5")]}>
                    {Localise(messages, "Shop Hours")}
                  </Text>
                  <ShopHoursInput />
                  <Text style={theme.CheckBox.errorStyle}>
                    {Localise(messages, error)}
                  </Text>
                  <SaveCancelButtons buttonTitle={Localise(messages, "Save")} />
                </View>
              );
            }}
          />
          {!isMOLOnlyUser && <RNDivider style={tw("my-6")} />}
        </View>
      )}
      {isDesktop && !isMOLOnlyUser && (
        <View style={{ zIndex: -1 }}>
          <ContactInformationActions saveSettings={saveSettings} />
          <RNDivider style={tw("my-6")} />
        </View>
      )}
      {!isMOLOnlyUser && <ShopLogo />}
    </AccordionWithBorder>
  );
};

export default ShopHours;
