import React, { useEffect } from "react";
import { Image, Platform, ScrollView, View } from "react-native";
import { Divider, Text } from "react-native-elements";

import tw from "tailwind-rn";
import qw from "query-string";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import { colors, shapes } from "styles/theme";
import { IMAGES } from "static/assets/images";

import SingleScreen from "components/containers/single-screen";
import HeaderBar from "components/containers/header";
import { CustomModal } from "components/elements";

import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import UserProfileStorage from "library/storage/userProfile";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { request } from "library/utils/request";

import OrderStatus from "./order-status";
import OrderDetails from "./order-details";
import DeliveryDetails from "./delivery-details";
import MapModalContent from "./delivery-details/map-modal-content";
import ImageModalContent from "./delivery-details/image-modal-content";
import DeliveryStatusScreen from "./delivery-status-screen";
import { getOrderCurrentStatus, isOrderDeliveryAttempted } from "./helper";

const OrderDeliveryStatusPage = () => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);
  const [trackingId, setTrackingId] = useStateIfMounted("");
  const [trackingDetails, setTrackingDetails] = useStateIfMounted({});
  const [loading, setLoading] = useStateIfMounted(true);
  const [modalName, setModalName] = useStateIfMounted("");
  const [imageSize, setImageSize] = useStateIfMounted({});
  const [deliveryStatus, setDeliveryStatus] = useStateIfMounted("");

  useEffect(() => {
    if (Platform.OS === "web" && window) {
      const { id: urlTrackingId } = qw.parse(window.location.search);
      urlTrackingId && UserProfileStorage.setTrackingId(urlTrackingId);
      urlTrackingId
        ? setTrackingId(urlTrackingId)
        : setTrackingId(UserProfileStorage.getTrackingId());
    }
  }, []);

  useEffect(() => {
    getTrackingDetails();
  }, [trackingId]);

  const getTrackingDetails = () => {
    setLoading(true);
    setDeliveryStatus("");

    trackingId &&
      request("get-order-status-tracking-details", {
        trackingId: trackingId,
      })
        .then((res) => {
          setLoading(false);

          if (!isEmpty(res)) {
            setTrackingDetails(res);

            const orderItemDetails = res?.order?.orderItems?.[0] || {};

            if (!isEmpty(orderItemDetails)) {
              const orderCurrentStatus =
                getOrderCurrentStatus(orderItemDetails);

              const deliveryMethod = get(
                orderItemDetails,
                "deliveryInfo.deliveryMethod",
                ""
              );

              if (
                ![
                  "STORE_PICKUP",
                  "MOL_CUSTOMER_PICKUP",
                  "PARTNER_STORE_PICKUP",
                ].includes(deliveryMethod)
              ) {
                const isDeliveryAttempted = isOrderDeliveryAttempted(
                  res?.events || [],
                  get(orderItemDetails, "messages", [])
                );

                if (orderCurrentStatus === "Delivered") {
                  setDeliveryStatus(
                    isDeliveryAttempted
                      ? "ATTEMPTED_DELIVERY_AND_DELIVERED"
                      : "DELIVERED"
                  );
                } else if (isDeliveryAttempted) {
                  setDeliveryStatus("ATTEMPTED_DELIVERY");
                }
              } else {
                setDeliveryStatus(
                  orderCurrentStatus === "Delivered" ? "DELIVERED" : ""
                );
              }
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("error: " + error);
        });
  };

  return (
    <ScrollView>
      <HeaderBar showWelcomeSection={false} />

      <SingleScreen
        title={
          isDesktop
            ? `${Localise(messages, "Order")} & ${Localise(
                messages,
                "Delivery Status"
              )}`
            : ""
        }
      >
        <ScrollView contentContainerStyle={{ padding: isDesktop ? 5 : 0 }}>
          {isEmpty(trackingId) ? (
            <View
              style={{
                flex: 1,
                marginTop: 20,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 20,
                  fontWeight: "400",
                  marginTop: 50,
                }}
              >
                {Localise(
                  messages,
                  "This page no longer exists. Please check the URL or try a different link."
                )}
              </Text>
            </View>
          ) : (
            <>
              {isEmpty(trackingDetails) ? (
                <View
                  style={{
                    flex: 1,
                    marginTop: 20,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {loading ? (
                    <>
                      <Image
                        style={{
                          width: Platform.OS === "web" ? 400 : 250,
                          height: Platform.OS === "web" ? 400 : 250,
                        }}
                        resizeMode="cover"
                        source={IMAGES["preparing-order-vase"]}
                      />
                      <Text
                        style={{
                          width: 200,
                          textAlign: "center",
                          fontSize: 20,
                          fontWeight: "400",
                        }}
                      >
                        {Localise(
                          messages,
                          "Buttercup is loading your order details"
                        )}
                      </Text>
                    </>
                  ) : (
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 20,
                        fontWeight: "400",
                        marginTop: 50,
                      }}
                    >
                      {Localise(
                        messages,
                        "This request is taking too long to respond. Please"
                      )}{" "}
                      <Text
                        style={{ textDecorationLine: "underline" }}
                        onPress={() => {
                          getTrackingDetails();
                        }}
                      >
                        {Localise(messages, "refresh")}
                      </Text>{" "}
                      {Localise(messages, "the page.")}
                    </Text>
                  )}
                </View>
              ) : (
                <>
                  {isEmpty(trackingDetails?.order) ? (
                    <DeliveryStatusScreen trackingDetails={trackingDetails} />
                  ) : (
                    <View>
                      {modalName.length > 0 && (
                        <CustomModal
                          modalVisible={true}
                          modalContent={{
                            content:
                              modalName === "map" ? (
                                <MapModalContent
                                  trackingDetails={trackingDetails}
                                  setModalName={setModalName}
                                />
                              ) : (
                                <ImageModalContent
                                  trackingDetails={trackingDetails}
                                  modalName={modalName}
                                  setModalName={setModalName}
                                  imageSize={imageSize}
                                  setImageSize={setImageSize}
                                />
                              ),
                            buttons: [],
                          }}
                          contentStyle={{
                            backgroundColor: "white",
                            ...(modalName === "map"
                              ? { width: "100%" }
                              : imageSize),
                          }}
                          modalStyle={
                            Platform.OS !== "web"
                              ? {
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: 1,
                                  backgroundColor: "#00000070",
                                  color: "#FFFFFF",
                                }
                              : {
                                  ...(modalName === "map" && {
                                    width: isDesktop ? "75%" : "100%",
                                  }),
                                }
                          }
                        />
                      )}

                      <View
                        style={[
                          tw("flex flex-col"),
                          {
                            ...(isDesktop && shapes.sectionBorder),
                            padding: 0,
                            width: isDesktop
                              ? deliveryStatus.length > 0
                                ? 1115
                                : 721
                              : "100%",
                            height: isDesktop ? 630 : "auto",
                          },
                        ]}
                      >
                        <View
                          style={[
                            isDesktop
                              ? { padding: 15 }
                              : { ...shapes.sectionBorder, marginBottom: 8 },
                          ]}
                        >
                          <Text
                            style={{
                              fontSize: 18,
                              fontWeight: "bold",
                              color: colors.highlighter,
                            }}
                          >
                            {Localise(messages, "Order")} #{" "}
                            {trackingDetails?.order?.orderItems?.[0]
                              ?.messageNumber || ""}
                          </Text>
                        </View>

                        {isDesktop && <Divider style={{ marginVertical: 0 }} />}

                        <View
                          style={[
                            tw(`flex flex-${isDesktop ? "row" : "col"}`),
                            { padding: isDesktop ? 10 : 0 },
                          ]}
                        >
                          <OrderStatus trackingDetails={trackingDetails} />
                          <OrderDetails trackingDetails={trackingDetails} />

                          {deliveryStatus.length > 0 && (
                            <DeliveryDetails
                              trackingDetails={trackingDetails}
                              setModalName={setModalName}
                              deliveryStatus={deliveryStatus}
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  )}
                </>
              )}
            </>
          )}
        </ScrollView>
      </SingleScreen>
    </ScrollView>
  );
};

export default OrderDeliveryStatusPage;
