import React, { useEffect } from "react";
import { Platform, ActivityIndicator } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { useNavigation, CommonActions } from "@react-navigation/native";

import get from "lodash/get";
import UpsertRouteScreen from "components/views/drawer/delivery/routes-dashboard/upsert-route";
import DeliverySettings from "components/views/drawer/delivery/routes-dashboard/delivery-settings";
import DeliveryDashboard from "components/views/drawer/delivery";
import CurrentOrdersScreen from "components/views/drawer/order-listing/current-orders";
import CreateSubscriptionScreen from "components/views/drawer/create-subscription";
import CreateOrderScreen from "components/views/drawer/create-order";
import DashboardScreen from "components/views/drawer/dashboard";
import StaffListingScreen from "components/views/drawer/staff-listing/index.js";
import Loading from "components/views/drawer/loader";
import CreateEvent from "components/views/drawer/create-event";
import InactiveUser from "components/views/drawer/inactive-user";
import StaffProfileScreen from "components/views/drawer/staff-profile";
import CreateStaffProfileScreen from "components/views/drawer/florist-staff-profile";
import ProfileScreen from "components/views/drawer/user-profile";
import FTDMemberSettings from "components/views/drawer/ftd-admin/member-settings";
import FTDUserSettings from "components/views/drawer/ftd-admin/user-settings";
import FTDCreateUser from "components/views/drawer/create-ftd-staff-profile";
import MOLSettings from "components/views/drawer/ftd-admin/mol-settings";
import ShopSettings from "components/views/drawer/shop-settings";
import Menu from "components/containers/menu";
import HeaderBar from "components/containers/header";
import ProxyHeader from "components/containers/header/proxy";
import SuspendHeader from "components/containers/header/suspend";
import AppSettingsContext from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import FullStory from "@fullstory/react-native";
import Help from "components/views/drawer/help";
import FeedBack from "components/views/drawer/feedback";
import WebviewWrapper from "components/views/drawer/webview";
import Demo from "components/views/drawer/demo";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { colors } from "styles/theme";
import { CustomModal } from "components/elements";
import I18NContext from "library/contexts/i18N";
import AppSettingsStorage from "library/storage/appSettings";
import ReportsScreen from "components/views/drawer/reports";
import MercuryOnline from "components/views/drawer/mercury-online";
import ProductCatalog from "components/views/drawer/product-catalog";
import CustomerDirectory from "components/views/drawer/customer-directory";
import Events from "components/views/drawer/events";
import GiftCards from "components/views/drawer/gift-cards";
import CreateEnclosureCardScreen from "components/views/drawer/create-enclosure-card";
import QrCodeScanner from "components/views/drawer/qr-code-scanner";

import CreateCustomer from "components/views/drawer/customer-directory/upsert-screen/create-customer";
import RoutesScreen from "components/views/drawer/routes";
import MOLRefunds from "components/views/drawer/mol-refunds";
// import ReleaseNotes from "components/views/drawer/help/release-notes";
import CreateTask from "components/views/drawer/delivery/delivery-service-dashboard/upsert-screen/create-task";
import {
  selectDashboardShopCode,
  selectCreateSubscriptionShopCode,
  selectCurrentPage,
  selectIsQuickBooksSessionExpired,
} from "library/sagas/ongoing/global-data/selector";
import { selectCreateOrderShopCode } from "library/sagas/views/home/drawer/create-order/selector";
import {
  setShowSessionInfo,
  setCurrentPage,
} from "library/sagas/ongoing/global-data/slice";
import { selectSessionId } from "library/sagas/session/selector";
import { Entitlements, isMHQNonCoreMember } from "library/utils/entitlements";
import Environment from "library/utils/environment";

const MenuComponentMap = {
  dashboard: DashboardScreen,
  "create-subscription": CreateSubscriptionScreen,
  "create-order": CreateOrderScreen,
  "create-card": CreateEnclosureCardScreen,
  "staff-management": StaffListingScreen,
  "customer-directory": CustomerDirectory,
  "gift-cards": GiftCards,
  orders: CurrentOrdersScreen,
  "staff-profile": StaffProfileScreen,
  "create-staff-profile": CreateStaffProfileScreen,
  profile: ProfileScreen,
  "shop-settings": ShopSettings,
  "delivery-service": WebviewWrapper,
  help: Help,
  logout: Loading,
  "ftd-create-user": FTDCreateUser,
  "ftd-member-settings": FTDMemberSettings,
  "ftd-user-settings": FTDUserSettings,
  "ftd-mol-settings": MOLSettings,
  "ftd-profile": ProfileScreen,
  "ftd-logout": Loading,
  feedback: FeedBack,
  demo: Demo,
  "lmds-delivery": DeliveryDashboard,
  "create-route": UpsertRouteScreen,
  "edit-route": UpsertRouteScreen,
  reports: ReportsScreen,
  "delivery-settings": DeliverySettings,
  "mercury-online": MercuryOnline,
  "product-catalog": ProductCatalog,
  "create-customer": CreateCustomer,
  "lmds-routes": RoutesScreen,
  "mercury-online-refunds": MOLRefunds,
  // releases: ReleaseNotes,
  "create-delivery-service-request": CreateTask,
  events: Events,
  "create-event": CreateEvent,
  "qr-code-scan": QrCodeScanner,
};

const Home = () => {
  const { setCurrentLocale } = React.useContext(I18NContext);
  const { allowedMenus, isInactiveUser } = React.useContext(AppSettingsContext);
  const { permissions } = React.useContext(AppSettingsContext);
  const {
    proxyUser,
    userType,
    userProfile: { email = "", firstName = "", id = "", memberCode = "" },
    memberCodes = [],
    suspendedMembers = [],
  } = React.useContext(UserProfileContext);

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";
  const macAddressFromHtml =
    isElectronApp && document.getElementById("macAddress").value;

  const [memberCodesList, setMemberCodesList] = useStateIfMounted(memberCodes);
  const [showLogoutModal, setLogoutModal] = useStateIfMounted(false);
  const [macAddress, setMacAddress] = useStateIfMounted(macAddressFromHtml);
  const dashboardSelectedShopCode = useSelector(selectDashboardShopCode);
  const createOrderSelectedShopCode = useSelector(selectCreateOrderShopCode);
  const createSubscriptionShopCode = useSelector(
    selectCreateSubscriptionShopCode
  );
  const currentPage = useSelector(selectCurrentPage);
  const sessionId = useSelector(selectSessionId);
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const isQuickBooksSessionExpired = useSelector(
    selectIsQuickBooksSessionExpired
  );

  const filteredShopCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (Entitlements.CREATE_ORDER in permissions[memberCode])
      filteredShopCodes.push(memberCode);
  });

  let hideMenusList = [];
  let eligibleAllowedMenus = [...allowedMenus];

  const eligibleMemberCodes = Environment.get(
    "ENABLE_EVENTS_PLANNER",
    ""
  ).split(",");

  const showEventsPlanner =
    eligibleMemberCodes.includes("ALL") ||
    memberCodes.some((member) => eligibleMemberCodes.includes(member));

  const isFTDUser = userType === "FTD";

  const proxyUserAccount =
    get(proxyUser, "shopGroups.0") ||
    get(proxyUser, "memberCodes.0") ||
    "Florist";

  let initialRoute = "dashboard";
  if (Platform.OS === "web") {
    const pathArray = window.location.pathname.split("/");
    initialRoute = pathArray && pathArray[1];
    if (typeof FS !== "undefined" && id !== "") {
      // eslint-disable-next-line no-undef
      FS.identify(id, {
        displayName: `${firstName} - ${memberCode}`,
        email: email,
      });
    }
  } else {
    if (id !== "") {
      FullStory.identify(id, {
        displayName: `${firstName} - ${memberCode}`,
        email: email,
      });
    }
  }

  useEffect(() => {
    let timer;
    if (isElectronApp && !macAddress) {
      timer = setInterval(() => {
        const macAddressFromPage = document.getElementById("macAddress").value;
        if (macAddressFromPage) {
          clearInterval(timer);
          setMacAddress(macAddressFromPage);
        }
      }, 2000);
    }

    if (!isFTDUser) return;
    AppSettingsStorage.setLanguage("en");
    setCurrentLocale("en");

    return () => {
      timer && clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setMemberCodesList(memberCodes);
  }, [memberCodes, allowedMenus]);

  const modalContent = {
    content: (
      <ActivityIndicator size="small" color={colors.activityIndicator} />
    ),
  };
  let member = "";
  if (currentPage === "create-subscription") {
    member = createSubscriptionShopCode;
  } else if (currentPage === "create-order") {
    member = createOrderSelectedShopCode;
  }
  member = member || dashboardSelectedShopCode;
  const isMHQNonCoreUser = isMHQNonCoreMember(
    filteredShopCodes.length > 1
      ? member === "all"
        ? ""
        : member
      : filteredShopCodes[0]
  );

  if (!showEventsPlanner) {
    hideMenusList = hideMenusList.concat(["events", "create-event"]);
  }
  hideMenusList.forEach((name) => {
    const menuIdx = eligibleAllowedMenus.findIndex(
      (menu) => menu.name?.toLowerCase() === name
    );
    menuIdx > -1 && eligibleAllowedMenus.splice(menuIdx, 1);
  });

  return (
    <>
      {showLogoutModal && (
        <CustomModal
          modalVisible={showLogoutModal}
          modalContent={modalContent}
          contentStyle={{ padding: 0, width: 0, height: 0 }}
          modalStyle={{
            padding: 0,
            ...(Platform.OS !== "web" && {
              flex: 1,
              backgroundColor: "rgb(0, 0, 0)",
              opacity: 0.7,
            }),
          }}
        ></CustomModal>
      )}

      <HeaderBar
        subHeader={() => {
          return (
            <>
              {isElectronApp &&
                isMHQNonCoreUser &&
                ["create-order", "create-subscription"].includes(currentPage) &&
                !sessionId && (
                  <ProxyHeader
                    content={`Enter Opening Balance to access system`}
                    color="#e5175e1a"
                    image="terminal"
                    showClose={false}
                    handleClick={() => {
                      if (currentPage !== "create-order") {
                        navigation.dispatch(
                          CommonActions.navigate({
                            name: "create-order",
                          })
                        );
                        dispatch(setCurrentPage("create-order"));
                      }
                      dispatch(setShowSessionInfo(true));
                    }}
                  />
                )}
              {isElectronApp && !macAddress && (
                <ProxyHeader
                  content={`Please wait a moment while the system loads.`}
                  color="#e5175e1a"
                  showClose={false}
                />
              )}
              {proxyUser ? (
                <ProxyHeader
                  content={`You are viewing ${proxyUserAccount} account`}
                  color="#94cde6"
                  image="ftd-admin-view"
                />
              ) : suspendedMembers.length > 0 ? (
                <SuspendHeader
                  suspendedMembers={suspendedMembers}
                  memberCodes={memberCodes}
                />
              ) : null}

              {Platform.OS === "web" &&
                !isElectronApp &&
                isQuickBooksSessionExpired && (
                  <ProxyHeader
                    content={`Your QuickBooks session has expired. Please re-authenticate to sync your order details.`}
                    color="#e5175e1a"
                  />
                )}
            </>
          );
        }}
      />
      {firstName && !isFTDUser && memberCodesList.length === 0 ? (
        <Loading
          message={
            "There is no active shop currently configured for this account."
          }
        />
      ) : eligibleAllowedMenus.length ? (
        <Menu
          initialRouteName={initialRoute}
          options={eligibleAllowedMenus}
          screenRef={MenuComponentMap}
          setLogoutModal={setLogoutModal}
        />
      ) : isInactiveUser ? (
        <InactiveUser setLogoutModal={setLogoutModal} />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Home;
