export const getInitialValues = (details) => {
  const initalValues = {
    customerType: "false",
    firstName: undefined,
    lastName: undefined,
    businessName: undefined,
    phone: "",
    isAddressRequired: false,
    isEmailRequired: false,
    emailMarketingOptIn: true,
    emailOptIn: true,
    address: {
      addressLine1: undefined,
      addressLine2: undefined,
      country: "US",
      city: undefined,
      state: undefined,
      zipcode: undefined,
      addressType: "Billing",
      isPrimary: true,
    },
  };
  return {
    ...initalValues,
    ...details,
  };
};
