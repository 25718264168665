import React, { useEffect, useMemo, useContext, useCallback } from "react";
import {
  View,
  TouchableOpacity,
  Image,
  Platform,
  ScrollView,
  ActivityIndicator,
  TouchableWithoutFeedback,
} from "react-native";
import { Text, Badge, Icon } from "react-native-elements";
import {
  useNavigation,
  CommonActions,
  useRoute,
} from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import UserProfileStorage from "library/storage/userProfile";
import I18NContext from "library/contexts/i18N";

import tw from "tailwind-rn";

import { fonts, colors, shapes } from "styles/theme";
import { IMAGES } from "static/assets/images";
import { PopupMenu, ToasterHandler, Tooltip } from "components/elements";
import { selectSessionId } from "library/sagas/session/selector";
import {
  setCurrentPage,
  setShowSessionInfo,
  setQrScanVariant,
} from "library/sagas/ongoing/global-data/slice";
import { fetchAutoRoutes } from "library/sagas/views/home/drawer/delivery/slice";
import {
  selectShowSessionInfo,
  selectCurrentPage,
  selectQrScanVariant,
} from "library/sagas/ongoing/global-data/selector";
import {
  selectCreateOrderShopCode,
  selectShopCodeForDraftOrder,
  selectDraftOrdersByMember,
  selectIsEditOrder,
  selectIsSubscription,
  selectDraftsToDelete,
} from "library/sagas/views/home/drawer/create-order/selector";

import { DeviceContext } from "library/contexts/appSettings";
import AppSettingsContext from "library/contexts/appSettings";

import { Entitlements, isMHQNonCoreMember } from "library/utils/entitlements";

import SubHeader from "../sub-header";
import { Breadcrumb } from "../bread-crumb";
import { CreateMenuOptions } from "../helper";
import {
  hasQRScanFeature,
  isEnabledForMember,
  hasDraftOrderFeature,
} from "library/utils/featureAvailability";
import {
  setNavParams,
  fetchDraftOrders,
  deleteDraftOrder,
  resetOrderItemTabs,
  setDraftsToDelete,
} from "library/sagas/views/home/drawer/create-order/slice";
import useStateIfMounted from "library/utils/useStateIfMounted";
import Modal from "modal-enhanced-react-native-web";
import moment from "moment";
import { macAddressBannerExists } from "library/utils/formatter";
import UserProfileContext from "library/contexts/userProfile";
import { selectedShopCodes } from "library/sagas/ongoing/current-orders/selector";
import { ORDERS } from "library/constants";

/*
 * Settings Icon in the screen header layout.
 */
const SettingsMenu = ({ settingsNavigationRoute }) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { messages, Localise } = React.useContext(I18NContext);
  const { page, screen: screenRoute } = settingsNavigationRoute;
  const { permissions } = useContext(AppSettingsContext);

  const filteredShopCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (Entitlements.SHOP_SETTINGS in permissions[memberCode])
      filteredShopCodes.push(memberCode);
  });

  if (filteredShopCodes.length < 1) return null;

  return (
    <TouchableOpacity
      style={{ paddingHorizontal: 5 }}
      onPress={() => {
        dispatch(setCurrentPage(page));
        navigation.navigate(page, { screen: screenRoute });
        if (page === "delivery-settings") {
          dispatch(fetchAutoRoutes());
        } else if (page === "create-route") {
          UserProfileStorage.setAutoRouteId(undefined);
        }
      }}
      testID="settings-redirection"
      accessibilityLabel="settings-redirection"
    >
      <Tooltip
        text={Localise(messages, "Shop Setting")}
        renderForWebOnly={true}
      >
        <Image
          style={{
            width: 28,
            height: 28,
          }}
          resizeMode="contain"
          source={IMAGES[`settings-new`]}
        />
      </Tooltip>
    </TouchableOpacity>
  );
};

const SessionMenu = ({ currentPage }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const sessionInfoId = useSelector(selectSessionId);
  const { messages, Localise } = React.useContext(I18NContext);
  return (
    <TouchableOpacity
      style={tw("flex-row items-center px-1")}
      onPress={() => {
        dispatch(setShowSessionInfo(true));
        if (currentPage !== "create-order") {
          dispatch(setCurrentPage("create-order"));
          navigation.navigate("create-order");
        }
      }}
      key={`TerminalIcon`}
      testID="TerminalIcon"
      accessibilityLabel="TerminalIcon"
    >
      <Tooltip text={Localise(messages, "Cash Drawer")} renderForWebOnly={true}>
        <Image
          style={{ width: 28, height: 28 }}
          resizeMode="cover"
          source={IMAGES["terminal"]}
        />
      </Tooltip>
      <Text
        style={{
          borderRadius: 50,
          width: 8,
          height: 8,
          backgroundColor: sessionInfoId ? "green" : "red",
          marginBottom: 15,
        }}
      ></Text>
    </TouchableOpacity>
  );
};

export const QRScanIcon = ({ currentPage, imageName = `qr_code_scan` }) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const qrScanVariant = useSelector(selectQrScanVariant);
  const { messages, Localise } = React.useContext(I18NContext);
  return (
    <TouchableOpacity
      style={{ paddingHorizontal: 5 }}
      onPress={() => {
        const orderScan = [
          "create-order",
          "orders",
          "create-event",
          "create-card",
          "events",
        ].includes(currentPage);

        if (orderScan) {
          dispatch(setQrScanVariant("order-scan"));
          dispatch(setCurrentPage("qr-code-scan"));
          navigation.navigate("qr-code-scan");
        } else {
          dispatch(
            setQrScanVariant(
              ["create-route", "edit-route"].includes(currentPage)
                ? qrScanVariant !== "order-route-scan"
                  ? "order-route-scan"
                  : ""
                : ""
            )
          );
        }
      }}
      testID="qr-scan-redirection"
      accessibilityLabel="qr-scan-redirection"
    >
      <Tooltip text={Localise(messages, "QR Scanning")} renderForWebOnly={true}>
        <Image
          style={{
            width: 28,
            height: 28,
          }}
          resizeMode="contain"
          source={IMAGES[imageName]}
        />
      </Tooltip>
    </TouchableOpacity>
  );
};

const DraftOrdersIcon = ({ currentPage }) => {
  const [showPopup, setShowPopup] = useStateIfMounted(false);

  const createOrderShopCode = useSelector(selectShopCodeForDraftOrder);
  const selectedshops = useSelector(selectedShopCodes);

  const shopCode = ["orders"].includes(currentPage)
    ? selectedshops?.map((shops) => shops["Shop Codes"]?.value)
    : createOrderShopCode;

  const draftOrders = useSelector(selectDraftOrdersByMember(shopCode));
  const { messages, Localise } = React.useContext(I18NContext);
  const openDraftOrdersList = () => {
    setShowPopup(true);
  };

  return (
    <>
      <TouchableOpacity
        onPress={openDraftOrdersList}
        testID="draft-order-icon"
        accessibilityLabel="draft-order-icon"
        style={{ paddingRight: 5 }}
      >
        <Tooltip
          text={Localise(messages, "Draft Order")}
          renderForWebOnly={true}
        >
          <Image
            style={{
              width: 32,
              height: 28,
            }}
            resizeMode="contain"
            source={IMAGES["draft-orders"]}
          />
        </Tooltip>
        {draftOrders.length > 0 && (
          <Badge
            value={draftOrders.length}
            containerStyle={{ position: "absolute", top: -10, right: -4 }}
            badgeStyle={{ backgroundColor: colors.red }}
            testID="draft-orders-count"
          />
        )}
      </TouchableOpacity>
      <DraftOrdersList
        setShowPopup={setShowPopup}
        showPopup={showPopup}
        draftOrders={draftOrders}
        currentPage={currentPage}
      />
    </>
  );
};

const DraftOrdersList = ({
  showPopup,
  setShowPopup,
  draftOrders,
  currentPage,
}) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const route = useRoute();
  const { action = "", id = "" } = route?.params || {};
  const { setParams } = navigation;
  const { messages, Localise } = useContext(I18NContext);
  const { proxyUser, suspendedMembers = [] } = useContext(UserProfileContext);
  const draftsToDelete = useSelector(selectDraftsToDelete);

  const closeDraftOrdersList = () => {
    setShowPopup(false);
  };

  const headerBannerHeight = useCallback(() => {
    const macAddressBannerHeight = macAddressBannerExists() ? 50 : 0;
    const suspendedHeaderExists = suspendedMembers.length > 0;
    let bannerHeight = proxyUser || suspendedHeaderExists ? 50 : 0;

    return bannerHeight + macAddressBannerHeight;
  }, [proxyUser, suspendedMembers, macAddressBannerExists]);

  const getDisplayText = (draftOrder = {}) => {
    const {
      order: {
        customerInfo: {
          firstName: customerFirstName = "",
          lastName: customerLastName = "",
          isBusinessProfile = false,
          businessName = "",
        } = {},
        orderItems: [orderItem] = [],
      } = {},
      updatedOn,
    } = draftOrder;

    const {
      recipientInfo: {
        firstName: recipientFirstName = "",
        lastName: recipientLastName = "",
      } = {},
    } = orderItem || {};

    const customerName = isBusinessProfile
      ? businessName
      : `${customerFirstName} ${customerLastName}`.trim();

    const recipientName = `${recipientFirstName} ${recipientLastName}`.trim();

    const formattedDateTime = moment
      .utc(updatedOn)
      .local()
      .format("MM/DD/YYYY, hh:mm A");

    return customerName
      ? `Customer ${customerName} order saved on ${formattedDateTime}`
      : recipientName
      ? `Order for Recipient ${recipientName} saved on ${formattedDateTime}`
      : `Order saved on ${formattedDateTime}`;
  };

  const opendraftOrder = ({ draftOrderID, memberCode }) => {
    const params = {
      id: draftOrderID,
      action: "draft",
      smc: memberCode,
      type: undefined,
    };
    dispatch(setCurrentPage("create-order"));
    closeDraftOrdersList();
    dispatch(setNavParams({ ...params }));
    navigation.dispatch(
      CommonActions.navigate({
        name: "create-order",
        params,
      })
    );
  };

  const deleteDraft = (order) => {
    const { draftOrderID, memberCode } = order;
    dispatch(
      deleteDraftOrder({
        params: { draftOrderID, sendingMember: memberCode },
        resolve: (response) => {
          if (currentPage === "create-order") {
            dispatch(resetOrderItemTabs());
            if (action === "draft" && id === draftOrderID) {
              setParams({
                action: undefined,
                id: undefined,
                smc: undefined,
                dm: undefined,
              });
            }
          }
          dispatch(
            setDraftsToDelete(
              draftsToDelete.filter((id) => id !== draftOrderID)
            )
          );
          ToasterHandler(
            "success",
            Localise(
              messages,
              response?.message
                ? response?.message
                : ORDERS.DRAFT_DELETE_SUCCESS_MESSAGE
            )
          );
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(messages, ORDERS.DRAFT_DELETE_FAILURE_MESSAGE)
          );
        },
      })
    );
  };

  return (
    <Modal visible={showPopup} onBackdropPress={closeDraftOrdersList}>
      <TouchableWithoutFeedback onPress={closeDraftOrdersList}>
        <View
          style={{ ...tw("flex-1 items-end"), top: headerBannerHeight() + 50 }}
        >
          <TouchableWithoutFeedback>
            <View style={{ ...shapes.sectionBorder, width: 350, padding: 0 }}>
              <View
                style={[
                  tw("flex flex-row justify-between items-center"),
                  { padding: 15 },
                ]}
              >
                <View style={[tw("flex flex-row items-center"), { gap: 4 }]}>
                  <Text
                    style={fonts.sectionHeading}
                    testID="draft_orders_title"
                    accessibilityLabel="draft_orders_title"
                  >
                    {Localise(messages, ORDERS.DRAFT_TITLE)}
                  </Text>
                  <Tooltip
                    text={`${Localise(messages, ORDERS.DRAFT_INFO_TEXT)}`}
                  >
                    <Icon
                      name="info-outline"
                      size={20}
                      iconStyle={{
                        color: colors.button,
                      }}
                    />
                  </Tooltip>
                </View>

                <TouchableOpacity
                  onPress={closeDraftOrdersList}
                  testID="close_draft_orders_list"
                  accessibilityLabel="close_draft_orders_list"
                >
                  <Image source={IMAGES["cancel"]} style={tw("w-5 h-5")} />
                </TouchableOpacity>
              </View>
              <View
                style={{
                  paddingLeft: 15,
                  paddingBottom: 15,
                  paddingRight: 5,
                }}
              >
                {draftOrders.length === 0 ? (
                  <View
                    style={{
                      ...tw("items-center justify-center rounded-sm p-3"),
                      ...shapes.sectionBorder,
                      marginRight: 10,
                    }}
                    testID="empty_draft_orders_list"
                    accessibilityLabel="empty_draft_orders_list"
                  >
                    <Text style={tw("font-bold")}>
                      {Localise(messages, ORDERS.DRAFT_EMPTY_TEXT)}
                    </Text>
                    <Text style={tw("text-justify")}>
                      {Localise(messages, ORDERS.DRAFT_EMPTY_DESCRIPTION)}
                    </Text>
                  </View>
                ) : (
                  <ScrollView
                    style={{
                      maxHeight: 230,
                      paddingRight: draftOrders.length > 10 ? 5 : 10,
                    }}
                  >
                    {draftOrders.map((order) => (
                      <View
                        key={order.draftOrderID}
                        style={[
                          shapes.sectionBorder,
                          tw("flex flex-row my-1 rounded-sm p-2"),
                        ]}
                      >
                        <View style={[tw("w-10/12 justify-center")]}>
                          <TouchableOpacity
                            onPress={() => {
                              opendraftOrder(order);
                            }}
                            testID="open_draft_order"
                            accessibilityLabel="open_draft_order"
                            disabled={draftsToDelete.includes(
                              order.draftOrderID
                            )}
                          >
                            <Text>{getDisplayText(order)}</Text>
                          </TouchableOpacity>
                        </View>

                        <View style={tw("w-2/12 items-end")}>
                          {draftsToDelete.includes(order.draftOrderID) ? (
                            <ActivityIndicator
                              style={{ marginLeft: 10 }}
                              color={colors.activityIndicator}
                            />
                          ) : (
                            <TouchableOpacity
                              onPress={() => {
                                deleteDraft(order);
                              }}
                              disabled={draftsToDelete.includes(
                                order.draftOrderID
                              )}
                              testID="delete_draft_order"
                              accessibilityLabel="delete_draft_order"
                            >
                              <Image
                                style={tw("w-5 h-5")}
                                source={IMAGES["delete"]}
                              />
                            </TouchableOpacity>
                          )}
                        </View>
                      </View>
                    ))}
                  </ScrollView>
                )}
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

/*
 * Create Icon in the screen header layout.
 */
export const CreateMenu = ({ createNavigationRoute, hasAccessToCreate }) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { messages, Localise } = React.useContext(I18NContext);

  if (createNavigationRoute === "" || !hasAccessToCreate) return null;
  const tooltipMessage =
    createNavigationRoute === "create-order"
      ? "Create Order"
      : createNavigationRoute === "create-event"
      ? "Create Event"
      : "";
  return (
    <TouchableOpacity
      style={{ paddingHorizontal: 5 }}
      onPress={() => {
        dispatch(setCurrentPage(createNavigationRoute));
        dispatch(setShowSessionInfo(false));
        //Need to recheck to pass empty params
        navigation.navigate({
          name: createNavigationRoute,
          params: { action: "", id: "" },
        });
      }}
      testID="create-redirection"
      accessibilityLabel="create-redirection"
    >
      <Tooltip
        text={Localise(messages, tooltipMessage)}
        renderForWebOnly={true}
      >
        <Image
          style={{
            width: 28,
            height: 28,
          }}
          resizeMode="contain"
          source={IMAGES[`create`]}
        />
      </Tooltip>
    </TouchableOpacity>
  );
};

/*
 * Kebab Icon in the screen header layout.
 */
export const KebabMenu = ({
  hasAccessToCreate,
  hasAccessToCreateEvent,
  currentPage,
}) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  if (!hasAccessToCreate) return null;
  const menuOptions = CreateMenuOptions(currentPage, hasAccessToCreateEvent);

  const onCreateAction = (key, menuOptions) => {
    const { route } = menuOptions.find((e) => e.key === key);
    dispatch(setCurrentPage(route));
    dispatch(setShowSessionInfo(false));
    navigation.navigate(route);
  };

  return (
    <PopupMenu
      containerStyle={tw("ml-1 mr-2 items-end")}
      optionsContainerStyle={tw("p-1 m-8")}
      menuOptions={menuOptions}
      onSelect={(val) => onCreateAction(val, menuOptions)}
      testID="list_create_menu_screen_header"
      accessibilityLabel="list_create_menu_screen_header"
    >
      <Image
        style={{
          width: 11,
          height: 28,
        }}
        resizeMode="contain"
        source={IMAGES[`kebab_menu_icon`]}
      />
    </PopupMenu>
  );
};

const ScreenHeader = ({
  title,
  createRoute = "",
  settingsRoute,
  siblings,
  sideCarIdentifier,
  setSideCarIdentifier,
  recordAction,
  setRecordAction,
  listingType,
  setShowQRScan = true,
  showKebabMenu = true,
  loader,
}) => {
  const { isTablet, isDesktop, isMobile } = useContext(DeviceContext);
  const { permissions } = useContext(AppSettingsContext);
  const dispatch = useDispatch();
  const showSessionInfo = useSelector(selectShowSessionInfo);
  //const sessionId = useSelector(selectSessionId);
  const currentPage = useSelector(selectCurrentPage);
  const isEditOrder = useSelector(selectIsEditOrder);
  const isSubscription = useSelector(selectIsSubscription);
  const shopCode = useSelector(selectCreateOrderShopCode);
  const isMHQNonCoreUser = isMHQNonCoreMember(shopCode);
  const showEventsPlanner = isEnabledForMember(
    "ENABLE_EVENTS_PLANNER",
    shopCode
  );

  const entitlement =
    currentPage === "events"
      ? Entitlements.CREATE_EVENT
      : Entitlements.CREATE_ORDER;

  const filteredShopCodes = [];
  const eventEligibleShopCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (entitlement in permissions[memberCode])
      filteredShopCodes.push(memberCode);
    if (Entitlements.CREATE_EVENT in permissions[memberCode])
      eventEligibleShopCodes.push(memberCode);
  });

  const hasAccessToCreate =
    ["create-route", "create-delivery-service-request"].includes(createRoute) ||
    filteredShopCodes.length >= 1;

  const hasAccessToCreateEvent =
    showEventsPlanner && isDesktop && eventEligibleShopCodes.length >= 1;

  const customCreateRoute = showSessionInfo
    ? currentPage === "create-order"
      ? "create-order"
      : currentPage === "create-event"
      ? "create-event"
      : ""
    : "";

  let sideCarTitle = "";
  let sideCarKey;
  if (
    recordAction &&
    recordAction.includes("selectNewFlorist") &&
    recordAction.split("_")[2] === sideCarIdentifier
  ) {
    sideCarTitle = "Order Detail";
    sideCarKey = "BackToOrderDetail";
  } else if (
    sideCarIdentifier &&
    sideCarIdentifier.includes("createStaffProfile")
  ) {
    sideCarTitle = "Staff Management";
    sideCarKey = "BackToListing";
  } else if (sideCarIdentifier && listingType !== "deliveryTasks") {
    sideCarTitle = listingType === "staff" ? `Staff Management` : `Orders`;
    sideCarKey = "BackToListing";
  } else if (sideCarIdentifier === "additional_info") {
    sideCarTitle = "Create Order";
    sideCarKey = "BackToCreateOrder";
  } else {
    sideCarTitle = "";
    sideCarKey = "";
  }

  const subHeaderProps = {
    showShadow: false,
    showNavText: isMobile ? false : true,
    title: sideCarTitle,
    ...(sideCarKey !== "" && {
      onTrigger: () => {
        if (sideCarKey === "BackToOrderDetail") {
          setRecordAction(sideCarIdentifier);
        } else if (
          ["BackToListing", "BackToCreateOrder"].includes(sideCarKey)
        ) {
          setSideCarIdentifier();
        }
      },
    }),
  };

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  // If no shop has access to this then don't show this component.
  const showQRScan =
    setShowQRScan &&
    currentPage !== "qr-code-scan" &&
    hasQRScanFeature(permissions);

  const hasDraftFeature = hasDraftOrderFeature(permissions);
  const showDraftOrders = useMemo(
    () =>
      Platform.OS === "web" &&
      ["create-order", "orders"].includes(currentPage) &&
      hasDraftFeature &&
      !isEditOrder &&
      !isSubscription,
    [currentPage, permissions, isEditOrder, isSubscription]
  );

  useEffect(() => {
    if (hasDraftFeature && ["create-order", "orders"].includes(currentPage)) {
      dispatch(fetchDraftOrders());
    }
  }, []);

  return (
    <View
      style={[
        tw("flex flex-row items-center justify-between mb-1"),
        {
          height: 60,
          ...(Platform.OS === "web" && {
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.4,
            shadowRadius: 7,
            shadowColor: colors.medium,
          }),
          borderBottomColor: colors.grayScaleLight,
          borderBottomWidth: 1,
        },
      ]}
    >
      <View style={tw("flex flex-row")}>
        {title === "Help" && !isDesktop ? (
          <Text
            style={{
              ...fonts.heading1,
              ...(isTablet && { fontSize: 16, fontWeight: "400" }),
              paddingLeft: 15,
            }}
          >
            {title}
          </Text>
        ) : (
          <>
            <SubHeader {...subHeaderProps} showShadow={false} />
            {isDesktop && (
              <View style={tw("flex")}>
                <View style={tw("flex-row")}>
                  <Text
                    style={{
                      ...fonts.heading1,
                      ...(isTablet && { fontSize: 16, fontWeight: "400" }),
                      paddingLeft: 15,
                    }}
                  >
                    {title}
                  </Text>
                  {loader && loader()}
                </View>
                <Breadcrumb />
              </View>
            )}
          </>
        )}
      </View>
      <View style={tw("flex flex-row items-center mr-4")}>
        <CreateMenu
          createNavigationRoute={createRoute || customCreateRoute}
          hasAccessToCreate={hasAccessToCreate}
        />
        {showDraftOrders && <DraftOrdersIcon currentPage={currentPage} />}
        {showQRScan ? <QRScanIcon currentPage={currentPage} /> : null}
        {isElectronApp && isMHQNonCoreUser && (
          <SessionMenu currentPage={currentPage} />
        )}
        {settingsRoute ? (
          <SettingsMenu settingsNavigationRoute={settingsRoute} />
        ) : null}
        {showKebabMenu && (
          <KebabMenu
            hasAccessToCreate={hasAccessToCreate}
            currentPage={currentPage}
            hasAccessToCreateEvent={hasAccessToCreateEvent}
          />
        )}
        {siblings && siblings()}
      </View>
    </View>
  );
};

export default ScreenHeader;
