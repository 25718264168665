import * as Yup from "yup";

export const getValidationSchema = (Localise, messages) =>
  Yup.object().shape({
    creditLimit: Yup.string()
      .required(Localise(messages, "Please enter Credit Limit"))
      .matches(/^\d+\.?\d*$/, Localise(messages, "Invalid Credit Limit")),
    invoicePreference: Yup.string().required(
      Localise(messages, "Please select Invoice Preference")
    ),
    statementPreference: Yup.string().required(
      Localise(messages, "Please select Statement Preference")
    ),
  });
