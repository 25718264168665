import { put, takeLatest, takeEvery, call, select } from "redux-saga/effects";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import AppSettingsStorage from "library/storage/appSettings";
import { hasQRScanFeature } from "library/utils/featureAvailability";
import { request } from "library/utils/request";

import {
  fetchOrderData,
  fetchAllOrdersData,
  setApiResponse,
  setPageInitProgress,
  setPageInitSuccess,
  setApiError,
  generateQRCode,
  setQrCode,
  openOrderFromScan,
  setOrderDetailsFromScan,
  lockOrder,
  lockStatus,
  fetchRefundPreview,
  setRefundPreview,
  setRefundPreviewInitProgress,
  setRefundPreviewInitSuccess,
  modifyOrder,
} from "./slice";
import { selectQrCode, selectApiResponse } from "./selector";
import { selectImages } from "library/sagas/ongoing/global-data/selector";
import {
  fetchImageData,
  setCurrentPage,
} from "library/sagas/ongoing/global-data/slice";
import {
  setRecordData,
  setActiveTab,
  setRecordId,
  setDeliveryMethod,
  setSourceMemberCode,
} from "library/sagas/ongoing/current-orders/slice";

import UserProfileStorage from "library/storage/userProfile";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";

import { compare as generateJSONPatch } from "fast-json-patch";
import { isLocalOrder } from "library/utils/orderListing";

import { formatPrice } from "library/utils/formatter";
import * as Navigation from "library/utils/navigation.js";

const orderDetailsServiceRequest = (params) => request("order-details", params);
const generateQRCodeServiceRequest = (params) =>
  request("generate-qr-code", params);
const lockOrderServiceRequest = (params) => request("lock-order", params);
const lockStatusServiceRequest = (params) =>
  request("get-order-lock-status", params);
const refundPreviewServiceRequest = (params) =>
  request("get-refund-preview", params);

function* handleFetchOrderDetails(action = {}) {
  const params = get(action, "payload", {});
  const {
    refreshDetails,
    resolve = () => {},
    reject = () => {},
    ...rest
  } = params;

  try {
    if (refreshDetails) yield put(setPageInitProgress());

    const res = yield call(orderDetailsServiceRequest, rest);
    const imagesData = yield select(selectImages);
    if (!isEmpty(res)) {
      if (res && !isEmpty(res.cardInfo)) {
        const { isLogoOpted, isBannerOpted, logoImageURL, bannerImageURL } =
          JSON.parse(res.cardInfo.cardSettings);
        let imgURL;
        if (isLogoOpted) imgURL = logoImageURL;
        else if (isBannerOpted) imgURL = bannerImageURL;
        if (imgURL && !imagesData[imgURL]) yield put(fetchImageData(imgURL));
      }

      if (res && res.orderItems) {
        const { price = [], hasSettlementError = false } =
          res.orderItems[0] || {};

        const amountChargedToCustomer =
          res?.orderAmounts.find(
            (obj) => obj.name === "amountChargedToCustomer"
          )?.value || 0;

        const priceIncludingDelivery =
          price.find((obj) => obj.name === "orderTotal")?.value || 0;

        let refundAvailable = 0;

        if (res?.paymentDetails) {
          const { paymentDetails = {} } = res;
          const paymentMethods = get(paymentDetails, "paymentMethod", []);

          paymentMethods.map((each) => {
            const {
              paymentMethodDetails: { authorizationDetails, refundDetails },
            } = each;

            if (!isEmpty(authorizationDetails) && !hasSettlementError) {
              refundAvailable += parseFloat(
                authorizationDetails?.find((obj) => obj.name === "amount")
                  ?.value || 0
              );
            }

            if (!isEmpty(refundDetails)) {
              refundDetails.map((each) => {
                refundAvailable -= parseFloat(each.amount);
              });
            }
          });
        }

        const { orderItems } = res;
        if (res?.promoCode) {
          orderItems[0].promoCode = res.promoCode;
        }
        const orderRes = {
          ...res,
          orderItems,
          orderTotalPrice: parseFloat(amountChargedToCustomer),
          priceIncludingDelivery,
          refundAvailable: formatPrice(refundAvailable),
        };

        yield put(setRecordData(orderRes));
        yield put(setApiResponse(orderRes));
        yield put(setPageInitSuccess());
        if (params?.responseMsg?.length) {
          yield put(setActiveTab("order-summary"));
          params.scrollToEndOfPage();
        }
        resolve && resolve(orderRes);
      }
    }
  } catch (error) {
    yield put(setApiError(error));
    reject && reject("Failed to fetch order details. Please try again.");
    console.log("Failed to fetch Order Details - ", error);
  }
}

function* handleGenerateQRCode(action = {}) {
  const {
    params,
    type,
    resolve,
    reject,
    qrType = "ORDER",
  } = get(action, "payload", {});
  const { qrPayload = [] } = params;
  const permissions = AppSettingsStorage.getAllPermissions();
  const hasQRScanPermission = hasQRScanFeature(permissions);
  try {
    const qrImageData = yield select(selectQrCode);

    const checkForExistance = () => {
      const dataExists = qrPayload.every((each) => qrImageData[each.id]);
      return !dataExists;
    };

    if (!hasQRScanPermission) {
      reject && reject();
      return;
    }

    if (
      (type === "SINGLE" && isEmpty(qrImageData[qrPayload[0].oid])) ||
      (type === "BULK" &&
        ((qrType === "ORDER_ACTIONS" && checkForExistance()) ||
          qrType === "ORDER"))
    ) {
      const res = yield call(generateQRCodeServiceRequest, params);
      yield put(setQrCode(res));
      resolve && resolve(res);
    } else {
      resolve && resolve(qrImageData);
    }
  } catch (error) {
    console.log("failed to generate QR Code");
    reject && reject();
  }
}

function* handleOpenOrderFromScan(action = {}) {
  const { params = {}, resolve, reject } = get(action, "payload", {});
  const memberCodes = UserProfileStorage.getProfileMemberCodes();

  try {
    const {
      dm: deliveryMethod = "",
      sm: sourceMemberCode = "",
      oid: orderItemId = "",
    } = params;

    if (deliveryMethod && sourceMemberCode && orderItemId) {
      if (memberCodes.includes(sourceMemberCode)) {
        // setting recordID, deliveryMethod and SourceMemberCode from QR code value
        yield put(setRecordId(orderItemId));
        yield put(setDeliveryMethod(deliveryMethod));
        yield put(setSourceMemberCode(sourceMemberCode));
        yield put(setCurrentPage("orders"));
        yield put(setOrderDetailsFromScan(true));
        resolve && resolve();
        // Redirecting to orders page.
        yield Navigation.navigate({
          name: "orders",
        });
      } else {
        reject && reject("You don’t have access to this order");
      }
    } else {
      reject && reject("QR code is not from MercuryHQ");
    }
  } catch (error) {
    reject && reject("Failed to open the order from scan, Please try again!");
  }
}

function* handleLockingOrder(action = {}) {
  const { params, resolve, reject } = get(action, "payload", {});

  try {
    const res = yield call(lockOrderServiceRequest, params);
    resolve && resolve(res);
  } catch (error) {
    console.log("failed to lock the order", error);
    reject && reject();
  }
}

function* handleLockStatus(action = {}) {
  const { params, resolve, reject } = get(action, "payload", {});

  try {
    const res = yield call(lockStatusServiceRequest, params);
    resolve && resolve(res);
  } catch (error) {
    console.log("failed to lock status", error);
    reject && reject();
  }
}

function* handleFetchRefundPreview(action = {}) {
  const { params, resolve, reject } = get(action, "payload", {});

  try {
    yield put(setRefundPreviewInitProgress());
    const res = yield call(refundPreviewServiceRequest, params);
    res.refundTransactions.forEach((transaction) => {
      transaction.originalRefundAmount = transaction.refundAmount;
    });
    yield put(setRefundPreview(res));
    yield put(setRefundPreviewInitSuccess());
    resolve && resolve(res);
  } catch (error) {
    console.log("failed to generate QR Code");
    reject && reject();
  }
}

export function* handleModifyOrder({ payload }) {
  const { resolve, reject, params } = payload;

  const modifyServiceRequest = (params) => request("modify-order", params);

  try {
    const orderDetailsRes = yield select(selectApiResponse);
    // order modification will happen only on the single order item so keeping 0 as index
    const orderItemId = get(orderDetailsRes, `orderItems.0.orderItemId`, "");
    const deliveryMethod = get(
      orderDetailsRes,
      `orderItems.0.deliveryInfo.deliveryMethod`,
      ""
    );

    const updatedOrderDetailsResponse = cloneDeep(orderDetailsRes);
    // setting latest values based on config
    Object.keys(params).forEach((each) => {
      const obj = params[each];
      set(updatedOrderDetailsResponse, obj.path, obj.value);
    });
    // Generating patch
    const orderUpdates = generateJSONPatch(
      orderDetailsRes,
      updatedOrderDetailsResponse
    );

    const localOrder = isLocalOrder(deliveryMethod);

    // Incase of localOrder, lock it and then modify.
    if (localOrder) {
      const lockResponse = yield call(lockOrderServiceRequest, {
        recordId: orderItemId,
        deliveryMethod,
      });

      if (lockResponse?.status?.toLowerCase() === "success") {
        const response = yield call(modifyServiceRequest, {
          recordId: orderItemId,
          deliveryMethod,
          orderUpdates,
        });
        resolve && resolve(response);
      } else {
        reject && reject();
      }
    } else {
      const response = yield call(modifyServiceRequest, {
        recordId: orderItemId,
        deliveryMethod: deliveryMethod,
        orderUpdates,
      });
      resolve && resolve(response);
    }
  } catch (error) {
    console.log("Error: ", error);
    reject && reject(error);
  }
}

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
export function* watchSaga() {
  yield takeLatest(fetchOrderData.type, handleFetchOrderDetails);
  yield takeEvery(fetchAllOrdersData.type, handleFetchOrderDetails);
  yield takeLatest(generateQRCode.type, handleGenerateQRCode);
  yield takeLatest(openOrderFromScan.type, handleOpenOrderFromScan);
  yield takeLatest(lockOrder.type, handleLockingOrder);
  yield takeLatest(lockStatus.type, handleLockStatus);
  yield takeLatest(fetchRefundPreview.type, handleFetchRefundPreview);
  yield takeLatest(modifyOrder.type, handleModifyOrder);
}

export default watchSaga;
