import React, { useContext, useMemo } from "react";
import { View, Platform } from "react-native";
import { Text, Icon } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { DeviceContext } from "library/contexts/appSettings";
import tw from "tailwind-rn";
import get from "lodash/get";
import moment from "moment";
import { colors, backgroundColors } from "styles/theme";
import { CustomModal, ToasterHandler } from "components/elements";
import {
  frequency,
  subscriptionEnds,
} from "components/views/drawer/create-subscription/helper";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";

import { deleteSubscriptionTemplate } from "library/sagas/views/home/drawer/customer-directory/slice";
import { selectCustomerDetails } from "library/sagas/views/home/drawer/customer-directory/selector";
import { setNavParams } from "library/sagas/views/home/drawer/create-order/slice";
import ItemActions from "./item-actions";

const SubscriptionItem = ({ data }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const customerDetails = useSelector(selectCustomerDetails);
  const { isDesktop } = useContext(DeviceContext);

  const [showSubscriptionModal, setShowSubscriptionModal] =
    useStateIfMounted("");
  const { messages, Localise } = useContext(I18NContext);

  const {
    pause = [],
    schedule: { lastDeliveryDate },
  } = data || {};
  const activePause = pause.find(({ status }) => status === "ACTIVE");

  const cancelSubscription = () => {
    const { memberCode: shopCode, customerId, id: subscriptionId } = data;

    dispatch(
      deleteSubscriptionTemplate({
        params: { shopCode, customerId, subscriptionId },
        resolve: () => {
          ToasterHandler(
            "success",
            Localise(messages, "Subscription canceled")
          );
        },
        reject: () => {
          ToasterHandler(
            "error",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
        },
      })
    );
  };

  const redirectToSubscriptionScreen = (action) => {
    // setting the navParams prior to actual redirection so that i can decide the flow from first render itself.
    dispatch(setNavParams({ action: "edit", type: "subscription" }));
    const isModify = action === "Modify";
    navigation.dispatch(
      CommonActions.navigate({
        name: "create-order",
        params: {
          customerDetails,
          subscriptionData: { ...data, isModify },
          type: "subscription",
          action: isModify ? "edit" : "copy",
        },
      })
    );
  };

  const ShowSubscriptionModal = ({ testId }) => {
    const modalContent = {
      content: (
        <View style={tw("flex items-center justify-center")}>
          <Text
            style={{
              ...tw("p-2"),
              fontSize: 18,
              fontWeight: "bold",
              color: colors.dark,
            }}
          >
            {Localise(
              messages,
              `${
                showSubscriptionModal === "Cancel" ? "Cancel" : "Edit"
              } Subscription`
            )}
          </Text>

          <Text
            style={{
              ...tw("p-4"),
              fontSize: 15,
              color: colors.dark,
            }}
          >
            {Localise(
              messages,
              `This will ${
                showSubscriptionModal === "Cancel" ? "cancel" : "modify"
              } all future deliveries`
            )}
          </Text>
          <Text
            style={{
              fontSize: 14,
              color: colors.dark,
            }}
          >
            {Localise(messages, `Do you want to continue?`)}
          </Text>
        </View>
      ),
      buttons: [
        { type: "primary", title: Localise(messages, "Nevermind") },
        { type: "secondary", title: Localise(messages, "Continue") },
      ],
    };

    return (
      <CustomModal
        modalVisible={showSubscriptionModal?.length > 0}
        modalContent={modalContent}
        testID={`${testId}_modal`}
        primaryhandler={() => {
          setShowSubscriptionModal("");
        }}
        secondaryhandler={() => {
          setShowSubscriptionModal("");

          if (showSubscriptionModal === "Cancel") cancelSubscription();

          if (showSubscriptionModal === "Modify")
            redirectToSubscriptionScreen(showSubscriptionModal);
        }}
        contentStyle={[
          tw("p-4"),
          {
            backgroundColor: colors.buttonText,
            width: "100%",
            borderWidth: 2,
            borderColor: colors.button,
            borderRadius: 4,
          },
        ]}
        modalStyle={
          Platform.OS !== "web"
            ? {
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#00000070",
                color: backgroundColors.secondary,
              }
            : { width: "35%" }
        }
      ></CustomModal>
    );
  };

  const onAction = (menuKey) => {
    if (["Cancel", "Modify"].includes(menuKey)) {
      setShowSubscriptionModal(menuKey);
    } else {
      redirectToSubscriptionScreen(menuKey);
    }
  };

  const subscriptionInfo = get(data, "schedule.repeat.0");

  const occasion = get(
    data,
    "orderTemplate.orderItems.0.deliveryInfo.occasion",
    ""
  );
  let occasionLabel = (occasion && occasion.replace("_", " ")) || "";
  occasionLabel =
    occasionLabel?.charAt(0).toUpperCase() +
    occasionLabel?.slice(1).toLowerCase();

  const statusColor =
    data.status === "PAUSED"
      ? "#6c0057"
      : data.status === "INACTIVE"
      ? "#c4c4c4"
      : "#17aee5";

  const config = useMemo(
    () => [
      {
        details: [
          { label: Localise(messages, "Occasion"), value: occasionLabel },
          {
            label: Localise(messages, "First Delivery Date"),
            value: moment(subscriptionInfo.firstDeliveryDate).format(
              "MM/DD/YYYY"
            ),
          },
        ],
      },
      {
        details: [
          {
            label: Localise(messages, "Frequency"),
            value:
              frequency.find(
                (each) => each.value === subscriptionInfo.frequency
              )?.label || "",
          },
          {
            label: Localise(messages, "Ends"),
            value:
              subscriptionEnds.find(
                (each) => each.value === subscriptionInfo.ends
              )?.label || "Never",
          },
        ],
      },
    ],
    [Localise, messages, occasionLabel, subscriptionInfo]
  );

  return (
    <View
      key={data.id}
      style={{
        flexDirection: "row",
        borderWidth: 1,
        borderColor: colors.grayScaleLight,
        marginVertical: 7,
        padding: 4,
        width: "100%",
      }}
      testID={data?.id}
    >
      <View
        style={{
          width: 8,
          backgroundColor: statusColor,
          marginRight: isDesktop ? 20 : 8,
        }}
      />
      <View
        style={{
          flexDirection: isDesktop ? "row" : "column",
          flexWrap: "wrap",
          paddingVertical: 7,
          zIndex: 10,
          width: "90%",
        }}
        pointerEvents={"none"}
      >
        {config.map((rowConfig, index) => (
          <View
            key={index}
            style={{
              ...tw("flex flex-row"),
              width: isDesktop ? "50%" : "100%",
            }}
          >
            {rowConfig.details.map((detail, idx) => (
              <View key={idx} style={{ ...tw("flex flex-col"), width: "49%" }}>
                <Text style={tw("font-semibold pb-1")}>{detail.label}</Text>
                <Text style={tw("text-sm pb-1")}>{detail.value}</Text>
              </View>
            ))}
          </View>
        ))}
        <View
          style={{
            ...tw("flex flex-row mt-2"),
            width: "100%",
          }}
        >
          {(activePause || lastDeliveryDate) && (
            <Icon
              name="info"
              type="simple-line-icon"
              color={colors.primary}
              size={12}
            />
          )}
          <Text
            style={{
              paddingLeft: 5,
            }}
          >
            {activePause
              ? `Your subscription is paused from ${moment(
                  activePause.startDate
                ).format("MM/DD/YYYY")} to ${moment(activePause.endDate).format(
                  "MM/DD/YYYY"
                )}`
              : lastDeliveryDate
              ? `Your last recurring order for ${moment(
                  lastDeliveryDate
                ).format("MM/DD/YYYY")} is created.`
              : null}
          </Text>
        </View>
      </View>
      <ItemActions onSelect={onAction} data={data} />
      <ShowSubscriptionModal testId={data?.id} />
    </View>
  );
};

export default SubscriptionItem;
