import React, { useEffect, useContext } from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";

import tw from "tailwind-rn";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import last from "lodash/last";
import filter from "lodash/filter";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import { useFormikContext } from "formik";

import { backgroundColors } from "styles/theme";

import {
  selectRecordData,
  selectOrderLocked,
} from "library/sagas/ongoing/current-orders/selector";

import TakeAction from "components/views/drawer/order-details/order-head/take-action";
import { getOrderStatus } from "../order-breadcrumb/helper";
import { hasPaymentProcessingError } from "components/views/drawer/order-details/helper";
import { selectShowOrderUnpaidModal } from "library/sagas/ongoing/order-details/selector";
import { orderDsRequestRerouting } from "library/utils/orderListing";
import { AppSettingsContext } from "library/contexts/appSettings";
import { hasDesignCenterFeature } from "library/utils/featureAvailability";

const TakeActionContainer = React.memo(
  ({
    index,
    listingType,
    isSmallScreen,
    isMobile,
    actionTriggered,
    triggerAction,
    triggerRequest,
    actionsList,
    subActionsList,
    actionCallback,
    menuAction,
    doNotFill,
    sourceMemberCode,
    isActionProcessing,
    isPickUpOrder = false,
    setMenuAction,
    showDriverAction = false,
    orderRefundStatus,
    setOrderRefundStatus,
    scrollViewRef,
    tabDetailScrollRef,
    paymentDetails,
    customerInfo,
    cardInfo,
    imagesData,
    selectedAction,
    setSelectedAction,
    isCancelDSModal,
    setShowCancelDSModal,
    showReasonForm,
    setShowReasonForm,
    showPayment,
    setShowDconForm,
  }) => {
    const orderDetailResponse = useSelector(selectRecordData);
    const showOrderUnpaidModal = useSelector(selectShowOrderUnpaidModal);
    const isOrderLocked = useSelector(selectOrderLocked);
    const { setFieldValue } = useFormikContext();
    const { permissions } = useContext(AppSettingsContext);

    const orderDetails = {
      ...orderDetailResponse.orderItems[index],
      priceIncludingDelivery: orderDetailResponse.priceIncludingDelivery || 0,
      orderAmounts: orderDetailResponse.orderAmounts,
    };

    const {
      status,
      direction,
      messages,
      deliveryInfo: { deliveryDate, deliveryMethod = "" },
      hasFulfillmentError = false,
      hasDSFulfillmentError = false,
      hasPendingPayLaterPayment = false,
      hasSettlementError,
      orderSource,
      paymentItemStatus = "",
      giftCardType = "",
      designer: { id: designerID = "" } = {},
    } = orderDetails;

    const hasDesignCenter = hasDesignCenterFeature(
      permissions,
      sourceMemberCode
    );

    // const [showReasonForm, setShowReasonForm] = useStateIfMounted(true);
    // const [isCancelDSModal, setShowCancelDSModal] = useStateIfMounted(false);

    const relatedMessages = filter(
      messages,
      (message) => message.messageType === "Cancel"
    );
    const latestMessage = last(relatedMessages);
    const {
      direction: messageDirection,
      requestStatus,
      id: askId,
    } = latestMessage || {};
    const isCancelPendingApproval =
      messageDirection === "INBOUND" && requestStatus === "WAITING";

    const isOutBoundCancelDenied =
      messageDirection === "OUTBOUND" && requestStatus === "DENIED";

    const internalStatus = getOrderStatus(
      { status, isPickUpOrder },
      listingType
    );

    let internalSubStatus = "";

    if (
      orderDetails.numOfUnreadChatMessages > 0 ||
      orderDetails.numOfUnreadStructuredResponses > 0
    )
      internalSubStatus = "view-chats";
    else if (orderDetails.numOfReadChatMessages > 0)
      internalSubStatus = "review-chats";

    const showActions = ["rejected", "error"].includes(internalStatus)
      ? hasFulfillmentError
      : true;

    let showAdjustmentaction =
      moment().isAfter(moment(deliveryDate)) && // Is past delivery date
      moment().isBefore(moment(deliveryDate).add(90, "days")) && // past 90 days window
      moment(deliveryDate).month() !== moment().month(); // only past month

    const orderSubActionsList =
      (internalSubStatus && subActionsList[internalSubStatus]) || [];
    const orderSubActions = cloneDeep(orderSubActionsList);
    const dsRerouteInProgress = orderDsRequestRerouting(
      pick(orderDetails, ["latestDSRequestedStatus"])
    );
    if (
      hasFulfillmentError ||
      hasDSFulfillmentError ||
      dsRerouteInProgress ||
      hasSettlementError
    ) {
      const markAsUnreadIndex = orderSubActions.findIndex(
        (action) => action.key === "markAsRead"
      );
      orderSubActions.splice(markAsUnreadIndex, 1);
    }

    let orderActions =
      (direction === "INBOUND"
        ? hasDSFulfillmentError || dsRerouteInProgress
          ? actionsList["dsfullfillmenterror"]
          : status !== "FORWARDED"
          ? actionsList[
              hasSettlementError ? "settlement-error" : internalStatus
            ]
          : []
        : deliveryMethod === "PHONE_OUT"
        ? actionsList[internalStatus]
        : internalStatus !== "cancelled" &&
          !isOutBoundCancelDenied &&
          showActions
        ? actionsList["outgoing"]
        : []) || [];

    orderActions =
      ([
        "cancelled",
        "error",
        "forfeited",
        "rejected",
        "cancelled-pickup",
      ].includes(internalStatus) ||
        hasSettlementError) &&
      !orderActions.find((e) => e.key === "copy-order")
        ? orderActions.concat([{ key: "copy-order", label: "Copy Order" }])
        : orderActions;

    orderActions =
      direction === "INBOUND" &&
      hasDesignCenter &&
      ["accepted", "design", "accepted-pickup", "design-pickup"].includes(
        internalStatus
      ) &&
      !hasSettlementError &&
      designerID === ""
        ? orderActions.concat([
            { key: "assign-to-designer", label: "Assign to Designer" },
          ])
        : orderActions;

    let finalOrderActions = cloneDeep(orderActions);

    if (
      orderSource === "MOL" ||
      deliveryMethod === "FLORIST_DELIVERED" ||
      deliveryMethod === "PHONE_OUT"
    ) {
      const rejectActionIndex = finalOrderActions.findIndex(
        (action) => action.key === "reject"
      );

      if (rejectActionIndex !== -1)
        finalOrderActions.splice(rejectActionIndex, 1);

      // For MOL_CUSTOMER_PICKUP orders orderSource will be MOL
      if (
        deliveryMethod === "MOL_CUSTOMER_PICKUP" &&
        ["NEW", "VIEWED", "PRINTED"].includes(status)
      ) {
        // Adding accept button
        // finalOrderActions.unshift({ key: "accept", label: "Accept" });
        // Removing below 3 actions as order is not accepted. Doing it here as we don't have config for unaccepted pickup orders.
        finalOrderActions = finalOrderActions.filter(
          (each) =>
            ![
              "accept-print",
              "design",
              "designed",
              "out-for-delivery",
              "delivery-confirmation",
              "delivery-exception",
            ].includes(each.key)
        );
      }
    } else if (orderSource === "DoorDash" || orderSource === "UberEats") {
      const copySaleIndex = finalOrderActions.findIndex(
        (action) => action.key === "copy-order"
      );
      if (copySaleIndex !== -1) {
        finalOrderActions.splice(copySaleIndex, 1);
      }
    } else if (
      hasFulfillmentError &&
      ["rejected", "error"].includes(internalStatus) &&
      !isEmpty(paymentDetails)
    ) {
      finalOrderActions.map((each) => {
        if (each.key === "cancel") {
          each.label = "Cancel & Refund";
        }
      });
    }

    const isLocalOrder = [
      "FLORIST_DELIVERED",
      "STORE_PICKUP",
      "WALK_IN",
      "PHONE_OUT",
    ].includes(deliveryMethod);

    const adjustmentAction =
      showAdjustmentaction &&
      direction === "OUTBOUND" &&
      deliveryMethod !== "PHONE_OUT" &&
      !["CANCELLED", "REJECTED", "FORFEITED", "ERROR"].includes(status)
        ? [{ key: "adjustment", label: "Adjustment" }]
        : [];

    const rejectAction =
      [
        "incoming-pickup",
        "accepted-pickup",
        "design-pickup",
        "designed-pickup",
        "delivery-pickup",
      ].includes(internalStatus) && !isLocalOrder
        ? [{ key: "reject", label: "Reject" }]
        : [];

    const driverActions = showDriverAction //ToDo: Add Order status condition - delivered/cancelled to hide
      ? [
          { key: "navigation", label: "Start Navigation" },
          { key: "callRecipient", label: "Call Recipient" },
          { key: "textRecipient", label: "Text Recipient" },
        ]
      : [];

    const refundStatus = get(
      paymentDetails,
      "paymentMethod.0.paymentMethodDetails.refundDetails.0.processFlag",
      ""
    );

    let totalRefund = 0;
    let orderTotal = 0;

    paymentDetails?.paymentMethod?.forEach((paymentType, index) => {
      const { refundDetails = [], settlementDetails = {} } =
        paymentType?.paymentMethodDetails;
      refundDetails.forEach((each, idx) => {
        const { processFlag: refundStatusLocal = "", amount } =
          refundDetails[idx] || {};
        if (refundStatusLocal === "PROCESSED" || refundStatusLocal === "NEW") {
          totalRefund += amount;
        }
      });
      orderTotal += settlementDetails?.amount;
    });

    const hasPaymentError = paymentDetails?.paymentMethod?.some((each) =>
      hasPaymentProcessingError({
        paymentStatus: each.paymentStatus,
        checkSettlementDetails: false,
      })
    );

    const refundAction =
      !hasPendingPayLaterPayment &&
      !giftCardType.includes("GIFT_CARD") &&
      showPayment &&
      !hasFulfillmentError &&
      ((orderSource !== "MOL" && !hasSettlementError && !hasPaymentError) ||
        (orderSource === "MOL" && status !== "CANCELLED")) &&
      paymentItemStatus !== "REFUNDED"
        ? (!isOrderLocked.isLocked &&
            ((orderSource === "MOL" && direction === "INBOUND") ||
              direction === "OUTBOUND" ||
              isLocalOrder) &&
            refundStatus === "") ||
          ((refundStatus === "PROCESSED" || refundStatus === "NEW") &&
            orderTotal !== totalRefund)
          ? [
              {
                key: "refund",
                label: "Refund",
              },
            ]
          : []
        : [];

    const isRefundEligibleCancelledOrder =
      ["STORE_PICKUP", "WALK_IN"].includes(deliveryMethod) &&
      ["CANCELLED"].includes(status);

    const cancelAction =
      !giftCardType.includes("GIFT_CARD") &&
      isLocalOrder &&
      status !== "CANCELLED"
        ? [{ key: "cancel", label: "Cancel" }]
        : [];

    // const isPastDate = moment(deliveryDate).isBefore(moment().add(-1, "days"));

    const dsErrorAction =
      direction === "INBOUND" &&
      (hasDSFulfillmentError || dsRerouteInProgress) &&
      status !== "DELIVERED"
        ? [{ key: "delivery-confirmation", label: "Confirm Delivery" }]
        : [];

    const takeActions = finalOrderActions.concat(
      orderSubActions,
      adjustmentAction,
      rejectAction,
      refundAction,
      cancelAction,
      dsErrorAction
    );

    const isCopySaleForDeadOrder =
      takeActions.filter((e) => e.key === "copy-order") &&
      ["cancelled", "forfeited", "rejected"].includes(internalStatus);
    const cancelApprovalInfo = { askId };

    const hasViewChat = internalSubStatus === "view-chats";

    useEffect(() => {
      if (selectedAction === "") {
        setFieldValue("actionItem", "");
      }
    }, [selectedAction]);

    useEffect(() => {
      if (!showOrderUnpaidModal) {
        setSelectedAction("");
        setFieldValue("actionItem", "");
      }
    }, [showOrderUnpaidModal]);

    return (
      <View style={tw("flex flex-row items-center justify-between flex-wrap")}>
        <View
          style={{
            ...tw("flex-row"),
            backgroundColor: backgroundColors.secondary,
            opacity:
              doNotFill && !(isCancelPendingApproval || hasViewChat)
                ? isCopySaleForDeadOrder || isRefundEligibleCancelledOrder
                  ? 1
                  : 0.4
                : 1,
          }}
          pointerEvents={
            doNotFill && !(isCancelPendingApproval || hasViewChat)
              ? isCopySaleForDeadOrder || isRefundEligibleCancelledOrder
                ? "auto"
                : "none"
              : "auto"
          }
        >
          <TakeAction
            orderDetails={orderDetails}
            orderActions={takeActions}
            actionTriggered={actionTriggered}
            triggerAction={triggerAction}
            triggerRequest={triggerRequest}
            actionCallback={actionCallback}
            menuAction={menuAction}
            isSmallScreen={isSmallScreen}
            isMobile={isMobile}
            sourceMemberCode={sourceMemberCode}
            isCancelPendingApproval={isCancelPendingApproval}
            cancelApprovalInfo={cancelApprovalInfo}
            isActionProcessing={isActionProcessing}
            isPickUpOrder={isPickUpOrder}
            setMenuAction={setMenuAction}
            doNotFill={doNotFill}
            hasViewChat={hasViewChat}
            driverActions={driverActions}
            orderRefundStatus={orderRefundStatus}
            setOrderRefundStatus={setOrderRefundStatus}
            scrollViewRef={scrollViewRef}
            tabDetailScrollRef={tabDetailScrollRef}
            customerInfo={customerInfo}
            cardInfo={cardInfo}
            imagesData={imagesData}
            paymentDetails={paymentDetails}
            isCopySaleForDeadOrder={
              isCopySaleForDeadOrder || isRefundEligibleCancelledOrder
            }
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
            showReasonForm={showReasonForm}
            setShowReasonForm={setShowReasonForm}
            isCancelDSModal={isCancelDSModal}
            setShowCancelDSModal={setShowCancelDSModal}
            setShowDconForm={setShowDconForm}
            internalStatus={internalStatus}
          />
        </View>
      </View>
    );
  }
);

export default TakeActionContainer;
