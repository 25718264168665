import { Linking } from "react-native";
import service from "library/utils/services";
import Environment from "./environment";
import qs from "query-string";
import get from "lodash/get";
import uniq from "lodash/uniq";
import Logger from "library/utils/logger";

import UserProfileStorage from "library/storage/userProfile";
import UserAuthStorage from "library/storage/userAuth";
import EnvironmentStorage from "library/storage/environment";
import AppSettingsStorage from "library/storage/appSettings";
import MOCKJSON from "static/api/mock-json";
import { Platform } from "react-native";
import UserAuthUtils from "library/utils/userAuth";
import moment from "moment";

const bulkUploadServices = [
  "create-bulk-accounts-mhq",
  "create-bulk-accounts-mcloud",
  "invalidate-sessions",
  "sync-store-settings",
  "upload-migration-data",
];

const ordersListingServices = ["order-listing"];

const autoPrintServices = [
  "get-auto-print-orders",
  "get-auto-print-inbound-orders",
  "get-auto-print-outbound-orders",
];

const createOrderServices = ["create-order"];

const autoRouteServices = ["get-autoroutes-data"];

const fetchWithTimeout = async (url, options, timeout = 30000) => {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) => setTimeout(() => reject("timeout"), timeout)),
  ]);
};

const getFileNameAndType = (contentDisposition) => {
  if (!contentDisposition) return { fileName: "", fileType: "" };

  const fileNameMatch = /filename="?([^"]+)"?/.exec(contentDisposition);
  if (fileNameMatch && fileNameMatch[1]) {
    const fileName = fileNameMatch[1];
    const splitMatch = /^(.*)\.([^.]*)$/.exec(fileName);
    if (splitMatch) {
      return { fileName: splitMatch[1], fileType: splitMatch[2] };
    } else {
      return { fileName, fileType: "" };
    }
  } else {
    return { fileName: "", fileType: "" };
  }
};

export const request = async (
  serviceName,
  query = {},
  controller = new AbortController(),
  mock,
  retry,
  useWebApiGateway,
  useProxyGateway
) => {
  const currentLocale = AppSettingsStorage.getLanguage();
  if (mock) {
    return MOCKJSON[serviceName];
  }

  if (serviceName === "save-card-settings") {
    const preferences = query.preferences?.find(
      (ele) => ele.id === "card_settings"
    );
    const cardSettings = JSON.parse(get(preferences, "values.0", "{}"));
    if (cardSettings?.memberCode) query.memberCode = cardSettings.memberCode;
  }

  const {
    name: authGroupName,
    memberCodes: profileMemberCodes = [],
    shopGroups,
    roles,
  } = UserProfileStorage.getAuthGroup();

  const accountMemberCodes = UserProfileStorage.getAccountMemberCodes() || [];

  const isFloristAdmin = UserProfileStorage.getRole() === "ADMIN";

  const proxyUser = UserProfileStorage.getProxyUser();

  const defaultMemberCodes =
    isFloristAdmin && !proxyUser ? accountMemberCodes : profileMemberCodes;

  const requestContextMemberCodes = uniq(
    query.memberCode
      ? [query.memberCode]
      : query.memberCodes || defaultMemberCodes
  );

  // const epApiDomain = Environment.get(
  //   "EPAPI_DOMAIN",
  //   "ep-api-gateway-qa1.gcp.ftdi.com"
  // );

  const webApiDomain = Environment.get(
    "API_DOMAIN",
    "nonprod-gke-primary-1-web-api-gateway-qa1.gcp.ftd.com"
  );

  const webApiProxyDomain = Environment.get(
    "API_PROXY_DOMAIN",
    "nonprod-gke-primary-1-proxy-qa1.gcp.ftdi.com"
  );

  const publicApiKey = Environment.get(
    "PUBLIC_API_KEY",
    "32B81368-2040-4641-BC7B-2656799C7C73"
  );

  const publicApiDomain = Environment.get(
    "PUBLIC_API_DOMAIN",
    "pt-qa1.ftdi.com"
  );

  const publicUiDomain = Environment.get(
    "PUBLIC_UI_DOMAIN",
    "launchpadtest.ftd.com"
  );

  const serviceDetails = service(serviceName, query, {
    authGroupName,
    memberCode: requestContextMemberCodes[0],
    mosMemberCode: `${requestContextMemberCodes[0]}`,
  });

  const terminalRequest = serviceName === "command-to-terminal";

  const publicApiTimeout =
    bulkUploadServices.includes(serviceName) ||
    serviceDetails?.url?.includes("mercury-reporting/")
      ? Environment.get("PUBLIC_API_BULK_UPLOAD_TIMEOUT", 300000)
      : ordersListingServices.includes(serviceName) ||
        autoRouteServices.includes(serviceName)
      ? Environment.get("ORDERS_LISTING_API_TIMEOUT", 60000)
      : createOrderServices.includes(serviceName)
      ? Environment.get("CREATE_ORDER_API_TIMEOUT", 65000)
      : autoPrintServices.includes(serviceName)
      ? Environment.get("AUTO_PRINT_ORDERS_TIMEOUT", 65000)
      : terminalRequest
      ? Environment.get("TERMINAL_TRANSACTION_TIMEOUT", 120000)
      : Environment.get("PUBLIC_API_TIMEOUT", 30000);

  const accessToken = UserAuthStorage.getAccessToken();
  const refreshTokenRequest =
    serviceName === "extend-login" && query.refresh_token !== undefined;

  const appBuildInfoRequest = serviceName === "get-build-info";

  const feedbackRequest = serviceName === "feedback";
  const geocodeRequest = serviceName === "google-geocode";
  const googleplaceidRequest = serviceName === "google-place-id";
  const autoCompleteLocationRequest = serviceName === "autocomplete-location";
  const autoCompleteLocationSearchRequest = serviceName === "textsearch";
  const createWeddingBlog = serviceName === "create-wedding-blog";
  const createSubscribeRequest = serviceName === "subscribe-customer-attentive";
  const generateQRCode = serviceName === "generate-qr-code";

  const logRequest = serviceName === "log";

  const adyenPaymentRequest = serviceName === "initiate-adyen-payment";

  const isRequestWithFormData =
    serviceName === "create-bulk-accounts-mhq" ||
    serviceName === "create-bulk-accounts-mcloud" ||
    serviceName === "upload-migration-data" ||
    serviceName === "create-bulk-ds-request";

  const isRequestWithJSONPatches =
    serviceName === "modify-order" || serviceName === "order-summary";

  const { method, url, body } = serviceDetails;
  const serviceApi = `https://${
    useWebApiGateway
      ? webApiDomain
      : useProxyGateway
      ? webApiProxyDomain
      : refreshTokenRequest ||
        feedbackRequest ||
        geocodeRequest ||
        googleplaceidRequest ||
        autoCompleteLocationRequest ||
        autoCompleteLocationSearchRequest ||
        logRequest ||
        appBuildInfoRequest ||
        adyenPaymentRequest ||
        createWeddingBlog ||
        createSubscribeRequest ||
        terminalRequest ||
        generateQRCode
      ? publicUiDomain
      : publicApiDomain
  }/${url}`
    .replace(
      "/e/p/mercury/",
      `/e/p/${Environment.get("MOS_VERSION", "mercury")}/`
    )
    .replace(
      "/e/p/member-notification/",
      `/e/p/${Environment.get("MNS_VERSION", "member-notification")}/`
    )
    .replace(
      "/e/p/mercury-reporting/",
      `/e/p/${Environment.get("MOS_REPORTING_VERSION", "mercury-reporting")}/`
    )
    .replace(
      "/e/p/ep-user-profile/",
      `/e/p/${Environment.get("EP_UP_VERSION", "ep-user-profile")}/`
    );

  const headers = {
    "client-context": JSON.stringify({ siteId: "mercuryos" }),
    "X-Timezone": encodeURIComponent(moment.tz.guess()),
    Authorization: `apiKey ${publicApiKey}`,
    "Accept-Language": currentLocale,
    ...(authGroupName && {
      "request-context": JSON.stringify({
        authGroupName:
          UserProfileStorage.getOperatorRole() === "FTD_CSR"
            ? "FTD_CSR_ROLE"
            : authGroupName,
        memberCodes: requestContextMemberCodes,
        shopGroups,
        roles:
          UserProfileStorage.getOperatorRole() === "FTD_CSR"
            ? ["FTD_CSR"]
            : roles,
      }),
    }),
    ...(refreshTokenRequest
      ? {
          Authorization: `Basic TUVSQ1VSWU9TOnNhbXBsZXNlY3JldA==`,
          "Content-Type": "application/x-www-form-urlencoded",
        }
      : isRequestWithFormData
      ? {
          ...(accessToken && { "ep-authorization": accessToken }),
        }
      : isRequestWithJSONPatches
      ? {
          "Content-Type": "application/json-patch+json",
          ...(accessToken && { "ep-authorization": accessToken }),
        }
      : {
          "Content-Type": "application/json",
          ...(accessToken && { "ep-authorization": accessToken }),
        }),
  };

  try {
    if (serviceName === "oneLogin") {
      const url = `${serviceApi}?callbackUrl=https://${publicUiDomain}/authorize`;
      Linking.openURL(url);
      return;
    }

    const response = await fetchWithTimeout(
      serviceApi,
      {
        method,
        headers,
        signal: controller.signal,
        ...(body && {
          body: refreshTokenRequest
            ? qs.stringify(body)
            : isRequestWithFormData
            ? body
            : JSON.stringify(body),
        }),
      },
      publicApiTimeout
    );

    if (response.status === 200) {
      try {
        const isOctectStream =
          response.headers
            .get("Content-Type")
            .indexOf("application/octet-stream") > -1;
        const isCSVFile =
          response.headers.get("Content-Type").indexOf("application/csv") > -1;
        const isPDFFile =
          response.headers.get("Content-Type").indexOf("application/pdf") > -1;
        const isExcelFile =
          response.headers
            .get("Content-Type")
            .indexOf(
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) > -1;

        if (isCSVFile || isOctectStream || isPDFFile || isExcelFile) {
          const contentDisposition = response.headers.get(
            "Content-Disposition"
          );
          const { fileName, fileType } = getFileNameAndType(contentDisposition);
          if (Platform.OS === "web") {
            const data = await response.blob();
            return { data, fileName, fileType };
          } else {
            const data = isCSVFile
              ? await response.text()
              : await response.blob();
            return { data, fileName, fileType };
          }
        } else {
          const json = await response.json();
          return json;
        }
      } catch {
        if (["order-actions"].includes(serviceName)) {
          return { response: "Success", recordId: body?.recordId };
        }
        return undefined;
      }
    } else if (
      ["update-event", "update-public-event"].includes(serviceName) &&
      [200, 201].includes(response.status)
    ) {
      return { response: "Success" };
      // 401 - access token expired, try refresh token
    } else if (
      ["save-event", "update-event"].includes(serviceName) &&
      [400].includes(response.status)
    ) {
      return Promise.reject(await response.json());
    } else if (
      response.status === 401 &&
      !["login", "logout", "extend-login"].includes(serviceName)
    ) {
      const isSessionExtended = !retry && (await authInterceptor());
      if (isSessionExtended) {
        console.log("Session extended back, retrying request...");
        return request(
          serviceName,
          query,
          controller,
          mock,
          true,
          useWebApiGateway,
          useProxyGateway
        );
      } else return Promise.reject("logout");

      // handle retry for 500 dashboard
    } else if (
      !retry &&
      (response.status === 500 || response.status === 503) &&
      ["get-dashboard-widgets"].includes(serviceName)
    ) {
      return request(
        serviceName,
        query,
        controller,
        mock,
        true,
        useWebApiGateway,
        useProxyGateway
      );
    } else if (
      !retry &&
      response.status === 502 &&
      ["get-tax"].includes(serviceName)
    ) {
      // As part of MSOL-14925 - Investigated this, Incase of 502 this is not getting called so added new condition in catch block for retry. Need to do cleanup later.
      console.log("Request failed with status 502. Retrying..");
      return request(
        serviceName,
        query,
        controller,
        mock,
        true,
        useWebApiGateway,
        useProxyGateway
      );
    }
    // handle rest all error codes
    else {
      return responseInterceptor(serviceName, response);
    }

    // fetchWithTimeout - request got timed out.
  } catch (err) {
    if (err === "timeout") {
      controller && controller.abort();

      if (!retry) {
        console.log("Request timed out, retrying...");
        return request(
          serviceName,
          query,
          new AbortController(),
          mock,
          true,
          useWebApiGateway,
          useProxyGateway
        );
      } else {
        return Promise.reject("REQUEST_TIME_OUT");
      }
    }

    // catch all exceptions
    console.log(err);
    if (
      ["get-tax", "delivery-listing", "get-route-data"].includes(serviceName) &&
      err.name === "AbortError"
    ) {
      return Promise.reject("AbortError");
    } else if (
      !retry &&
      err.message === "Failed to fetch" &&
      ["get-tax"].includes(serviceName)
    ) {
      return request(
        serviceName,
        query,
        controller,
        mock,
        true,
        useWebApiGateway,
        useProxyGateway
      );
    } else {
      return Promise.reject("CATCH ALL ERROR");
    }
  }
};

const authInterceptor = async () => {
  // make sure serving request is not any auth related one - login/logout/extend-login.
  // after extending login, retry the serving request if its a GET (only)

  UserAuthStorage.clearAccessToken();
  UserAuthStorage.clearSSOUser();
  if (UserAuthStorage.getRefreshTokenCalled() !== "true") {
    UserAuthStorage.setRefreshTokenCalled();
    return extendSession()
      .then(() => true)
      .catch((err) => {
        UserAuthStorage.clearRefreshToken();
        console.log("Session extension failed", JSON.stringify(err));
      });
  }
};

const responseInterceptor = async (service, response) => {
  if (
    (response.status === 401 || response.status === 404) &&
    service.includes("login")
  ) {
    return Promise.reject("INVALID_LOGIN_ATTEMPT");
  }
  if (
    (response.status === 404 && service.includes("authorize-payment")) ||
    (response.status === 400 &&
      ["tokenize-credit-card", "authorize-payment"].includes(service))
  ) {
    let resp = await response.json();
    if (resp.errors.length) {
      Logger.warn("CREDIT_CARD_PROCESS_FAILURE", resp.errors[0]?.message);
      return Promise.reject("CREDIT_CARD_PROCESS_FAILURE");
    }
  }

  if (response.status === 403 && service.includes("login")) {
    const res = await response.json();
    return Promise.reject(res);
  }

  if (
    response.status === 403 &&
    ["validate-teleflora-credentials", "validate-WOI-credentials"].includes(
      service
    )
  ) {
    return Promise.reject("INVALID_CREDENTIALS");
  }

  if (service.includes("get-menus")) {
    const res = await response.json();
    if (
      (res.status === 400 &&
        res.message.includes(
          "IDM_ERR_14: Something went wrong, please contact administrator"
        )) ||
      (res.status === 403 &&
        res.message.includes(
          "IDM_ERR_15: Something went wrong, please contact administrator"
        ))
    ) {
      return Promise.reject("INACTIVE_ACCOUNT");
    }
  }

  if (service.includes("order-actions")) {
    const res = await response.json();
    if (res.status === "422 UNPROCESSABLE_ENTITY") {
      return Promise.reject("INVALID_ACTION");
    } else if (
      res?.status === "406 NOT_ACCEPTABLE" &&
      res?.errors?.length &&
      res?.errors[0]?.message?.includes("There are new updates on this order")
    ) {
      return Promise.reject("There are new updates on this order");
    }
    return Promise.reject(res);
  }

  if (response.status === 404 && service.includes("initiate-reset-password")) {
    return Promise.reject("INVALID_ACCOUNT");
  }

  if (
    response.status === 404 &&
    ["get-mol-content", "get-mol-content-meta", "delete-draft-order"].includes(
      service
    )
  ) {
    return Promise.reject("NOT_FOUND");
  }

  if (response.status === 406 && service.includes("validate-order")) {
    try {
      let resp = await response.json();
      if (
        resp.errors.length &&
        resp.errors[0].message.includes("CUSTOMER_ALREADY_EXISTS")
      ) {
        return Promise.reject("CUSTOMER_ALREADY_EXISTS");
      } else {
        Logger.warn("Error occured at validate-order", resp.errors[0]?.message);
        return Promise.reject(get(resp, "errors.0.message", ""));
      }
    } catch {
      return Promise.reject("ERROR");
    }
  }

  if (response.status === 422 && ["modify-order"].includes(service)) {
    const resp = await response.json();
    if (
      resp.errors.length &&
      resp.errors[0].message.includes("No matching lock found")
    ) {
      return Promise.reject("NO_LOCK_FOUND_DURING_MODIFY_ORDER");
    }
    return Promise.reject(resp);
  }

  if (
    response.status === 409 &&
    (service.includes("create-staff-profile") ||
      service.includes("edit-staff-profile"))
  ) {
    return Promise.reject("USER_ALREADY_EXISTS");
  }

  if (
    response.status === 409 &&
    (service.includes("create-shop-group") ||
      service.includes("edit-shop-group"))
  ) {
    return Promise.reject("SHOP_GROUP_ALREADY_EXISTS");
  }

  if (
    response.status === 422 &&
    UserAuthStorage.getAccessToken() &&
    service.includes("extend-login")
  ) {
    return Promise.resolve("EXTEND_SESSION_PARALLEL_CALLS");
  }

  if (
    ["create-product", "mol-order-search", "upsert-customer"].includes(service)
  )
    return Promise.reject(await response.json());

  if (service.includes("delivery-service-cancel")) {
    let res = await response.json();
    if (response.status === 400) {
      res = { ...res, code: response.status };
    }
    return Promise.reject(res);
  }

  if (response.status === 500 && service.includes("order-requests"))
    return Promise.reject(await response.json());

  if (response.status !== 200 && service.includes("sync-store-settings"))
    return Promise.reject(await response.json());

  if (
    response.status !== 200 &&
    ["create-autoroutes", "edit-autoroutes", "create-task"].includes(service)
  )
    return Promise.reject(await response.json());

  if (response.status === 406 && ["order-requests"].includes(service))
    return Promise.reject(await response.json());
  if (response.status) console.log("Failed with status code:", response.status);
  else console.log("Failed with null response");

  if (["save-promo-code"].includes(service)) {
    const res = await response.json();
    if (res?.status === "406 NOT_ACCEPTABLE" && res?.errors?.length) {
      return Promise.reject("NOT_ACCEPTABLE");
    }
  }

  if (["command-to-terminal"].includes(service)) {
    const res = await response.json();

    if (res.errorType === "TOKEN_GENERATION_ERROR") {
      if (response.status === 500) {
        return Promise.reject("SERVER_ERROR");
      }
      if (response.status === 400) {
        return Promise.reject("SETUP_ERROR");
      }
    }
    return Promise.reject(res);
  }

  try {
    const resp = await response.json();
    if (resp.errors.length) {
      Logger.warn("Error occured", resp.errors[0]?.message);
    }
    return Promise.reject(get(resp, "errors.0.message", ""));
  } catch {
    return Promise.reject("ERROR");
  }
};

const extendSession = () => {
  const refreshToken = UserAuthStorage.getRefreshToken();

  if (!refreshToken) return Promise.reject("Refresh Token expired");

  const { email = "" } = UserProfileStorage.getUser();
  const keepMeSignedIn = UserAuthStorage.getKeepMeSignedIn();

  return request("extend-login", {
    refresh_token: refreshToken,
    deviceType: UserAuthUtils.getDeviceType(),
    mobileTokenId: EnvironmentStorage.getFCMDeviceToken(),
    email,
    keepMeSignedIn,
  }).then((res) => {
    try {
      if (res === "EXTEND_SESSION_PARALLEL_CALLS") return Promise.resolve();

      const { access_token: accessToken, refresh_token: refreshToken } = res;
      UserAuthStorage.setAuthToken({
        accessToken,
        refreshToken,
        keepMeSignedIn,
      });
      UserAuthStorage.clearRefreshTokenCalled();
      return Promise.resolve(res);
    } catch (err) {
      UserAuthStorage.clearRefreshTokenCalled();
      return Promise.reject("Invalid token response", JSON.stringify(err));
    }
  });
};
