import * as React from "react";
import { View, ScrollView, TouchableOpacity, Dimensions } from "react-native";
import { Text, CheckBox, Divider, Badge, Button } from "react-native-elements";
import { MultiSlider } from "components/elements";
import toLower from "lodash/toLower";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import tw from "tailwind-rn";
import { theme, colors, backgroundColors, fonts } from "styles/theme";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { DeviceContext } from "library/contexts/appSettings";
import { Platform } from "react-native";
import moment from "moment";
import I18NContext from "library/contexts/i18N";
import DateRangeFilter from "components/views/drawer/date-range-filter";
import { CustomDateRangeFilter as CustomDateSelectFilter } from "components/views/drawer/customer-directory/upsert-screen/customer/activity/filters";

//eslint-disable-next-line
export const FilterComponent = React.memo(
  ({
    selectedFiltersMap,
    setFilter,
    unsetFilter,
    filterOptions,
    showPriceRange,
    title = "Filters",
    clearAllFilters,
    filters,
    filterIcon,
    menuOptionWidth,
    onApply,
    onCancel,
    showDateType = true,
    showFiltersTitle,
    range = 21,
    showDesignerFilters,
  }) => {
    const { isDesktop, isSmallScreen } = React.useContext(DeviceContext);

    const onSelectCheckBox = (section, title, value) => {
      const index = get(selectedFiltersMap, `${section}.${title}`);

      if (index >= 0) unsetFilter(index, value);
      else {
        if (title === "This Month") {
          value = {
            startDate: moment().startOf("month").format("YYYY-MM-DD"),
            endDate: moment().endOf("month").format("YYYY-MM-DD"),
            dateType: "DELIVERY_DATE",
          };
        }

        setFilter(section, title, value);
      }
    };

    const onPriceRangeSelect = (section, title, value) => {
      const priceRangeFilter = get(selectedFiltersMap, section, {});
      const index = Object.values(priceRangeFilter)[0];
      if (title !== "$0-$300") setFilter(section, title, value, index);
      else unsetFilter(index);
    };

    const onDateRangeSelect = (section, title, value) => {
      setFilter(section, title, value);
    };

    const { messages, Localise } = React.useContext(I18NContext);

    const menuRef = React.useRef();
    var skipCloseEvent = false;

    const closeFilters = () => {
      skipCloseEvent = true;
      menuRef?.current?.close();
    };

    const onOpen = () => {
      skipCloseEvent = false;
    };

    let coreActionsList = filterOptions;

    if (!showDesignerFilters) {
      // Removing "designed, assigned Orders, unassigned Orders" objects from the order status array
      Object.values(filterOptions).forEach((value, index) => {
        const updatedArray = value?.filter(
          (item) =>
            item.value !== "designed" &&
            item.value !== "assignedOrders" &&
            item.value !== "unassignedOrders"
        );
        coreActionsList[Object.keys(filterOptions)[index]] = updatedArray;
      });
    }

    return (
      <Menu
        ref={menuRef}
        onClose={() => !skipCloseEvent && onCancel()}
        onOpen={onOpen}
      >
        <MenuTrigger testID={title} accessibilityLabel={title}>
          <View
            style={tw(
              `${
                !isDesktop ? "flex-col" : "flex-row"
              } justify-around items-center ${
                isDesktop && !showFiltersTitle ? "ml-5" : ""
              }`
            )}
          >
            {filterIcon}
            {isDesktop && showFiltersTitle && (
              <Text>{Localise(messages, title)} </Text>
            )}
            {filters && filters.length > 0 && (
              <Badge
                containerStyle={[
                  {
                    right: isDesktop ? -18 : -10,
                    ...tw("mb-4"),
                    position: "absolute",
                  },
                  Platform.OS !== "web" && { top: -10 },
                ]}
                badgeStyle={{ backgroundColor: "#e5175e" }}
                value={filters.length}
              />
            )}
          </View>
        </MenuTrigger>
        <MenuOptions
          optionsContainerStyle={{
            width: menuOptionWidth,
            padding: 5,
            maxHeight: !isDesktop ? Dimensions.get("window").height : "80%",
            maxWidth: !isDesktop && "80%",
            //overflowY: "scroll",
            marginTop: 20,
          }}
          customStyles={{
            optionsWrapper: {
              flex: 1,
            },
            optionWrapper: {
              padding: 5,
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            },
          }}
        >
          <View style={{ flex: 1 }}>
            <ScrollView
              style={{
                flexGrow: 0,
                maxHeight: !isDesktop
                  ? Dimensions.get("window").height / 2
                  : "80%",
              }}
            >
              {filters && filters.length > 0 && (
                <MenuOption
                  onSelect={() => clearAllFilters(toLower(title))}
                  disableTouchable={false}
                >
                  <View style={tw("flex flex-col w-full")}>
                    <Text style={{ ...fonts.heading4, marginVertical: 3 }}>
                      {Localise(messages, "Clear")} {Localise(messages, title)}
                    </Text>
                    <Divider />
                  </View>
                </MenuOption>
              )}
              {Object.keys(coreActionsList).map((optionKey) => (
                <CustomCheckBoxFilter
                  key={optionKey}
                  section={optionKey}
                  options={coreActionsList[optionKey]}
                  valuesRef={selectedFiltersMap}
                  onPress={onSelectCheckBox}
                  isSmallScreen={isSmallScreen}
                  onDateRangeSelect={onDateRangeSelect}
                  filters={filters}
                  showDateType={showDateType}
                  range={range}
                />
              ))}
              {showPriceRange && (
                <CustomMultiSliderFilter
                  section="Price Range"
                  valuesRef={selectedFiltersMap}
                  onChange={onPriceRangeSelect}
                />
              )}
            </ScrollView>

            <View
              style={{
                flexDirection: "row",
                margin: 10,
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
            >
              <View>
                <Text
                  style={[fonts.link1, tw("m-2")]}
                  onPress={() => {
                    closeFilters();
                    onCancel();
                  }}
                >
                  {Localise(messages, "Close")}
                </Text>
              </View>
              <Button
                title={Localise(messages, "Apply")}
                onPress={() => {
                  closeFilters();
                  onApply({ filters });
                }}
                testID={"Apply"}
                accessibilityLabel={"Apply"}
              />
            </View>
          </View>
        </MenuOptions>
      </Menu>
    );
  }
);

const CustomMultiSliderFilter = ({ section, valuesRef, onChange }) => {
  const priceRangeFilter = get(valuesRef, section, {});
  const [min = "0", max = "300"] =
    Object.keys(priceRangeFilter)[0]?.replace(/[$]/g, "").split("-") || [];
  const [multiSliderValues, setMultiSliderValues] = useStateIfMounted([
    min,
    max,
  ]);
  const { messages, Localise } = React.useContext(I18NContext);

  return (
    <>
      <MenuOption onSelect={() => false}>
        <View style={tw("flex flex-col w-full")}>
          <Text style={{ ...fonts.heading4, marginVertical: 3 }}>
            {Localise(messages, section)}
          </Text>
          <Divider />
        </View>
      </MenuOption>

      <MenuOption onSelect={() => false}>
        <View style={tw("flex flex-col justify-center items-center ml-2")}>
          <Text>
            {Localise(messages, "Min")}: {multiSliderValues[0]},{" "}
            {Localise(messages, "Max")}: {multiSliderValues[1]}
          </Text>
          <MultiSlider
            minValue={0}
            maxValue={300}
            rangeValues={multiSliderValues}
            sliderLength={100}
            onValuesChange={setMultiSliderValues}
            onValuesChangeFinish={() =>
              onChange(
                section,
                `$${multiSliderValues[0]}-$${multiSliderValues[1]}`,
                multiSliderValues
              )
            }
          />
        </View>
      </MenuOption>
    </>
  );
};

const CustomCheckBoxFilter = ({
  section,
  options,
  valuesRef,
  onPress,
  onDateRangeSelect,
  isSmallScreen,
  filters = [],
  showDateType = true,
  range = 21,
}) => {
  const isUnrpintedOrders = section === "Unprinted Orders";
  const isRushOrders = section === "Rush Orders";
  const individualFilter = isUnrpintedOrders || isRushOrders;
  const { messages, Localise } = React.useContext(I18NContext);
  return (
    <>
      <MenuOption onSelect={() => false} disableTouchable={true}>
        <View style={tw("flex flex-col w-full")}>
          <Text
            style={{
              ...fonts.heading4,
              marginVertical: !individualFilter ? 3 : 0,
            }}
          >
            {!individualFilter ? Localise(messages, section) : ""}
          </Text>
          <Divider />
        </View>
      </MenuOption>

      {options.map((e, index) => {
        const selectedFilterIndex = get(valuesRef, `${section}.${e.title}`);
        const isCustomDateRange = e?.title === "Custom Range";
        const dateRangeFilter =
          filters.find(
            (obj) =>
              obj["Date Range"] && obj["Date Range"].title === "Custom Range"
          ) || {};
        const dateRangeFilterValue = dateRangeFilter["Date Range"]?.value || {};
        const defaultStartEndDate = moment(e.date);
        const minStartDate = moment(e.date).subtract(e.minDays, "days");
        const minEndDate = moment(e.date).add(e.maxDays, "days");

        return (
          <View key={index} style={{ zIndex: isCustomDateRange ? 1 : 0 }}>
            <MenuOption key={index} onSelect={() => false}>
              <View
                style={[
                  tw("flex flex-1 flex-row items-center"),
                  { paddingRight: 20 },
                ]}
              >
                {!isCustomDateRange ? (
                  <>
                    <CheckBox
                      containerStyle={[
                        theme.CheckBox.inputContainerStyle,
                        { paddingRight: 3 },
                      ]}
                      size={18}
                      checked={selectedFilterIndex >= 0}
                      onPress={() => onPress(section, e.title, e.value)}
                      testID={e.title}
                      accessibilityLabel={e.title}
                    />
                    <Text onPress={() => onPress(section, e.title, e.value)}>
                      {Localise(messages, e.title)}
                    </Text>
                  </>
                ) : !e.canSelectDates ? (
                  <CustomDateRangeFilter
                    checked={selectedFilterIndex >= 0}
                    isSmallScreen={isSmallScreen}
                    onSelect={onDateRangeSelect}
                    onUnselect={() => onPress(section, e.title, e.value)}
                    title={e.title}
                    dateRangeFilter={filters.find(
                      (obj) =>
                        obj["Date Range"] &&
                        obj["Date Range"].title === "Custom Range"
                    )}
                    section={section}
                    showDateType={showDateType}
                    range={range}
                  />
                ) : (
                  <CustomDateSelectFilter
                    onSelect={(val) =>
                      onDateRangeSelect("Date Range", e.title, val)
                    }
                    setDateRangeSelected={() => {}}
                    dateRangeSelected={true}
                    startDate={
                      dateRangeFilterValue.startDate || defaultStartEndDate
                    }
                    endDate={
                      dateRangeFilterValue.endDate || defaultStartEndDate
                    }
                    isEditable={false}
                    hideType={true}
                    minDate={minStartDate}
                    maxDate={minEndDate}
                    popperPlacement="bottom"
                  />
                )}
              </View>
            </MenuOption>
          </View>
        );
      })}
      {individualFilter && <Divider />}
    </>
  );
};

//eslint-disable-next-line
export const SelectedFilters = React.memo(
  ({
    filters,
    unsetFilter,
    search,
    unsetSearch,
    shops,
    unsetShops,
    sort,
    unsetSort,
    orderIds,
    unsetOrders,
    isSmallScreen,
    clearAllFilters,
  }) => {
    const { isDesktop } = React.useContext(DeviceContext);
    const { messages, Localise } = React.useContext(I18NContext);
    const [isExpanded, setIsExpanded] = React.useState(false);

    const orderIdsArray = orderIds?.split(",");
    const orderIdsList = isExpanded
      ? orderIdsArray
      : orderIdsArray?.slice(0, 3);

    return (
      <View
        style={[
          tw("flex flex-row justify-start items-baseline pl-4"),
          isSmallScreen ? tw("pr-4") : { width: "85%" },
        ]}
      >
        {/* Search List */}
        {!isEmpty(search) && (
          <View
            style={{
              ...tw("flex-row items-center"),
              paddingRight: 30,
              paddingVertical: 10,
              width: "15%",
              flexGrow: 1,
            }}
          >
            <Text style={fonts.heading4}>
              {Localise(messages, "Search Results")}:
            </Text>
            <Text>{` "${search.value}" `} </Text>
            <Text style={fonts.link1} onPress={() => unsetSearch()}>
              {Localise(messages, "Clear")}
            </Text>
          </View>
        )}

        {/* Filters List */}
        {isDesktop && filters && filters.length > 0 && (
          <View
            style={{
              ...tw("flex-row flex-wrap items-center"),
              width: "40%",
              paddingRight: 10,
              flexGrow: 1,
            }}
          >
            <Text style={{ ...fonts.heading4 }}>
              {Localise(messages, "Filters")}:
            </Text>
            {filters.map((e, index) => {
              const value = Object.values(e).pop();
              const id = Array.isArray(value.value)
                ? value.value.map((v) => v.title).join(" ")
                : value.title;

              const val = Array.isArray(value.value)
                ? value.value.map((v) => v.value)
                : value.value;
              const [type] = Object.entries(e).pop();
              return (
                <TouchableOpacity
                  key={index}
                  style={{
                    ...tw("flex-row justify-between items-center m-2"),
                    backgroundColor: backgroundColors.navBar,
                    borderColor: "#a9a9a9",
                    borderWidth: 0.5,
                    borderRadius: 3,
                    padding: 10,
                    shadowColor: colors.medium,
                    shadowOffset: {
                      width: 0,
                      height: 0.5,
                    },
                    shadowRadius: 1,
                    shadowOpacity: 0.5,
                  }}
                  onPress={() => {
                    unsetFilter(index, val, true);
                  }}
                  testID={id}
                  accessibilityLabel={id}
                >
                  <Text
                    style={{
                      paddingRight: 5,
                      color: colors.highlighter,
                      fontWeight: "bold",
                    }}
                  >
                    X
                  </Text>
                  <Text
                    style={{ color: colors.highlighter, fontWeight: "bold" }}
                  >
                    {id === "Custom Range"
                      ? `${Localise(messages, id)}: ${moment(
                          val.startDate
                        ).format("MM/DD/YY")} - ${moment(val.endDate).format(
                          "MM/DD/YY"
                        )}`
                      : type === "ApproachingDrivers"
                      ? Localise(messages, val)
                      : Localise(messages, id)}
                  </Text>
                </TouchableOpacity>
              );
            })}
            <Text
              style={fonts.link1}
              onPress={() => clearAllFilters("filters")}
              testID={"clear_all"}
              accessibilityLabel={"clear_all"}
            >
              {Localise(messages, "Clear")}
            </Text>
          </View>
        )}

        {/* Shops List */}
        {isDesktop && shops && shops.length > 0 && (
          <View
            style={{
              ...tw("flex-row flex-wrap items-center"),
              width: "30%",
              paddingRight: 10,
              flexGrow: 1,
            }}
          >
            <Text style={{ ...fonts.heading4 }}>
              {Localise(messages, "Showing Shops")}:
            </Text>
            {shops.map((e, index) => {
              const value = Object.values(e).pop();
              const id = value.title;
              return (
                <TouchableOpacity
                  key={index}
                  style={{
                    ...tw("flex-row justify-between items-center m-2"),
                    backgroundColor: backgroundColors.navBar,
                    borderColor: "#a9a9a9",
                    borderWidth: 0.5,
                    borderRadius: 3,
                    padding: 10,
                    shadowColor: colors.medium,
                    shadowOffset: {
                      width: 0,
                      height: 0.5,
                    },
                    shadowRadius: 1,
                    shadowOpacity: 0.5,
                  }}
                  onPress={() => {
                    unsetShops(index, true);
                  }}
                  testID={id}
                  accessibilityLabel={id}
                >
                  <Text
                    style={{
                      paddingRight: 5,
                      color: colors.highlighter,
                      fontWeight: "bold",
                    }}
                  >
                    X
                  </Text>
                  <Text
                    style={{ color: colors.highlighter, fontWeight: "bold" }}
                  >
                    {value.title}
                  </Text>
                </TouchableOpacity>
              );
            })}
            <Text
              style={fonts.link1}
              onPress={() => clearAllFilters("shops")}
              testID={"clear_all"}
              accessibilityLabel={"clear_all"}
            >
              {Localise(messages, "Clear")}
            </Text>
          </View>
        )}

        {/* Sort List */}
        {isDesktop && !isEmpty(sort) && (
          <View
            style={{
              ...tw("flex-row items-center"),
              paddingRight: 10,
              width: "15%",
              flexGrow: 1,
            }}
          >
            <Text style={{ ...fonts.heading4 }}>
              {Localise(messages, "Sort")}:
            </Text>
            <TouchableOpacity
              style={{
                ...tw("flex-row justify-between items-center m-2"),
                backgroundColor: backgroundColors.navBar,
                borderColor: "#a9a9a9",
                borderWidth: 0.5,
                borderRadius: 3,
                padding: 10,
                shadowColor: colors.medium,
                shadowOffset: {
                  width: 0,
                  height: 0.5,
                },
                shadowRadius: 1,
                shadowOpacity: 0.5,
              }}
              onPress={() => {
                unsetSort();
              }}
              testID={sort.title}
              accessibilityLabel={sort.title}
            >
              <Text
                style={{
                  paddingRight: 5,
                  color: colors.highlighter,
                  fontWeight: "bold",
                }}
              >
                X
              </Text>
              <Text style={{ color: colors.highlighter, fontWeight: "bold" }}>
                {Localise(messages, sort.title)}
              </Text>
            </TouchableOpacity>
            <Text
              style={fonts.link1}
              onPress={() => unsetSort()}
              testID={"clear_all"}
              accessibilityLabel={"clear_all"}
            >
              {Localise(messages, "Clear")}
            </Text>
          </View>
        )}

        {/* Orders List */}
        {isDesktop && orderIds?.length > 0 && (
          <View
            style={{
              ...tw("flex-row flex-wrap items-center"),
              width: "30%",
              paddingRight: 10,
              flexGrow: 1,
            }}
          >
            <Text style={{ ...fonts.heading4 }}>
              {Localise(messages, "Orders")}:
            </Text>
            {orderIdsList.map((value, index) => {
              return (
                <TouchableOpacity
                  key={index}
                  style={{
                    ...tw("flex-row justify-between items-center m-2"),
                    backgroundColor: backgroundColors.navBar,
                    borderColor: "#a9a9a9",
                    borderWidth: 0.5,
                    borderRadius: 3,
                    padding: 10,
                    shadowColor: colors.medium,
                    shadowOffset: {
                      width: 0,
                      height: 0.5,
                    },
                    shadowRadius: 1,
                    shadowOpacity: 0.5,
                  }}
                  onPress={() => {
                    unsetOrders(index, true);
                  }}
                  testID={value}
                  accessibilityLabel={value}
                >
                  <Text
                    style={{
                      paddingRight: 5,
                      color: colors.highlighter,
                      fontWeight: "bold",
                    }}
                  >
                    X
                  </Text>
                  <Text
                    style={{ color: colors.highlighter, fontWeight: "bold" }}
                  >
                    {value}
                  </Text>
                </TouchableOpacity>
              );
            })}
            {orderIdsArray?.length > 3 && !isExpanded && (
              <TouchableOpacity
                style={{
                  ...tw("flex-row justify-between items-center m-2"),
                  backgroundColor: backgroundColors.navBar,
                  borderColor: "#a9a9a9",
                  borderWidth: 0.5,
                  borderRadius: 3,
                  padding: 10,
                  shadowColor: colors.medium,
                  shadowOffset: {
                    width: 0,
                    height: 0.5,
                  },
                  shadowRadius: 1,
                  shadowOpacity: 0.5,
                }}
                onPress={() => {
                  setIsExpanded(true);
                }}
                testID={"more"}
                accessibilityLabel={"more"}
              >
                <Text style={{ color: colors.highlighter, fontWeight: "bold" }}>
                  {`+${orderIdsArray.length - 3} more`}
                </Text>
              </TouchableOpacity>
            )}
            <Text
              style={fonts.link1}
              onPress={() => clearAllFilters("orders")}
              testID={"clear_all"}
              accessibilityLabel={"clear_all"}
            >
              {Localise(messages, "Clear")}
            </Text>
          </View>
        )}
      </View>
    );
  }
);

const CustomDateRangeFilter = ({
  checked,
  onSelect,
  onUnselect,
  section,
  title,
  dateRangeFilter,
  showDateType = true,
  range = 21,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { startDate, endDate, dateType } =
    (dateRangeFilter && dateRangeFilter["Date Range"].value) || {};

  const handleSelect = () => {
    !checked
      ? onSelect("Date Range", title, {
          startDate:
            startDate || moment().subtract(1, "week").format("YYYY-MM-DD"),
          endDate:
            endDate ||
            moment()
              .add(range === 31 ? 3 : 2, "week")
              .format("YYYY-MM-DD"),
          dateType: dateType || "DELIVERY_DATE",
        })
      : onUnselect();
  };
  return (
    <View>
      <View style={tw("flex flex-row items-center")}>
        <CheckBox
          containerStyle={[
            theme.CheckBox.inputContainerStyle,
            { paddingRight: 3 },
          ]}
          size={18}
          checked={checked}
          onPress={handleSelect}
          testID={title}
          accessibilityLabel={title}
        />
        <Text onPress={handleSelect}>{Localise(messages, title)}</Text>
      </View>
      {checked && (
        <View style={tw("flex flex-col justify-center items-center")}>
          <DateRangeFilter
            section={section}
            title={title}
            onSelect={onSelect}
            value={dateRangeFilter && dateRangeFilter["Date Range"].value}
            range={range}
            showDateType={showDateType}
          />
        </View>
      )}
    </View>
  );
};

export default {
  FilterComponent,
  SelectedFilters,
};
