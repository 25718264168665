/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-native-elements";
import { View, Text, Platform, ActivityIndicator } from "react-native";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import { Spinner } from "components/elements";
import { Form } from "components/elements/forms";
import Listing from "components/containers/listing-new";
import UserProfileStorage from "library/storage/userProfile";
import { basicPaymentInfo } from "components/views/drawer/create-order/config.js";
import { ToasterHandler, CustomModal } from "components/elements";
import { formatPrice } from "library/utils/formatter";
import {
  selectCustomerDetails,
  selectCustomerReceivePayments,
  selectOrderHistoryLimit,
  selectNoop,
  selectOpenItemUnpaidOrdersData,
} from "library/sagas/views/home/drawer/customer-directory/selector";
import { selectSessionId } from "library/sagas/session/selector";
import { selectMemberId } from "library/sagas/session/selector";
import { SaveCancelButtons } from "../../../helper";
import {
  setCurrentCustomer,
  saveCustomerReceivePayment,
  fetchOpenItemUnpaidOrders,
  fetchCustomerReceivePayments,
} from "library/sagas/views/home/drawer/customer-directory/slice";
import {
  PaymentComponent,
  getPaymentValidationSchema,
  TokenizeAndAuthorizePayment,
  getCardType,
  sendCommandToPAXTerminalV1,
  TransactionTypes,
  initiateRefundThroughTerminal,
  getRandomInt,
  getCancelTerminalTxnMessage,
} from "library/utils/payment-options";
import * as Yup from "yup";
import set from "lodash/set";
import get from "lodash/get";
import tw from "tailwind-rn";
import { fonts, colors, theme } from "styles/theme";
import { selectShopPreferences } from "library/sagas/views/home/drawer/shop-settings/common/selector";
import {
  setShopCode,
  fetchShopPaymentSettings,
} from "library/sagas/views/home/drawer/shop-settings/common/slice";
import { createTransaction } from "library/sagas/session/slice";
import AppSettingsContext from "library/contexts/appSettings";
import { Entitlements } from "library/utils/entitlements";
import Environment from "library/utils/environment";

import Item from "./list-item";
import ApplyPayment from "./apply-payment-section";
import moment from "moment";
import { keyMap } from "components/views/drawer/order-details/helper";
import { getCurrentPOSSettings } from "components/views/drawer/shop-settings/helper";
import useStateIfMounted from "library/utils/useStateIfMounted";

Yup.addMethod(Yup.string, "isNonZero", function (errorMessage) {
  return this.test(`is-non-zero`, errorMessage, function (value) {
    return parseFloat(value) !== 0;
  });
});

export const getValidationSchema = (
  Localise,
  messages,
  paymentMethodLocal,
  accountType
) => {
  return accountType === "Open_Item"
    ? paymentMethodLocal === "CREDIT_BALANCE"
      ? Yup.object().shape({
          memberCode: Yup.string().required(
            Localise(messages, "Please select a shop")
          ),
          paymentDetails: getPaymentValidationSchema(),
          ordersInfo: Yup.array().of(
            Yup.object().shape({
              paymentAmount: Yup.string()
                .label("paymentAmount")
                .matches(/^\d+\.?\d*$/, Localise(messages, "Invalid Amount")),
            })
          ),
        })
      : Yup.object().shape({
          amount: Yup.string()
            .required(Localise(messages, "Please enter Amount"))
            .matches(/^\d+\.?\d*$/, Localise(messages, "Invalid Amount"))
            .isNonZero(Localise(messages, "Invalid Amount")),
          memberCode: Yup.string().required(
            Localise(messages, "Please select a shop")
          ),
          paymentDetails: getPaymentValidationSchema(),
          ordersInfo: Yup.array().of(
            Yup.object().shape({
              paymentAmount: Yup.string()
                .label("paymentAmount")
                .matches(/^\d+\.?\d*$/, Localise(messages, "Invalid Amount")),
            })
          ),
        })
    : Yup.object().shape({
        amount: Yup.string()
          .required(Localise(messages, "Please enter Amount"))
          .matches(/^\d+\.?\d*$/, Localise(messages, "Invalid Amount"))
          .isNonZero(Localise(messages, "Invalid Amount")),
        memberCode: Yup.string().required(
          Localise(messages, "Please select a shop")
        ),
        paymentDetails: getPaymentValidationSchema(),
      });
};

const ReceivePayment = ({ UIConfig = {} }) => {
  const { permissions = {} } = React.useContext(AppSettingsContext);
  const {
    InputFields: { fields },
  } = UIConfig;
  const { firstName: operator = "" } = UserProfileStorage.getUser();
  const shopField = fields.filter((item) => item.title === "Shop");
  const shopGroupCodes = get(shopField, "0.formFieldProps.data", []);
  const memberCode =
    shopGroupCodes.length === 1 ? get(shopGroupCodes, "0.value", "") : "";
  const isStandAlone = memberCode.length > 0;

  const dispatch = useDispatch();
  const { isMobile } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const customerDetails = useSelector(selectCustomerDetails);
  const shopPreferences = useSelector(selectShopPreferences);
  const sessionId = useSelector(selectSessionId);
  const memberId = useSelector(selectMemberId);
  const openItemUnpaidOrders = useSelector(selectOpenItemUnpaidOrdersData);
  const previousPaymentsData = useSelector(selectCustomerReceivePayments);

  const formRef = useRef();
  const sendingMemberRef = useRef("");
  const terminalTxnCancellingRef = useRef(false);

  const [paymentOptions, setPaymentOptions] = useStateIfMounted([]);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useStateIfMounted("");
  const [validationOnChange, setValidationOnChange] = useStateIfMounted(false);
  const [isLoading, setIsLoading] = useStateIfMounted(false);
  const [viewMoreCounts, setViewMoreCounts] = useStateIfMounted(10);
  const [paymentMethodLocal, setPaymentMethodLocal] = useStateIfMounted("");
  const [isCreditBalance, setIsCreditBalance] = useStateIfMounted("");
  const [isFormSubmitting, setFormSubmitting] = useStateIfMounted(false);
  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  let selectedShopPermissions = get(
    permissions,
    customerDetails.storeOrigin,
    {}
  );
  const isCPSEntitlementEnabled = selectedShopPermissions[
    Entitlements.CREATE_ORDER
  ]?.includes(Entitlements.CREATE_ORDER_PAGE.CUSTOMER_SEARCH);

  const isSavedPaymentEligible =
    isCPSEntitlementEnabled && customerDetails.paymentInfo.creditCardId;

  const { houseAccountInfo = {}, storeOrigin = "" } = customerDetails;

  const standaloneCreditBalance = formatPrice(
    houseAccountInfo?.memberCodeBalance?.length > 0
      ? houseAccountInfo?.memberCodeBalance?.find(
          (obj) => obj.memberCode === memberCode
        )?.creditBalance || ""
      : ""
  );

  selectedShopPermissions =
    memberCode.length > 0
      ? get(permissions, memberCode, {})
      : selectedShopPermissions;

  const isOpenItemEligibleShop = selectedShopPermissions[
    Entitlements.HOUSE_ACCOUNT
  ]?.includes(Entitlements.HOUSE_ACCOUNT_PERMISSIONS.OPEN_ITEM_ACCOUNTING);

  const isAccountType = houseAccountInfo?.accountType || "";

  const paymentDetails = { ...basicPaymentInfo };
  set(paymentDetails, "paymentMethod.0.enablePayment", true);

  const initialValues = {
    memberCode,
    creditBalance: standaloneCreditBalance || "",
    amount: "",
    optionalNotes: "",
    customerInfo: {
      ...customerDetails,
      firstPaymentInfo: customerDetails.paymentInfo,
    },
    paymentDetails,
    ordersInfo: [],
  };

  const ListOfColumns = [
    { column: "date", columnWidth: "19%" },
    { column: "paymentType", columnWidth: "38%" },
    { column: "amountPaid", columnWidth: "17%" },
    { column: "notes", columnWidth: "26%" },
  ];

  const Config = {
    sections: [
      {
        component: {
          props: {
            noRecordsMessage: Localise(messages, "No Payments Yet!"),
            viewMore: {
              label: Localise(messages, "View More"),
              value: 5,
              initial: 5,
            },
            selectors: {
              dataSelector: selectCustomerReceivePayments,
              limitSelector: selectOrderHistoryLimit,
              accordionSelector: selectNoop,
            },
            actions: { setPageAction: setCurrentCustomer },
            itemComponent: {
              name: Item,
              props: {
                columns: ListOfColumns,
              },
            },
          },
        },
      },
    ],
    selectors: null,
    showHeader: false,
    headerTitleWidth: 200,
    scrollContainerStyles: {},
  };

  const unpaidOrdersList = (memberCodeLocal) => {
    // fetching open item unpaid orders on selecting shop
    if (
      houseAccountInfo?.accountType?.length &&
      houseAccountInfo?.accountType === "Open_Item"
    ) {
      setIsLoading(true);
      dispatch(
        fetchOpenItemUnpaidOrders({
          storeOrigin: storeOrigin,
          houseAccountId: houseAccountInfo?.houseAccountId,
          shopCode: memberCodeLocal,
          resolve: () => {
            setIsLoading(false);
          },
          reject: () => {
            ToasterHandler(
              "error",
              Localise(messages, "Failed to load balance payments")
            );
            setIsLoading(false);
          },
        })
      );
    }
  };

  const saveReceivePayment = (
    values,
    formikBag,
    authDetails = {},
    tokenId = ""
  ) => {
    const ordersInfoValues =
      values?.ordersInfo?.length > 0
        ? values?.ordersInfo.filter((obj) => obj?.orderID?.length > 0)
        : [];

    let totalCreditBalanceUsed = 0;
    let enteredUnpaidOrdersAmt = [];

    // summing-up the entered unpaid orders amount
    ordersInfoValues?.forEach((obj) => {
      totalCreditBalanceUsed += parseFloat(obj?.paymentAmount);
      // checking if the entered unpaid orders amount is greater than pending balance
      openItemUnpaidOrders?.forEach((order) => {
        const {
          orderTotalAmount = 0,
          amountReceived = 0,
          refunds = [],
          orderID = "",
        } = order;
        let refundedAmount = 0;
        for (let i = 0; i < refunds?.length; i++) {
          refundedAmount = refundedAmount + refunds[i]?.refundedAmount;
        }
        const totalAmountReceived = amountReceived + refundedAmount;
        const balance = formatPrice(orderTotalAmount - totalAmountReceived);
        if (orderID === obj?.orderID) {
          Number(obj?.paymentAmount) > Number(balance) &&
            enteredUnpaidOrdersAmt.push(obj?.paymentAmount);
        }
      });
    });

    let paymentDetails = { currencyCode: "USD" };
    const {
      houseAccountInfo: { houseAccountId, creditLimit },
      customerId,
      storeOrigin,
    } = customerDetails;

    const { savePayment, paymentMethodType, paymentMethodDetails } =
      values.paymentDetails.paymentMethod[0];

    if (
      ["CASH_OR_CHECK", "PAID_ELSEWHERE", "CREDIT_BALANCE"].includes(
        paymentMethodType
      )
    ) {
      paymentDetails = {
        ...paymentDetails,
        paymentMethod: [
          {
            paymentMethodType,
            paymentMethodDetails: {
              amount:
                paymentMethodType === "CREDIT_BALANCE"
                  ? totalCreditBalanceUsed
                  : values.amount,
              note: values.optionalNotes,
            },
          },
        ],
      };
    } else if (paymentMethodType === "PAYMENT_TERMINAL") {
      paymentDetails = {
        ...paymentDetails,
        paymentMethod: [
          {
            ...values.paymentDetails.paymentMethod[0],
            paymentMethodType: "CREDIT_CARD",
            isHardwareTerminal: true,
            paymentMethodDetails: {
              ...authDetails,
              note: values.optionalNotes,
            },
          },
        ],
      };
    } else {
      let details = {};
      if (paymentMethodType === "CREDIT_CARD") {
        const { actualCardNumber = "", expDate, name } = paymentMethodDetails;
        const [expirationMonth, expirationYear] = expDate.split("/");
        const trimmedCardNumber = actualCardNumber.split(" ").join("");
        details = {
          nameOnCard: name,
          creditCardType: getCardType(actualCardNumber)?.toUpperCase(),
          creditCardNumber: trimmedCardNumber.substring(
            trimmedCardNumber.length - 4
          ),
          creditCardExpireMonth: expirationMonth,
          creditCardExpireYear: "20" + expirationYear,
          creditCardEncrypted: false,
        };
      } else if (paymentMethodType === "SAVED_CARD") {
        const { cardNumber = "" } = paymentMethodDetails;
        const { cardType, expirationYear, expirationMonth, nameOnCard } = get(
          values,
          "customerInfo.firstPaymentInfo",
          {}
        );
        details = {
          ...details,
          nameOnCard,
          creditCardType: cardType,
          creditCardNumber: cardNumber.substring(cardNumber.length - 4),
          creditCardExpireMonth: expirationMonth,
          creditCardExpireYear: expirationYear,
          creditCardEncrypted: false,
        };
      }
      paymentDetails = {
        ...paymentDetails,
        paymentMethod: [
          {
            ...values.paymentDetails.paymentMethod[0],
            paymentMethodType: "CREDIT_CARD",
            paymentMethodDetails: {
              ...details,
              authorizationDetails: authDetails,
              note: values.optionalNotes,
            },
          },
        ],
      };

      if (savePayment || paymentMethodType === "SAVED_CARD") {
        set(
          paymentDetails,
          "paymentMethod.0.paymentMethodDetails.tokenId",
          tokenId
        );
      }
    }

    const amount = get(values, "amount", "");

    if (enteredUnpaidOrdersAmt?.length > 0) {
      ToasterHandler("uh oh", Localise(messages, "Please enter valid Amount"));
      setFormSubmitting(false);
      formikBag.setSubmitting(false);
    } else if (
      paymentMethodType === "CREDIT_BALANCE" &&
      totalCreditBalanceUsed > isCreditBalance
    ) {
      ToasterHandler(
        "uh oh",
        Localise(
          messages,
          "The Unpaid Orders amount total should not be greater than the available credit balance"
        )
      );
      setFormSubmitting(false);
      formikBag.setSubmitting(false);
    } else if (
      paymentMethodType === "CREDIT_BALANCE" &&
      totalCreditBalanceUsed === 0
    ) {
      ToasterHandler("uh oh", Localise(messages, "Please enter amount"));
      setFormSubmitting(false);
      formikBag.setSubmitting(false);
    } else if (
      paymentMethodType !== "CREDIT_BALANCE" &&
      Number(amount) < totalCreditBalanceUsed
    ) {
      ToasterHandler(
        "uh oh",
        Localise(messages, "Amount should be greater than Unpaid Orders total")
      );
      setFormSubmitting(false);
      formikBag.setSubmitting(false);
    } else {
      dispatch(
        saveCustomerReceivePayment({
          params: {
            houseAccountId,
            memberCode: values.memberCode,
            customerId,
            storeOrigin,
            creditLimit,
            paymentDetails,
            houseAccountType: isAccountType,
            ...(ordersInfoValues?.length > 0 && {
              ordersInfo: ordersInfoValues,
            }),
            creditBalance: isCreditBalance,
            isCreditBalancePayment:
              paymentMethodType === "CREDIT_BALANCE" ? true : false,
          },
          resolve: () => {
            //Create a activity
            const { isBusinessProfile, businessName, firstName, lastName } =
              customerDetails || {};

            if (sessionId && memberId === values.memberCode) {
              const amountChargedToCustomer = values.amount || 0;
              values.paymentDetails.paymentMethod[0];
              const paymentInfo = get(
                values,
                "paymentDetails.paymentMethod.0",
                ""
              );
              const {
                paymentMethodType: paymentType,
                paymentMethodDetails: {
                  tenderedAmount = "",
                  changeDueAmount = "",
                },
              } = paymentInfo;

              const isCashOrCheck = paymentType === "CASH_OR_CHECK";
              let userNote = {
                payment: keyMap[paymentType],
                isHAReceivePayment: true,
                customerName: isBusinessProfile
                  ? businessName
                  : `${firstName} ${lastName}`,
              };
              if (isCashOrCheck) {
                userNote = {
                  ...userNote,
                  tenderedAmount,
                  changeDueAmount,
                };
              }
              dispatch(
                createTransaction({
                  params: {
                    amount: amountChargedToCustomer,
                    type: isCashOrCheck ? "Cash" : "Non-Cash",
                    memberId: memberId,
                    macAddress: document.getElementById("macAddress").value,
                    user: operator,
                    userNote: JSON.stringify(userNote),
                    orderId: houseAccountId,
                  },
                  resolve: (resp) => {
                    // const transactionContent = getSessionReceiptContent(resp);
                    // ipcRenderer.send("printOrderInfo", 1, [transactionContent]);
                    console.log(
                      "Success - ",
                      `Successfully created ${paymentType} transaction`
                    );
                  },
                  reject: () => {
                    console.log("Error - ", "Failed to create transaction");
                  },
                })
              );
            }

            ToasterHandler(
              "success",
              Localise(messages, "Payment received successfully!")
            );

            if (memberCode.length > 0) {
              unpaidOrdersList(memberCode);
              dispatch(
                fetchCustomerReceivePayments({ memberCode: memberCode })
              );
            }

            setValidationOnChange(false);
            setFormSubmitting(false);
            formikBag.setSubmitting(false);

            //To preselect Payment Terminal option in case of Desktop app and single membercode
            if (isElectronApp && isStandAlone) {
              let paymentTerminalIndex = paymentOptions.findIndex(
                (paymentOption) => paymentOption.value === "PAYMENT_TERMINAL"
              );

              if (paymentTerminalIndex >= 0) {
                set(
                  initialValues,
                  "paymentDetails.paymentMethod.0.paymentMethodType",
                  "PAYMENT_TERMINAL"
                );
              }

              formikBag.resetForm(initialValues);
            } else {
              formikBag.resetForm(initialValues);
            }
          },
          reject: (error) => {
            // We are initiating refunds always (if it is terminal payment) as we are in a catch block that indicates HA receive payment is not successful
            if (paymentMethodType === "PAYMENT_TERMINAL") {
              const authorizationDetails = get(
                paymentDetails,
                "paymentMethod.0.paymentMethodDetails.authorizationDetails",
                []
              );

              const merchantReferenceId =
                authorizationDetails.find(
                  (obj) => obj.name === "merchantReferenceId"
                )?.value || "";

              const transactionId =
                authorizationDetails.find(
                  (obj) => obj.name === "transactionDetailsId"
                )?.value || "";

              initiateRefundThroughTerminal({
                sendingMember: values.memberCode,
                merchantReferenceId,
                transactionId,
                resolve: ({ message }) => {
                  setFormSubmitting(false);
                  formikBag.setSubmitting(false);

                  ToasterHandler("uh oh", Localise(messages, message));
                },
              });
            } else {
              setFormSubmitting(false);
              formikBag.setSubmitting(false);
              ToasterHandler("error", Localise(messages, error));
            }
          },
        })
      );
    }
  };

  const cancelTerminalTxn = () => {
    if (terminalTxnCancellingRef) terminalTxnCancellingRef.current = true;

    setCurrentPaymentMethod("");

    sendCommandToPAXTerminalV1({
      transactionType: TransactionTypes.CANCEL,
      sendingMember: sendingMemberRef ? sendingMemberRef.current : "",
      callback: ({ terminalResponse }) => {
        console.log(
          "Cancel Txn Response in Receive Payment :>> ",
          terminalResponse
        );

        setFormSubmitting(false);
        if (formRef) formRef.current.setSubmitting(false);

        ToasterHandler(
          "uh oh",
          Localise(
            messages,
            getCancelTerminalTxnMessage(terminalResponse?.ResponseText)
          )
        );

        if (terminalTxnCancellingRef) terminalTxnCancellingRef.current = false;
      },
    });
  };

  const waitMessage = terminalTxnCancellingRef.current
    ? "Canceling transaction."
    : "Payment in progress. Just a moment.";

  return (
    <>
      <Form
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={(values, formikBag) => {
          if (formikBag.isSubmitting) return;
          setFormSubmitting(true);

          const { paymentMethodType = "" } = get(
            values,
            "paymentDetails.paymentMethod.0",
            {}
          );

          // Generating merchant reference id like below as Heartland is accepting merchantRefId's in this pattern [0-9A-Za-z_-]+
          const merchantReferenceId = `HA${moment()}${getRandomInt(0, 9)}`;

          if (
            paymentMethodType === "CREDIT_CARD" ||
            paymentMethodType === "SAVED_CARD"
          ) {
            setCurrentPaymentMethod(paymentMethodType);

            TokenizeAndAuthorizePayment(
              values,
              merchantReferenceId,
              customerDetails.storeOrigin,
              values.amount,
              (authDetails = {}, tokenId = "") => {
                setCurrentPaymentMethod("");

                saveReceivePayment(values, formikBag, authDetails, tokenId);
              },
              () => {
                setCurrentPaymentMethod("");
                setFormSubmitting(false);
                formikBag.setSubmitting(false);

                ToasterHandler(
                  "error",
                  Localise(
                    messages,
                    "Failed to receive Payment. Please try again"
                  )
                );
              }
            );
          } else if (paymentMethodType === "PAYMENT_TERMINAL") {
            setCurrentPaymentMethod(paymentMethodType);

            const isSavePaymentSelected = get(
              values,
              "paymentDetails.paymentMethod.0.savePayment",
              false
            );

            sendCommandToPAXTerminalV1({
              transactionType: TransactionTypes.AUTHORIZATION,
              sendingMember: values.memberCode,
              merchantReferenceId,
              amount: values.amount,
              requestMultiUseToken: isSavePaymentSelected,
              callback: ({ terminalResponse, paymentMethodDetails = {} }) => {
                setCurrentPaymentMethod("");

                if (terminalResponse.ResponseCode === "00") {
                  set(
                    values,
                    "paymentDetails.paymentMethod.0.merchantReferenceId",
                    merchantReferenceId
                  );

                  saveReceivePayment(
                    values,
                    formikBag,
                    paymentMethodDetails,
                    paymentMethodDetails.tokenId
                  );
                } else {
                  setFormSubmitting(false);
                  formikBag.setSubmitting(false);

                  ToasterHandler(
                    "uh oh",
                    terminalResponse.ResponseText ||
                      Localise(
                        messages,
                        "Failed to receive Payment. Please try again"
                      )
                  );
                }
              },
              cancelBtnHandler: ({ txnStatus }) => {
                setCurrentPaymentMethod(txnStatus);
              },
            });
          } else {
            saveReceivePayment(values, formikBag);
          }
        }}
        validationSchema={getValidationSchema(
          Localise,
          messages,
          paymentMethodLocal,
          houseAccountInfo?.accountType
        )}
        validateOnChange={validationOnChange}
        validateOnBlur={validationOnChange}
        render={({ values, setValues, setFieldValue, submitCount }) => {
          const { memberCode = "", paymentDetails = {} } = values;

          const paymentMethodType = get(
            paymentDetails,
            "paymentMethod.0.paymentMethodType",
            ""
          );

          useEffect(() => {
            if (submitCount > 0) {
              setValidationOnChange(true);
            }
          }, [submitCount]);

          useEffect(() => {
            // setting credit balance specific to shop selection
            const fetchCreditBalance =
              houseAccountInfo?.memberCodeBalance?.length > 0
                ? houseAccountInfo?.memberCodeBalance?.find(
                    (obj) => obj.memberCode === memberCode
                  )?.creditBalance || ""
                : "";

            setFieldValue(
              `creditBalance`,
              `${formatPrice(fetchCreditBalance)}`
            );
            setIsCreditBalance(`${fetchCreditBalance}`);
            setPaymentOptions([]);
            setFieldValue(
              `paymentDetails.paymentMethod.0.paymentMethodType`,
              ""
            );

            if (memberCode !== undefined && memberCode !== "") {
              dispatch(setShopCode(memberCode));
              dispatch(fetchShopPaymentSettings());
              unpaidOrdersList(memberCode);

              sendingMemberRef.current = memberCode;
            }
            setViewMoreCounts(10);
          }, [memberCode]);

          useEffect(() => {
            if (houseAccountInfo?.houseAccountId?.length > 0) {
              unpaidOrdersList(memberCode);
            }
          }, [houseAccountInfo?.houseAccountId]);

          useEffect(() => {
            if (memberCode !== undefined && memberCode !== "") {
              dispatch(
                fetchCustomerReceivePayments({ memberCode: memberCode })
              );

              const paymentOptions = [];

              setIsCreditBalance(
                shopGroupCodes.length === 1
                  ? standaloneCreditBalance
                  : values?.creditBalance
              );

              if (isElectronApp) {
                const paymentTerminalEnabledForMembercode =
                  shopPreferences["payment_terminal"] === "true";

                const enablePaymentTerminal = Environment.get(
                  "ENABLE_PAYMENT_TERMINAL",
                  ""
                ).split(",");

                const hasAccessToPaymentTerminal =
                  enablePaymentTerminal.includes("ALL") ||
                  enablePaymentTerminal.includes(memberCode);

                if (
                  paymentTerminalEnabledForMembercode &&
                  hasAccessToPaymentTerminal
                ) {
                  const updatePaymentOptions = async () => {
                    const { terminal_settings = [] } =
                      UserProfileStorage.getShopPreferences(memberCode);

                    const { currentPOSSettings = {} } =
                      await getCurrentPOSSettings(terminal_settings);

                    if (currentPOSSettings?.serial_number) {
                      paymentOptions.unshift({
                        label: Localise(messages, "Payment Terminal"),
                        value: "PAYMENT_TERMINAL",
                      });

                      setFieldValue(
                        "paymentDetails.paymentMethod.0.paymentMethodType",
                        "PAYMENT_TERMINAL"
                      );
                    }
                  };

                  updatePaymentOptions();
                }
              }

              shopPreferences["cash_or_check"] === "true" &&
                paymentOptions.push({
                  label: Localise(messages, "Cash or Check"),
                  value: "CASH_OR_CHECK",
                });

              if (shopPreferences["credit_card"] === "true") {
                paymentOptions.push({
                  label: Localise(messages, "Credit Card"),
                  value: "CREDIT_CARD",
                });

                if (isSavedPaymentEligible) {
                  paymentOptions.push({
                    label: Localise(messages, "Saved Payment"),
                    value: "SAVED_CARD",
                  });
                }
              }

              shopPreferences["paid_elsewhere"] === "true" &&
                paymentOptions.push({
                  label: Localise(messages, "Paid Elsewhere"),
                  value: "PAID_ELSEWHERE",
                });

              isOpenItemEligibleShop &&
                (shopGroupCodes.length === 1
                  ? standaloneCreditBalance > 0
                  : values?.creditBalance > 0) &&
                openItemUnpaidOrders?.length > 0 &&
                paymentOptions.push({
                  label: Localise(messages, "Credit Balance"),
                  value: "CREDIT_BALANCE",
                });

              setPaymentOptions(paymentOptions);
            } else {
              setPaymentOptions([]);
            }
          }, [
            shopPreferences,
            openItemUnpaidOrders?.length,
            standaloneCreditBalance,
          ]);

          useEffect(() => {
            if (paymentMethodType === "CREDIT_BALANCE") {
              setFieldValue(`amount`, ``);
            }
            setPaymentMethodLocal(paymentMethodType);
          }, [paymentMethodType]);

          useEffect(() => {
            formRef.current.resetForm();
          }, []);

          let showUnpaidOrdersList =
            isOpenItemEligibleShop &&
            memberCode !== undefined &&
            memberCode !== "";

          return (
            <View style={!isMobile ? { paddingLeft: 5 } : {}}>
              {currentPaymentMethod.length > 0 && (
                <CustomModal
                  modalVisible={currentPaymentMethod.length > 0}
                  modalContent={{
                    content: (
                      <View style={[tw("items-center")]}>
                        <View style={tw("flex flex-row")}>
                          <ActivityIndicator
                            style={{ marginLeft: 5, marginTop: -5 }}
                            color={colors.activityIndicator}
                          />
                          <Text style={{ marginLeft: 10 }}>
                            {Localise(messages, "Processing Payment")}
                          </Text>
                        </View>
                        {currentPaymentMethod === "PAYMENT_TERMINAL" && (
                          <View style={{ marginTop: 16 }}>
                            <Text>
                              {Localise(
                                messages,
                                "Cancel transaction from Terminal using [X] key or click Cancel button below."
                              )}
                            </Text>
                          </View>
                        )}
                      </View>
                    ),
                    ...(currentPaymentMethod === "PAYMENT_TERMINAL" && {
                      buttons: [
                        {
                          type: "primary",
                          title: Localise(messages, "Cancel"),
                        },
                      ],
                    }),
                  }}
                  primaryhandler={cancelTerminalTxn}
                  contentStyle={[tw("p-4"), { backgroundColor: "white" }]}
                  modalStyle={
                    Platform.OS !== "web" && {
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 1,
                      backgroundColor: "#00000070",
                      color: "#FFFFFF",
                    }
                  }
                />
              )}

              {isFormSubmitting && currentPaymentMethod.length === 0 && (
                <CustomModal
                  modalVisible={isFormSubmitting}
                  modalContent={{
                    content: (
                      <View style={[tw("items-center")]}>
                        <View style={tw("flex flex-row")}>
                          <ActivityIndicator
                            style={{ marginLeft: 5, marginTop: -5 }}
                            color={colors.activityIndicator}
                          />
                          <Text style={{ marginLeft: 10 }}>
                            {`${Localise(messages, waitMessage)}`}
                          </Text>
                        </View>
                      </View>
                    ),
                  }}
                  contentStyle={[tw("p-4"), { backgroundColor: "white" }]}
                  modalStyle={
                    Platform.OS !== "web" && {
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 1,
                      backgroundColor: "#00000070",
                      color: "#FFFFFF",
                    }
                  }
                />
              )}

              {fields.map((fieldInfo, index) => {
                const {
                  formFieldElement: Component,
                  title,
                  formFieldProps,
                } = fieldInfo;

                return (title === "Shop" && isStandAlone) ||
                  (title === "Credit Balance" &&
                    (!isOpenItemEligibleShop ||
                      isAccountType !== "Open_Item")) ||
                  (title === "Amount" &&
                    paymentMethodType === "CREDIT_BALANCE") ? null : (
                  <View
                    key={index}
                    style={tw(
                      `flex flex-row justify-start pb-2 items-${
                        Platform.OS !== "web" ? "center" : "baseline"
                      }`
                    )}
                  >
                    <Text
                      style={{
                        height: 40,
                        width: isMobile ? "25%" : "30%",
                        ...fonts.heading5,
                      }}
                    >
                      {title}
                    </Text>
                    <View style={{ width: isMobile ? "75%" : "70%" }}>
                      <Component {...formFieldProps} />
                    </View>
                  </View>
                );
              })}

              <PaymentComponent
                paymentOptions={paymentOptions}
                values={values}
                setValues={setValues}
                enableCPS={true}
                grandTotal={values.amount}
                hideFields={true}
                setFieldValue={setFieldValue}
              />

              {isElectronApp && paymentMethodType === "PAYMENT_TERMINAL" ? (
                <View
                  style={[
                    tw("flex flex-row flex-wrap"),
                    { marginTop: 15, zIndex: -1 },
                  ]}
                >
                  <Text
                    style={[
                      {
                        ...fonts.heading6,
                        fontWeight: "normal",
                      },
                    ]}
                  >
                    {`* ${Localise(
                      messages,
                      "You cannot save digital payments like Apple Pay, Google Pay, Samsung Pay, and PayPal."
                    )}`}
                  </Text>
                </View>
              ) : null}
              {showUnpaidOrdersList && isAccountType === "Open_Item" && (
                <>
                  <Text
                    style={{
                      height: 15,
                      width: isMobile ? "25%" : "30%",
                      ...fonts.heading5,
                      marginTop: 20,
                    }}
                  >
                    {Localise(messages, "Apply Payment to")}
                  </Text>

                  {isLoading ? (
                    <View style={{ minHeight: 100 }}>
                      <Spinner size="large" />
                    </View>
                  ) : (
                    <>
                      {openItemUnpaidOrders?.length > 0 ? (
                        <ApplyPayment
                          openItemUnpaidOrders={openItemUnpaidOrders}
                          viewMoreCounts={viewMoreCounts}
                          setFieldValue={setFieldValue}
                        />
                      ) : (
                        <Text style={{ textAlign: "center", ...fonts.default }}>
                          {Localise(messages, "No Unpaid Orders")}
                        </Text>
                      )}
                    </>
                  )}
                </>
              )}
              <View
                style={
                  showUnpaidOrdersList &&
                  viewMoreCounts < openItemUnpaidOrders?.length && [
                    tw(
                      `flex flex-row justify-between pb-2 items-${
                        Platform.OS !== "web" ? "center" : "baseline"
                      }`
                    ),
                  ]
                }
              >
                {showUnpaidOrdersList &&
                  viewMoreCounts < openItemUnpaidOrders?.length && (
                    <Button
                      titleStyle={theme.Button.secondaryTitleStyle}
                      buttonStyle={{
                        ...theme.Button.secondaryButtonStyle,
                        paddingHorizontal: 10,
                        paddingVertical: 6,
                        width: 95,
                        height: 35,
                      }}
                      containerStyle={{
                        alignItems: "flex-end",
                        marginHorizontal: 0,
                      }}
                      title={Localise(messages, "View More")}
                      onPress={() => {
                        setViewMoreCounts(viewMoreCounts + 10);
                      }}
                      testID="view_more"
                      accessibilityLabel="view_more"
                    />
                  )}
                <SaveCancelButtons title={"Submit Payment"} />
              </View>
              {(memberCode || isStandAlone) &&
              previousPaymentsData?.length > 0 ? (
                <>
                  <Text
                    style={{
                      height: 20,
                      width: "40%",
                      ...fonts.heading5,
                      marginTop: 20,
                      marginLeft: 4,
                    }}
                  >
                    {Localise(messages, "Previous Payments")}
                  </Text>
                  <Listing UIConfig={Config} />
                </>
              ) : (
                <></>
              )}
            </View>
          );
        }}
      />
    </>
  );
};

export default ReceivePayment;
