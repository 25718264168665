export const getReleaseNotes = (Localise, messages, isDesktop = true) => [
  {
    name: "Release 35 - 10/03/2024",
    title: Localise(messages, "Release 35 - 10/03/2024"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Break Out Products for Incoming Wire Order",
          level: 0,
          childContent: [
            {
              type: "text",
              content: `This feature provides ability to split products for incoming orders or update the product description via the "Edit Product" link, which redirects to the editable product block on the order screen.`,
              level: 1,
              childContent: [
                {
                  type: "image",
                  content: "split_product_help1",
                  width: isDesktop ? 950 : 250,
                  height: isDesktop ? 242 : 64,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
                {
                  type: "image",
                  content: "split_product_help2",
                  width: isDesktop ? 950 : 250,
                  height: isDesktop ? 242 : 64,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "Order Listing: Sort by Incoming Order Date & Time",
          level: 0,
          childContent: [
            {
              type: "text",
              content: `A new Sort by order date option has been added, allowing users to sort orders by the date they were created.`,
              level: 1,
              childContent: [
                {
                  type: "image",
                  content: "order_listing_sort",
                  width: isDesktop ? 950 : 250,
                  height: isDesktop ? 242 : 64,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "Add-On Features",
          level: 0,
          childContent: [
            {
              type: "text",
              content: `Essentials users now have access to the self-service features, which can be enabled through the Add-on Feature tab by Shop Admin. The self-service features allow shop administrators to manage subscriptions by selecting and paying for individual features as needed.`,
              level: 1,
            },
            {
              type: "text",
              content: `QR Code Scanning`,
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: `This feature enables users to efficiently scan QR codes on printed artifacts for single or bulk orders using a mobile or handheld scanner. It provides quick access to order details, streamlining the process and eliminating the need for manual searches, saving both time and effort.`,
                  level: 1,
                },
              ],
            },
            {
              type: "text",
              content: `Delivery Dashboard:`,
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: `The Delivery Dashboard provides real-time insights into the status of routes, orders, and zones within a store, allowing users to easily monitor and manage store operations.`,
                  level: 1,
                },
              ],
            },
            {
              type: "text",
              content: `Design Center:`,
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: `This feature allows users to efficiently assign designers to orders and track order status throughout the design process. Users can assign individual or multiple orders to a designer, view orders by designer, and quickly identify unassigned orders, streamlining overall workflow management.`,
                  level: 1,
                },
              ],
            },
            {
              type: "text",
              content: `QuickBooks Integration: `,
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: `The QuickBooks Integration feature allows users to easily synchronize retail transactions for accounting. It streamlines financial processes by accurately transferring transaction data to QuickBooks, simplifying accounting tasks and improving financial accuracy.`,
                  level: 1,
                },
              ],
            },
            {
              type: "image",
              content: "addon_help",
              width: isDesktop ? 950 : 250,
              height: isDesktop ? 242 : 64,
              webCustomStyle: { resizeMode: "contain" },
              level: 0,
            },
          ],
        },
        {
          type: "text",
          content: "Route Enhancements",
          level: 0,
          childContent: [
            {
              type: "text",
              content: `Add Orders from Multiple Shops and Locations in Route: Essentials and above users can now add orders from multiple shops and easily search or filter orders within a route. Shops can also select the start and end locations based on the chosen shops in the route.`,
              level: 1,
              childContent: [
                {
                  type: "image",
                  content: "multi_shop_route",
                  width: isDesktop ? 950 : 250,
                  height: isDesktop ? 242 : 64,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "Delivery Zones in Order",
          level: 0,
          childContent: [
            {
              type: "text",
              content: `Essentials and Above users can now view Delivery Zones while creating or editing orders, as well as in the order details sidecar. Applies to Florist delivered and incoming wire orders`,
              level: 1,
              childContent: [
                {
                  type: "image",
                  content: "zone_name",
                  width: isDesktop ? 950 : 250,
                  height: isDesktop ? 242 : 64,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 34 - 09/11/2024",
    title: Localise(messages, "Release 34 - 09/11/2024"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Print Enhancements:",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "You can now print artifacts from the sidecar and the order tile on the Order Dashboard, with a check mark displayed after each artifact is printed.",
              level: 1,
              childContent: [
                {
                  type: "image",
                  content: "print_enhancement",
                  width: isDesktop ? 950 : 250,
                  height: isDesktop ? 242 : 64,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Mileage Display in Create Order Screen:",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "In the Create Order screen, selecting a delivery method now shows the mileage based on the recipient's address.",
              level: 1,
              childContent: [],
            },
            {
              type: "image",
              content: "mileage_create_order",
              width: isDesktop ? 652 : 260,
              height: isDesktop ? 388 : 155,
              webCustomStyle: { resizeMode: "contain" },
              level: 0,
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Label Change for Incoming Order Delivery Method:",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                'Incoming orders will now display the delivery method as "Florist Delivered" instead of "Wireout".',
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
      ],
    },
  },
  {
    name: "Release 33 - 09/03/2024",
    title: Localise(messages, "Release 33 - 09/03/2024"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Phone Out & Pay Later Orders for Essentials",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "The Pay Later feature allows customers to place orders and pay at pickup",
                  level: 1,
                },
                {
                  type: "image",
                  content: "pay_later_help",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 648 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                },
                {
                  type: "text",
                  content:
                    "Phone Out orders let you contact florists outside the Mercury network to fulfill orders.",
                  level: 1,
                },
                {
                  type: "image",
                  content: "phone_out_help",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 648 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
            {
              type: "text",
              content: "Draft Orders",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "This feature enables you to begin an order and save it for later completion, ensuring that all progress is preserved.",
                  level: 1,
                },
                {
                  type: "image",
                  content: "draft_order_help_1",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 648 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
                {
                  type: "image",
                  content: "draft_order_help_2",
                  width: isDesktop ? 983 : 200,
                  height: isDesktop ? 250 : 100,
                  webCustomStyle: { resizeMode: "contain" },
                  mobileCustomStyle: { aspectRatio: 3 },
                  level: 0,
                },
              ],
            },
            {
              type: "text",
              content: "Credit Card Settlement Report",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "This feature provides a comprehensive summary of credit card transactions, including transaction details, approval codes, and monetary amounts.",
                  level: 1,
                },
                {
                  type: "image",
                  content: "CC_settle_help_1",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 648 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
                {
                  type: "image",
                  content: "CC_settle_help_2",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 648 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
                {
                  type: "image",
                  content: "CC_settle_help_3",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 648 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
                {
                  type: "image",
                  content: "CC_settle_help_4",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 648 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
            {
              type: "text",
              content: "Timed Delivery",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "With the help of this feature, customers can request timed delivery, ensuring orders arrive by a specific time.",
                  level: 1,
                },
                {
                  type: "image",
                  content: "timed_delivery_help_1",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 500 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
                {
                  type: "image",
                  content: "timed_delivery_help_2",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 250 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
            {
              type: "text",
              content: "Split Payment",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "This feature provides customers with the flexibility to split the total cost of an order across multiple payment methods, such as credit/debit cards, Paid Elsewhere, Cash/Check, or other options.",
                  level: 1,
                },
                {
                  type: "image",
                  content: "split_payment_help_1",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 500 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
                {
                  type: "image",
                  content: "split_payment_help_2",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 250 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
            {
              type: "text",
              content: "Aged Analysis Report",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "The Aged Analysis report helps users assess credit risk by tracking aging, spotting late payments, and managing credit limits and potential unpaid debts.",
                  level: 1,
                },
                {
                  type: "image",
                  content: "aged_analysis_report_help_1",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 600 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
                {
                  type: "image",
                  content: "aged_analysis_report_help_2",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 350 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
                {
                  type: "image",
                  content: "aged_analysis_report_help_3",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 500 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
                {
                  type: "image",
                  content: "aged_analysis_report_help_4",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 500 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
            {
              type: "text",
              content: "Design Center",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "This feature lets users assign designers to orders, track order statuses, and manage orders by designer, including assigning multiple orders, grouping by designer, and identifying unassigned orders.",
                  level: 1,
                },
                {
                  type: "image",
                  content: "design_center_help",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 400 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
            {
              type: "text",
              content: "Alacarte Billing",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Essentials users now have access to the Advance Route Optimization feature, which can be enabled through the Add-on Feature tab by Shop Admin. The self-service Ala Carte Billing allows shop administrators to manage subscriptions by selecting and paying for individual features as needed.",
                  level: 1,
                },
                {
                  type: "text",
                  content:
                    "Advance Route Optimization: If your route includes more than 25 stops, timed deliveries, or multiple shop pick-up points, you can now create and optimize these routes by enabling the Advance Route Optimization feature. A nominal fee will apply based on your usage.",
                  level: 1,
                },
                {
                  type: "image",
                  content: "alacarte_billing_help",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 400 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
            {
              type: "text",
              content: "Redelivery of Delivery Service Requests",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "This feature enables you to redeliver completed or attempted deliveries, delivery service requests to the same location.",
                  level: 1,
                },
                {
                  type: "image",
                  content: "redelivery_help",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 400 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
            {
              type: "text",
              content: "Rerouting Delivery",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "This feature automates fulfillment error handling for Delivery Service Orders, letting FTD Delivery Service automatically request a new provider if the current one cancels. This ensures uninterrupted service. If rerouting fails, the user will see the order and delivery request in needs action.",
                  level: 1,
                },
                {
                  type: "image",
                  content: "rerouting_help",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 400 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
            {
              type: "text",
              content: "Funeral log",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "A streamlined tool to keep your shop organized and efficient by effortlessly managing and tracking funeral services, including creating and managing funeral logs, adding to orders, and printing acknowledgment lists.",
                  level: 1,
                },
                {
                  type: "image",
                  content: "funeral_log_help_1",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 500 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
                {
                  type: "image",
                  content: "funeral_log_help_2",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 500 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
            {
              type: "text",
              content: "Upfront Delivery Charges",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: `You can now view delivery service charges upfront when selecting "Delivery Service" from orders and in the Delivery Service Request when entering an address. This ensures clear pricing and helps you make informed decisions.`,
                  level: 1,
                },
                {
                  type: "image",
                  content: "upfront_delivery_charges_help_1",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 450 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
                {
                  type: "image",
                  content: "upfront_delivery_charges_help_2",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 400 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
            {
              type: "text",
              content: "Price and Date change enhancements",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: `Enhancement on price and date change workflow now provides clear visibility into the latest requested, approved, or denied updates in order listings and the sidecar.`,
                  level: 1,
                },
                {
                  type: "image",
                  content: "price_date_change_help",
                  width: isDesktop ? 983 : 295,
                  height: isDesktop ? 400 : 194,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 32 - 09/02/2024",
    title: Localise(messages, "Release 32 - 09/02/2024"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Delivery Info on Tri-Fold Card Printing:",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                'Delivery instructions can now be printed on the third section of tri-fold card, by selecting the "Recipient Top Half/ Delivery Information Bottom Half" option in the Florist/Recipient Info Placement managed under Printing Preferences in Shop settings. Premium users also have the option to print a QR code.',
              level: 1,
              childContent: [
                {
                  type: "image",
                  content: "delivery_info_on_trifold_1",
                  width: isDesktop ? 619 : 280,
                  height: isDesktop ? 500 : 226,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "delivery_info_on_trifold_2",
                  width: isDesktop ? 781 : 260,
                  height: isDesktop ? 441 : 147,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Enhanced Delivery Instructions Management:",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Users can now add or edit delivery instructions directly from the order detail sidecar.",
              level: 1,
              childContent: [],
            },
            {
              type: "image",
              content: "enhanced_delivery_instruction",
              width: isDesktop ? 948 : 280,
              height: isDesktop ? 407 : 120,
              webCustomStyle: { resizeMode: "contain" },
              level: 0,
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Sidecar Retention After Order Edit:",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "After editing and submitting an order, users will stay on the same order in the order listing page, with the sidecar remaining open.",
              level: 1,
              childContent: [],
            },
            {
              type: "image",
              content: "sidecar_retention",
              width: isDesktop ? 950 : 288,
              height: isDesktop ? 385 : 117,
              webCustomStyle: { resizeMode: "contain" },
              level: 0,
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Recipe Addition and Selection:",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "In the Product Catalog, users can now select and add recipes, which can be utilized when placing orders.",
              level: 1,
              childContent: [],
            },
            {
              type: "image",
              content: "recipe_addition_and_selection",
              width: isDesktop ? 722 : 280,
              height: isDesktop ? 507 : 197,
              webCustomStyle: { resizeMode: "contain" },
              level: 0,
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
      ],
    },
  },
  {
    name: "Release 31 - 08/28/2024",
    title: Localise(messages, "Release 31 - 08/28/2024"),
    component: {
      childContent: [
        {
          type: "text",
          content: "CVV Code is not required for Future Purchases:",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "You can now opt to use your Saved Credit card without a need to provide CVV code during checkout, enabling a quicker and smoother transaction process for future purchases.",
              level: 1,
              childContent: [
                {
                  type: "image",
                  content: "save_cvv_checkout",
                  width: isDesktop ? 942 : 295,
                  height: isDesktop ? 668 : 209,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "“+Add Contact” in Email Validation:",
          level: 0,
          childContent: [
            {
              type: "text",
              content: `This feature allows users to create additional receive-only email addresses by adding "+Add Contact" tag to their primary email address. All emails sent to these addresses will be delivered to the main inbox.`,
              level: 1,
              childContent: [],
            },
            {
              type: "image",
              content: "add_contact_email",
              width: isDesktop ? 818 : 260,
              height: isDesktop ? 331 : 105,
              webCustomStyle: { resizeMode: "contain" },
              level: 0,
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Hover Descriptions for All Icons:",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "We have added hover descriptions to all icons across our application, providing users with helpful text descriptions when they hover over each icon.",
              level: 1,
              childContent: [],
            },
            {
              type: "image",
              content: "hover_desc_1",
              width: isDesktop ? 900 : 245,
              height: isDesktop ? 396 : 108,
              webCustomStyle: { resizeMode: "contain" },
              level: 0,
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "image",
              content: "hover_desc_2",
              width: isDesktop ? 950 : 250,
              height: isDesktop ? 514 : 135,
              webCustomStyle: { resizeMode: "contain" },
              level: 0,
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "image",
              content: "hover_desc_3",
              width: isDesktop ? 989 : 250,
              height: isDesktop ? 442 : 112,
              webCustomStyle: { resizeMode: "contain" },
              level: 0,
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content:
            "Support for Multiple Email Addresses in On-Demand Invoices:",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "We now support entering multiple email addresses when creating on-demand invoices, making it easier to send invoices to multiple recipients at once.",
              level: 1,
              childContent: [],
            },
            {
              type: "image",
              content: "email_invoice_1",
              width: isDesktop ? 995 : 250,
              height: isDesktop ? 378 : 95,
              webCustomStyle: { resizeMode: "contain" },
              level: 0,
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "image",
              content: "email_invoice_2",
              width: isDesktop ? 900 : 250,
              height: isDesktop ? 564 : 157,
              webCustomStyle: { resizeMode: "contain" },
              level: 0,
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content:
            "Enhanced UI Update Experience: Silent Refresh and Pop-Up Notifications:",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "To improve the user experience during UI updates:",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content: "Silent Refresh:",
                  level: 0,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "When a UI update occurs and the user is not logged in, the system will now refresh silently in the background without displaying a pop-up.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "Pop-Up Behavior:",
                  level: 0,
                  childContent: [
                    {
                      type: "text",
                      content: "Create Order and Edit Order Screens:",
                      level: 0,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "Users will receive a pop-up notification to save their work before applying updates. After saving, the screen will refresh, and the saved work will be restored.",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                    {
                      type: "text",
                      content:
                        "Other Screens (e.g., Create Route, Create Product, Addon, Collection):",
                      level: 0,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "Users will see a pop-up asking if they want to update now or later. If they choose to update now, the work will not be saved. If they choose to update later, the pop-up will reappear in 30 minutes. However, if the user navigates to a different page within that time, the system will refresh silently without showing the pop-up again.",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                    {
                      type: "text",
                      content: "Multiple Tabs:",
                      level: 0,
                      childContent: [
                        {
                          type: "text",
                          content: `If the user has multiple tabs open, the pop-up will appear on the active tab if it’s on the "Create Order" or "Create Route" screen, with the same options as above.`,
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Fixed Facility Search: Accurate Addresses Now Displayed",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "The facility search did not work correctly in production, with some results displaying incorrect or irrelevant addresses instead of proper funeral home addresses. The issue has been addressed, and the search now displays the correct addresses.",
              level: 1,
              childContent: [
                {
                  type: "image",
                  content: "fix_facility_1",
                  width: isDesktop ? 900 : 250,
                  height: isDesktop ? 294 : 82,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "fix_facility_2",
                  width: isDesktop ? 664 : 250,
                  height: isDesktop ? 310 : 117,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 30 - 07/31/2024",
    title: Localise(messages, "Release 30 - 07/31/2024"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Core and Above",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Order Detail Sidecar Header",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "The header will now remain fixed when users scroll through the Order details tab or any other tabs in the order sidecar.",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "order_details_header",
                      width: isDesktop ? 942 : 295,
                      height: isDesktop ? 920 : 288,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content:
                "Shop Settings - Tab Title, Tax Rate, & Toggle for fees to be Taxable",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Added a toggle for each fee to decide whether to calculate tax.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: `Renamed "Delivery & Fees" to "Delivery, Pickup & Fees” in Shop Settings.`,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: `A new section called "Tax Settings" will now display under Shop Details.`,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Tax Rules selection for the store has been moved to the new Tax Settings section.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "tax_rules1",
                  width: isDesktop ? 957 : 290,
                  height: isDesktop ? 435 : 132,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "tax_rules2",
                  width: isDesktop ? 916 : 278,
                  height: isDesktop ? 404 : 122,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ Connect and above",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Email and eFlorist Order editing updates",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: `When user trying to edit the email or tel eflorist orders in case if they want to change the country, state, city, zip, or order price will now prompt a popup asking if the user wants to recalculate Fees & Tax.`,
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "recalculate_tax_popup",
                      width: isDesktop ? 950 : 271,
                      height: isDesktop ? 445 : 127,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ Standard and above",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Popup when User clicks on “Delete Card” Button",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Users will now see a confirmation popup when deleting a card from Create Order or from the customer directory pages.",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "delete_card_popup1",
                      width: isDesktop ? 961 : 275,
                      height: isDesktop ? 441 : 126,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "delete_card_popup2",
                      width: isDesktop ? 954 : 281,
                      height: isDesktop ? 437 : 128,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 29 - 06/27/2024",
    title: Localise(messages, "Release 29 - 06/27/2024"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Core and Above",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Duplicate Customer entry Error Popup",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "An error popup now appears when a user enters a non-unique information and allows them to select the correct option or modify their input",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "OLD",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "customer_entry_error1",
                      width: isDesktop ? 907 : 272,
                      height: isDesktop ? 589 : 177,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                    },
                  ],
                },
                {
                  type: "text",
                  content: "NEW",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "customer_entry_error2",
                      width: isDesktop ? 983 : 295,
                      height: isDesktop ? 648 : 194,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "Enhanced Customer Search",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "When typing in the first/last name, phone, or email fields, a dropdown with suggestions will appear. Selecting from the dropdown will override the entered information, though users have the option to ignore the suggestions if they prefer.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "OLD",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "enhanced_customer_search1",
                      width: isDesktop ? 955 : 287,
                      height: isDesktop ? 757 : 227,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                    },
                  ],
                },
                {
                  type: "text",
                  content: "NEW",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "enhanced_customer_search2",
                      width: isDesktop ? 940 : 282,
                      height: isDesktop ? 651 : 195,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "Sales Tax Summary Report Enhancement",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "The total tax rate is now shown, with the Fees column split to accurately display taxable and non-taxable fees. All values are shown with two decimal places, ensuring numerical accuracy and consistency.",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "sales_tax_report_enhancement",
                      width: isDesktop ? 999 : 280,
                      height: isDesktop ? 286 : 80,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 28 - 06/11/2024",
    title: Localise(messages, "Release 28 - 06/11/2024"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Customer Merge & Purge",
              isDefaultFont: true,
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: `You can now merge 1 customer profile to another customer profile`,
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: `Perform from the Customer Profile`,
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "customer_merge_purge1",
                      width: isDesktop ? 800 : 150,
                      height: isDesktop ? 300 : 150,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "customer_merge_purge2",
                      width: isDesktop ? 800 : 150,
                      height: isDesktop ? 300 : 150,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "Bugs fixes and Enhancements",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Create Delivery Service Request Pickup time Validation",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Added validation to alert users if the pick-up time selected is in the past after keeping the create delivery service request page open for a long time. Users will see validation message if pick up time is past or not valid upon submission.",
                  level: 1,
                },
              ],
            },
            {
              type: "text",
              content: "Create Route Button",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: `The "Create Route" button is now displayed upfront, even when no shops are selected. Previously, this button was shown only when a shop was selected.`,
                  level: 1,
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 27 - 05/28/2024",
    title: Localise(messages, "Release 27 - 05/28/2024"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Core and Above",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Delivery Enhancements",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: "Change sequence of stops in route by drag and drop",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "route_dnd_help",
                      width: isDesktop ? 450 : 254,
                      height: isDesktop ? 374 : 107,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                    },
                  ],
                },
                {
                  type: "text",
                  content: "Filter on Route page filters orders in Map as well",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "route_map_filters",
                      width: isDesktop ? 884 : 254,
                      height: isDesktop ? 374 : 107,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "Improve delivery pages with enhanced visuals for create, search, sort, and filter.",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "delivery_UI_enhancements",
                      width: isDesktop ? 884 : 200,
                      height: isDesktop ? 300 : 100,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "Sending florist can now see Attempted delivery updates from delivery service in order journey",
                  level: 1,
                },
                {
                  type: "text",
                  content: "New price updates for FTD Delivery Service",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: `New Price for FTD delivery service can be viewed on FTD Delivery Service Terms and Conditions`,
                      level: 1,
                      childContent: [
                        {
                          type: "image",
                          content: "FTD_DS_legal",
                          width: isDesktop ? 884 : 254,
                          height: isDesktop ? 374 : 107,
                          webCustomStyle: { resizeMode: "contain" },
                          level: 0,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 26 - 04/24/2024",
    title: Localise(messages, "Release 26 - 04/24/2024"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Core",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Auto Print option for Order Related Messages",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "We have introduced the Auto Print feature for order-related messages, ensuring automatic printing of incoming and outgoing messages related to wire orders.",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "auto_print_option",
                      width: isDesktop ? 884 : 254,
                      height: isDesktop ? 374 : 107,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Identify when order is printed",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Easily identify printed and unprinted orders with the checkmark icon on the printer symbol in the sidecar and order tile.",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "order_printed",
                      width: isDesktop ? 967 : 290,
                      height: isDesktop ? 422 : 127,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Ability to Change Font in Legal Card Message",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "The ability to customize the Legal Card Message by adjusting the font size to personal preference has been provided.",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "font_card_message",
                      width: isDesktop ? 488 : 293,
                      height: isDesktop ? 471 : 283,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Option to Mark as Read from Meatballs",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Introducing the 'Mark as Read' option in the meatballs menu within the order tile, enabling users to easily mark orders as read.",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "mark_as_read",
                      width: isDesktop ? 818 : 286,
                      height: isDesktop ? 365 : 128,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Filter Orders with Customer Name in Order Listing",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Users now have the capability to search for orders using the customer's name directly within the Order Listing page.",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "filter_orders_customer_name",
                      width: isDesktop ? 953 : 280,
                      height: isDesktop ? 400 : 118,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Walk-in will not Auto print Invoice",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "The auto-print function will refrain from automatically printing Walk-in orders.",
                  level: 1,
                },
              ],
            },
            {
              type: "text",
              content: "Pickup Order will not Auto print Invoice.",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "The auto-print function will refrain from automatically printing invoices for Pickup Orders.",
                  level: 1,
                },
              ],
            },
            {
              type: "text",
              content: "Showing Debit Memo Transactions",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Debit Memo transactions are now displayed in the UI, allowing for the application of credit against them.",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "debit_memo_transaction",
                      width: isDesktop ? 997 : 299,
                      height: isDesktop ? 359 : 108,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "House Account Statement",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "HA statement pdf will now be created with customer name instead of account # when downloading customer statements.",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "house_account_statement",
                      width: isDesktop ? 944 : 270,
                      height: isDesktop ? 382 : 109,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
        },
        {
          type: "text",
          content:
            "“Attempted Delivery” action in order listing and order details sidecar",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "The “Attempted Delivery” delivery status option is now available in the Meat Balls menu on the order listing page and in the order details actions drop-down menu.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "“Not Delivered” delivery status is renamed as “Attempted Delivery” option.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "Order Details:",
              level: 0,
              childContent: [
                {
                  type: "image",
                  content: "attempted_delivery_sidecar",
                  width: isDesktop ? 944 : 270,
                  height: isDesktop ? 382 : 109,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "Order MeatBalls:",
              level: 0,
              childContent: [
                {
                  type: "image",
                  content: "attempted_delivery_meatballs",
                  width: isDesktop ? 944 : 270,
                  height: isDesktop ? 382 : 109,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                  childContent: [],
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "Zip Outline Icon in Route Detail Page and Map Display",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "You can now see Zip outline tool tip for this icon in Route Detail Page.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "The zip outline will be displayed in a distinguished gray color border on the Map.",
              level: 1,
              childContent: [
                {
                  type: "image",
                  content: "route_zip_outline",
                  width: isDesktop ? 500 : 270,
                  height: isDesktop ? 382 : 109,
                  webCustomStyle: { resizeMode: "contain" },
                  level: 0,
                  childContent: [],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 25 - 04/17/2024",
    title: Localise(messages, "Release 25 - 04/17/2024"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Core",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "FTD Delivery Service",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Opting for “FTD Delivery Service” as Delivery provider will provide greater coverage, pricing benefits and ASAP delivery for the orders",
                  level: 1,
                },
                {
                  type: "text",
                  content:
                    "FTD Delivery Service Pricing and Terms and Conditions are available in the Mercury HQ Help Page",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "FTD_DS_TC",
                      width: isDesktop ? 955 : 200,
                      height: isDesktop ? 710 : 150,
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "FTD Admins will enable the FTD Delivery Service as Provider for Shops who wants to opt for FTD Delivery Service",
                  level: 1,
                },
                {
                  type: "text",
                  content:
                    "Users can enable/disable Delivery Service from Shop Settings as needed.",
                  level: 1,
                },
                {
                  type: "text",
                  content:
                    "Pick up time can be selected from every 30 mins interval drop list for the Orders and Delivery Service Requests for ASAP deliveries via FTD Delivery Service",
                  level: 1,
                },
                {
                  type: "text",
                  content:
                    "FTD Delivery Service will assign the order to most efficient delivery partner as for the recipient address",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "FTD_DS_create_request",
                      width: isDesktop ? 900 : 300,
                      height: isDesktop ? 550 : 200,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "FTD_DS_create_order_request",
                      width: isDesktop ? 900 : 300,
                      height: isDesktop ? 470 : 150,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "Once order is submitted to delivery service, users can cancel and resubmit order to FTD delivery service. However, they cannot modify an existing one.",
                  level: 1,
                },
              ],
            },
            {
              type: "text",
              content: "Price and Date change Notification Enhancements",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Design changes have been done, so users can clearly and easily identify price and date changes in the Order Listing page. ",
                  level: 1,
                },
                {
                  type: "text",
                  content:
                    "When there is a price or date change pending both requester and receiver of price change request will see the “New Price”/” New Date” and label “Pending” in Order Listing",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Sender of price/date change requests will see pending under the requested date or price",
                      level: 1,
                      childContent: [
                        {
                          type: "image",
                          content: "outgoing_date_price_request",
                          width: isDesktop ? 900 : 300,
                          height: isDesktop ? 100 : 30,
                          webCustomStyle: { resizeMode: "contain" },
                          mobileCustomStyle: { aspectRatio: 10 },
                          level: 0,
                          childContent: [],
                        },
                      ],
                    },
                    {
                      type: "text",
                      content:
                        "Receiver of price/date change request will see the Status as Approve Price/ Approve Date ",
                      level: 1,
                      childContent: [
                        {
                          type: "image",
                          content: "approve_date_price_request",
                          width: isDesktop ? 1400 : 300,
                          height: isDesktop ? 170 : 40,
                          webCustomStyle: { resizeMode: "contain" },
                          mobileCustomStyle: { aspectRatio: 8 },
                          level: 0,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "When there is approved/denied response, requester will see the “New Price”/” New Date” along with label Approved/ Denied and status as Price Approved/ Price Denied/ Date Approved/ Date Denied",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "date_price_response",
                      width: isDesktop ? 1400 : 300,
                      height: isDesktop ? 270 : 60,
                      webCustomStyle: { resizeMode: "contain" },
                      mobileCustomStyle: { aspectRatio: 5 },
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "Route Enhancements",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: "Select Multiple/All orders and add to the route",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "route_multi_select_orders",
                      width: isDesktop ? 500 : 250,
                      height: isDesktop ? 300 : 150,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "Date Filter is added in route page to provide flexibility to select orders from different delivery date than route date",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Default route page shows orders of delivery date same as route date",
                      level: 1,
                    },
                    {
                      type: "text",
                      content:
                        "One can select a date range allowed from past 7 days till future 4 days and add orders to route.",
                      level: 1,
                    },
                  ],
                },
                {
                  type: "text",
                  content: "Search Orders and add orders to the route",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Search parameters- Order number, recipient name, city, state, zip code",
                      level: 1,
                      childContent: [
                        {
                          type: "image",
                          content: "search_date_filters_route",
                          width: isDesktop ? 500 : 150,
                          height: isDesktop ? 500 : 150,
                          webCustomStyle: { resizeMode: "contain" },
                          level: 0,
                          childContent: [],
                        },
                      ],
                    },
                    {
                      type: "text",
                      content:
                        "Show directions shows updated map - Saves route and show directions in map",
                      level: 1,
                    },
                    {
                      type: "text",
                      content:
                        "Take action row is sticky, scrolls throughout the route page ",
                      level: 1,
                    },
                    {
                      type: "text",
                      content:
                        "Drivers can edit route and sequence stops from driver dashboard. Applies for Standard and Above entitlements.",
                      level: 1,
                    },
                    {
                      type: "text",
                      content:
                        "View Zip Outline and 15 mile shop radius in Routes map by clicking on icons provided next to lasso tool",
                      level: 1,
                      childContent: [
                        {
                          type: "image",
                          content: "zip_15mile_route",
                          width: isDesktop ? 600 : 200,
                          height: isDesktop ? 480 : 150,
                          webCustomStyle: { resizeMode: "contain" },
                          level: 0,
                          childContent: [],
                        },
                      ],
                    },
                    {
                      type: "text",
                      content: "Lasso tool can be used in full screen as well.",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "Click on lasso tool icon from route detail page",
                          level: 1,
                        },
                        {
                          type: "text",
                          content: "Go to Full Screen view",
                          level: 1,
                        },
                        {
                          type: "text",
                          content: "Draw on map using freehand",
                          level: 1,
                        },
                        {
                          type: "text",
                          content:
                            "Orders coming under selected range, will be added to route and saved",
                          level: 1,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Existing features have been moved from Premium to Standard",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: "All existing reports",
                  level: 1,
                },
                {
                  type: "text",
                  content: "Open Item House Accounts",
                  level: 1,
                },
                {
                  type: "text",
                  content: "Credit & Debit memos",
                  level: 1,
                },
                {
                  type: "text",
                  content: "Late Fees",
                  level: 1,
                },
                {
                  type: "text",
                  content: "Email & SMS Customizations",
                  level: 1,
                },
                {
                  type: "text",
                  content: "Order Status Page",
                  level: 1,
                },
              ],
            },
            {
              type: "text",
              content: "Statement & Invoice Customization",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Shop Settings > Shop Details > Printing Preferences",
                  level: 1,
                },
                {
                  type: "text",
                  content: "Tri-Fold (new look & feel)",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "trifold_new_look",
                      width: isDesktop ? 630 : 200,
                      height: isDesktop ? 480 : 150,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "House Account Statements",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Enable or Disable printing of Store Information",
                      level: 1,
                    },
                  ],
                },
                {
                  type: "text",
                  content: "Invoices",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Create a personal shop message",
                      level: 1,
                    },
                    {
                      type: "text",
                      content: "Include Care Instructions",
                      level: 1,
                    },
                    {
                      type: "image",
                      content: "order_invoices",
                      width: isDesktop ? 630 : 200,
                      height: isDesktop ? 480 : 150,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "Invoices - Sections 3 & 4",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "You can customize what gets printed in these sections",
                      level: 1,
                    },
                    {
                      type: "image",
                      content: "invoices_section34",
                      width: isDesktop ? 500 : 200,
                      height: isDesktop ? 430 : 150,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "invoices_section34_customise",
                      width: isDesktop ? 200 : 200,
                      height: isDesktop ? 140 : 150,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "Multiple Contacts",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "You can now add multiple contacts to your customer profiles.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Perform from the Customer Profile",
                      level: 1,
                    },
                    {
                      type: "text",
                      content: "Perform from the Create Order Page",
                      level: 1,
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "When a contact is selected in the create order page, their information will be displayed on the printed materials, as well as the Order Details SideCar.",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "add_multiple_contacts_customer",
                      width: isDesktop ? 320 : 100,
                      height: isDesktop ? 500 : 150,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "add_multiple_contacts_create_order",
                      width: isDesktop ? 600 : 300,
                      height: isDesktop ? 320 : 150,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "contact_order_details",
                      width: isDesktop ? 600 : 300,
                      height: isDesktop ? 300 : 150,
                      webCustomStyle: { resizeMode: "contain" },
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "Email Invoice",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "You can now email an invoice directly to a customer.",
                  level: 1,
                },
                {
                  type: "text",
                  content:
                    "Find the order, access the order details sidecar, click actions, click “Email Invoice”",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "email_invoice_help",
                      width: isDesktop ? 800 : 300,
                      height: isDesktop ? 270 : 100,
                      webCustomStyle: { resizeMode: "contain" },
                      mobileCustomStyle: { aspectRatio: 3 },
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ Premium",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "QR Codes | Handheld Scanners",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: "Handheld Scanner",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Order orchestration has never been easier! ",
                      level: 1,
                    },
                    {
                      type: "text",
                      content:
                        "We’ve introduced QR Codes previously.  Use the handheld scanner with the QR code functionality.",
                      level: 1,
                    },
                  ],
                },
                {
                  type: "text",
                  content: "Shop Settings",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Enable QR codes to print on your print artifacts",
                      level: 1,
                      childContent: [
                        {
                          type: "image",
                          content: "qr_shop_settings",
                          width: isDesktop ? 800 : 300,
                          height: isDesktop ? 320 : 130,
                          webCustomStyle: { resizeMode: "contain" },
                          level: 0,
                          childContent: [],
                        },
                      ],
                    },
                    {
                      type: "text",
                      content: "QR Code Page",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "To start scanning orders, simply click on the QR Code icon in the header",
                          level: 1,
                        },
                        {
                          type: "text",
                          content:
                            "Once on the QR code page, scan one or many orders",
                          level: 1,
                        },
                        {
                          type: "text",
                          content:
                            "Move orders through the workflow by scanning the QR Code on your computer monitor, or simply click the corresponding action button",
                          level: 1,
                        },
                        {
                          type: "image",
                          content: "qr_code_page",
                          width: isDesktop ? 600 : 300,
                          height: isDesktop ? 320 : 150,
                          webCustomStyle: { resizeMode: "contain" },
                          level: 0,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 24 - 03/20/2024",
    title: Localise(messages, "Release 24 - 03/20/2024"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Connect",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "New Entitlement – MHQ Connect",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "We have created a new entitlement, 'MHQ Connect,' strategically positioned between our existing Core and Standard entitlements.",
                  level: 1,
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Order Status Link",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Users can now view the order status link within the confirmation email.",
                  level: 1,
                },
                {
                  type: "image",
                  content: "order_status_link_email",
                  width: isDesktop ? 944 : 270,
                  height: isDesktop ? 313 : 89,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Enable Email & SMS Customization",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Florists now have the capability to customize the content of both email and SMS messages directly from the MHQ UI.",
                  level: 1,
                },
                {
                  type: "image",
                  content: "enable_email_and_sms",
                  width: isDesktop ? 659 : 285,
                  height: isDesktop ? 401 : 174,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Enabling Existing reports of premium entitlement to Standard entitlement",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Standard entitlement users will now have access to the Premium entitlement reports like Aged Analysis, Product comparison, Product sales, and Sales Summary.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "standard_entitlement_with_reports",
                  width: isDesktop ? 732 : 280,
                  height: isDesktop ? 645 : 248,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Order Copy Logging Enhancement",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Now, when an order is copied, the system will log this action in Order Journey of the new order.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "order_copy_logging",
                  width: isDesktop ? 613 : 281,
                  height: isDesktop ? 620 : 284,
                  level: 0,
                  childContent: [],
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Delivery",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Save Route Draft",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "After adding orders to the route, users can now save the route draft by clicking on the 'Save Draft,' 'Show on Map,' or 'Optimize' buttons.",
                  level: 1,
                },
                {
                  type: "image",
                  content: "save_route_draft",
                  width: isDesktop ? 602 : 271,
                  height: isDesktop ? 279 : 126,
                  level: 0,
                  childContent: [],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 23 - 02/22/2024",
    title: Localise(messages, "Release 23 - 02/22/2024"),
    component: {
      childContent: [
        {
          type: "text",
          content: "MHQ Core",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Help Page Redesign",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: "OLD",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "help_page_old_1",
                      width: isDesktop ? 936 : 267,
                      height: isDesktop ? 439 : 125,
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "help_page_old_2",
                      width: isDesktop ? 344 : 155,
                      height: isDesktop ? 661 : 297,
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "NEW",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "help_page_new_1",
                      width: isDesktop ? 949 : 271,
                      height: isDesktop ? 464 : 133,
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "help_page_new_2",
                      width: isDesktop ? 955 : 273,
                      height: isDesktop ? 457 : 131,
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "help_page_new_3",
                      width: isDesktop ? 951 : 272,
                      height: isDesktop ? 460 : 131,
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "Verint Flow Removal",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "The Verint (Feedback) icon has been removed from the navigation menu, as we transitioned to our customized form for improved efficiency and user experience across Mercury HQ.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "OLD",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "verint_feedback_old",
                      width: isDesktop ? 970 : 291,
                      height: isDesktop ? 692 : 208,
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "NEW",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "verint_feedback_new_1",
                      width: isDesktop ? 397 : 179,
                      height: isDesktop ? 617 : 278,
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "verint_feedback_new_2",
                      width: isDesktop ? 936 : 267,
                      height: isDesktop ? 419 : 120,
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "Create Order Icon Transformation",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "We have revamped the Create Order icon in the navigation from the traditional '+' symbol to a new 'sheet with pen' icon. This change is implemented to align the '+' symbol with the broader concept of 'create' or 'add' functionalities throughout Mercury HQ.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "OLD",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "create_order_icon_old",
                      width: isDesktop ? 419 : 168,
                      height: isDesktop ? 668 : 267,
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "NEW",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "create_order_icon_new",
                      width: isDesktop ? 475 : 238,
                      height: isDesktop ? 583 : 292,
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 22 - 02/09/2024",
    title: Localise(messages, "Release 22 - 02/09/2024"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Special Instructions on Invoice",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Special Instructions are now printed on the invoice, ensuring no crucial information is missed.",
                  level: 1,
                },
                {
                  type: "text",
                  content: "OLD",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "invoice_without_special_instruction",
                      width: isDesktop ? 692 : 277,
                      height: isDesktop ? 638 : 255,
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "NEW",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "invoice_with_special_instruction",
                      width: isDesktop ? 690 : 276,
                      height: isDesktop ? 633 : 253,
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 21 - 01/23/2024",
    title: Localise(messages, "Release 21 - 01/23/2024"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Order Details Sidecar - Tab Restructure",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "We have made some improvements to the Order Details Sidecar. ",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Order Details tab - will display things like the recipient info, products, recopies, card message, customer info, and more.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Delivery tab - will display the recipient address, delivery date, delivery instructions, and delivery method. This is also where you can change the delivery method to Delivery Service and have one of our partners deliver the order for you.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Order Journey tab - will display the audit trail of the order, life cycle events, messages between florists, and more.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Payment tab - will display the payment details, as well as the",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "NEW",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "order_detail_sidecar_new",
                      width: isDesktop ? 939 : 282,
                      height: isDesktop ? 400 : 120,
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "OLD",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "order_detail_sidecar_old",
                      width: isDesktop ? 936 : 281,
                      height: isDesktop ? 423 : 127,
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Sales Tax - Jurisdictional breakdowns",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Jurisdictional breakdowns are now displayed on the order details sidecar.",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "sales_tax_jurisdictional_breakdowns",
                      width: isDesktop ? 866 : 289,
                      height: isDesktop ? 439 : 146,
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Sales Tax Report - Reimagined",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Jurisdictional breakdowns are now displayed on this report.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Allowing for easier understanding on how to remit taxes.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "NOTE: Be sure to update your tax settings for local and wire orders, using ‘Point of Origin’ or ‘Destination’",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Shop Settings > Delivery & Pickup > Fees > Tax Rules",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "image",
                  content: "tax_jurisdiction_settings",
                  width: isDesktop ? 593 : 297,
                  height: isDesktop ? 87 : 44,
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Canada Delivery",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "When a shop resides in Canada, the MercuryHQ system will still help with your delivery needs.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Included with this functionality: Address Verification, Google API, Routing, Ad-Hoc stops, and more.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Push Notifications - Delivery Service Errors",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Get a push notification when there is a Delivery Service Error. No need to be tied to your desk with this mobile app functionality. Get push notifications wherever you are.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Push Notifications - Customizations",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Customize the push notifications that you want to see and disable the ones you don’t need.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "From your mobile app, click the hamburger menu (3 stacked lines) icon, and go to your profile.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Blue is enabled, grey is disabled.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "push_notifications_customizations",
                  width: isDesktop ? 387 : 258,
                  height: isDesktop ? 839 : 559,
                  level: 0,
                  childContent: [],
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ Premium",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Advanced Orchestration - QR Codes - Mobile",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "QR Codes allow shops to have advanced orchestration of their workflows.  Enable the QR codes in Shop Settings > Orders > Order Settings > QR Code.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Once enabled, the print artifacts will contain a QR Code. ",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "qr_codes_1",
                  width: isDesktop ? 966 : 290,
                  height: isDesktop ? 450 : 135,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Print Artifacts",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Order Detail, Designer Worksheet, and Invoice will contain the QR Codes.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "qr_codes_2",
                  width: isDesktop ? 826 : 248,
                  height: isDesktop ? 884 : 265,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Design",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "From the mobile app, scan an order, and move it through the workflow.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Orders > QR Code icon > select action",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "qr_codes_3",
                  width: isDesktop ? 387 : 258,
                  height: isDesktop ? 839 : 559,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "qr_codes_4",
                  width: isDesktop ? 387 : 258,
                  height: isDesktop ? 839 : 559,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "qr_codes_5",
                  width: isDesktop ? 387 : 258,
                  height: isDesktop ? 839 : 559,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Create Route",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Creating routes has never been more easier than using this multi-scan.  Quickly add multiple orders to a route with consecutive scans.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Delivery > Create route > QR Code icon",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "qr_codes_6",
                  width: isDesktop ? 387 : 258,
                  height: isDesktop ? 839 : 559,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "qr_codes_7",
                  width: isDesktop ? 387 : 258,
                  height: isDesktop ? 839 : 559,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Reprint Logic",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "While scanning an order, MercuryHQ will notify you if the print artifact needs to be reprinted because of updated information.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Gift Cards",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Gift Cards can be sold and redeemed from anywhere! A customer can purchase and redeem a gift card in store, over the phone, or on your Mercury Online Website.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Gift Card Registry",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "See all of your sold gift cards and current balances here.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "You can even resend the digital gift card email.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "Sell Gift Cards",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Product Catalog > Gift Cards",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "There will be two types of gift cards, physical and digital, and you can enable or disable sales.",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                    {
                      type: "text",
                      content: "Create Order > Product > GiftCard",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "Select if the customer is purchasing a physical gift card or digital.",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "Redeem Gift Cards",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "gift_cards_1",
                  width: isDesktop ? 939 : 282,
                  height: isDesktop ? 389 : 117,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "gift_cards_2",
                  width: isDesktop ? 942 : 295,
                  height: isDesktop ? 551 : 172,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "gift_cards_3",
                  width: isDesktop ? 950 : 237,
                  height: isDesktop ? 667 : 167,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Late Fees",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "You can charge late fees on past due house accounts.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Shop Settings > Payments > House Accounts > Charge Late Fees",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "Enable to change, Disable to not charge.  Enter the dollar amount. ",
                          level: 1,
                          childContent: [],
                        },
                        {
                          type: "text",
                          content:
                            "If a customer has a balance that was not paid before the new statement run, they will see the late fee.",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Email & SMS Customizations",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Customize what your customer sees when they receive notifications from your shop.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Shop Settings > Notifications",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "Enable/Disable SMS or Email notifications.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Customize the content.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "You can even add tokens, which are order attributes that can be pulled into the notifications.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "email_and_sms_customizations_1",
                  width: isDesktop ? 927 : 247,
                  height: isDesktop ? 610 : 163,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "email_and_sms_customizations_2",
                  width: isDesktop ? 924 : 264,
                  height: isDesktop ? 567 : 162,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Order Status Page",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Be sure to include the order status link to customer notifications.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "order_status_page_1",
                  width: isDesktop ? 927 : 247,
                  height: isDesktop ? 599 : 160,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Order Status Page is where customers can receive live status updates.  When they access the link, they will see all the important information regarding their order, including current status.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "order_status_page_2",
                  width: isDesktop ? 919 : 245,
                  height: isDesktop ? 617 : 165,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Pay Later Orders",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "A customer sometimes wants to place an order and come into the store and pickup and pay.  We call these Pay Later orders in MercuryHQ.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Create Order > Delivery Method = Pickup > Payment Type = Pay Later",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "pay_later_orders_1",
                  width: isDesktop ? 960 : 288,
                  height: isDesktop ? 487 : 146,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "You can spot these orders in the Orders Page from the Pay Later icon and see a call to action regarding payment.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "pay_later_orders_2",
                  width: isDesktop ? 933 : 280,
                  height: isDesktop ? 396 : 119,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Multi-Location Routes",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "The Create Route page has been improved to support the Florists that have multiple locations or multiple shops.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Single Route, Multi-Shop Code",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Create a route that has orders belonging to one or more shop codes.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "Single Route, Multi-Location",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Create a route that has orders belonging to one or more locations.",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "Pick which location the driver will start and end.",
                          level: 1,
                          childContent: [],
                        },
                        {
                          type: "text",
                          content:
                            "Pick up stop for Shop will get automatically added if order from another shop is added",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                    {
                      type: "text",
                      content:
                        "Confirm when route is expected to start to get real time traffic optimization",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "multi_location_routes_1",
                  width: isDesktop ? 939 : 282,
                  height: isDesktop ? 453 : 136,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "multi_location_routes_2",
                  width: isDesktop ? 912 : 274,
                  height: isDesktop ? 373 : 112,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "multi_location_routes_3",
                  width: isDesktop ? 908 : 272,
                  height: isDesktop ? 420 : 126,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Phone Out Order",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Sometimes there isn’t an active Mercury System at the shop who is going to fill an order for you.  If you have to give a wire order to a shop over the phone, indicate that it is a Phone Out order.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Create Order > Delivery Method = Phone Out",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "Type the code, name, or any details in the Florist Partner Name",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "phone_out_order",
                  width: isDesktop ? 922 : 277,
                  height: isDesktop ? 407 : 122,
                  level: 0,
                  childContent: [],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 20 - 01/11/2024",
    title: Localise(messages, "Release 20 - 01/11/2024"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Core",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Auto Print without enabling Auto Accept:",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Auto Print and Auto Accept are now Independent Toggles, allowing Auto Print to print the orders without Enabling Auto Accept for Orders.",
                  level: 1,
                },
                {
                  type: "text",
                  content: "NEW",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "auto_print_new",
                      width: isDesktop ? 619 : 279,
                      height: isDesktop ? 239 : 108,
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "OLD",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "auto_print_old",
                      width: isDesktop ? 614 : 276,
                      height: isDesktop ? 156 : 70,
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Duplicate Product Display:",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "If same product is added twice, now appears as 2 Products Instead of Increasing Quantity to 2, allowing florist to design based on Customer Needs.",
                  level: 1,
                },
                {
                  type: "image",
                  content: "duplicate_product",
                  width: isDesktop ? 620 : 279,
                  height: isDesktop ? 348 : 157,
                  level: 0,
                  childContent: [],
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Order Reorder Warning:",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "A warning message will appear upon clicking the Re-order button during the order creation process, to ensure confirmation before proceeding to the replacement of the entire order.",
              level: 1,
            },
            {
              type: "image",
              content: "order_reorder_warning",
              width: isDesktop ? 724 : 290,
              height: isDesktop ? 384 : 154,
              level: 0,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 19 - 01/10/2024",
    title: Localise(messages, "Release 19 - 01/10/2024"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Core",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Automatic Browser update with latest code:",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "When users are on screens like create/edit order or any other, a notification popup will be displayed if there are updates such as the addition of new code or a configuration version update.",
                  level: 1,
                },
                {
                  type: "image",
                  content: "automatic_browser_update",
                  width: isDesktop ? 724 : 290,
                  height: isDesktop ? 384 : 154,
                  level: 0,
                  childContent: [],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 18 - 11/29/2023",
    title: Localise(messages, "Release 18 - 11/29/2023"),
    component: {
      childContent: [
        {
          type: "text",
          content: "MercuryHQ Core and Above",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Ad-Hoc Tri-Fold Enclosure Card",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Need to create a tri-fold enclosure card outside of an order that’s in your system?",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Orders Page > Three Dots > Create Card",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "We’ll bring over your Shop Setting defaults, you just type the enclosure card message and recipient info.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "ad_hoc_tri_fold_enclosure_card_1",
                  width: 299,
                  height: 195,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "ad_hoc_tri_fold_enclosure_card_2",
                  width: isDesktop ? 960 : 288,
                  height: isDesktop ? 488 : 146,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "ad_hoc_tri_fold_enclosure_card_3",
                  width: isDesktop ? 591 : 236,
                  height: isDesktop ? 875 : 350,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Order Summary Report - Enhancements",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: "The Order Summary Report has been enhanced!",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "New Layout",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "More Data",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Structured & Organized",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "order_summary_report_enhancements",
                  width: isDesktop ? 950 : 253,
                  height: isDesktop ? 566 : 151,
                  level: 0,
                  childContent: [],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 17 - 10/03/2023",
    title: Localise(messages, "Release 17 - 10/03/2023"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Multi-Order Checkout",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Need to create multiple orders and allow the customer to checkout once? Look no further!",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "$80 Out of the cooler arrangement",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "$100 Florist Delivered Anniversary Arrangement",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "$75 arrangement wired out for mom’s birthday",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Customer pays $255 (plus fees and taxes) and orders are tracked separately in Mercury HQ",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "Create Order > Attach a customer profile > Sidecar will display Customer Insights",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "multi_order_checkout_1",
                  width: isDesktop ? 977 : 244,
                  height: isDesktop ? 475 : 119,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "multi_order_checkout_2",
                  width: isDesktop ? 819 : 273,
                  height: isDesktop ? 444 : 148,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Tax Rules - Destination vs Origin",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: "Set tax rules specific for your shop.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Origin-based taxation means the tax is determined based on the location of the seller.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Destination-based taxation means the tax is determined based on the location of the delivery.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Shop Settings > Delivery & Pickup > Fees",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "tax_rules",
                  width: isDesktop ? 615 : 185,
                  height: isDesktop ? 399 : 120,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Worksheet",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: "Worksheet is a great tool for designers, providing",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "price breakdowns",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "design details",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "recipes",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "Usually printed when shops hand write enclosure cards or print tri-fold cards.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Detach delivery slip when arrangement has been designed.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Manually print",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Orders > Order Details SideCar > Printer drop down > Worksheet",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "Auto-print along with other print artifacts.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Mercury HQ Windows App > Shop Settings > Orders > Printer Settings",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "work_sheet_1",
                  width: isDesktop ? 683 : 205,
                  height: isDesktop ? 877 : 263,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "work_sheet_2",
                  width: isDesktop ? 884 : 295,
                  height: isDesktop ? 350 : 117,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "SMS Order Notifications",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Customers can now opt into receiving SMS order notifications.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Order Notifications include: Order, In-Design, Out for Delivery, Attempted Delivery, Delivery Date Change, Delivered",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Customer Directory > Customer Details > Customer Info > SMS Order Updates",
                  level: 1,
                  childContent: [
                    {
                      type: "image",
                      content: "sms_order_notifications_1",
                      width: 264,
                      height: 39,
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "OR enable during the order taking process",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Create Order > Attach/Customer > SMS",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "sms_order_notifications_2",
                  width: isDesktop ? 749 : 250,
                  height: isDesktop ? 326 : 109,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Rush/Guarantee Timed Delivery Upcharge",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: "You can now upcharge for Rush Orders.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Setting: Shop Settings > Delivery & Pickup > Fees & Coverage > Fees",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Set the fee, by what time the order needs to be taken, and what time you’ll guarantee delivery",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "Order: Create Order > Enter all order details > Check Rush Order checkbox",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Example: If your delivery fee for 60515 is $12 and the Rush upcharge is $5 → the create order screen will show a delivery fee of $17.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "Order tiles will display the Rush Delivery tile icon",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Orders page now includes Rush Orders filter option",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "rush_delivery_upcharge_1",
                  width: isDesktop ? 661 : 198,
                  height: isDesktop ? 513 : 154,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "rush_delivery_upcharge_2",
                  width: isDesktop ? 884 : 295,
                  height: isDesktop ? 392 : 131,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "rush_delivery_upcharge_3",
                  width: isDesktop ? 884 : 295,
                  height: isDesktop ? 436 : 145,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "rush_delivery_upcharge_4",
                  width: isDesktop ? 885 : 295,
                  height: isDesktop ? 268 : 89,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Delivery",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: "Rush/Guarantee Timed Delivery Routing",
                  level: 0,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Rush orders carry through to the Delivery Routing process.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Order tiles will display the Rush Delivery tile icon.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Whether the route was created manually or automatically, Mercury HQ will:",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content: "Optimize based on timed deliveries",
                          level: 1,
                          childContent: [],
                        },
                        {
                          type: "text",
                          content:
                            "Recommend a Start Time to ensure timed deliveries make it there on time.",
                          level: 1,
                          childContent: [],
                        },
                        {
                          type: "text",
                          content:
                            "Display “Next Timed: HH:MM” on the route tiles",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                    {
                      type: "text",
                      content:
                        "Create Route page now includes Rush Orders filter option.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "rush_timed_delivery_routing_1",
                      width: isDesktop ? 883 : 294,
                      height: isDesktop ? 398 : 133,
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "rush_timed_delivery_routing_2",
                      width: isDesktop ? 910 : 273,
                      height: isDesktop ? 736 : 221,
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "Ad-Hoc Stops",
                  level: 0,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Need to have a delivery driver pickup or drop-off something than an order? You can now include ad-hoc stops on their route.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Delivery > Create Route > Add Stop",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Add Stop form will display",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "Type in necessary details like Stop Name, Address (with AVS), and Description (action to perform at stop).",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                    {
                      type: "text",
                      content: "Add Stop tile will display with a blue pin.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Optimizing the route will include the ad-hoc stop.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Printing the manifest will display all of the appropriate details of the ad-hoc stop.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Driver perspective will provide options like Completed or Not Completed.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "ad_hoc_stops_1",
                      width: isDesktop ? 882 : 294,
                      height: isDesktop ? 394 : 131,
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "ad_hoc_stops_2",
                      width: isDesktop ? 883 : 294,
                      height: isDesktop ? 385 : 128,
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "ad_hoc_stops_3",
                      width: isDesktop ? 885 : 295,
                      height: isDesktop ? 392 : 131,
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "ad_hoc_stops_4",
                      width: isDesktop ? 558 : 279,
                      height: isDesktop ? 405 : 203,
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "ad_hoc_stops_5",
                      width: isDesktop ? 649 : 216,
                      height: isDesktop ? 215 : 72,
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ Premium",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "QuickBooks Integration",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Mercury HQ now has QuickBooks integration with retail transactions.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Simple setup: Shop Settings > Payments > QuickBooks Integration",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Once you enable this feature, you will be prompted to perform a Single Sign-On (SSO) for your QuickBooks online account.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "Transactions are synchronized in real-time!",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "quick_books_integration",
                  width: isDesktop ? 884 : 295,
                  height: isDesktop ? 347 : 116,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "House Accounts - Open Item",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "House Account customers who prefer to pay individual invoices can do so when their House Account type is Open Item.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Customer Directory > Customer Profile > Customer Details SideCar > House Account Section > Accounting Type",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Data Migration will bring over accounting type.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "Receive Payment section now allows users to apply payment toward specific invoices.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Displays invoices that have an unpaid balance.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Customer Directory > Customer Profile > Customer Details SideCar > Receive Payment > Apply Payment to",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "Enter the dollar amounts next to corresponding orders.",
                          level: 1,
                          childContent: [],
                        },
                        {
                          type: "text",
                          content: "Balance is displayed for convenience.",
                          level: 1,
                          childContent: [],
                        },
                        {
                          type: "text",
                          content:
                            "Any payments that have not been applied towards invoices will display in the credit balance.",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "house_accounts_open_item_1",
                  width: isDesktop ? 884 : 295,
                  height: isDesktop ? 399 : 133,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "house_accounts_open_item_2",
                  width: isDesktop ? 884 : 295,
                  height: isDesktop ? 363 : 121,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "House Account - Credit/Debt Memo",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Credit and debit memos help maintain accurate records of financial transactions and ensure that the balance on the house account reflects the correct amount owed by the customer.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "They serve as documentation for adjustments made to the account and help maintain transparency and clarity in financial dealings between the business and the customer.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Customer Directory > Customer Profile > Customer Details SideCar > Credit/Debit Memo",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Select Credit or Debit",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Complete Amount and Notes",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "Statements will reflect appropriately",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "house_accounts_credit_debit_memo_1",
                  width: isDesktop ? 883 : 294,
                  height: isDesktop ? 351 : 117,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "house_accounts_credit_debit_memo_2",
                  width: isDesktop ? 885 : 295,
                  height: isDesktop ? 328 : 109,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "house_accounts_credit_debit_memo_3",
                  width: isDesktop ? 782 : 235,
                  height: isDesktop ? 917 : 275,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "In Design and Designed Statuses",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "For operational efficiency, it is important for users to know if an order is on the design desk or designed and ready in the cooler",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Same actions for changing an order status",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Order Details Sidecar → click on the Designed icon",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Order Details Sidecar → click Actions dropdown and select Designed",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Order tile meatballs → click Designed",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "(New - See Design Center)",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "When an order has been marked In Design or Designed → Mercury HQ will display everywhere where appropriate.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Dashboard",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Order Tile",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Order Details Sidecar",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Delivery",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Reporting",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "ETC",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },

                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "inDesign_and_designed_statueses_1",
                  width: isDesktop ? 886 : 295,
                  height: isDesktop ? 396 : 132,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "inDesign_and_designed_statueses_2",
                  width: isDesktop ? 883 : 294,
                  height: isDesktop ? 396 : 132,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "inDesign_and_designed_statueses_3",
                  width: isDesktop ? 651 : 217,
                  height: isDesktop ? 443 : 148,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Design Center",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Robust experience for Managers as well as Designers!",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Easy access from the Orders Page - Click on the Design Shears, which acts as a toggle to access the Designer Assignments",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Orders page",
                      level: 1,
                      childContent: [
                        {
                          type: "image",
                          content: "design_center_1",
                          width: isDesktop ? 50 : 15,
                          height: isDesktop ? 47 : 14,
                          level: 0,
                          childContent: [],
                        },
                      ],
                    },
                    {
                      type: "text",
                      content: "Design Center ",
                      level: 1,
                      childContent: [
                        {
                          type: "image",
                          content: "design_center_2",
                          width: isDesktop ? 55 : 17,
                          height: isDesktop ? 58 : 17,
                          level: 0,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "design_center_3",
                  width: isDesktop ? 885 : 295,
                  height: isDesktop ? 313 : 104,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "design_center_4",
                  width: isDesktop ? 884 : 295,
                  height: isDesktop ? 401 : 134,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Quick actions display Designer Assignments",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "design_center_5",
                  width: isDesktop ? 886 : 295,
                  height: isDesktop ? 403 : 134,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Click to see all orders assigned to this designer",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "design_center_6",
                  width: isDesktop ? 884 : 295,
                  height: isDesktop ? 398 : 133,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Two sections, Unassigned and Assigned",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "design_center_7",
                  width: isDesktop ? 886 : 295,
                  height: isDesktop ? 403 : 134,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Select orders, then click the Actions dropdown to either assign or remove assignment",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "design_center_8",
                  width: isDesktop ? 884 : 295,
                  height: isDesktop ? 323 : 108,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "design_center_9",
                  width: isDesktop ? 884 : 295,
                  height: isDesktop ? 391 : 130,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Utilize search and bulk when assigning",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Tip: Helpful at peak - assign long stem roses to a designer for assembly.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "design_center_10",
                  width: isDesktop ? 883 : 294,
                  height: isDesktop ? 401 : 134,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Assign also from the Order Details Sidecar, product tab, or designer field",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "design_center_11",
                  width: isDesktop ? 441 : 265,
                  height: isDesktop ? 747 : 448,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Reports - Customer lifetime value",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "The Customer Report will display the lifetime value of the customer.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Reports > Report Type > Customer Report",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "customer_lifetime_value_in_reports",
                  width: isDesktop ? 736 : 245,
                  height: isDesktop ? 331 : 110,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Reports - Aged Analysis",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Risk Assessment and track aging on unpaid house account invoices.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Reports > Report Type > Aged Analysis",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Will display all invoices that have an unpaid balance.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Aging will display",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content: "Current",
                          level: 1,
                          childContent: [],
                        },
                        {
                          type: "text",
                          content: "30 Days",
                          level: 1,
                          childContent: [],
                        },
                        {
                          type: "text",
                          content: "60 Days",
                          level: 1,
                          childContent: [],
                        },
                        {
                          type: "text",
                          content: "90 Days",
                          level: 1,
                          childContent: [],
                        },
                        {
                          type: "text",
                          content: "120+ Days",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Reports - Product Sales",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: "Report for product sales analysis.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Reports > Report Type > Product Sales",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "product_sales_in_reports",
                  width: isDesktop ? 635 : 212,
                  height: isDesktop ? 269 : 90,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Reports - Product Comparison",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: "Compare product sales between date ranges",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Reports > Report Type > Product Comparison",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "product_comparison_in_reports",
                  width: isDesktop ? 836 : 251,
                  height: isDesktop ? 287 : 86,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Reports - Assignments added to Order Detail",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Enhancements have been made to the Order Detail Report, displaying the assignment of Designer and Driver",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Reports > Report Type > Order Detail Report",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Reports - Sales Summary",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Sales Summary is a great report for overview and sales details!",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Reports > Report Type > Sales Summary",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "sales_summary_in_reports",
                  width: isDesktop ? 739 : 246,
                  height: isDesktop ? 568 : 189,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Delivery",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: "Fulfillment Dashboard",
                  level: 0,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "End to End store operations and fulfillment dashboard is here!",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Provides real-time visibility into the status and progress of routes, orders, and zones within the shop.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Select a date range to appear on the dashboard.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Switch between Zones or Routes view.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Yes, we made everything a click-through.",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "Clicking will take the user to the orders page with the appropriate filter.",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "fulfillment_dashboard_1",
                      width: isDesktop ? 884 : 295,
                      height: isDesktop ? 391 : 130,
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "fulfillment_dashboard_2",
                      width: isDesktop ? 884 : 295,
                      height: isDesktop ? 362 : 121,
                      level: 0,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 16 - 07/31/2023",
    title: Localise(messages, "Release 16 - 07/31/2023"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Customer Insights",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Customer insights are essential for businesses to understand and improve customer satisfaction and stay competitive in the market. By leveraging data, your business can create effective strategies, deliver personalized experiences, and foster long-lasting customer relationships.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Create Order > Attach a customer profile > Sidecar will display Customer Insights",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Average Order Value",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "Allows the user to suggest a price or arrangement based on the customer’s profile history.",
                          level: 1,
                          childContent: [],
                        },
                        {
                          type: "text",
                          content:
                            "Allows the user to upsell, creating an opportunity for increased AOV.",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                    {
                      type: "text",
                      content: "Previous Orders",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "Makes the reorder process quick, efficient, and accurate.",
                          level: 1,
                          childContent: [],
                        },
                        {
                          type: "text",
                          content:
                            "By knowing what the customer has ordered in the past, you can personalize the order intake experience and make relevant product and add-on suggestions.",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "customer_insights",
                  width: isDesktop ? 990 : 297,
                  height: isDesktop ? 446 : 133,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Resell Optimization",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "The customer order history allows for more effective communication and helps avoid reorder mistakes on recipient names, addresses, items, or quantities.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Create Order > Attach a customer profile > Search Recipients",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Previous Recipients",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "Select from the list and Mercury HQ will populate the Recipient’s Name, Phone, and Address",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "Create Order > Attach a customer profile > Sidecar will display previous orders > Reorder button",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Reorder",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "Click the reorder button and Mercury HQ will populate the Order Details such as: Recipient information, Product(s), Qty, Price, Design Notes, Delivery Instructions, Occasion, and more.",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "resell_optimization_1",
                  width: isDesktop ? 843 : 286,
                  height: isDesktop ? 356 : 121,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "resell_optimization_2",
                  width: isDesktop ? 844 : 287,
                  height: isDesktop ? 357 : 121,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Delivery Fees | Incoming Wire Orders",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Mercury HQ will now display your delivery fee on incoming wire orders.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Takes the guess work out of the equation and give employees a clear pricing breakdown.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "df_incoming_wire_orders",
                  width: isDesktop ? 974 : 292,
                  height: isDesktop ? 591 : 177,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Delivery Service Reporting",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Want to see a full month of reporting for easy reconciliation?",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "No Problem - Mercury HQ will allow for 31 days of reporting.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "delivery_service_reporting",
                  width: isDesktop ? 914 : 274,
                  height: isDesktop ? 217 : 65,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 15 - 07/02/2023",
    title: Localise(messages, "Release 15 - 07/02/2023"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Page Breadcrumb",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: "Always go back to where you came from",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Easy navigation: Breadcrumbs provide a quick and convenient method for users to navigate around the website hierarchy. By clicking on any of the links in the breadcrumb trail, users can easily return to a previously visited page without having to rely on the browser's back button or site menu.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "co_page_breadcrumb",
                  width: isDesktop ? 678 : 226,
                  height: isDesktop ? 473 : 158,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Design Notes - Print on Legal Size Invoice",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: "Design notes will print on legal size invoices",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "invoice_with_design_notes",
                  width: isDesktop ? 640 : 256,
                  height: isDesktop ? 833 : 333,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Create Order > Product Catalog Filters",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: "Apply a filter to view a select list of products",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "co_product_catalog_filters_1",
                  width: isDesktop ? 255 : 226,
                  height: isDesktop ? 284 : 158,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "co_product_catalog_filters_2",
                  width: isDesktop ? 957 : 287,
                  height: isDesktop ? 457 : 127,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 14 - 06/07/2023",
    title: Localise(messages, "Release 14 - 06/07/2023"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Order Page Redesign",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "There is a new look & feel to the order experience!",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Create order page",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content: "Cleaner looks, with containers",
                          level: 1,
                          childContent: [],
                        },
                        {
                          type: "text",
                          content:
                            "Don’t forget to check the sidecar, for items like Delivery Instructions or Customer Notes",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                    {
                      type: "text",
                      content: "Order SideCar",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "No more long scrolls, find the information faster with the new tabbed design",
                          level: 1,
                          childContent: [],
                        },
                        {
                          type: "text",
                          content:
                            "The current status will be displayed with the word, click the icons to move through the workflow",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "create_order_redesign",
                  width: isDesktop ? 891 : 267,
                  height: isDesktop ? 482 : 144,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "order_journey_redesign",
                  width: isDesktop ? 955 : 286,
                  height: isDesktop ? 474 : 142,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "order_summary_redesign",
                  width: isDesktop ? 723 : 289,
                  height: isDesktop ? 759 : 303,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "order_details_product_redesign",
                  width: isDesktop ? 718 : 287,
                  height: isDesktop ? 781 : 312,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "order_details_delivery_redesign",
                  width: isDesktop ? 721 : 288,
                  height: isDesktop ? 662 : 264,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "order_listing_redesign",
                  width: isDesktop ? 726 : 290,
                  height: isDesktop ? 507 : 202,
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "POS Session & Cash Drawer Management",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Cash drawer management is essential for the financial stability and security of a business, and is a critical component of a well-functioning POS system.",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Session management allows the POS system to keep track of which user performed which actions and when.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "This helps with auditing and accountability in case of any disputes or errors.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "Must use the Mercury HQ Windows App to operate the hardware and POS Sessions.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Opening Balance",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "No Sale",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Paid In",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Paid Out",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Balance Check",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Closing Balance",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "cash_drawer_create_order",
                  width: isDesktop ? 864 : 259,
                  height: isDesktop ? 296 : 88,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "cash_drawer_balance",
                  width: isDesktop ? 866 : 259,
                  height: isDesktop ? 430 : 129,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "cash_drawer_session",
                  width: isDesktop ? 863 : 254,
                  height: isDesktop ? 515 : 151,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "cash_drawer_end_session",
                  width: isDesktop ? 866 : 259,
                  height: isDesktop ? 425 : 127,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Decouple Refund & Cancel",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Separate actions now available",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Cancel",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Refund",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "cancel_order",
                      width: isDesktop ? 714 : 285,
                      height: isDesktop ? 211 : 84,
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "refund_cancel_order",
                      width: isDesktop ? 953 : 285,
                      height: isDesktop ? 393 : 117,
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Quick Sale",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Toggle the create order screen for quick walk-in sales – great for high volume shops",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "image",
              content: "quick_sale",
              width: isDesktop ? 928 : 297,
              height: isDesktop ? 437 : 139,
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Location Search",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "No need to google business addresses, Mercury HQ Location Name field will do it for you",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Select the Location Type",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Type in a Location Name",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "See a list of Business Names & Addresses",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Select from list, to populate address information into the recipient fields",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "Note: have a filtered list by adding the City or State or Zip before searching Location Names.",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "location_search",
                  width: isDesktop ? 961 : 300,
                  height: isDesktop ? 733 : 229,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "Delivery",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Driver Approaching",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Be notified when a Delivery Service Driver is approaching",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Delivery Dashboard",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Push Notifications, for when you’re away from the computer",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "Clicking the quick filter, will filter the orders tiles on the page",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "image",
              content: "delivery_dashboard_driver",
              width: isDesktop ? 915 : 274,
              height: isDesktop ? 974 : 292,
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "image",
              content: "delivery_dashboard_driver_notification",
              width: isDesktop ? 489 : 293,
              height: isDesktop ? 480 : 288,
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Pickup Manifest",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: "Print the pickup manifest",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Go pull the orders, and have them ready by the time the Delivery Service Driver arrives at the shop",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "image",
              content: "pickup_manifest",
              width: isDesktop ? 928 : 286,
              height: isDesktop ? 541 : 167,
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 13 - 04/26/2023",
    title: Localise(messages, "Release 13 - 04/26/2023"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Delivery Service POD URL",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Easily track Delivery Service tasks with the POD URL in the Order/Task Journey",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "delivery_service_tasks",
                  width: isDesktop ? 904 : 282,
                  height: isDesktop ? 389 : 121,
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Multiple Products per order",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "This feature will allow the create order screen to sell multiples",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Multiple products",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Multiple add-ons",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Multiple products with multiple add-ons",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "Quantity fields are listed when a product has been selected",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "multiple_products_order",
                  width: isDesktop ? 972 : 299,
                  height: isDesktop ? 479 : 147,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Customer Profile Activity - Display sent emails",
                  level: 0,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "The Customer Profile Activity section will now display audit information on emails sent",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Want to see more detail, click the link to view the sent email",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "customer_profile_emails",
                      width: isDesktop ? 654 : 294,
                      height: isDesktop ? 554 : 249,
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 12 - 04/05/2023",
    title: Localise(messages, "Release 12 - 04/05/2023"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Shop Settings - Including Suspend/Resume",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content: "Shop Settings has been redesigned!",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Shop Settings now has a tab structure, allowing users to find settings easier and faster.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Mercury Settings has been moved to Shop Settings, and renamed as Mercury Network.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Suspend/Resume",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "Shop Settings > Mercury Network > Your Network Status",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: "image",
                  content: "Shop_Settings_Mercury_Network",
                  width: isDesktop ? 933 : 286,
                  height: isDesktop ? 497 : 152,
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Print Management",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Print Management is an exciting feature that will allow Mercury HQ members to assign printer assignments for print artifacts.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "For example, always print Order Details on the “Back Office Lexmark”. ",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "This feature saves the users time, and helps in efficiency, saving clicks in having to select the printer and tray every time they want to print something.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "This feature only works in the Mercury HQ Windows App.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Configure Print Settings",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Windows App (Download here)",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Shop Settings",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Printer Settings",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "Configure print artifacts and respective printer settings",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Auto Print (Core & Standard)",
              level: 0,
              childContent: [
                {
                  type: "text",
                  content:
                    "Core & Standard members will have the ability to “Auto Print”.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "The long awaited feature will allow our members to automatically print, when auto accept is enabled. ",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "This feature only works in the Mercury HQ Windows App, and will go hand-in-hand with Print Management.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Configure Auto-Print",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Windows App (Download here)",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Shop Settings",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Printer Settings",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "Configure print artifacts and respective printer settings",
                          level: 1,
                          childContent: [
                            {
                              type: "text",
                              content:
                                "Toggle Auto-Print (Enabled or Disabled)",
                              level: 1,
                              childContent: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "Auto_Print_Settings",
                      width: isDesktop ? 971 : 277,
                      height: isDesktop ? 323 : 92,
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 11 - 03/27/2023",
    title: Localise(messages, "Release 11 - 03/27/2023"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Customer Profile Activity Feed - Audit changes",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content: "New Activity section added to customer profiles.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "The Activity feed will display useful information and is the foundation for the Customer Account audit trail.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Customer Profile Audit will log any changes made to a Customer Profile, the user who made the changes, and the date/time it occurred.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Find the Activity section",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Customer Directory",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Click a customer tile",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Customer Details",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content: "Activity",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "customer_profile_activity",
                  width: isDesktop ? 731 : 292,
                  height: isDesktop ? 315 : 126,
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Email Statements for House Account",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Now Mercury HQ can email your customer’s House Account statements directly.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "On the 1st of every month, Mercury HQ automatically generates statements. This feature will automatically email the members as well.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Florist members will still have the ability to print and mail a hardcopy.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "This will save the florist members time and money, should they choose to just allow Mercury HQ to email statements instead of printing as well.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Email Statements",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "No action is required, the system will automatically email house account statements on the 1st of every month.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Enhancements",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content: "Improvements have been made to the Suspend action.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "DCONs can be submitted with a previous delivery date selected.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Relay Fee",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "There are costs associated to sending an order through the FTD Network for a florist to fill. Now, we are giving the florist members an option to pass that cost on their customer.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Relay fee is a fee that the member can charge to their customer. Relay fee will not be sent with the order into the Network. It is a fee that will stay with the sending florist.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "The fee can automatically show up in the create order screen, when the user selects Wire Out as the Delivery Method.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Set a Relay Fee",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Shop Settings",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Delivery & Pickup",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Relay Fee",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "image",
                  content: "relay_fee_setting",
                  width: isDesktop ? 981 : 272,
                  height: isDesktop ? 852 : 236,
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Tracking Promo Codes",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "A part of any Business, is tracking the promotions and communications. Mercury HQ will have an option in the create order screen for a Customer to provide a promo code.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Enter a Promo Code on an order",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Create Order",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Order Summary section",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Promo Code",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "Promo codes can now be tracked on the existing Order Details report.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "View Orders with Promo Codes",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Reports",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Order Detail Report",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "image",
                  content: "promo_code_tracking",
                  width: isDesktop ? 936 : 291,
                  height: isDesktop ? 318 : 98,
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Payment Error Handling",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Mercury HQ will now allow the user to update the payment information on orders in Needs Action for payment or processing errors.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Mercury HQ users will have an opportunity to contact the customer and correct any credit card information, or process with a new payment method.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Handling a Payment Error",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Orders",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Needs Action",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Payment Error",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "payment_error_handling",
                  width: isDesktop ? 936 : 288,
                  height: isDesktop ? 475 : 146,
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Subscription Orders - Apply Invoice towards House Account",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Mercury HQ released Subscription Orders, and applying payment towards a credit card. Now, we are releasing with the ability to applying payment towards charging an invoice to a House Account.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Increase customer loyalty, and assist with passive income.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Create a Subscription and charge invoice towards House Account",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Customer Directory",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Click a customer tile",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "*Ensure the customer is enabled for House Account",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                    {
                      type: "text",
                      content: "Subscriptions section",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Create New Subscription",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content: "Payment Type - Invoice",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Delivery",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Delivery - Page Updates",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content: "Delivery page now will display tabs!",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Routes",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Delivery Service",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "Find all of your Delivery Service Requests in one place.",
                          level: 1,
                          childContent: [],
                        },
                        {
                          type: "text",
                          content:
                            "Search and Filters will allow the users to find what they’re looking for.",
                          level: 1,
                          childContent: [],
                        },
                        {
                          type: "text",
                          content:
                            "Download Results will allow download a report based on search and filtered results.",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "image",
              content: "delivery_dashboard_ds",
              width: isDesktop ? 957 : 287,
              height: isDesktop ? 410 : 123,
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Auto Route - with automatic route creation",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content: "Auto Route Settings have moved",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Delivery > ‘Gear’ icon (in upper right hand corner)",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Zone Definitions are Full Screen",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Layers - display the Delivery Service radius ring",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Auto Route toggle",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content:
                            "When enabled, automatically create routes for that zone on the 1st order in the defined area.",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "delivery_dashboard_routes",
                  width: isDesktop ? 952 : 285,
                  height: isDesktop ? 216 : 64,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "auto_route_settings",
                  width: isDesktop ? 959 : 287,
                  height: isDesktop ? 484 : 145,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "auto_routes",
                  width: isDesktop ? 948 : 284,
                  height: isDesktop ? 697 : 209,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Enhancements",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Core users can upload a photo and signature as part of DCON",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Standard users can now apply zone filters when creating routes",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "zones_filters",
                  width: isDesktop ? 946 : 283,
                  height: isDesktop ? 494 : 148,
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 10 - 01/27/2023",
    title: Localise(messages, "Release 10 - 01/27/2023"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Subscription Orders",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content: "Increase customer loyalty and satisfaction.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",

                  content: "Create passive incoming",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Subscriptions",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Create from the Customer Profile.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Create Subscription template displays.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Credit Card, or Saved Card can be used as the payment method.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "Subscription orders are indicated on the order tiles with the icon",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "SubscriptionIcon",
                  width: 43,
                  height: 35,
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "image",
              content: "CustomerDirSubscription",
              width: isDesktop ? 958 : 287,
              height: isDesktop ? 474 : 142,
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "image",
              content: "CreateSubscription",
              width: isDesktop ? 752 : 283,
              height: isDesktop ? 699 : 233,
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "image",
              content: "SubscriptionOrders",
              width: isDesktop ? 893 : 264,
              height: isDesktop ? 122 : 41,
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Occasion Reminder Report",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Find the new Occasion Reminder Report in the Reports list.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Download the report by occasion and delivery date range.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "Want to make your customers feel special?",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Give your customers a call.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Remind them of the order they placed last year.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Ask if they’d like to place an order for their upcoming occasion.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "image",
              content: "OccasionReminder",
              width: isDesktop ? 825 : 275,
              height: isDesktop ? 526 : 175,
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Sales Tax Reporting",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "The Sales Tax report is used to provide information to assist in completing the monthly sales tax report that you are required to file with your state or province. Although this report is beneficial in many states, each state is required to file different reports.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "The Sales Tax report is sorted by Taxable Sales and Non Taxable Sales, Tax figures can be based on either delivery or sale dates.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Accrual - includes dollar amounts for house account charges that have not yet been paid. (If you select Accrual as the Type, in the Date area, select whether you want the dates to be based on delivery date or sales date.)",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Cash Basis - only includes actual cash you have received. (If you select Cash Basis as the Type, in the Date area, sales date will be defaulted.)",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Delivery Date - when this is selected, orders completed with a delivery date within the months selected for the report will be included",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Sale Date - when this is selected, orders completed with an entry date within the months selected for the report will be included.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "image",
              content: "SalesTax",
              width: isDesktop ? 887 : 296,
              height: isDesktop ? 394 : 131,
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "Zone Definitions",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Zone Definitions will allow your shop to define your delivery zones.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "This is a great way to set your predefined routes.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Clicking the Gear icon on the Delivery Dashboard will open up Zone Definitions.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Creating a Zone Definition",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Draw the zone on the map",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Give a custom title, or use the default of Zone #",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Set the order capacity",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "image",
              content: "DeliveryDashboard",
              width: isDesktop ? 959 : 287,
              height: isDesktop ? 467 : 140,
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Modify Delivery Method",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "When modifying an order in Mercury HQ, you can now modify and change the delivery method.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Example, the customer called and said they can no longer stop by and pickup the order and is requesting delivery instead.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "International Phone Numbers",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Mercury HQ will now store International Phone Numbers.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 9 - 12/15/2022",
    title: Localise(messages, "Release 9 - 12/15/2022"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Colorado Retail Delivery Fee TAX",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "CO introduced a new tax, that must be displayed as a separate line item for orders being delivered in CO.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Mercury HQ will display the appropriate tax to be charge to the customer when taking payment in CO AND the order is being delivered in CO",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Create Order will display the separate tax.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Legal size invoice will display the separate tax.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Order Detail Report will display the separate tax.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "image",
              content: "CORetailDeliveryFee",
              width: isDesktop ? 726 : 290,
              height: isDesktop ? 391 : 156,
              level: 1,
              childContent: [],
            },
            {
              type: "image",
              content: "COInvoiceStatement",
              width: isDesktop ? 631 : 283,
              height: isDesktop ? 280 : 126,
              level: 1,
              childContent: [],
            },
            {
              type: "image",
              content: "COOrderDetailReport",
              width: isDesktop ? 785 : 282,
              height: isDesktop ? 84 : 30,
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "Credit Card Processing on Ingested Orders",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Don’t have credit card processing turned on for your third party websites?  No problem, Mercury HQ can process them when they are ingested.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Email Orders (Websites hosted by someone other than FTD)",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Teleflora eFlorist Orders (Websites hosted by Teleflora)",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "Universal Data Import - Enhancements",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Enhancements to linking order history to a customer’s profile on exception imports.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "Windows App - Enhancements",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Swiping of credit cards with the Star mag strip swipe.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "Order Ingestion - Enhancements",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Mercury HQ will now validate Teleflora credentials.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Mercury HQ will now validate Email Order credentials.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "House Accounts & Statements - Enhancements",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Statements will display the canceled orders more precisely.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 8 - 11/30/2022",
    title: Localise(messages, "Release 8 - 11/30/2022"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "House Accounts & Statements",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "If the customer’s house account limit has been exceeded, we’ll notify you in the create order screen.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Save time and energy, with Auto Generation of House Account Statements.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Download Monthly Statements on the Reports Page.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Each customer profile will display their latest statement.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "You can use the same statement paper stock you used for MPOS.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "reportsHouseAccStat",
                      width: isDesktop ? 651 : 285,
                      height: isDesktop ? 388 : 185,
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "",
                      level: 0,
                      childContent: [],
                    },
                    {
                      type: "image",
                      content: "customerHouseAccStat",
                      width: isDesktop ? 625 : 290,
                      height: isDesktop ? 786 : 354,
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Legal-size Invoices are enabled",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Alignment issues have been corrected to improve the Teleflora & Bloomnet order print outs.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Universal Data Import",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Enhancements done to ensure all order history will display on the customer’s profile.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Order Ingestion",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Enhancements done to ensure the credentials are valid for Teleflora Integration.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "text",
              content: "Create Route Draw",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Enhanced the experience by replacing the draw on map button with an intuitive icon.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "editInteractiveMap",
                  width: isDesktop ? 473 : 286,
                  height: isDesktop ? 356 : 228,
                  level: 1,
                  childContent: [],
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Mercury HQ Core & Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Driver Assist",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "We’ll assist your driver and prompt them when Mercury HQ believes they are at the incorrect address.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "driverAssist",
                  width: isDesktop ? 339 : 285,
                  height: isDesktop ? 668 : 564,
                  level: 1,
                  childContent: [],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 7 - 11/16/2022",
    title: Localise(messages, "Release 7 - 11/16/2022"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Create Route Draw",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Only a few clicks and 5 seconds to create a google optimized route, returning the most efficient routes.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Reduce planning time",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Create more accurate routes",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "Draw on the map to add orders to a route, click optimize, and assign a driver.  Yeah, it’s that easy!",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "The free form shape you draw, will auto close the shape for you.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "Click individual pins to add or remove from the route.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "interactiveMap",
                  width: isDesktop ? 721 : 360,
                  height: isDesktop ? 394 : 197,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "drawnMap",
                  width: isDesktop ? 585 : 292,
                  height: isDesktop ? 820 : 410,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "Delivery Fees & Coverage Area Interface",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Manage your coverage areas with an Interactive Zip code map.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Displays covered vs non-covered Zips",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content: "Green = Covered",
                          level: 1,
                          childContent: [],
                        },
                        {
                          type: "text",
                          content: "Gray = Not Covered",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                    {
                      type: "text",
                      content:
                        "Displays delivery fees, including active exceptions.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Displays Zip, Status, City, State, Current Delivery Fee.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "Introducing Address Exceptions",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Address - increase delivery fee, or do not deliver.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "floristCoveredZips",
                  width: isDesktop ? 585 : 292,
                  height: isDesktop ? 420 : 210,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "deliveryFeeException",
                  width: isDesktop ? 585 : 292,
                  height: isDesktop ? 420 : 210,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "House Accounts",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Enable House Account on a customer’s profile, and set the credit limit.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Charge the Customer’s House Account with a payment type of “Invoice”",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Receive Payment on the Customer’s House Account",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "enableHouseAccount",
                  width: isDesktop ? 585 : 292,
                  height: isDesktop ? 420 : 210,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "invoicePayment",
                  width: isDesktop ? 516 : 310,
                  height: isDesktop ? 269 : 161,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "currentBalance",
                  width: isDesktop ? 585 : 292,
                  height: isDesktop ? 820 : 410,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "Order Ingestion",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Integrate and Ingest orders from your other services.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Other Services may include:",
                      level: 1,
                      childContent: [
                        {
                          type: "text",
                          content: "BloomNet",
                          level: 1,
                          childContent: [],
                        },
                        {
                          type: "text",
                          content: "Teleflora (Wire & eFlorist)",
                          level: 1,
                          childContent: [],
                        },
                        {
                          type: "text",
                          content: "Email Orders (AKA WOI)",
                          level: 1,
                          childContent: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: "text",
                  content:
                    "Mercury HQ will automatically create or update Customer Profiles for you.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Integration Settings will store your preferences.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Filters have been added to the Orders Page.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Order Detail Printout & Order Details Fulfillment will display the order source.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "integrationSettings",
                  width: isDesktop ? 660 : 330,
                  height: isDesktop ? 660 : 330,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "orderTypeFilter",
                  width: isDesktop ? 585 : 292,
                  height: isDesktop ? 420 : 210,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "nonMHQOrderPrint",
                  width: isDesktop ? 585 : 292,
                  height: isDesktop ? 420 : 210,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "Legal-size Invoices are enabled",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Legal size Invoice card stock interchangeable with MPOS and Mercury HQ.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Order Details Print icon drop list now includes print options like “Invoice”",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "legalSizeInvoicePrint",
                  width: isDesktop ? 585 : 292,
                  height: isDesktop ? 420 : 210,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "Data Migration",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Want your customer and order history brought over to Mercury HQ?  No problem!  You can migrate 3 years of historical data.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Customers will still have their open balance, and order history.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ Core & Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Copy Order",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Take action row and meatballs will now display the option to copy order.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Cancel by mistake, or customer reorder are common uses.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "copySale",
                  width: isDesktop ? 585 : 292,
                  height: isDesktop ? 420 : 210,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "Create Order",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Address Suggestion and Address verification services have been added to support Canadian addresses.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "avsCanadaSupport",
                  width: isDesktop ? 585 : 292,
                  height: isDesktop ? 420 : 210,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "Location Type",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "When no location name is entered or received on orders, Mercury HQ will default to a Location Type of Residence.",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Especially helpful when submitting to Delivery Service, one less field to manually update.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
                {
                  type: "image",
                  content: "defaultLocation",
                  width: isDesktop ? 585 : 292,
                  height: isDesktop ? 420 : 210,
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "",
                  level: 0,
                  childContent: [],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 6 - 09/21/2022",
    title: Localise(messages, "Release 6 - 09/21/2022"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Core & Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Shop Settings",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content: "Fee & Coverage section for different entitlements.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "For local or wired orders, use the Paid Elsewhere payment method.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Configure and set delivery fees by city and zip code depending upon the entitlement user.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "Create Order",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "An error message appears when an order is placed with a duplicate email ID.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "A Search filter for local and FTD products.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "Order Summary",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Product Details - Additional Add-ons to order as part of order modification and special instructions for the Add-on product.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Delivery Method Redesign - Date/Price Change.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Take Action – Modify Order and Save changes buttons in order details sidecar.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Modify Mode",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content:
                        "Locked message when a user is modifying the order which is currently in modification mode.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Users can change the delivery method and modify special instructions in the product details section.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Users can make a payment with the available payment methods when an additional price is charged.",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content:
                        "Users can get a refund when the order is eligible for a refund after the modification.",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
                {
                  type: "text",
                  content: "User can Modify",
                  level: 1,
                  childContent: [
                    {
                      type: "text",
                      content: "Customer Info",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Delivery Info",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Product Details",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Fulfillment",
                      level: 1,
                      childContent: [],
                    },
                    {
                      type: "text",
                      content: "Pickup Info",
                      level: 1,
                      childContent: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "text",
              content: "Delivery Service",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Delivery Service Cancel Order will show as a fulfillment error in Needs Action.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Delivery Permissions - user will now have both the Driver and Delivery manager roles.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Update Recipient information on wired orders while submitting to Delivery service.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "Product Catalog",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content: "Unified Product Catalog to manage all products.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "Customer Directory",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Show total count of customers per member in the Customer Search field.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Users can export a Customer Directory report.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "Windows App",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "POS Receipts are printed on the Star Receipt Printer when the sale is completed.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Florists can place an order by swiping a credit card.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content:
                "Order Detail Report must include all information captured on the order.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Fixed bugs on the Reports screen so users can download the Customer Report.",
              level: 1,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 5 - 06/28/2022",
    title: Localise(messages, "Release 5 - 06/28/2022"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Core & Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Delivery Service",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Order status update for undeliverable orders with more actions like canceling and rescheduling.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "Orders",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "All orders now appear under one comprehensive page, allowing more seamless searches and actions.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Orders are updated in Mercury HQ when the orders are placed on the MOL Shopify website.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "When enabled, a Paid Elsewhere feature is listed in the payment type on Create Order screen.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Update Delivery method details while switching between the member codes.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "A credit card toggle is enabled for standard member code to view the option in the Create Order screen.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Error message while processing a credit card payment.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Discount % is applicable depending upon the Customer profile.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "View Tax rate, Tax Exempt, and Paid Elsewhere details on the Payment Card. ",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "Shop Settings",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Payment types are determined by what the user accepts as a payment method in their store.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Users can define and customize their delivery fee, and the tax is calculated based on shop location.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content:
                "Fixed bugs on Create Order screen (Discount % and Payment), Orders, Delivery Service, and Shop Settings.",
              level: 1,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 4 - 05/31/2022",
    title: Localise(messages, "Release 4 - 05/31/2022"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Delivery Service",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Users can manage undeliverable orders with more actions like canceling and rescheduling.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Setting undeliverable preferences, pickup times, and service charges.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Dashboard",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Printing - more order detail print options are available from the Dashboard.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Calendar - more interactions are now available, like clicking on a date and checking your orders for that day.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Orders",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "All orders now appear under one comprehensive page, allowing even more seamless searches and actions.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "The Order Detail print page is redesigned, so the content is organized for a better glance. We highlight essential details like add-ons and recipes and added checkboxes to help make shop operations even more efficient.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Validations for specific fields on Create Customer Profile.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "Error messages for non-routed orders.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Recipient Details - ability to update the recipient name and address.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Maps",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "New controls on how you interact with maps.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content:
            "Fixed bugs on Dashboard, Create Order screen, Shop Settings, Reports, Email templates, and Routes.",
          level: 1,
          childContent: [],
        },
      ],
    },
  },
  {
    name: "Release 3 - 04/27/2022",
    title: Localise(messages, "Release 3 - 04/27/2022"),
    component: {
      childContent: [
        {
          type: "text",
          content:
            "Order Detail Print Out - updated more content for additional clarity",
          level: 1,
          childContent: [],
        },
        {
          type: "text",
          content:
            "Delivery Service - more clarity around managing requests and changes",
          level: 1,
          childContent: [],
        },
        {
          type: "text",
          content:
            "Fixed bugs on the Calendar, Delivery and Order Details, Delivery Confirmations, Address Verification, Recipes, and Add-ons",
          level: 1,
          childContent: [],
        },
      ],
    },
  },
  {
    name: "Release 2 - 04/21/2022",
    title: Localise(messages, "Release 2 - 04/21/2022"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ Core & Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Delivery Service - users can manage undeliverable orders with more actions like canceling and rescheduling, setting undeliverable preferences and pickup times",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "Dashboard",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Printing - more order detail print options available from the Dashboard",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Calendar - more interactions now available – like clicking on a date and seeing your orders for that day",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content: "Orders",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "All orders now appear under one comprehensive page to allow for even more seamless searches and actions",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "We redesigned the Order Detail print page so content is organized for better scannability. We highlight important details like add-ons and recipes and added checkboxes to help make shop operations even more efficient",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content:
                "Recipient Details - ability to update recipient name and address and reprint enclosure cards as needed",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Maps - new controls over when and how you interact with maps",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Mercury HQ Standard",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Product Catalog - now available in Mercury HQ Standard",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Additional updates also for Customer Profile management, Push Notifications, Reports, and Refunds",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Updated Nav - One Orders Page",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "image",
              content: "newNav",
              width: isDesktop ? 329 : 165,
              height: isDesktop ? 776 : 388,
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Orders Page - New Nav (Collapsed View)",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "",
              level: 0,
              childContent: [],
            },
            {
              type: "image",
              content: "ordersCollapsed",
              width: isDesktop ? 972 : 364,
              height: isDesktop ? 369 : 138,
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Delivery Service",
          level: 0,
          childContent: [
            {
              type: "image",
              content: "undeliverableAction",
              width: isDesktop ? 585 : 292,
              height: isDesktop ? 210 : 105,
              level: 3,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Edit Recipient Info",
          level: 0,
          childContent: [
            {
              type: "image",
              content: "editRecipientName",
              width: isDesktop ? 748 : 280,
              height: isDesktop ? 273 : 102,
              level: 3,
              childContent: [],
            },
            {
              type: "image",
              content: "editRecipientAddress",
              width: isDesktop ? 742 : 278,
              height: isDesktop ? 364 : 136,
              level: 3,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Map",
          level: 0,
          childContent: [
            {
              type: "image",
              content: "orderMap",
              width: isDesktop ? 526 : 263,
              height: isDesktop ? 470 : 235,
              level: 3,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Order Detail Page",
          level: 0,
          childContent: [
            {
              type: "image",
              content: "orderDetailPrint",
              width: isDesktop ? 510 : 255,
              height: isDesktop ? 660 : 330,
              level: 3,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 1 - 03/01/2022",
    title: Localise(messages, "Release 1 - 03/01/2022"),
    component: {
      childContent: [
        {
          type: "text",
          content:
            "Payments & Refunds - you can now take payment and issue refunds in Mercury HQ with credit cards, cash, or check",
          level: 1,
          childContent: [],
        },
        {
          type: "text",
          content:
            "User Permissions - Mercury HQ admins now have more control over what data individual users have access to",
          level: 1,
          childContent: [],
        },
        {
          type: "text",
          content:
            "Local Orders - shops can now manage Local Orders and in-store pickup orders",
          level: 1,
          childContent: [],
        },
        {
          type: "text",
          content: "Reports - shops can now generate Order Summary reports",
          level: 1,
          childContent: [],
        },
        {
          type: "text",
          content: "Mercury Online - we’ve added even more features",
          level: 1,
          childContent: [],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "",
          level: 0,
          childContent: [],
        },
        {
          type: "text",
          content: "Payments & Refund",
          level: 0,
          childContent: [],
        },
        {
          type: "image",
          content: "paymentsRefund",
          childContent: [],
          width: isDesktop ? 484 : 242,
          height: isDesktop ? 873 : 436,
          level: 3,
        },
        {
          type: "text",
          content: "Permissions",
          level: 0,
          childContent: [],
        },
        {
          type: "image",
          content: "permissions",
          childContent: [],
          width: isDesktop ? 484 : 242,
          height: isDesktop ? 417 : 209,
          level: 3,
        },
        {
          type: "text",
          content: "Order Summary Report",
          level: 0,
          childContent: [],
        },
        {
          type: "image",
          content: "orderSummaryReport",
          childContent: [],
          width: isDesktop ? 608 : 304,
          height: isDesktop ? 585 : 293,
          level: 3,
        },
      ],
    },
  },
];

export const getMOLReleaseNotes = (Localise, messages) => [
  {
    name: "September 2024 - 9/30/24",
    title: Localise(messages, "September 2024 - 9/30/24"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Zip Code and fulfillment method (Delivery or Pickup) moved back to the product pages to check availability earlier in the checkout process.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Admin and Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Ability to select “Full Refund” or “Partial Refund” radio buttons when initiating a refund for a faster and clearer user experience.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "New Delivery Exception rule called “Overwrite Delivery Fee”.  Can be used for specific days of the week and/or calendar dates.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Associating Add-Ons to Collections/Products can now be done when creating the Add-on.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content:
            "Squashed more bugs in Mercury HQ and also in Shopify related to tax calculation logic for multi-item orders, error handling for partial refunds, Dropship local delivery, recipes duplication on global products and other UI related issues.",
          level: 1,
          childContent: [],
        },
      ],
    },
  },
  {
    name: "August 2024 - 9/03/24",
    title: Localise(messages, "August 2024 - 9/03/24"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "ApplePay is now a payment option at checkout.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Funeral/Visitation service times are now an option for customers to select for funeral and sympathy orders during checkout.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Customers can now add a quantity to their bouquet, gift or add-ons on the product and cart pages.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "New calendar on the product pages that is displayed for Dropship type products that displays expedited and ground shipping fees and fulfillment dates.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Admin and Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Recipes can now be seen and printed in the Product Catalog for FTD/Global Products.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Ability to add and print recipe details in the Product Catalog for Custom/Local Products.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Florists can now submit a partial refund for Mercury Online orders.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Ability to create a new Delivery Exception to change your delivery to “In-Store Pickup Only” for specific days of the week or a date range for all order fulfillment in Shop Settings > Delivery, Pickup & Fees.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "New “Dropship” product type option available when creating a new product in the Product Catalog.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "New Ground and Expedited Shipping fees and Shipping Cut-off options for Dropship products in Shop Settings > Delivery, Pickup & Fees.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Ability to create a new Delivery Exception in Shop Settings > Delivery, Pickup & Fees for Dropship products to block specific days of the week or a date range from being selected for shipping/fulfillment in the calendar on the product page of your website.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Ability to manually sort Add-Ons using “drag & drop” for individual products in the Product Catalog that display in the “Make your gift extra special with add-ons” section in a specific order on the product pages on your website.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Option to edit the Special Instructions placeholder text, information and tooltip that is displayed in checkout.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content:
            "Squashed more bugs in Mercury HQ and also in Shopify related to delivery phone number not displaying on the customer thank you page, gift cards, removed redundant labels in the Product Catalog, fixed some translations that were not working on the homepage and resolved product feed service issues.",
          level: 1,
          childContent: [],
        },
      ],
    },
  },
  {
    name: "April 2024 - 4/18/24",
    title: Localise(messages, "April 2024 - 4/18/24"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Full end-to-end customer service to Grocery Accounts through Service Bureau.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Gift Cards, Customers can now purchase and redeem a Gift Card on their website or in store.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "An E-commerce website in Canada under the Mercury Online platform with multi language support.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Admin and Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Service Bureau toggle under shop setting, to enable/disable the status for Service Bureau members.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "CAD currency support for Canadian members.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Language picker to add/remove languages shown on MOL site.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content:
            "Squashed more bugs in Mercury HQ and also in Shopify related to show/hide product image on the Shopify website.",
          level: 1,
          childContent: [],
        },
      ],
    },
  },
  {
    name: "Mid-March 2024 - 3/18/24",
    title: Localise(messages, "Mid-March 2024 - 3/18/24"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Added “Rush Delivery available at checkout.” to the PDP when the Rush Delivery option is active for your site and eligible products.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Redesign of the Add-ons display on the PDP to organize and better present product upgrade options.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Site search now supports finding products by SKU as well as name.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Admin and Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Removed the time option when setting up a Mercury Online website suspension.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Added the ability to schedule temporary content changes for the Main Menu Navigation, Homepage Banner and Announcement Banner.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Created new admin screens for the Mercury Online operations and support teams to better manage global content changes to keep sites current for seasons and holidays.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content:
            "Squashed more bugs in Mercury HQ and also in Shopify related to variant name displaying HTML in cart and checkout, date picker not working when design time exceeds shop hours, product availability fix for pickup orders.",
          level: 1,
          childContent: [],
        },
      ],
    },
  },
  {
    name: "February 2024 - 2/28/24",
    title: Localise(messages, "February 2024 - 2/28/24"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Hovering on the image on the product pages allows the user to zoom in on the arrangement to see flower details.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "“Recipient Information” heading added to checkout to further help customers enter information accurately.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Admin and Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Users can now offer Free Delivery or Free Rush Delivery at checkout by setting delivery charges to $0 in Fees and Coverage.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content:
            "Squashed more bugs in Mercury HQ and also in Shopify related to checkout as outlined above",
          level: 1,
          childContent: [],
        },
      ],
    },
  },
  {
    name: "January 2024 - 02/22/24",
    title: Localise(messages, "January 2024 - 02/22/24"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "For users on MercuryHQ Premium, customers can now purchase and redeem a Gift Card on their website or in-store.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Added Delivery date on both Order status & Thank you page. Customers can now see Delivery date on both Order status & Thank you pages..",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "Added Recipient Information label on Checkout page.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "Rush Delivery now supports $0 fee.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Admin and Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "MercuryHQ Premium Entitlement members can now create a local Gift Card product in Product Catalog",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content:
            "Squashed more bugs in Mercury HQ and also in Shopify related to Sympathy and Wedding landing pages and Product details page",
          level: 1,
          childContent: [],
        },
      ],
    },
  },
  {
    name: "December 2023 - 01/29/24",
    title: Localise(messages, "December 2023 - 01/29/24"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Google Map was removed from homepage for mobile users to improve page speed load time.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "“Testimonials” section in Shopify Admin for manual adding customer reviews for users not subscribed to FTD’s Online Rating Service.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "Optional “Popup” in Shopify Admin.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Admin and Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Ability to revert overridden changes on products and collections back to the FTD global settings as well as setting start and end dates for content changes (Example:  Homepage Banner).",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content:
            "Squashed more bugs in Mercury HQ and also in Shopify related to Customer Reviews landing page and About Us page.",
          level: 1,
          childContent: [],
        },
      ],
    },
  },
  {
    name: "November 2023 - 11/29/23",
    title: Localise(messages, "November 2023 - 11/29/23"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "New Shopify 2.0 Theme.  Page speed increase to improve conversion - especially on mobile devices.  Ability to customize your site even more in Shopify Admin.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "New Shopify Checkout 2.0.  Single page checkout and the ability for customers to edit their product and delivery details while on the checkout page.  Address verification was also enabled to improve accuracy of delivery information.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Quick Shop button on homepage allows customers the ability to land on a filtered product listing page with the occasion and prices they are searching for.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "For stores with multiple brands, you can now display and link to those site by displaying the brand logo in a new header section at the top of your webpages.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Admin and Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "New Fulfillment Dates option within the Product Catalog.  Allows the user to set the dates a specific product is available for delivery or pickup.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content:
            "Squashed more bugs in Mercury HQ and also in Shopify related to product minimums and SRP pricing.",
          level: 1,
          childContent: [],
        },
      ],
    },
  },
  {
    name: "September 2023 - 10/04/2023",
    title: Localise(messages, "September 2023 - 10/04/2023"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "We removed the “View Order” button from new order confirmations that are sent to the florist store owner as we do not allow florists to manage their orders in Shopify.",
              level: 1,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
  {
    name: "August 2023 - 09/04/2023",
    title: Localise(messages, "August 2023 - 09/04/2023"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury HQ - Admin and Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Ability to automatically route website orders designated to other shops within a shop group for operational efficiency.",
              level: 1,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
  {
    name: "July 2023 - 07/26/2023",
    title: Localise(messages, "July 2023 - 07/26/2023"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Enhancements to the Wedding Consultation form.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Admin and Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Shop Hours and Delivery Cutoff Times settings have been moved to Shop Settings.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Products purchased from the FTD Marketplace that are seasonal or codified will be active or inactive based on purchase.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Local Delivery Only option has been removed from global products.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Filters have been added for Local Delivery Only and Rush Delivery in order to easily find products with those settings.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Ability for FTD to strategically merchandise product on the homepage and other collections for members that have not manually configured their products.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content:
            "Squashed more bugs in Mercury HQ and Shopify related to Wedding Submissions and Rush Delivery.",
          level: 1,
          childContent: [],
        },
      ],
    },
  },
  {
    name: "June 2023 - 07/04/2023",
    title: Localise(messages, "June 2023 - 07/04/2023"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "New payment facilitator has been integrated into Shopify checkout.  The checkout flow has been updated to reflect the new payment process.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "Migrated Analytics to GA4.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Rush/Timed Delivery allows customers to request delivery before a certain time of day for an additional fee.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Admin and Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Add-ons site display can now be customized  sorted to determine their display order on the site.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "The homepage rotating banner can now show up to 6 images.",
              level: 1,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
  {
    name: "May 2023 - 06/06/2023",
    title: Localise(messages, "May 2023 - 06/06/2023"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "New Wedding Landing Pages (Consultation Form, For the Reception, For the Service, Shop by Color) to drive more SEO traffic as well as help customers easily find what they are looking to purchase or contact the florist.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "House Account now available as a payment option",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Live Chat on the site allows for real-time communication",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Admin and Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Ability to make updates to “All Shops” at one time for stores with multiple locations within a Shop Group.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Syncing of customer marketing communications opt-in preferences from Shopify to MercuryHQ for Marketing Communications.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Shared Catalogs have been introduced in order to group global products for sharing across stores.",
              level: 1,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
  {
    name: "April 2023 - 04/26/2023",
    title: Localise(messages, "April 2023 - 04/26/2023"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Customer can now add a tip to their order during checkout.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Users can now create a Free Shipping discount in Shopify Admin.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "“Site Audit” and “Position Tracking” are now included in SEMRush project creation during onboarding of new stores.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Users that are part of a multi-store Shop Group can now edit their product catalog for one store or all stores at the same time.",
              level: 1,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
  {
    name: "March 2023 - 03/27/2023",
    title: Localise(messages, "March 2023 - 03/27/2023"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Allow a user to select a pickup location from a list of stores in checkout (if applicable).",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Funeral & Sympathy Landing Pages to drive more SEO traffic as well as help customers easily find what they are looking for.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "In-Store Pick Only has been added to the page filter and to the take action dropdown menu for bulk changes.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Ability to add a custom Flower Type or Color tag on local products to be used in the filters on collection pages.",
              level: 1,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
  {
    name: "February 2023 - 02/28/2023",
    title: Localise(messages, "February 2023 - 02/28/2023"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Ability to show Add-Ons on Product Page to test conversion.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Ability to add a custom Flower Type or Color tag on local products to be used in the filters on collection pages.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Updated the field labels in checkout to the make the sender and recipient details are clear to the customer.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                'Introduced a new "tab design" in the Shop Settings page for better organization and visibility.',
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Added new “Plus” and “Gear” icons to the Product Catalog pages to streamline the user interface and allow users to easily create products and collections, and to access catalog and collection settings.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Collections now have their own pages for better visibility and editing.",
              level: 1,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 7.0 - 01/26/2023",
    title: Localise(messages, "Release 7.0 - 01/26/2023"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Added the Milestone Reviews Badge to the Customer Reviews landing page for users who are subscribed to the FTD Online Rating Service.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Added Optional Availability Dates that can be configured for each product in the catalog.",
              level: 1,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 6.0 - 01/11/2023",
    title: Localise(messages, "Release 6.0 - 01/11/2023"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Created a new Reviews widget (Real Reviews from Real Customers) that will display a Milestone Reviews Badge (if applicable), Overall Site Ratings and a Rotating display of the most recent reviews.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Added the Reviews widget to the Homepage for users subscribed to the FTD Online Rating Service. ",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Added the Reviews widget to the About Us page for users subscribed to the FTD Online Rating Service.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "A new floating Shopper Approved Review Seal was added on every page of your site for users subscribed to the FTD Online Rating Service.  The seal displays your reviews star rating and total review count.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Updated the layout and added SEO copy to the Customer Reviews landing page.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Fixed a bug where site translations were missing in certain content areas of the site.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "New Availability switch to allow a user to set a product as In-Store Pickup Only.",
              level: 1,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 5.0 - 12/28/2022",
    title: Localise(messages, "Release 5.0 - 12/28/2022"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "For users subscribed to the FTD Online Rating Service we enhanced Site Reviews with a new floating Shopper Approved Seal on every page that displays your star rating and total review count.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Customer Reviews link added to About Us site navigation for users with Site Reviews active as part of the FTD Online Rating Service.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Co-branding displayed in footer: Powered by Shopify and FTD.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Enabled multilingual translations on Shopify for users that are subscribed to the service.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Enabled cart abandonment tools on Shopify for users subscribed to FTD’s Online Rating Service.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Ability to associate add-ons to products/collections in bulk.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "New fields for Color and Flower Type tagging for local/custom products to be used for the filter on collection pages in Shopify.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Call for Price and Remove Call for Price values added to the Take Action bulk drop down menu.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Removed Promo Eligible switch from product Availability section as it will be for future use.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "Enhancements made to SEMRush creation of projects.",
              level: 1,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 4.5 - 12/13/2022",
    title: Localise(messages, "Release 4.5 - 12/13/2022"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "Enabled Shopper Approved (site/seller reviews).",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "A lock icon and Secure Checkout text was added below the Add to Basket button on product display pages.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "The Announcement Banner text has been updated with local focused content that can be customized.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Addons are now showing the On Sale price in Shopify when Compare at Price is added from Mercury HQ.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "The address verification service (AVS) that is used in Shopify has been updated and we are populating the default city displayed based on the preferred zip code option coming from USPS.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "A new Rotating Banner option was added within Homepage Banner site content settings.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Sold Out and Remove Sold Out values added to the Take Action dropdown and is applicable to all types of products and add-ons.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Call for Price added to the product pages in Mercury HQ. This feature allows pricing not to be shown on products in Shopify. The store phone number will be displayed.",
              level: 1,
              childContent: [],
            },
          ],
        },
      ],
    },
  },

  {
    name: "Release 4.4 - 11/30/2022",
    title: Localise(messages, "Release 4.4 - 11/30/2022"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "On Sale Tag added to product Variation Buttons.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "Restacking of PDP Elements in Mobile.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Inclusion of all searchable fields for the product search.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "Mobile app updated with product catalog redesign.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "Squashed more bugs in Shopify and Mercury HQ",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Deletion of Local active addon and product from the page with success toast message.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Increased Text size for banner coming from Mercury HQ global.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Overlapping issue of the sidecar over products is resolved.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Fixed Manual UI for Mobile.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
          ],
        },
        {
          type: "text",
          content: "SEMRush Setup",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "SEMRush Setup is added into MOL sync process.",
              level: 1,
              childContent: [],
            },
          ],
        },
      ],
    },
  },

  {
    name: "Release 4.3 - 11/21/2022",
    title: Localise(messages, "Release 4.3 - 11/21/2022"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Shopify",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Added Recommended Products to the Search Results page when there are no search results returned.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "Added Recommended Products to the About Us page.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content:
                "Filter enabled on Product Listing Pages to allow users to filter by Flower Type and Color.",
              level: 1,
              childContent: [],
            },
          ],
        },
        {
          type: "text",
          content: "Mercury HQ - Product Catalog",
          level: 0,
          childContent: [
            {
              type: "text",
              content:
                "Filter enabled to allow users to filter while viewing/editing Products and Collections (Active, Inactive, Global, Local, Add-Ons, Catalog Minimum).",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "Mobile app updated with product catalog redesign.",
              level: 1,
              childContent: [],
            },
            {
              type: "text",
              content: "Squashed more bugs in Shopify and Mercury HQ",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content: "Removed Add-ons from You May Also Like",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Improvements to Manual Sorting and Drag and Drop functionality in Product Catalog.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Fixed dead-clicks on Product Tiles.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content: "Browser Back Button functionality restored",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Special Characters coming in Recipient and Billing Name and Address fields showing a ? has been fixed.",
                  level: 1,
                  childContent: [],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: "Release 4.2 - 11/03/2022",
    title: Localise(messages, "Release 4.2 - 11/03/2022"),
    component: {
      childContent: [
        {
          type: "text",
          content: "Mercury Online - Product Catalog Redesign",
          level: 0,
          childContent: [
            {
              type: "text",
              content: "New Product Catalog",
              level: 1,
              childContent: [
                {
                  type: "text",
                  content:
                    "Custom, Local Product Tiles, Thumbnail Images, and Center Image Alignment.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Redesign of Product, Create Product, Add-on, Catalog Settings, Bulk Updates, and Collections.",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Compare at Price to allow user to show sales price (outside of a Discount or Promotion created in Shopify Admin).",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Ability to Increase and Decrease pricing for Products by Dollar or Percentage in one update (bulk feature)",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Ability to Activate and Inactive products in one update (bulk feature)",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Ability to add and remove products from a collection in one update (bulk feature)",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Ability to delete local products in one update (bulk feature)",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Ability to apply Minimum Price rule to entire catalog and also exclude products from rule",
                  level: 1,
                  childContent: [],
                },
                {
                  type: "text",
                  content:
                    "Ability to auto-activate or not auto-activate global products",
                  level: 1,
                  childContent: [],
                },
              ],
            },
            {
              type: "text",
              content:
                "Fixed bugs in Shopify (Home page Banner background as a hyperlink assigned to the PLP page, and Delivery Date Calendar), MOL website (Delivery Fees to the city and zip code)",
              level: 1,
              childContent: [],
            },
          ],
        },
      ],
    },
  },
];
