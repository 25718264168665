import React, { useEffect } from "react";
import {
  View,
  ActivityIndicator,
  Platform,
  TouchableOpacity,
} from "react-native";
import { Text, Image } from "react-native-elements";
import { ToasterHandler } from "components/elements";

import tw from "tailwind-rn";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";
import { request } from "library/utils/request";
import IMAGES from "static/assets/images";

import {
  Form,
  FormFieldPicker,
  FormFieldSwitch,
  SubmitButton,
} from "components/elements/forms";
import { getValidationSchema } from "./config";
import { fonts, colors } from "styles/theme";

import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";

const SettingItem = React.memo(
  ({
    title,
    settingId,
    initialValues: initailPreferences,
    loadingKey,
    printers,
    saveSettings,
    shopSettings,
    printersInfo,
    shopCode,
    macAddress,
  }) => {
    const hasMultiplePreferences = isArray(initailPreferences);
    let initialValues = {};
    let initialValuesIndex = -1;

    if (hasMultiplePreferences) {
      initialValuesIndex = initailPreferences.findIndex(
        (each) => each.macAddress === macAddress
      );
      if (initialValuesIndex > -1)
        initialValues = initailPreferences[initialValuesIndex];
    } else {
      initialValues = initailPreferences;
    }

    const { messages, Localise } = React.useContext(I18NContext);
    const [selectedPrinter, setSelectedPrinter] = useStateIfMounted(
      initialValues?.printer || ""
    );
    const [onChangeValidation, setOnChangeValidation] =
      useStateIfMounted(false);

    const validationSchema = getValidationSchema(Localise, messages);

    const specificSettingInfo = get(shopSettings, settingId, {});

    const specificSetting = isArray(specificSettingInfo)
      ? specificSettingInfo.find((each) => each.macAddress === macAddress)
      : specificSettingInfo;

    const isSettingsExists = !isEmpty(specificSetting);

    const getPrinterTrays = () => {
      if (selectedPrinter === "") return [];
      const selectedPrinterDetails =
        printersInfo.filter((each) => each.printerName === selectedPrinter) ||
        [];

      const traysInfo = [];
      selectedPrinterDetails[0]?.trays.map((each) =>
        traysInfo.push({ label: each.name, value: each.code })
      );
      return traysInfo;
    };

    const onSubmit = async (values, settingId) => {
      const clonedInitialVals = cloneDeep(initailPreferences);
      let newValues = "";
      if (hasMultiplePreferences) {
        if (initialValues?.macAddress !== values.macAddress) {
          clonedInitialVals.push(values);
        } else {
          clonedInitialVals.splice(initialValuesIndex, 1, values);
        }
        newValues = clonedInitialVals;
      } else {
        newValues = [values];
      }

      const preferences = [
        {
          id: settingId,
          values: [JSON.stringify(newValues)],
        },
      ];
      saveSettings({ preferences });
    };

    const onClear = async (settingId, resetForm, setSelectedPrinter) => {
      const clonedInitialVals = cloneDeep(initailPreferences);
      const initialValuesIndex = initailPreferences.findIndex(
        (each) => each.macAddress === macAddress
      );
      if (initialValuesIndex > -1) {
        clonedInitialVals.splice(initialValuesIndex, 1);
      }

      const preferences = [
        {
          id: settingId,
          values: [JSON.stringify(clonedInitialVals)],
        },
      ];
      setSelectedPrinter("");
      saveSettings({ preferences }, true, false, () => {
        resetForm();
      });
    };

    return (
      <View
        style={[
          tw(`flex flex-row items-center w-full`),
          {
            borderWidth: 1,
            borderColor: colors.grayScaleLight,
            justifyContent: "space-evenly",
          },
        ]}
        testID={settingId}
      >
        <Form
          initialValues={{ ...initialValues, macAddress }}
          validationSchema={validationSchema[settingId]}
          onSubmit={(values) => onSubmit(values, settingId)}
          validateOnChange={onChangeValidation}
          validateOnBlur={onChangeValidation}
          render={({ values, resetForm, setFieldValue, submitCount }) => {
            //eslint-disable-next-line
            useEffect(() => {
              if (submitCount > 0) {
                setOnChangeValidation(true);
              }
            }, [submitCount]);

            return (
              <View
                style={[
                  tw("flex flex-row w-4/5	"),
                  {
                    alignItems: Platform.OS !== "web" ? "center" : "baseline",
                    justifyContent: "space-evenly",
                  },
                ]}
              >
                <View
                  style={{
                    width: "20%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={fonts.heading5}>
                    {Localise(messages, title)}
                  </Text>
                  {[
                    "order_messages_incoming_print_settings",
                    "order_messages_outgoing_print_settings",
                  ].includes(settingId) && (
                    <Image
                      style={{ width: 28, height: 15, marginLeft: 10 }}
                      resizeMode="cover"
                      source={IMAGES["beta-label"]}
                    />
                  )}
                </View>
                <FormFieldPicker
                  fsClass="fs-exclude"
                  containerStyle={{
                    width: "25%",
                    paddingBottom: 5,
                  }}
                  placeholder={{ label: "Select Printer", value: "" }}
                  data={printers}
                  name="printer"
                  onChange={(e) => {
                    setSelectedPrinter(e);
                    values.printer !== e && setFieldValue("tray", "");
                  }}
                  testID="printer_selection"
                />
                <FormFieldPicker
                  fsClass="fs-exclude"
                  containerStyle={{
                    width: "25%",
                    paddingBottom: 5,
                  }}
                  placeholder={{ label: "Select Tray", value: "" }}
                  data={getPrinterTrays()}
                  name="tray"
                  testID="tray_selection"
                />

                <View
                  style={[
                    tw("flex flex-row items-center justify-center"),
                    { width: "20%" },
                  ]}
                >
                  <SubmitButton disableOnDirty={true} title="Save" />
                  <View style={{ minWidth: 45 }}>
                    {!isEmpty(initialValues) && (
                      <TouchableOpacity
                        onPress={() => {
                          setOnChangeValidation(false);
                          onClear(settingId, resetForm, setSelectedPrinter);
                        }}
                        testID={"clear"}
                        accessibilityLabel={"clear"}
                      >
                        <View>
                          <Text style={fonts.link1}>
                            {Localise(messages, "Clear")}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              </View>
            );
          }}
        />
        <View style={tw(`flex flex-row items-center justify-evenly w-1/5`)}>
          <View style={tw("flex flex-row items-center")}>
            <View
              style={{
                width: 75,
              }}
            >
              <Text style={fonts.heading5}>
                {Localise(messages, "Auto Print")}
              </Text>
            </View>
            <FormFieldSwitch
              name="auto_print"
              value={isSettingsExists ? specificSetting["auto_print"] : false}
              onValueChange={(val) => {
                const autoPrintAcceptedOrders = get(
                  shopSettings,
                  "auto_print_accept_orders",
                  ""
                );
                if (
                  val &&
                  [
                    "order_messages_incoming_print_settings",
                    "order_messages_outgoing_print_settings",
                  ].includes(settingId)
                ) {
                  request("mark-messages-printed", [
                    {
                      memberCode: shopCode,
                      direction: settingId.includes("incoming")
                        ? "INBOUND"
                        : "OUTBOUND",
                      messageType: "RELATED_MESSAGE",
                    },
                  ]);
                }

                const handleSwitch = () => {
                  const clonedInitialVals = cloneDeep(initailPreferences);
                  let newValues = "";
                  if (hasMultiplePreferences) {
                    clonedInitialVals.splice(initialValuesIndex, 1, {
                      ...initialValues,
                      auto_print: val,
                    });
                    newValues = clonedInitialVals;
                  } else {
                    newValues = [
                      {
                        ...initialValues,
                        auto_print: val,
                      },
                    ];
                  }

                  const preferences = [
                    {
                      id: settingId,
                      values: [JSON.stringify(newValues)],
                    },
                  ];
                  saveSettings({
                    preferences,
                    isAutoPrintToggle:
                      settingId !== "pickup_manifest_print_settings",
                  });
                };
                if (settingId === "pickup_manifest_print_settings") {
                  handleSwitch();
                } else {
                  if (autoPrintAcceptedOrders === "true") {
                    handleSwitch();
                  } else {
                    ToasterHandler(
                      "error",
                      Localise(
                        messages,
                        "Activate Auto Print in Shop Settings to use this feature"
                      )
                    );
                  }
                }
              }}
              disabled={!isSettingsExists}
              testID="auto_print"
            />
          </View>
          {loadingKey === settingId && (
            <ActivityIndicator
              color={colors.activityIndicator}
              testID={"setting_loader"}
            />
          )}
        </View>
      </View>
    );
  }
);

export default SettingItem;
