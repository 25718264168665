import React from "react";
import { Text } from "react-native-elements";
import { View } from "react-native";
import tw from "tailwind-rn";
import styles from "./styles";
import I18NContext from "library/contexts/i18N";
import UserProfileStorage from "library/storage/userProfile";
import {
  convertToTimeZone,
  isBurqProvider,
} from "library/utils/deliveryService";
import { dateTimeFormat } from "library/utils/datetime";
import { fonts } from "styles/theme";
import get from "lodash/get";
import moment from "moment";
import startCase from "lodash/startCase";
import {
  formatPhoneForPayload,
  phoneNumberFormatter,
} from "library/utils/formatter";

export const getShopDetails = (selectedShopCode) => {
  const shopNames = UserProfileStorage.getAllShopNames();
  const shopTimeZone = UserProfileStorage.getShopTimeZone(selectedShopCode);
  const floristTimeZone = shopTimeZone || "America/Chicago";

  return {
    shopCode: selectedShopCode,
    shopField: `${selectedShopCode} ${shopNames[selectedShopCode]}`,
    shopName: shopNames[selectedShopCode],
    floristTimeZone: floristTimeZone,
  };
};

export const generateContent = (data, suppressNull = false) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { messages, Localise } = React.useContext(I18NContext);
  return Object.keys(data).map((key) => {
    const value = data[key] || "";
    if (!value && suppressNull) return;
    return (
      <View
        style={{ ...tw("flex flex-row my-1"), zIndex: -1 }}
        key={key}
        fsClass={
          ["Phone", "Address"].includes(key) ? "fs-exclude" : "fs-unmask"
        }
      >
        <Text style={{ ...fonts.default, ...styles.labelText }}>
          {Localise(messages, key)}
        </Text>
        {typeof value === "object" ? (
          <View>
            {Object.keys(value).map((key) => (
              <Text style={[tw("pb-1"), fonts.default]} key={`${key}-value`}>
                {value[key]}
              </Text>
            ))}
          </View>
        ) : (
          <Text
            style={{
              ...fonts.default,
              ...styles[key],
            }}
          >
            {value}
          </Text>
        )}
      </View>
    );
  });
};

export const getUpdatedDateTime = (
  date,
  time,
  floristTimeZone,
  add = false
) => {
  const dateTime = moment(date + "T" + time + ":00").format(dateTimeFormat);

  let updatedDateTime;

  if (add) {
    updatedDateTime = moment
      .tz(dateTime, dateTimeFormat, floristTimeZone)
      .utc()
      .add(1, "hours")
      .format(dateTimeFormat);
  } else {
    updatedDateTime = moment
      .tz(dateTime, dateTimeFormat, floristTimeZone)
      .utc()
      .format(dateTimeFormat);
  }

  return updatedDateTime;
};

export const buildDSRequestDetails = (dsRequestDetails) => {
  const {
    referenceId,
    items,
    delivery,
    pickup,
    siteId: shopCode,
    vendor: { name: deliveryProvider = "" } = {},
    fulfillmentType = "Express",
    estimatedDistance = "",
  } = dsRequestDetails;

  const shopDetails = getShopDetails(shopCode);
  const { floristTimeZone } = shopDetails;

  return {
    referenceNumber: referenceId,
    ...shopDetails,
    //need to check all providers
    distanceThreshold: 15,
    items: [
      {
        itemDescription: get(items, "0.description", ""),
        NoOfItems: get(items, "0.quantity", "").toString(),
        totalItemValue: parseFloat(get(items, "0.cost.value", "")).toFixed(2),
      },
    ],
    deliveryDetails: {
      assignment: "deliveryService",
      deliveryDate: moment(
        convertToTimeZone(
          get(delivery, "timeWindow.start", ""),
          floristTimeZone
        )
      ).format("YYYY-MM-DD"),
      pickUpDateTime:
        deliveryProvider.toLowerCase() === "doordash"
          ? `${moment(
              convertToTimeZone(
                get(pickup, "timeWindow.start", ""),
                floristTimeZone
              )
            ).format("HH:mm A")} ${startCase(fulfillmentType.toLowerCase())}`
          : convertToTimeZone(
              get(pickup, "timeWindow.start", ""),
              floristTimeZone
            ),
      undeliverableAction: get(delivery, "options.undeliverableAction", ""),
      specialDeliveryInstructions: get(delivery, "notes", ""),
    },
    recipientDetails: {
      firstName: get(delivery, "address.firstName", ""),
      lastName: get(delivery, "address.lastName", ""),
      phone: phoneNumberFormatter(get(delivery, "contact.phone", "")),
      addressLine1: get(delivery, "address.addressLine1", ""),
      addressLine2: get(delivery, "address.addressLine2", ""),
      country: get(delivery, "address.country", ""),
      city: get(delivery, "address.city", ""),
      state: get(delivery, "address.state", ""),
      zipcode: get(delivery, "address.zip", ""),
      locationType: get(delivery, "address.locationType", "")?.toLowerCase(),
      locationName: get(delivery, "address.locationName", ""),
    },
    injectTimings: [],
    timeWindow: [],
    pickupUpdatedTime: {},
    estimatedDistance: estimatedDistance,
    specialInstructionsCharCount: 0,
    deliveryProvider: deliveryProvider,
  };
};

export const getReqObj = ({
  values,
  edit = false,
  shipmentId = "",
  dsRequestDetails: { correlatedShipments = {} } = {},
  redeliver = false,
  previousShipmentId = "",
}) => {
  const { redeliveryFor = [] } = correlatedShipments;
  const shopLocations = UserProfileStorage.getAllShopLocation();

  const {
    shopCode,
    shopName,
    floristTimeZone,
    referenceNumber,
    deliveryProvider,
    estimatedDistance = "",
    fulfillmentType = "STANDARD", // default sending "STANDARD" for Roadie
  } = values;
  const {
    addressLine1: shopAddressLine1 = "",
    city: shopCity = "",
    state: shopState = "",
    zip: shopZip = "",
    latitude: shopLatitude = "",
    longitude: shopLongitude = "",
  } = shopLocations[shopCode] || {};
  const {
    firstName = "",
    lastName = "",
    phone = "",
    addressLine1 = "",
    addressLine2 = "",
    country = "US",
    city = "",
    state = "",
    zipcode = "",
    locationType = "",
    locationName = "",
    geoLocation = {},
  } = get(values, "recipientDetails", {});
  const {
    deliveryDate = "",
    pickUpDateTime = "",
    undeliverableAction = "",
    specialDeliveryInstructions = "",
  } = get(values, "deliveryDetails", {});

  const body = {
    referenceId: referenceNumber,
    size: "medium", //default
    items: values.items.map((item) => ({
      description: item.itemDescription,
      quantity: item.NoOfItems,
      cost: {
        value: parseFloat(item.totalItemValue).toFixed(2),
        currency: "USD", //default
      },
    })),
    pickup: {
      address: {
        locationType: "Business", //default
        locationName: shopName,
        addressLine1: shopAddressLine1,
        city: shopCity,
        state: shopState,
        zip: shopZip,
        country: "US",
        timeZone: floristTimeZone,
        geoLocation: {
          latitude: shopLatitude,
          longitude: shopLongitude,
        },
      },
      contact: {
        firstName: shopName,
        lastName: "",
        phone: UserProfileStorage.getShopPhoneNumber(shopCode).replace(
          /[^\d]/g,
          ""
        ),
        email: UserProfileStorage.getShopEmail(shopCode),
      },
      timeWindow: {
        start: getUpdatedDateTime(
          deliveryDate,
          deliveryProvider?.toLowerCase() === "doordash"
            ? pickUpDateTime?.split(" ")[0]
            : moment(pickUpDateTime).format("HH:mm"),
          floristTimeZone
        ),
      },
    },
    delivery: {
      address: {
        firstName: `${firstName}`,
        lastName: `${lastName}`,
        locationType: locationType,
        locationName: locationName,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        state: state,
        zip: zipcode,
        country: country,
        ...(geoLocation && { geoLocation }),
      },
      contact: {
        firstName: `${firstName}`,
        lastName: `${lastName}`,
        phone: formatPhoneForPayload(phone),
      },
      notes: specialDeliveryInstructions,
      options: {
        undeliverableAction: undeliverableAction,
      },
    },
    estimatedDistance: estimatedDistance,
    fulfillmentType:
      deliveryProvider?.toLowerCase() === "doordash"
        ? pickUpDateTime?.split(" ")[2].toUpperCase()
        : isBurqProvider(deliveryProvider)
        ? "EXPRESS"
        : fulfillmentType.toUpperCase(),
    deliveryProvider: deliveryProvider,
    ...(redeliveryFor?.length > 0 && {
      correlatedShipments,
    }),
  };
  const reqObj = {
    shopCode,
    body,
    ...(edit && { shipmentId }),
    ...(redeliver && { previousShipmentId }),
  };

  return reqObj;
};

//Mobile: Not Every 30 minutes
export const handleSelectedPickupTimeIsBeforeCurrentTime = (
  pickUpDate,
  pickUpDateTime,
  pickUpTimeWindow,
  setFieldValue,
  shopTimeZone
) => {
  let currentDate = moment(
    convertToTimeZone(moment.utc().format(), shopTimeZone)
  ).format();
  let pickupTime = pickUpDateTime ? pickUpDateTime : currentDate;

  let newPickUpTimeOnly = moment(pickupTime).format("HH");
  let timeMorning = moment(pickUpTimeWindow[0] + ":00:00", "HH:mm:ss");
  let timeAfternoon = moment(pickUpTimeWindow[1] + ":00:00", "HH:mm:ss");
  let actualTimeOfCheck = moment(newPickUpTimeOnly + ":00:00", "HH:mm:ss");
  let differenceForMorning = moment.duration(
    timeMorning.diff(actualTimeOfCheck)
  );
  let differenceForAfternoon = moment.duration(
    timeAfternoon.diff(actualTimeOfCheck)
  );

  if (
    Math.abs(parseInt(differenceForMorning.asMinutes())) <=
    Math.abs(parseInt(differenceForAfternoon.asMinutes()))
  ) {
    setFieldValue(
      "deliveryDetails.pickUpDateTime",
      `${moment(pickUpDate).format("YYYY-MM-DD")}T${
        pickUpTimeWindow[0] + ":00:00"
      }`
    );
  } else {
    setFieldValue(
      "deliveryDetails.pickUpDateTime",
      `${moment(pickUpDate).format("YYYY-MM-DD")}T${
        pickUpTimeWindow[1] + ":00:00"
      }`
    );
  }
};

//Mobile: Not Every 30 minutes : Handle time restriction
export const handlePickupTimeWindow = (
  pickUpTimeWindow,
  pickUpDate,
  dsMinDate,
  currentTime,
  setFieldValue,
  pickUpDateTime,
  shopTimeZone
) => {
  if (pickUpTimeWindow.length == 2) {
    let isSameDay = moment(pickUpDate).isSame(dsMinDate, "day");
    if (isSameDay || !pickUpDate) {
      let actualTime = "";
      let timeMorning = moment(pickUpTimeWindow[0] + ":00:00", "HH:mm:ss");
      let timeAfternoon = moment(pickUpTimeWindow[1] + ":00:00", "HH:mm:ss");

      if (currentTime.isAfter(timeMorning)) {
        if (currentTime.isAfter(timeAfternoon)) {
          let addOneDate = convertToTimeZone(
            moment().utc().add(1, "days").toDate(),
            shopTimeZone
          );
          setFieldValue(
            "deliveryDetails.deliveryDate",
            moment(addOneDate).format("YYYY-MM-DD")
          );
          actualTime = pickUpTimeWindow[0] + ":00:00";
        } else {
          actualTime = pickUpTimeWindow[1] + ":00:00";
        }
        setFieldValue(
          "deliveryDetails.pickUpDateTime",
          `${moment(pickUpDate).format("YYYY-MM-DD")}T${actualTime}`
        );
      } else {
        handleSelectedPickupTimeIsBeforeCurrentTime(
          pickUpDate,
          pickUpDateTime,
          pickUpTimeWindow,
          setFieldValue,
          shopTimeZone
        );
      }
    } else {
      handleSelectedPickupTimeIsBeforeCurrentTime(
        pickUpDate,
        pickUpDateTime,
        pickUpTimeWindow,
        setFieldValue,
        shopTimeZone
      );
    }
  } else if (pickUpTimeWindow.length == 1) {
    let isSameDay = moment(pickUpDate).isSame(dsMinDate, "day");
    if (isSameDay || !pickUpDate) {
      let actualTime = "";
      let timeMorning = moment(pickUpTimeWindow[0] + ":00:00", "HH:mm:ss");

      if (currentTime.isAfter(timeMorning)) {
        let addOneDate = convertToTimeZone(
          moment().utc().add(1, "days").toDate(),
          shopTimeZone
        );
        setFieldValue(
          "deliveryDetails.deliveryDate",
          moment(addOneDate).format("YYYY-MM-DD")
        );
        actualTime = pickUpTimeWindow[0] + ":00:00";
      } else {
        actualTime = pickUpTimeWindow[0] + ":00:00";
      }
      setFieldValue(
        "deliveryDetails.pickUpDateTime",
        `${moment(pickUpDate).format("YYYY-MM-DD")}T${actualTime}`
      );
    } else {
      setFieldValue(
        "deliveryDetails.pickUpDateTime",
        `${moment(pickUpDate).format("YYYY-MM-DD")}T${
          pickUpTimeWindow[0] + ":00:00"
        }`
      );
    }
  }
};

//Web : Every 30 minutes : To get the minimum and maximum date for time picker in web to restrict the timings in picker for timewindow
export const getPickupTime = (
  pickUpDate,
  shopTimeZone,
  shopHours,
  updatedDate,
  currentFloristTime
) => {
  let dsPickUpTimings = {};
  dsPickUpTimings = {
    open: moment(pickUpDate ? pickUpDate : updatedDate)
      .set({
        hour: parseInt(shopHours?.openTime.split(":")[0]) || 0,
        minute: parseInt(shopHours?.openTime.split(":")[1]) || 0,
        second: parseInt(shopHours?.openTime.split(":")[2]) || 0,
      })
      .toDate(),
    close: moment(pickUpDate ? pickUpDate : updatedDate)
      .set({
        hour: parseInt(shopHours?.closeTime.split(":")[0]) || 0,
        minute: parseInt(shopHours?.closeTime.split(":")[1]) || 0,
        second: parseInt(shopHours?.closeTime.split(":")[2]) || 0,
      })
      .toDate(),
    updatedDate: updatedDate,
  };
  let currentDate = moment(
    convertToTimeZone(moment.utc().format(), shopTimeZone)
  ).format("YYYY-MM-DD");
  let openingTime = moment(
    `${pickUpDate ? pickUpDate : currentDate}T${shopHours?.openTime}`,
    "YYYY-MM-DDTHH:mm:ss"
  );
  let closingTime = moment(
    `${pickUpDate ? pickUpDate : currentDate}T${shopHours?.closeTime}`,
    "YYYY-MM-DDTHH:mm:ss"
  );

  let newFloristTime = moment(currentFloristTime).toDate();

  if (moment(currentFloristTime).isBetween(openingTime, closingTime)) {
    dsPickUpTimings.open = newFloristTime;
  }

  return dsPickUpTimings;
};

//Mobile: Every 30 minutes : Handle time restriction for pickupwindow
export const handlePickerTimings = (
  pickUpDate,
  setFieldValue,
  pickUpDateTime,
  shopTimeZone,
  shopHours,
  currentTime,
  currentFloristTime,
  isSameDay
) => {
  let openingTime = "";
  let closingTime = "";
  let selectedTime = "";
  let pickUpTimeOnly = moment(pickUpDateTime, "YYYY-MM-DDTHH:mm:ss").format(
    "HH:mm:ss"
  );
  let currentDate = moment(currentFloristTime).format("YYYY-MM-DD");

  selectedTime = moment(
    `${pickUpDate ? pickUpDate : currentDate}T${
      pickUpTimeOnly ? pickUpTimeOnly : moment(currentTime).format("hh:mm:ss")
    }`,
    "YYYY-MM-DDTHH:mm:ss"
  );

  openingTime = moment(
    `${pickUpDate ? pickUpDate : currentDate}T${shopHours?.openTime}`,
    "YYYY-MM-DDTHH:mm:ss"
  );
  closingTime = moment(
    `${pickUpDate ? pickUpDate : currentDate}T${shopHours?.closeTime}`,
    "YYYY-MM-DDTHH:mm:ss"
  );

  if ((isSameDay && selectedTime) || !pickUpDate) {
    let updatedPickUpTime = "";
    if (
      moment(selectedTime).isBetween(openingTime, closingTime) &&
      selectedTime.isBefore(currentFloristTime)
    ) {
      updatedPickUpTime = roundUpTime(moment(currentFloristTime).toDate());
    } else if (selectedTime.isBefore(openingTime)) {
      if (selectedTime.isBefore(currentFloristTime)) {
        updatedPickUpTime = roundUpTime(moment(currentFloristTime).toDate());
      } else {
        updatedPickUpTime = openingTime;
      }
    } else if (selectedTime.isAfter(closingTime)) {
      updatedPickUpTime = closingTime;
    }
    if (updatedPickUpTime)
      setFieldValue(
        "deliveryDetails.pickUpDateTime",
        roundUpTime(moment(updatedPickUpTime).toDate())
      );
  } else if (!isSameDay && selectedTime) {
    if (moment(currentFloristTime).isBetween(openingTime, closingTime)) {
      let nextDayUpdatedTime = "";
      if (
        selectedTime.isAfter(openingTime) &&
        selectedTime.isBefore(closingTime)
      ) {
        nextDayUpdatedTime = selectedTime;
      } else if (selectedTime.isAfter(closingTime)) {
        nextDayUpdatedTime = closingTime;
      } else if (selectedTime.isBefore(openingTime)) {
        if (selectedTime.isBefore(currentFloristTime)) {
          nextDayUpdatedTime = roundUpTime(currentFloristTime);
        } else {
          nextDayUpdatedTime = openingTime;
        }
      }
      if (nextDayUpdatedTime)
        setFieldValue(
          "deliveryDetails.pickUpDateTime",
          moment(nextDayUpdatedTime).format("YYYY-MM-DDTHH:mm:ss")
        );
    } else {
      let nextDayUpdatedTime = "";
      if (
        selectedTime.isAfter(openingTime) &&
        selectedTime.isBefore(closingTime)
      ) {
        nextDayUpdatedTime = selectedTime;
      } else if (selectedTime.isSameOrAfter(closingTime)) {
        nextDayUpdatedTime = closingTime;
      } else if (selectedTime.isBefore(openingTime)) {
        nextDayUpdatedTime = openingTime;
      }
      if (nextDayUpdatedTime)
        setFieldValue(
          "deliveryDetails.pickUpDateTime",
          moment(nextDayUpdatedTime).format("YYYY-MM-DDTHH:mm:ss")
        );
    }
  }
};

// rounding time to nearest 30 min to show it in pickup time drop down
export const roundUpTime = (datetime, ms = 30 * 60 * 1000) => {
  datetime = datetime || new Date();
  return new Date(Math.ceil(datetime.getTime() / ms) * ms);
};

//To check if date is past /same day
export const checkIfPastDate = (deliveryDate, shopCode) => {
  if (shopCode) {
    const shopTimeZone = UserProfileStorage.getShopTimeZone(shopCode);
    let currentFloristDate = convertToTimeZone(
      moment.utc().format(),
      shopTimeZone
    );
    let isSameDay = moment(deliveryDate).isSameOrBefore(
      currentFloristDate,
      "day"
    );
    if (isSameDay) return true;
  }
};
