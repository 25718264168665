import React, { useContext, useEffect, useRef } from "react";
import { View, TouchableOpacity } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Image, Text } from "react-native-elements";
import tw from "tailwind-rn";

import { Form, FormField, FormFieldSwitch } from "components/elements/forms";
import QuillEditor from "components/elements/react-quill-editor";
import { PopupMenu } from "components/elements";
import { ToasterHandler, Tooltip } from "components/elements";

import { fonts, shapes, colors, backgroundColors } from "styles/theme";
import IMAGES from "static/assets/images";

import { orderStatusSpecificTokenMenu } from "library/utils/notificationTokens";
import useStateIfMounted from "library/utils/useStateIfMounted";
import UserProfileStorage from "library/storage/userProfile";

import UserProfileContext from "library/contexts/userProfile";
import { AppSettingsContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import { fetchDefaultNotifications } from "library/sagas/views/home/drawer/shop-settings/common/slice";

import {
  selectShopCode,
  selectActiveNotificationTab,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { emailSettingsSchema } from "./config";
import { formatQuillEditorStyles, getMaxHeight } from "library/utils/formatter";
import {
  hasBirthdayReminderFeature,
  hasAnniversaryReminderFeature,
} from "library/utils/featureAvailability";
import { SaveCancelButtons } from "components/wrappers";

const EmailSettings = ({
  defaultNotification = {},
  notificationStatus = {},
  saveSettings,
}) => {
  const dispatch = useDispatch();
  const reactQuillEmailHeaderRef = useRef(null);
  const { proxyUser, suspendedMembers = [] } = useContext(UserProfileContext);
  const { messages, Localise } = useContext(I18NContext);
  const { permissions } = useContext(AppSettingsContext);

  const shopCode = useSelector(selectShopCode);
  const currentNotificationTab = useSelector(selectActiveNotificationTab);

  const getUserRoles = UserProfileStorage.getUserRoles();
  const enableEmailSectionEditing = [
    "birthday_occasion_notification_settings",
  ].includes(currentNotificationTab)
    ? hasBirthdayReminderFeature(permissions, shopCode)
    : ["anniversary_occasion_notification_settings"].includes(
        currentNotificationTab
      )
    ? hasAnniversaryReminderFeature(permissions, shopCode)
    : getUserRoles.includes("ADMIN") || getUserRoles.includes("MANAGER");

  const { smsToggle, smsBody, emailSubject, emailToggle, emailBody } =
    notificationStatus;

  const [emailToggleValue, setEmailToggleValue] =
    useStateIfMounted(emailToggle);
  const [emailDefaultSubj, setEmailDefaultSubj] =
    useStateIfMounted(emailSubject);
  const [emailDefaultBody, setEmailDefaultBody] = useStateIfMounted(emailBody);
  const [isSubmit, setIsSubmit] = useStateIfMounted(true);
  const [validationOnChange, setValidationOnChange] = useStateIfMounted(false);
  const [cursorPosition, setCursorPosition] = useStateIfMounted(0);
  const [emailSubjTokenMenu, setEmailSubjTokenMenu] = useStateIfMounted([]);
  const [emailBodyTokenMenu, setEmailBodyTokenMenu] = useStateIfMounted([]);

  const modules = {
    toolbar: [
      [{ font: ["arial", "georgia", "impact", "verdana", "tahoma", "times"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["link"],
    ],
  };
  useEffect(() => {
    setEmailToggleValue(emailToggle);
    setEmailDefaultSubj(emailSubject);
    setEmailDefaultBody(emailBody);
  }, [notificationStatus]);

  useEffect(() => {
    setEmailSubjTokenMenu(
      orderStatusSpecificTokenMenu[0]?.[currentNotificationTab]
        ?.emailSubjectTokenMenu
    );
    setEmailBodyTokenMenu(
      orderStatusSpecificTokenMenu[0]?.[currentNotificationTab]
        ?.emailBodyTokenMenu
    );
  }, [currentNotificationTab]);

  const onEditorChange = (value) => {
    if (enableEmailSectionEditing) {
      setEmailDefaultBody(value);
      value !== emailBody ? setIsSubmit(false) : setIsSubmit(true);
    }
  };

  const getEmailBodyValidation = () => {
    const tokensList =
      orderStatusSpecificTokenMenu[0]?.[currentNotificationTab]
        ?.emailBodyTokenMenu;

    let emailBodyTokens = [];
    tokensList.forEach((eachToken) => {
      const token = eachToken?.label?.toLowerCase()?.replace(/ /g, "_");
      emailBodyTokens.push(token);
    });

    const tokens = emailDefaultBody
      ?.toLowerCase()
      ?.replace(/ /g, "_")
      ?.match(/\[([^[\]]+)]/g);
    let isInvertedBracesPresent = [];

    for (let char of emailDefaultBody) {
      if (char === "[") {
        // If an opening bracket is found, push it onto the isInvertedBracesPresent
        isInvertedBracesPresent.push(char);
      } else if (char === "]") {
        // If a closing bracket is found, check if there is a matching opening bracket
        if (isInvertedBracesPresent.length === 0) {
          return false; // Unmatched closing bracket
        }
        isInvertedBracesPresent.pop(); // Matching opening bracket found
      }
    }

    const openSquareBracketsCount = emailDefaultBody?.match(/\[/g)?.length;
    const closeSquareBracketsCount = emailDefaultBody?.match(/\]/g)?.length;

    const isTokenValid =
      tokens?.length > 0
        ? tokens?.every((value) => emailBodyTokens.includes(value))
        : true;

    if (isTokenValid && openSquareBracketsCount === closeSquareBracketsCount) {
      return true;
    } else {
      return false;
    }
  };

  const handleCustomisedEmail = (values, formikBag) => {
    if (formikBag.isSubmitting) return;

    const isEmailBodyValid = getEmailBodyValidation();

    if (isEmailBodyValid) {
      let preferences = [
        {
          id: currentNotificationTab,
          values: [
            JSON.stringify({
              smsToggle: smsToggle,
              smsBody: smsBody,
              emailSubject: values?.emailSubject,
              emailToggle: emailToggleValue,
              emailBody: emailDefaultBody,
            }),
          ],
        },
      ];

      saveSettings({ preferences }, true, false, () => {
        setIsSubmit(true);
        formikBag.setSubmitting(false);
        dispatch(fetchDefaultNotifications(shopCode));
      });
    } else {
      ToasterHandler("uh oh", Localise(messages, "Invalid Token Format"));
      setIsSubmit(true);
      formikBag.setSubmitting(false);
    }
  };

  const handleSelectEmailSubjToken = (val, values, setFieldValue) => {
    const insertToken =
      emailSubjTokenMenu.find((obj) => obj.key === val)?.label || "";

    const modifiedEmailSubj =
      values?.emailSubject?.slice(0, cursorPosition) +
      insertToken +
      values?.emailSubject?.slice(cursorPosition);

    if (cursorPosition !== "" && modifiedEmailSubj?.length <= 120) {
      setFieldValue("emailSubject", modifiedEmailSubj);
      setEmailDefaultSubj(modifiedEmailSubj);
      modifiedEmailSubj !== emailSubject
        ? setIsSubmit(false)
        : setIsSubmit(true);
    }
  };

  const handleQuillRef = (quill) => {
    if (quill) {
      reactQuillEmailHeaderRef.current = quill.getEditor();
    }
  };

  const handleSelectEmailBodyToken = (val) => {
    if (reactQuillEmailHeaderRef.current) {
      const cursorPos =
        reactQuillEmailHeaderRef.current?.getSelection()?.index || 0;

      const insertToken =
        emailBodyTokenMenu.find((obj) => obj.key === val)?.label || "";

      // Insert the value at the last cursor position
      cursorPos !== "" &&
        reactQuillEmailHeaderRef.current.insertText(
          cursorPos,
          insertToken,
          "user"
        );
    }
  };

  const handleOnBlur = (event) => {
    const { selectionStart } = event.target;
    setCursorPosition(selectionStart);
  };

  const webStyles = {
    emailBodyContent: {
      fontFamily: fonts.fontFamily.default,
      lineHeight: 1,
      fontSize: 12,
      borderColor: colors.grayScaleLight,
      padding: 10,
      backgroundColor: backgroundColors.greyColor,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 2,
    },
  };

  return (
    <View style={[tw("flex"), { margin: 30 }]}>
      <Form
        initialValues={{
          emailSubject: emailDefaultSubj,
        }}
        onSubmit={(values, formikBag) => {
          handleCustomisedEmail(values, formikBag);
        }}
        validationSchema={emailSettingsSchema(
          messages,
          Localise,
          currentNotificationTab
        )}
        validateOnChange={validationOnChange}
        validateOnBlur={validationOnChange}
        render={(props) => {
          const { values, setFieldValue, resetForm, submitCount } = props;

          const maxScrollViewHeight = getMaxHeight(proxyUser, suspendedMembers);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (submitCount > 0) {
              setValidationOnChange(true);
            }
          }, [submitCount]);

          return (
            <View
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <View style={{ maxHeight: maxScrollViewHeight, width: "100%" }}>
                <View style={[shapes.sectionBorder, { padding: 0 }]}>
                  <View
                    style={[
                      tw("flex flex-row justify-between"),
                      { padding: 15 },
                    ]}
                  >
                    <Text style={[fonts.sectionHeading]}>
                      {Localise(messages, "Email")}
                    </Text>
                    <FormFieldSwitch
                      value={emailToggleValue}
                      onValueChange={(val) => {
                        if (enableEmailSectionEditing) {
                          setEmailToggleValue(val);
                          val !== emailToggle
                            ? setIsSubmit(false)
                            : setIsSubmit(true);
                        }
                        if (!enableEmailSectionEditing) {
                          let preferences = [
                            {
                              id: currentNotificationTab,
                              values: [
                                JSON.stringify({
                                  smsToggle: smsToggle,
                                  smsBody: smsBody,
                                  emailSubject: emailSubject,
                                  emailToggle: val,
                                  emailBody: emailBody,
                                }),
                              ],
                            },
                          ];
                          saveSettings({ preferences }, true, false, () => {
                            dispatch(fetchDefaultNotifications(shopCode));
                          });
                        }
                      }}
                      testID="emailToggleStatus"
                    />
                  </View>
                  <View
                    style={{
                      borderBottomColor: colors.light,
                      borderBottomWidth: 1,
                    }}
                  />
                  <View
                    style={{
                      paddingHorizontal: 45,
                      paddingTop: 15,
                      paddingBottom: 10,
                    }}
                  >
                    <View
                      style={[tw("flex flex-row justify-between items-center")]}
                    >
                      <Text style={[fonts.default]}>
                        {Localise(messages, "Subject Line")}
                      </Text>
                      {enableEmailSectionEditing &&
                        emailSubjTokenMenu.length > 0 && (
                          <PopupMenu
                            containerStyle={tw("ml-1 mr-2 items-end")}
                            optionsContainerStyle={tw("p-1 m-8")}
                            menuOptions={emailSubjTokenMenu}
                            menuOptionsTitle={
                              "Select a Token from the list or add manually with [ ] around the text description"
                            }
                            onSelect={(val) => {
                              handleSelectEmailSubjToken(
                                val,
                                values,
                                setFieldValue
                              );
                            }}
                            testID="email_subject_tokens"
                            accessibilityLabel="email_subject_tokens"
                            style={[
                              tw("flex flex-row justify-between items-center"),
                            ]}
                          >
                            <Tooltip
                              text={Localise(messages, "Add Token")}
                              renderForWebOnly={true}
                            >
                              <Image
                                style={{
                                  width: 20,
                                  height: 20,
                                }}
                                resizeMode="contain"
                                source={IMAGES["zoom-in"]}
                              />
                            </Tooltip>
                          </PopupMenu>
                        )}
                    </View>
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      name={`emailSubject`}
                      placeholder="Enter Email Subject"
                      containerStyle={[tw("px-0 pb-2")]}
                      errorStyle={[tw("pb-0 m-0")]}
                      multiline={true}
                      numberOfLines={1}
                      editable={enableEmailSectionEditing}
                      grayedOutOnDisable={!enableEmailSectionEditing}
                      onBlur={handleOnBlur}
                      onChangeText={(val) => {
                        if (enableEmailSectionEditing) {
                          setEmailDefaultSubj(val);
                          val !== emailSubject
                            ? setIsSubmit(false)
                            : setIsSubmit(true);
                        }
                      }}
                    />
                    {enableEmailSectionEditing && (
                      <TouchableOpacity
                        style={{
                          marginRight: "auto",
                          paddingBottom: 10,
                        }}
                        onPress={() => {
                          setEmailDefaultSubj(
                            defaultNotification?.emailSubject
                          );
                          defaultNotification?.emailSubject === emailSubject
                            ? setIsSubmit(true)
                            : setIsSubmit(false);
                        }}
                        testID="emailSubject_reset"
                        accessibilityLabel="emailSubject_reset"
                      >
                        <Text style={{ ...fonts.link1, fontWeight: "400" }}>
                          {Localise(messages, "Reset to Default")}
                        </Text>
                      </TouchableOpacity>
                    )}

                    <View
                      style={[tw("flex flex-row justify-between items-center")]}
                    >
                      <Text style={[fonts.default]}>
                        {Localise(messages, "Body")}
                      </Text>
                      {enableEmailSectionEditing && (
                        <PopupMenu
                          containerStyle={tw("ml-1 items-end")}
                          optionsContainerStyle={tw("p-1 m-8")}
                          menuOptions={emailBodyTokenMenu}
                          menuOptionsTitle={
                            "Select a Token from the list or add manually with [ ] around the text description"
                          }
                          onSelect={(val) => {
                            handleSelectEmailBodyToken(val);
                          }}
                          testID="email_body_tokens"
                          accessibilityLabel="email_body_tokens"
                          style={[
                            tw("flex flex-row justify-between items-center"),
                          ]}
                        >
                          <Tooltip
                            text={Localise(messages, "Add Token")}
                            renderForWebOnly={true}
                          >
                            <Image
                              style={{
                                width: 20,
                                height: 20,
                              }}
                              resizeMode="contain"
                              source={IMAGES["zoom-in"]}
                            />
                          </Tooltip>
                        </PopupMenu>
                      )}
                    </View>
                    {enableEmailSectionEditing ? (
                      <View
                        style={[
                          tw("p-2"),
                          {
                            width: "100%",
                            margin: "auto",
                            borderColor: colors.grayScaleLight,
                            borderWidth: 1,
                          },
                          fonts.style1,
                        ]}
                      >
                        <QuillEditor
                          key="emaiEditor"
                          placeholder={"Please Enter here"}
                          value={emailDefaultBody}
                          onEditorChange={onEditorChange}
                          handleRef={handleQuillRef}
                          nonEditable={!enableEmailSectionEditing}
                          modules={modules}
                          showMaxLengthError={false}
                          testID="email_editor"
                        />
                      </View>
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: formatQuillEditorStyles(emailDefaultBody),
                        }}
                        style={webStyles.emailBodyContent}
                      />
                    )}
                  </View>
                  <View
                    style={[
                      tw("flex flex-row  justify-between"),
                      { paddingHorizontal: 45, paddingBottom: 15 },
                    ]}
                  >
                    {enableEmailSectionEditing && (
                      <>
                        <TouchableOpacity
                          style={{
                            marginRight: "auto",
                          }}
                          onPress={() => {
                            const processedEmailBody =
                              defaultNotification?.emailBody
                                .replace(
                                  /class=`ql-align-center`/g,
                                  'class="ql-align-center"'
                                )
                                .replace(
                                  /class=`ql-align-right`/g,
                                  'class="ql-align-right"'
                                );
                            setEmailDefaultBody(processedEmailBody);
                            processedEmailBody === emailBody
                              ? setIsSubmit(true)
                              : setIsSubmit(false);
                          }}
                          testID="reset_email_body"
                          accessibilityLabel="reset_email_body"
                        >
                          <Text style={{ ...fonts.link1, fontWeight: "400" }}>
                            {Localise(messages, "Reset to Default")}
                          </Text>
                        </TouchableOpacity>

                        <SaveCancelButtons
                          disableOnDirty={isSubmit}
                          cancelTestId={"clear_email"}
                          saveTestId={"save_email"}
                          buttonTitle={Localise(messages, "Save")}
                          onCancel={() => {
                            resetForm();
                            setEmailDefaultBody(emailBody);
                            setEmailToggleValue(emailToggle);
                            setEmailDefaultSubj(emailSubject);
                            setIsSubmit(true);
                          }}
                        />
                      </>
                    )}
                  </View>
                </View>
                <View fsClass="fs-unmask"></View>
              </View>
            </View>
          );
        }}
      />
    </View>
  );
};

export default EmailSettings;
