import React from "react";
import { ScrollView, View } from "react-native";
import { Divider, Image, Text } from "react-native-elements";

import tw from "tailwind-rn";

import { colors, fonts, shapes } from "styles/theme";
import IMAGES from "static/assets/images";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import {
  getOrderCurrentStatus,
  prepareOrderStatusesData,
  statusesBGColors,
} from "../helper";

const OrderStatus = ({ trackingDetails }) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);

  const { order: orderObj = {}, events = [] } = trackingDetails;

  const orderItemDetails = orderObj?.orderItems?.[0] || {};

  const orderCurrentStatus = getOrderCurrentStatus(orderItemDetails);

  const orderStatusesData = prepareOrderStatusesData(
    orderObj?.orderType,
    orderItemDetails,
    events
  );

  return (
    <View
      style={[
        tw("flex flex-col"),
        {
          ...shapes.sectionBorder,
          width: isDesktop ? 328 : "100%",
          height: 540,
          padding: 0,
          marginHorizontal: isDesktop ? 11 : 0,
          marginVertical: 8,
        },
      ]}
    >
      <View
        style={{
          padding: 15,
          backgroundColor: statusesBGColors[orderCurrentStatus],
        }}
      >
        <Text
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: colors.highlighter,
            marginLeft: 15,
          }}
        >
          {Localise(messages, "Status")}: {orderCurrentStatus}
        </Text>
      </View>

      <Divider style={{ marginVertical: 0 }} />

      <ScrollView
        style={{ padding: 15, marginLeft: 15 }}
        nativeID="order_status_scrollbar"
      >
        {orderStatusesData.map((statusData, index) => {
          const {
            status,
            active = false,
            date = "",
            comments = "",
          } = statusData;

          return (
            <View
              key={`order_status_${index}`}
              style={[tw("flex flex-row"), { height: index < 3 ? 65 : 90 }]}
            >
              <View>
                <Image
                  style={{
                    width: 23,
                    height: 23,
                  }}
                  resizeMode="cover"
                  source={
                    IMAGES[
                      active
                        ? "check-mark-filled-in-green"
                        : "grey-filled-in-circle"
                    ]
                  }
                />

                {index < orderStatusesData.length - 1 && (
                  <View
                    style={{
                      height: index < 3 ? 45 : 70,
                      borderLeftWidth: 1,
                      borderStyle: "solid",
                      borderColor: colors.grayScaleLight,
                      marginLeft: 11,
                      position: "absolute",
                      top: 22,
                    }}
                  />
                )}
              </View>

              <View style={[{ marginLeft: 9, marginTop: 5, width: "90%" }]}>
                <Text
                  style={{
                    ...fonts.default,
                    fontSize: 15,
                    fontWeight: "bold",
                    color: active ? colors.primary : colors.disabled,
                  }}
                >
                  {status}
                </Text>

                {active && (
                  <Text
                    style={{ ...fonts.default, fontSize: 14, marginTop: 5 }}
                  >
                    {date}
                  </Text>
                )}

                {index >= 3 && active && comments.length > 0 && (
                  <Text
                    style={{
                      ...fonts.default,
                      fontSize: 14,
                      marginTop: 5,
                    }}
                    flexWrap="wrap"
                    numberOfLines={2}
                  >
                    {comments}
                  </Text>
                )}
              </View>
            </View>
          );
        })}
      </ScrollView>
    </View>
  );
};

export default OrderStatus;
