import React, { useEffect, useContext, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createStackNavigator } from "@react-navigation/stack";
import { request } from "library/utils/request";

import SplitScreen from "components/containers/split-screen-new";
import { ToasterHandler } from "components/elements";

import { fetchData } from "library/sagas/ongoing/current-orders/slice";
import {
  setInit,
  setReset,
  setSideCar,
  saveShopSettings,
  setShopCode,
  setAutoScroll,
} from "library/sagas/views/home/drawer/shop-settings/common/slice";
import {
  selectTabNames,
  selectShopCode,
  selectSideCar,
  selectAutoScroll,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { AppSettingsContext } from "library/contexts/appSettings";
import { Entitlements } from "library/utils/entitlements";
import useStateIfMounted from "library/utils/useStateIfMounted";
import fetchOrders from "library/utils/fetchOrders";
import I18NContext from "library/contexts/i18N";

import { MainScreen, SideScreen, CustomHeader } from "./helper";
import {
  ListOfOrderStatus,
  InitOrdersData,
} from "../order-listing/current-orders/config";

const Stack = createStackNavigator();

const ShopSettings = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const shopCode = useSelector(selectShopCode);
  const tabNames = useSelector(selectTabNames);
  const { type: showSideCar } = useSelector(selectSideCar);
  const {
    value: autoScreenScroll,
    scrollYTo,
    screen: screenToScroll,
  } = useSelector(selectAutoScroll);

  const { permissions } = useContext(AppSettingsContext);
  const { messages, Localise } = useContext(I18NContext);

  const [loadingKey, setLoadingKey] = useStateIfMounted("");
  const [cardSettingsFormikData, setCardSettingsFormikData] = useStateIfMounted(
    {}
  );
  const [error, setError] = useStateIfMounted({ keys: [] });
  const [fileInfo, setFileInfo] = useStateIfMounted({});
  const [refreshPreferences, setRefreshPreferences] = useStateIfMounted(true);

  const { params: { selectedSuspendedCode = [] } = {} } = route;

  const screenOneRef = createRef();
  const screenTwoRef = createRef();

  // Filtering the membercodes based on the permission - shop settings
  const shopCodes = Object.keys(permissions)
    .map(
      (memberCode) =>
        Entitlements.SHOP_SETTINGS in permissions[memberCode] && memberCode
    )
    .filter(Boolean);

  const saveSettings = (
    payload,
    updateProfileStorage = true,
    updateOrders = false,
    callBack = () => {}
  ) => {
    setLoadingKey(payload.preferences[0].id);
    dispatch(
      saveShopSettings({
        payload,
        updateProfileStorage,
        resolve: () => {
          if (
            payload.preferences[0]?.id.includes(
              "occasion_notification_settings"
            )
          ) {
            const { emailToggle = false } = JSON.parse(
              payload?.preferences[0]?.values[0] || "{}"
            );
            if (emailToggle) {
              request("activate-occasion-reminder", { memberCode: shopCode })
                .then(() => {
                  console.log("Account activated Successfully");
                })
                .catch((error) => {
                  console.log("Account activation Failed - ", error);
                });
            }
          }
          updateOrders &&
            fetchOrders(
              "current",
              { timeStamp: false },
              ListOfOrderStatus,
              InitOrdersData,
              (payload) => dispatch(fetchData(payload))
            );
          ToasterHandler(
            "hooray",
            Localise(messages, "Your settings have been saved")
          );
          setLoadingKey("");
          callBack();
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(messages, "Please try saving your settings again")
          );
          setLoadingKey("");
          callBack();
        },
      })
    );
  };

  // Dispatching the page Init actions & Reset on UnMount
  useEffect(() => {
    dispatch(
      setShopCode(
        shopCode.length > 0
          ? shopCode
          : selectedSuspendedCode.length === 1
          ? selectedSuspendedCode[0]
          : shopCodes[0]
      )
    );

    return () => dispatch(setReset());
  }, []);

  useEffect(() => {
    shopCode && dispatch(setInit({ permissions }));
  }, [shopCode]);

  useEffect(() => {
    if (scrollYTo && autoScreenScroll) {
      if (screenToScroll === 1) {
        screenOneRef?.current?.scrollTo({ x: 0, y: scrollYTo });
      } else if (screenToScroll === 2) {
        screenTwoRef?.current?.scrollTo({ x: 0, y: scrollYTo });
      }
    }
    return () => {
      dispatch(setAutoScroll({ value: false, scrollYTo: 0, screen: 1 }));
    };
  }, [autoScreenScroll]);

  return (
    <Stack.Navigator headerMode="none">
      {tabNames.map(({ key, title }) => (
        <Stack.Screen key={key} name={key} options={{ title }}>
          {() => (
            <SplitScreen
              ScreenOne={
                <MainScreen
                  defaultTab={key}
                  shopCodes={shopCodes}
                  loadingKey={loadingKey}
                  saveSettings={saveSettings}
                  error={error}
                  setCardSettingsFormikData={setCardSettingsFormikData}
                  setFileInfo={setFileInfo}
                  fileInfo={fileInfo}
                  refreshPreferences={refreshPreferences}
                  setRefreshPreferences={setRefreshPreferences}
                  screenOneRef={screenOneRef}
                />
              }
              ScreenTwo={
                <SideScreen
                  defaultTab={key}
                  cardSettingsFormikData={cardSettingsFormikData}
                  error={error}
                  fileInfo={fileInfo}
                  setError={setError}
                  setRefreshPreferences={setRefreshPreferences}
                />
              }
              showSecondScreen={!!showSideCar}
              rootNavigation={navigation}
              CustomHeader={() => (
                <CustomHeader defaultTab={key} shopCodes={shopCodes} />
              )}
              navHeaderProps={{
                title,
                onTrigger: () => dispatch(setSideCar()),
              }}
              {...{ screenOneRef, screenTwoRef }}
            />
          )}
        </Stack.Screen>
      ))}
    </Stack.Navigator>
  );
};

export default ShopSettings;
