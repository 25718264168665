import { takeLatest, put, call, takeEvery } from "redux-saga/effects";

import { request } from "library/utils/request";
import {
  processOrderResponse,
  mergeOrderResponse,
  refineOrders,
} from "library/utils/orderListing";
import Environment from "library/utils/environment";

import get from "lodash/get";
import first from "lodash/first";
import last from "lodash/last";
import cloneDeep from "lodash/cloneDeep";

import moment from "moment";

import {
  fetchAutoPrintOrders,
  fetchAutoPrintOrdersLastSyncTime,
  updateAutoPrintOrders,
  fetchData,
  setAutoPrintOrders,
  setData,
  setLoading,
} from "./slice";

const serviceRequest = (params) => request("order-listing", params);

const autoPrintServiceRequest = (params) =>
  request("get-auto-print-orders", params);
const autoPrintLastSyncTimeServiceRequest = (params) =>
  request("get-auto-print-orders-last-sync-time", params);
const autoPrintInboundServiceRequest = (params) =>
  request("get-auto-print-inbound-orders", params);
const autoPrintOutboundServiceRequest = (params) =>
  request("get-auto-print-outbound-orders", params);
const updateAutoPrintServiceRequest = (params) =>
  request("update-auto-print-orders", params);

const applySearchFilters = (orderList, actions) => {
  const refined = cloneDeep(orderList);
  Object.keys(refined).map((orderCategory) => {
    refined[orderCategory].orders = refineOrders(
      refined[orderCategory].orders,
      actions
    );
  });
  return refined;
};

/**
 * WORKER: Fetches data and publishes the successful result, or an error
 * @param {action} action the fetch data action definition
 */
function* handleFetchAutoPrintOrdersLastSyncTime(action = {}) {
  try {
    const params = get(action, "payload", {});
    const lastSyncTimeInfo = yield call(
      autoPrintLastSyncTimeServiceRequest,
      params
    );
    if (lastSyncTimeInfo.length) {
      let minLastSyncTime = moment().utc().format();

      lastSyncTimeInfo.forEach((element) => {
        const isBefore = moment(element.lastSyncTime).isBefore(minLastSyncTime);
        if (isBefore)
          minLastSyncTime = moment(element.lastSyncTime).utc().format();
      });

      yield put(
        setAutoPrintOrders({
          timeStamp: minLastSyncTime,
          initialTimeStamp: minLastSyncTime,
          orders: [],
        })
      );
    }
  } catch (error) {
    console.log("Failed to fetch auto-print Initial Time Stamp - ", error);
  }
}

/**
 * WORKER: Fetches data and publishes the successful result, or an error
 * @param {action} action the fetch data action definition
 */
function* handleFetchAutoPrintOrders(action = {}) {
  const params = get(action, "payload", {});
  const { data, resolve, reject } = params;

  try {
    const {
      inbound = true,
      outbound = true,
      messagesStartDate = "",
      startDate = "",
      endDate = "",
      memberCodes = [],
      isOrderDetailPrintEligible,
      isOutboundOrderDetailPrintEligible,
      isTrifoldPrintEligible,
      isInvoicePrintEligible,
      isDesignerWorksheetPrintEligible,
      isInboundOrderMessagePrintEligible,
      isOutboundOrderMessagePrintEligible,
      newAutoPrintContract,
    } = data || {};
    let orders = [];

    if (inbound && outbound) {
      orders = yield call(autoPrintServiceRequest, {
        messagesStartDate,
        startDate,
        endDate,
        memberCodes,
        isOrderDetailPrintEligible,
        isOutboundOrderDetailPrintEligible,
        isTrifoldPrintEligible,
        isInvoicePrintEligible,
        isDesignerWorksheetPrintEligible,
        isInboundOrderMessagePrintEligible,
        isOutboundOrderMessagePrintEligible,
        newAutoPrintContract,
      });
    } else if (inbound) {
      orders = yield call(autoPrintInboundServiceRequest, {
        messagesStartDate,
        startDate,
        endDate,
        memberCodes,
        isOrderDetailPrintEligible,
        isOutboundOrderDetailPrintEligible,
        isTrifoldPrintEligible,
        isInvoicePrintEligible,
        isDesignerWorksheetPrintEligible,
        isInboundOrderMessagePrintEligible,
        isOutboundOrderMessagePrintEligible,
        newAutoPrintContract,
      });
    } else if (outbound) {
      orders = yield call(autoPrintOutboundServiceRequest, {
        messagesStartDate,
        startDate,
        endDate,
        memberCodes,
        isOrderDetailPrintEligible,
        isOutboundOrderDetailPrintEligible,
        isTrifoldPrintEligible,
        isInvoicePrintEligible,
        isDesignerWorksheetPrintEligible,
        isInboundOrderMessagePrintEligible,
        isOutboundOrderMessagePrintEligible,
        newAutoPrintContract,
      });
    }

    yield put(
      setAutoPrintOrders({
        orders,
        timeStamp: moment().utc().format(),
      })
    );
    resolve && resolve();
  } catch (error) {
    yield put(
      setAutoPrintOrders({
        orders: [],
        timeStamp: moment().subtract(2, "minute").utc().format(),
      })
    );
    reject && reject();
    console.log(error);
  }
}

/**
 * WORKER: Fetches data and publishes the successful result, or an error
 * @param {action} action the fetch data action definition
 */
function* handleUpdateAutoPrintOrders(action = {}) {
  const params = get(action, "payload", {});
  const { resolve, reject, payload = [] } = params;

  try {
    yield call(updateAutoPrintServiceRequest, payload);
    resolve && resolve();
  } catch (error) {
    reject && reject();
    console.log(error);
  }
}

/**
 * WORKER: Fetches data and publishes the successful result, or an error
 * @param {action} action the fetch data action definition
 */
function* handleFetchCurrentOrders(action = {}) {
  const maxLength = Environment.get("MAX_ORDERS_LIMIT", "5000");
  try {
    const { params, originalData, initData, listingType, actions, resolve } =
      get(action, "payload", {});

    const response = yield call(serviceRequest, params);
    const { deliveryInfo: { deliveryDate: minDeliveryDate = "" } = {} } =
      last(response) || {};
    const { deliveryInfo: { deliveryDate: maxDeliveryDate = "" } = {} } =
      first(response) || {};

    const result = mergeOrderResponse(response, originalData);
    const updatedResp = processOrderResponse(result, initData, listingType);

    const {
      orderCollection,
      originalData: newOriginalData,
      timeStamp,
    } = updatedResp || {};

    const searchStartDate = params.deltaOrders ? "" : params.startDate;

    yield put(
      setData({
        data: applySearchFilters(orderCollection, actions),
        originalData: newOriginalData,
        timeStamp: timeStamp,
        isDeltaOrders: params.deltaOrders,
        hasMoreOrders: response.length > maxLength,
        minDeliveryDate: minDeliveryDate || searchStartDate,
        maxDeliveryDate,
      })
    );
    resolve && resolve();
  } catch (error) {
    yield put(setLoading(false));
    console.log(error);
  }
}

/**
 * WATCHER: Subscribe to Page actions
 */
export default function* watchSaga() {
  yield takeLatest(fetchData.type, handleFetchCurrentOrders);
  yield takeLatest(fetchAutoPrintOrders.type, handleFetchAutoPrintOrders);
  yield takeLatest(
    fetchAutoPrintOrdersLastSyncTime.type,
    handleFetchAutoPrintOrdersLastSyncTime
  );
  yield takeEvery(updateAutoPrintOrders.type, handleUpdateAutoPrintOrders);
}
