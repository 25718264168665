import React from "react";
import { Text, Image } from "react-native-elements";
import { View, Platform } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import isEmpty from "lodash/isEmpty";
import { colors, fonts, backgroundColors, theme } from "styles/theme";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import { getListOfRouteActions } from "./config";
import IMAGES from "static/assets/images";
import { Picker } from "components/elements";

//eslint-disable-next-line
const TakeAction = React.memo(
  ({
    routeData = {},
    actionHandler,
    disableSave,
    isPastRoute,
    driverAssignment,
    localPermissions = {},
    routes = [],
    CFROptimise,
    selectedAction,
    setSelectedAction,
    isEditRoute,
    routeHasEdits,
  }) => {
    const { messages, Localise } = React.useContext(I18NContext);
    const {
      status = "DEFAULT",
      manifestPrinted = false,
      ordersCount,
    } = routeData;
    const { isOptimized = false } = routes[0]?.route || {};

    const routeActions =
      getListOfRouteActions({
        status,
        localPermissions,
        CFROptimise,
        isOptimized,
        isEditRoute,
      }) || [];

    const { isDesktop, isMobile } = React.useContext(DeviceContext);
    const isSmallScreen = !isDesktop;

    if (isEmpty(routeActions) || isPastRoute) return null;

    const isActionEnabled = ({ key, actionType } = {}) => {
      return !(actionType.includes("save")
        ? disableSave
        : ["OPTIMIZE"].includes(key)
        ? isOptimized
        : !driverAssignment
        ? ["INPROGRESS", "COMPLETED"].includes(key)
        : false);
    };

    return (
      <View
        style={{
          borderWidth: 1,
          borderColor: colors.grayScaleLight,
          marginBottom: 10,
          paddingVertical: 5,
          paddingHorizontal: 10,
        }}
      >
        <View
          style={{
            ...tw("flex flex-row items-center justify-between"),
            paddingVertical: 5,
          }}
        >
          {/* Actions */}
          <View
            style={{
              ...tw("flex-row items-center flex-wrap"),
              width: "90%",
            }}
          >
            {!!routeActions.length && !isMobile && (
              <View style={tw("mr-2 mt-1")}>
                <Text
                  style={{
                    ...fonts.heading4,
                    color: backgroundColors.primary,
                  }}
                >
                  {Localise(messages, "Actions")}:
                </Text>
              </View>
            )}
            {!!routeActions.length && isMobile ? (
              <>
                <Picker
                  containerStyle={{
                    width: 180,
                    height: "auto",
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                  }}
                  items={routeActions
                    .filter((action) => action.key !== "PRINTROUTE")
                    .map((eachAction) => {
                      const { enabled = true } = eachAction;
                      const isEnabled = enabled && isActionEnabled(eachAction);
                      if (!isEnabled) return;
                      return {
                        label: eachAction.label,
                        ...(Platform.OS === "ios"
                          ? {
                              value: isEnabled
                                ? JSON.stringify(eachAction)
                                : null,
                            }
                          : { value: JSON.stringify(eachAction) }),
                        enabled: isEnabled,
                      };
                    })
                    .filter(Boolean)}
                  placeholder={{ label: "Actions", value: "" }}
                  value={selectedAction}
                  onValueChange={(action = {}) => {
                    if (!action) return;
                    setSelectedAction(JSON.stringify(action));
                    actionHandler({
                      action: JSON.parse(action).key,
                      routeId: routeData.routeId,
                      actionType: JSON.parse(action).actionType,
                    });
                  }}
                />
              </>
            ) : (
              <>
                {routeActions
                  .filter((action) => action.key !== "PRINTROUTE")
                  .map((eachAction, index) => {
                    return (
                      <View
                        key={index}
                        style={[
                          tw("flex flex-row justify-end"),
                          !isActionEnabled(eachAction) && {
                            opacity: 0.5,
                          },
                        ]}
                      >
                        <TouchableOpacity
                          testID="optimize"
                          accessibilityLabel="optimize"
                          style={{
                            ...theme.Button.secondaryButtonStyle,
                            paddingVertical: 10,
                            paddingHorizontal: 10,
                            margin: 5,
                            ...(!isActionEnabled(eachAction) && {
                              backgroundColor: "#d3d3d380",
                            }),
                          }}
                          onPress={() => {
                            if (isActionEnabled(eachAction)) {
                              actionHandler({
                                action: eachAction.key,
                                routeId: routeData.routeId,
                                actionType: eachAction.actionType,
                              });
                            }
                          }}
                        >
                          <Text
                            style={{
                              ...theme.Button.secondaryTitleStyle,
                              ...(!isActionEnabled(eachAction) && {
                                color: "#808080",
                              }),
                            }}
                          >
                            {Localise(messages, eachAction.label)}
                          </Text>
                        </TouchableOpacity>
                      </View>
                    );
                  })}
              </>
            )}
          </View>

          {/* Print Route Manifest */}
          <View
            style={{
              ...(isSmallScreen && { width: "10%" }),
              opacity: 1,
              alignItems: "flex-end",
            }}
          >
            {localPermissions.printManifest && ordersCount > 0
              ? routeActions
                  .filter((action) => action.key === "PRINTROUTE")
                  .map((eachAction, index) => {
                    return (
                      <TouchableOpacity
                        key={index}
                        activeOpacity={0.5}
                        onPress={() => {
                          actionHandler({
                            action: eachAction.key,
                            routeId: routeData.routeId,
                            actionType: eachAction.actionType,
                          });
                        }}
                        testID="print_route_manifest"
                        accessibilityLabel={
                          manifestPrinted
                            ? "manifest_printed"
                            : "manifest_not_printed"
                        }
                      >
                        <Image
                          style={{
                            width: 26,
                            height: 26,
                          }}
                          source={
                            IMAGES[manifestPrinted ? "printAgain" : "print"]
                          }
                        />
                      </TouchableOpacity>
                    );
                  })
              : null}
          </View>
        </View>
      </View>
    );
  }
);

export default TakeAction;
