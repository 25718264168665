import React, { useEffect, useMemo, useState } from "react";
import tw from "tailwind-rn";
import Address from "components/elements/address";
import { shapes, fonts } from "styles/theme";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { FieldArray } from "formik";
import {
  FormFieldPicker,
  FormField,
  FormFieldDatePicker,
} from "components/elements/forms";
import I18NContext from "library/contexts/i18N";
import IMAGES from "static/assets/images";
import { TabBar } from "components/elements";
import { useSelector } from "react-redux";
import { selectAddressVerificationInfo } from "library/sagas/ongoing/global-data/selector";
import AddressVerificationIndicator from "components/views/drawer/order-details/delivery-info/component/address-indicator";
import truncate from "lodash/truncate";
import { basicLocationInfo, DeliveryTypes } from "../config";

const LocationTime = (props) => {
  const { values, index, detailsIndex, setFieldValue, isDesktop } = props;
  const { messages, Localise } = React.useContext(I18NContext);
  const path = `locations.${detailsIndex}.locationTimes.${index}`;
  const locationTimes = values?.locations[detailsIndex]?.locationTimes;

  return useMemo(() => {
    return (
      <View
        style={[
          tw("flex flex-row flex-wrap w-full"),
          { zIndex: 100 + index * 2 },
        ]}
      >
        <FormField
          name="label"
          containerStyle={{
            width: isDesktop ? "20%" : "100%",
          }}
          placeholder={Localise(messages, "Label")}
          label={Localise(messages, "Label")}
          labelStyle={{ fontWeight: "normal" }}
          testID="label"
          accessibilityLabel="label"
          path={path}
        />
        <FormFieldDatePicker
          name="startTime"
          label={"Start Time"}
          labelStyle={{ fontWeight: "normal" }}
          placeholder="Select"
          containerStyle={{
            width: isDesktop ? "18%" : "100%",
            zIndex: 100 + index * 10,
          }}
          path={path}
          timeIntervals={15}
          iconName="clock"
          alignIconRight={true}
          showTimeSelect={true}
          showTimeSelectOnly={true}
          // minTime={"01:00"}
          // maxTime={"11:00"}
          dateFormat="h:mm a"
          inputContainerStyle={{ paddingRight: 0 }}
          popperPlacement={"top"}
        />
        <FormFieldDatePicker
          name="endTime"
          label={"End Time"}
          labelStyle={{ fontWeight: "normal" }}
          placeholder="Select"
          containerStyle={{
            width: isDesktop ? "18%" : "100%",
            zIndex: 100 + index * 10,
          }}
          path={path}
          timeIntervals={15}
          iconName="clock"
          alignIconRight={true}
          showTimeSelect={true}
          showTimeSelectOnly={true}
          // minTime={new Date(time)}
          // maxTime={new Date(time + 4 * 60 * 60)}
          dateFormat="h:mm a"
          inputContainerStyle={{ paddingRight: 0 }}
          popperPlacement={"top"}
        />
        <FormField
          name="notes"
          containerStyle={{
            width: isDesktop
              ? locationTimes.length > 1
                ? "39%"
                : "44%"
              : "100%",
          }}
          placeholder={Localise(
            messages,
            locationTimes.length > 1 ? "Add notes" : "Add notes "
          )}
          label={Localise(messages, "Notes")}
          labelStyle={{ fontWeight: "normal" }}
          testID="notes"
          accessibilityLabel="notes"
          path={path}
        />
        {locationTimes?.length > 1 && (
          <TouchableOpacity
            style={[
              tw("flex flex-row items-center justify-end"),
              { width: "5%", paddingTop: 8 },
            ]}
            onPress={() => {
              const times = [...locationTimes];
              times.splice(index, 1);
              setFieldValue(`locations.${detailsIndex}.locationTimes`, times);
            }}
          >
            <Image
              style={{
                width: 20,
                height: 20,
              }}
              source={IMAGES["cancel"]}
            />
          </TouchableOpacity>
        )}
      </View>
    );
  }, [
    index,
    isDesktop,
    messages,
    Localise,
    path,
    setFieldValue,
    detailsIndex,
    locationTimes,
  ]);
};

const LocationDetails = (props) => {
  const {
    isDesktop,
    values,
    setValues,
    Localise,
    messages,
    index: detailsIndex,
    setFieldValue,
    addressVerificationInfo = {},
    locationInfo = {},
  } = props;

  const path = `locations.${detailsIndex}`;
  const { locationTimes = [], address = { country: "US" } } = locationInfo;

  const { isAvsPerformed, isAvsSuggestedAddress, avsConfidence } =
    addressVerificationInfo;

  const isAvsVerified =
    isAvsPerformed === "Y" &&
    isAvsSuggestedAddress === "Y" &&
    avsConfidence === "HIGH";

  const {
    addressLine1 = "",
    city = "",
    state = "",
    country = "",
    zip = "",
  } = address;

  return (
    <View style={tw("flex flex-row flex-wrap")}>
      <FormField
        name="title"
        containerStyle={{
          width: isDesktop ? "30%" : "100%",
        }}
        placeholder={Localise(messages, "Title")}
        label={Localise(messages, "Title")}
        labelStyle={{ fontWeight: "normal" }}
        testID="title"
        accessibilityLabel="title"
        path={path}
      />
      <FormField
        name="name"
        containerStyle={{
          width: isDesktop ? "45%" : "100%",
        }}
        placeholder={Localise(messages, "Location or business name")}
        label={Localise(messages, "Location")}
        labelStyle={{ fontWeight: "normal" }}
        testID="name"
        accessibilityLabel="name"
        path={path}
      />
      <FormFieldDatePicker
        dateValueFormat="YYYY-MM-DD"
        name="deliveryDate"
        label={"Date"}
        labelStyle={{ fontWeight: "normal" }}
        placeholder="Date"
        popperPlacement="auto-end"
        containerStyle={{
          width: isDesktop ? "25%" : "100%",
          zIndex: 15,
        }}
        path={path}
      />

      <FormFieldPicker
        placeholder={{ label: "Delivery Type" }}
        containerStyle={{
          width: isDesktop ? "30%" : "100%",
        }}
        data={DeliveryTypes}
        name="deliveryType"
        label={Localise(messages, "Delivery Type")}
        labelStyle={{ fontWeight: "normal" }}
        testID="delivery_type"
        accessibilityLabel="delivery_type"
        path={path}
      />
      <FormField
        name="deliveryInstructions"
        containerStyle={{
          width: isDesktop ? "70%" : "100%",
        }}
        placeholder={Localise(messages, "Delivery Instructions")}
        label={Localise(messages, "Delivery Instructions")}
        labelStyle={{ fontWeight: "normal" }}
        testID="deliveryInstructions"
        accessibilityLabel="deliveryInstructions"
        path={path}
      />

      <Address
        values={values}
        setValues={setValues}
        isSmallScreen={!isDesktop}
        formIKPath={`${path}.address`}
        index={detailsIndex}
      />
      {!!(addressLine1 && city && state && country && zip) && (
        <AddressVerificationIndicator
          containerStyle={{
            paddingLeft: 5,
            marginTop: 8,
            marginBottom: 16,
            zIndex: -5,
          }}
          isVerified={isAvsVerified}
          isSmallScreen={!isDesktop}
        />
      )}
      <FormField
        name="locationNotes"
        containerStyle={{
          width: "100%",
          zIndex: -1,
        }}
        placeholder={Localise(messages, "Location Notes")}
        label={Localise(messages, "Location Notes")}
        labelStyle={{ fontWeight: "normal" }}
        testID="locationNotes"
        accessibilityLabel="locationNotes"
        multiline={true}
        numberOfLines={3}
        path={path}
      />
      <Text
        style={[fonts.sectionHeading, { paddingLeft: 4, paddingVertical: 8 }]}
      >
        {Localise(messages, "Location Times")}
      </Text>
      <View style={tw("flex-row flex-wrap items-center w-full")}>
        {locationTimes.map((each, index) => {
          return (
            <LocationTime
              key={index}
              {...props}
              index={index}
              detailsIndex={detailsIndex}
            />
          );
        })}
      </View>
      {locationTimes.length <= 5 && (
        <TouchableOpacity
          onPress={() => {
            setFieldValue(`${path}.locationTimes`, [
              ...locationTimes,
              {
                label: "",
                startTime: "",
                endTime: "",
                notes: "",
              },
            ]);
          }}
          style={{ paddingLeft: 4 }}
          testID="create_order"
          accessibilityLabel="create_order"
        >
          <Image
            style={{
              width: 24,
              height: 24,
            }}
            source={IMAGES["create-order"]}
          />
        </TouchableOpacity>
      )}
    </View>
  );
};

const LocationDetailsAndTimes = (props) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const {
    isDesktop,
    values,
    setFieldValue,
    memberCodes,
    currentTabIndex: menuTabIndex,
    setCurrentTabIndex: setMenuTabIndex,
    errors = {},
  } = props;
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const addressVerificationInfo = useSelector(selectAddressVerificationInfo);

  const tabNames = values?.locations?.map((each, index) => {
    return {
      key: index,
      title: truncate(each.name, { length: 15 }) || "Title",
      isRemovable: true,
    };
  });

  useEffect(() => {
    const errorTabIndex = errors.locations?.findIndex((x) => x);
    errorTabIndex >= 0 && setCurrentTabIndex(errorTabIndex);
  }, [errors.locations]);

  return useMemo(() => {
    const removeTabs = (key) => {
      const locations = [...values.locations];
      locations.splice(key, 1);
      setFieldValue("locations", [...locations]);
      setCurrentTabIndex(locations.length - 1);
    };

    const locationsCount = values?.locations?.length;

    return (
      <View
        style={[{ ...shapes.sectionBorder, paddingTop: 0 }, { marginTop: 10 }]}
      >
        <View style={[tw("flex flex-row items-center justify-between")]}>
          <TabBar
            tabNames={tabNames}
            currentTab={currentTabIndex}
            setCurrentTab={(val) => {
              if (!errors.locations) setCurrentTabIndex(val);
            }}
            scrollContainerStyle={{ width: "92%", marginBottom: 6 }}
            scrollTabBar={true}
            showScrollBar={false}
            showsScrollIndicators={true}
            showBorder={false}
            removeTab={tabNames.length > 1}
            setTabsCount={removeTabs}
          />
          {locationsCount <= 9 && (
            <TouchableOpacity
              onPress={() => {
                if (errors.locations) return;
                setFieldValue("locations", [
                  ...values.locations,
                  basicLocationInfo,
                ]);
                setCurrentTabIndex(locationsCount);
              }}
              testID="create_order"
              accessibilityLabel="create_order"
            >
              <Image
                style={{
                  width: 24,
                  height: 24,
                }}
                source={IMAGES["create-order"]}
              />
            </TouchableOpacity>
          )}
        </View>
        <Text
          style={[fonts.sectionHeading, { paddingLeft: 4, paddingBottom: 8 }]}
        >
          {Localise(messages, "Location Details")}
        </Text>

        <FieldArray
          name="locations"
          render={() => {
            return (
              <View>
                {values.locations?.map((location, index) => {
                  if (menuTabIndex === 1 && index === currentTabIndex) {
                    return (
                      <LocationDetails
                        {...props}
                        locationInfo={location}
                        messages={messages}
                        Localise={Localise}
                        index={currentTabIndex}
                        addressVerificationInfo={
                          addressVerificationInfo[currentTabIndex]
                        }
                      />
                    );
                  }
                })}
              </View>
            );
          }}
        />
      </View>
    );
  }, [
    isDesktop,
    values,
    currentTabIndex,
    Localise,
    messages,
    props,
    setFieldValue,
    tabNames,
    addressVerificationInfo,
    memberCodes,
    menuTabIndex,
    setMenuTabIndex,
  ]);
};

export default LocationDetailsAndTimes;
