import React, { useEffect } from "react";
import { View, Platform, Image } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Text } from "react-native-elements";
import {
  useNavigation,
  CommonActions,
  useNavigationState,
} from "@react-navigation/native";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { colors, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";
import IMAGES from "static/assets/images";
import { DeviceContext } from "library/contexts/appSettings";
import {
  setCurrentPage,
  setIsRouteEditing,
  setIsAdhocStopEditing,
  setIsRouteSave,
  setIsRouteSaveSuccess,
  setIsRouteOptimised,
  setOptimiseRoute,
  setIsRouteOptimiseDone,
} from "library/sagas/ongoing/global-data/slice";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import I18NContext from "library/contexts/i18N";
import { CustomModal } from "components/elements";
import startCase from "lodash/startCase";
import MenuComponent from "./mobile-menu";
const displayRoutes = {
  "ftd-member-settings": "Mercury Member Settings",
  "lmds-routes": "Driver Dashboard",
};

const DefaultBackNavigation = ({
  rootNavigation, // only in case nested navigators - ex: product-catalog
  setCurrentPage,
  isTablet,
  isRouteEditing,
  setIsRouteEditing,
  isAdhocStopEditing,
  setIsAdhocStopEditing,
  setIsRouteSave,
  isRouteSaveSuccess,
  setIsRouteSaveSuccess,
  isRouteOptimised,
  setIsRouteOptimised,
  setOptimiseRoute,
  isOptimiseDone,
  setIsRouteOptimiseDone,
  showNavText,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);

  let { routes = [], history = [] } = useNavigationState(
    (state) => state || {}
  );
  if (rootNavigation) {
    ({ routes = [], history = [] } = rootNavigation.dangerouslyGetState());
  }

  const historyExists = history.length > 1;
  const previousRoute = (historyExists && history[history.length - 2]) || {};
  const previousRouteInfo = routes.filter(
    (route) => route.key === previousRoute.key
  )[0];
  const navigation = useNavigation();

  useEffect(() => {
    if (isRouteSaveSuccess) {
      setIsRouteSaveSuccess(false);
      redirectToPreviousMenu();
    }
  }, [isRouteSaveSuccess]);

  useEffect(() => {
    if (isOptimiseDone) {
      setIsRouteOptimiseDone(false);
      redirectToPreviousMenu();
    }
  }, [isOptimiseDone]);

  const redirectToPreviousMenu = () => {
    setCurrentPage((previousRouteInfo && previousRouteInfo.name) || "");
    previousRoute.key &&
      navigation.dispatch(
        CommonActions.navigate({
          key: previousRoute.key,
        })
      );
  };

  const [modalVisible, setModalVisibile] = useStateIfMounted(false);
  const [modalVisibleForOptimise, setModalVisibleForOptimise] =
    useStateIfMounted(false);
  const modalContent = {
    content: (
      <Text style={{ ...tw("p-4"), fontSize: 15, color: colors.highlighter }}>
        {Localise(messages, "Do you want to save this Route?")}
      </Text>
    ),
    buttons: [
      { type: "secondary", title: Localise(messages, "Don't Save") },
      { type: "primary", title: Localise(messages, "Save Route") },
    ],
  };
  const modalContentForOptimise = {
    content: (
      <Text style={{ ...tw("p-4"), fontSize: 15, color: colors.highlighter }}>
        {Localise(
          messages,
          "This route has timed deliveries. Optimize the route to get a suggested start time."
        )}
      </Text>
    ),
    buttons: [
      { type: "secondary", title: Localise(messages, "Cancel") },
      { type: "primary", title: Localise(messages, "OK") },
    ],
  };

  if (!historyExists) return null;

  const handleSave = () => {
    setIsRouteSave(true);
    setModalVisibile(false);
  };

  const handleClose = () => {
    setIsRouteEditing(false);
    setIsAdhocStopEditing(false);
    setModalVisibile(false);
    if (!isRouteOptimised) {
      setModalVisibleForOptimise(true);
    } else redirectToPreviousMenu();
  };

  const handleOptimise = () => {
    setOptimiseRoute(true);
    setModalVisibleForOptimise(false);
  };

  const handleCloseForOptimise = () => {
    setOptimiseRoute(false);
    setIsRouteOptimised(true);
    setModalVisibleForOptimise(false);
    redirectToPreviousMenu();
  };

  return (
    <>
      <CustomModal
        modalVisible={modalVisible}
        modalContent={modalContent}
        primaryhandler={handleSave}
        secondaryhandler={handleClose}
        contentStyle={[
          tw("border border-black p-4"),
          { backgroundColor: backgroundColors.secondary, textAlign: "center" },
        ]}
        modalStyle={
          Platform.OS !== "web" && {
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            backgroundColor: "#00000070",
            color: "#FFFFFF",
          }
        }
      ></CustomModal>
      <CustomModal
        modalVisible={modalVisibleForOptimise}
        modalContent={modalContentForOptimise}
        primaryhandler={handleOptimise}
        secondaryhandler={handleCloseForOptimise}
        contentStyle={[
          tw("border border-black p-4"),
          { backgroundColor: backgroundColors.secondary, textAlign: "center" },
        ]}
        modalStyle={
          Platform.OS !== "web"
            ? {
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#00000070",
                color: colors.secondary,
              }
            : { width: "35%" }
        }
      ></CustomModal>
      <TouchableOpacity
        style={tw("flex-row justify-around items-center pt-1")}
        key={`mainBack`}
        onPress={() => {
          if (isRouteEditing || isAdhocStopEditing) {
            setModalVisibile(true);
          } else if (!isRouteOptimised) {
            setModalVisibleForOptimise(true);
          } else {
            redirectToPreviousMenu();
          }
        }}
        testID="main_back"
        accessibilityLabel="main_back"
      >
        <Image
          style={{
            width: 14,
            height: 16,
          }}
          source={IMAGES["left-arrow"]}
        />
        {showNavText && (
          <Text
            style={{
              fontSize: 14,
              fontWeight: isTablet ? "400" : "600",
              textTransform: "capitalize",
              paddingLeft: 2,
            }}
          >
            {previousRouteInfo &&
              previousRouteInfo.name &&
              Localise(
                messages,
                displayRoutes[previousRouteInfo.name] ||
                  startCase(previousRouteInfo.name.replace("lmds-", ""))
              )}
          </Text>
        )}
      </TouchableOpacity>
    </>
  );
};

const CustomBackNavigation = ({ onTrigger, title, isTablet, showNavText }) => {
  const { messages, Localise } = React.useContext(I18NContext);
  return (
    <TouchableOpacity
      style={tw("flex-row justify-around items-center pt-1")}
      onPress={() => {
        onTrigger();
      }}
      key={`detailsBack`}
      testID="details_back"
      accessibilityLabel="details_back"
    >
      <Image
        style={{
          width: 14,
          height: 16,
        }}
        source={IMAGES["left-arrow"]}
      />
      {showNavText && (
        <Text
          style={{
            fontSize: 14,
            fontWeight: isTablet ? "400" : "600",
            textTransform: "capitalize",
            paddingLeft: 2,
          }}
        >
          {Localise(messages, title)}
        </Text>
      )}
    </TouchableOpacity>
  );
};

const SubHeader = ({
  type = "primary",
  title,
  onTrigger,
  siblings,
  rootNavigation,
  setCurrentPage,
  isRouteEditing,
  setIsRouteEditing,
  isAdhocStopEditing,
  setIsAdhocStopEditing,
  setIsRouteSave,
  isRouteSaveSuccess,
  setIsRouteSaveSuccess,
  isRouteOptimised,
  setIsRouteOptimised,
  setOptimiseRoute,
  isOptimiseDone,
  setIsRouteOptimiseDone,
  showShadow = true,
  showNavText = true,
}) => {
  const { isDesktop, isTablet, isMobile } = React.useContext(DeviceContext);
  const currentNavigation = useNavigationState((state) => state || {});

  let { history = [] } = currentNavigation;
  if (rootNavigation) {
    ({ history = [] } = rootNavigation.dangerouslyGetState());
  }

  const historyExists = history.length > 1 || type === "secondary";
  if (isDesktop || (!historyExists && isTablet)) return null;

  return (
    <View
      style={{
        ...tw("flex flex-row items-center"),
        justifyContent: "space-between",
        height: 50,
        padding: 10,
        ...(showShadow && {
          shadowColor: colors.medium,
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowRadius: 7,
          shadowOpacity: 0.4,
          borderBottomColor: colors.medium,
          borderBottomWidth: 1,
        }),
        zIndex: 1,
        backgroundColor: "#fff",
      }}
      fsClass="fs-unmask"
    >
      <View
        style={{
          ...tw("flex flex-row"),
        }}
        fsClass="fs-unmask"
      >
        {isMobile && <MenuComponent iconCustomStyle={{ marginRight: 20 }} />}
        {onTrigger ? (
          <CustomBackNavigation
            onTrigger={onTrigger}
            title={title}
            isTablet={isTablet}
            showNavText={showNavText}
          />
        ) : (
          /* Back to previous route */
          <DefaultBackNavigation
            rootNavigation={rootNavigation}
            setCurrentPage={setCurrentPage}
            isTablet={isTablet}
            isRouteEditing={isRouteEditing}
            setIsRouteEditing={setIsRouteEditing}
            isAdhocStopEditing={isAdhocStopEditing}
            setIsAdhocStopEditing={setIsAdhocStopEditing}
            setIsRouteSave={setIsRouteSave}
            isRouteSaveSuccess={isRouteSaveSuccess}
            setIsRouteSaveSuccess={setIsRouteSaveSuccess}
            isRouteOptimised={isRouteOptimised}
            setIsRouteOptimised={setIsRouteOptimised}
            setOptimiseRoute={setOptimiseRoute}
            isOptimiseDone={isOptimiseDone}
            setIsRouteOptimiseDone={setIsRouteOptimiseDone}
            showNavText={showNavText}
          />
        )}
      </View>
      {siblings && siblings()}
    </View>
  );
};

const mapStateToProps = (state = {}) => {
  return {
    isRouteEditing: state.mhq.ongoing.global.isRouteEditing,
    isAdhocStopEditing: state.mhq.ongoing.global.isAdhocStopEditing,
    isRouteSaveSuccess: state.mhq.ongoing.global.isRouteSaveSuccess,
    isRouteOptimised: state.mhq.ongoing.global.isRouteOptimised,
    isOptimiseDone: state.mhq.ongoing.global.isOptimiseDone,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentPage,
      setIsRouteEditing,
      setIsAdhocStopEditing,
      setIsRouteSave,
      setIsRouteSaveSuccess,
      setIsRouteOptimised,
      setOptimiseRoute,
      setIsRouteOptimiseDone,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SubHeader);
