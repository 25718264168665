import React, { useContext, useEffect } from "react";
import { View, TouchableOpacity } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Image, Text } from "react-native-elements";
import tw from "tailwind-rn";

import {
  Form,
  FormField,
  FormFieldSwitch,
} from "components/elements/forms";
import { PopupMenu, Tooltip } from "components/elements";
import { getMaxHeight } from "library/utils/formatter";

import { fonts, shapes, colors} from "styles/theme";
import IMAGES from "static/assets/images";
import UserProfileStorage from "library/storage/userProfile";

import UserProfileContext from "library/contexts/userProfile";
import I18NContext from "library/contexts/i18N";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { orderStatusSpecificTokenMenu } from "library/utils/notificationTokens";
import { fetchDefaultNotifications } from "library/sagas/views/home/drawer/shop-settings/common/slice";
import { SaveCancelButtons } from "components/wrappers";
import {
  selectShopCode,
  selectActiveNotificationTab,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { smsSettingsSchema } from "./config";

const SmsSettings = ({
  defaultNotification = {},
  notificationStatus = {},
  saveSettings,
  isOccasionNotificaton = false,
}) => {
  const dispatch = useDispatch();
  const { proxyUser, suspendedMembers = [] } = useContext(UserProfileContext);
  const { messages, Localise } = React.useContext(I18NContext);

  const shopCode = useSelector(selectShopCode);
  const currentNotificationTab = useSelector(selectActiveNotificationTab);

  const { smsToggle, smsBody, emailSubject, emailToggle, emailBody } =
    notificationStatus;

  const [smsTokenMenu, setSmsTokenMenu] = useStateIfMounted([]);
  const [validationOnChange, setValidationOnChange] = useStateIfMounted(false);
  const [smsToggleValue, setSmsToggleValue] = useStateIfMounted(smsToggle);
  const [smsDefaultBody, setSmsDefaultBody] = useStateIfMounted(smsBody);
  const [isSubmit, setIsSubmit] = useStateIfMounted(true);
  const [cursorPosition, setCursorPosition] = useStateIfMounted(0);

  const defaultSMSCharLimit = smsBody?.length > 0 ? 120 - smsBody?.length : 120;
  const [characterLimit, setCharacterLimit] =
    useStateIfMounted(defaultSMSCharLimit);
  const getUserRoles = UserProfileStorage.getUserRoles();
  const enableSMSSectionEditing =
    getUserRoles.includes("ADMIN") || getUserRoles.includes("MANAGER");

  useEffect(() => {
    setSmsToggleValue(smsToggle);
    setSmsDefaultBody(smsBody);
  }, [notificationStatus]);

  useEffect(() => {
    setSmsTokenMenu(
      orderStatusSpecificTokenMenu[0]?.[currentNotificationTab]?.smsTokenMenu
    );
  }, [currentNotificationTab]);

  const handleCustomisedSMSText = (values, formikBag) => {
    if (formikBag.isSubmitting) return;
    let preferences = [
      {
        id: currentNotificationTab,
        values: [
          JSON.stringify({
            smsToggle: smsToggleValue,
            smsBody: values?.smsMessage,
            emailSubject,
            emailToggle,
            emailBody,
          }),
        ],
      },
    ];

    saveSettings({ preferences }, true, false, () => {
      setIsSubmit(true);
      formikBag.setSubmitting(false);
      dispatch(fetchDefaultNotifications(shopCode));
    });
  };

  useEffect(() => {
    setSmsToggleValue(smsToggle);
    setSmsDefaultBody(smsBody);
  }, []);

  useEffect(() => {
    setCharacterLimit(smsBody?.length > 0 ? 120 - smsBody?.length : 120);
  }, [smsBody?.length]);

  const handleChange = (event) => {
    const { value } = event.target;
    const smsMsg = value;
    setCharacterLimit(smsMsg?.length > 0 ? 120 - smsMsg?.length : 120);
    setSmsDefaultBody(smsMsg);
    smsMsg !== smsBody ? setIsSubmit(false) : setIsSubmit(true);
  };

  const handleOnBlur = (event) => {
    const { selectionStart } = event.target;
    setCursorPosition(selectionStart);
  };

  const handleSelectToken = (val, values, setFieldValue) => {
    const insertToken =
      smsTokenMenu.find((obj) => obj.key === val)?.label || "";

    const modifiedSmsMsg =
      values?.smsMessage?.slice(0, cursorPosition) +
      insertToken +
      values?.smsMessage?.slice(cursorPosition);

    if (cursorPosition !== "" && modifiedSmsMsg?.length <= 120) {
      setFieldValue("smsMessage", modifiedSmsMsg);
      setCharacterLimit(
        modifiedSmsMsg?.length > 0 ? 120 - modifiedSmsMsg?.length : 120
      );
      setSmsDefaultBody(modifiedSmsMsg);
      modifiedSmsMsg !== smsBody ? setIsSubmit(false) : setIsSubmit(true);
    }
  };

  return (
    <View
      style={[
        tw("flex"),
        {
          margin: 30,
          marginTop: isOccasionNotificaton ? 15 : 30,
        },
      ]}
    >
      <Form
        initialValues={{
          smsMessage: smsDefaultBody,
        }}
        onSubmit={(values, formikBag) => {
          handleCustomisedSMSText(values, formikBag);
        }}
        validationSchema={smsSettingsSchema(
          messages,
          Localise,
          currentNotificationTab
        )}
        validateOnChange={validationOnChange}
        validateOnBlur={validationOnChange}
        render={(props) => {
          const { values, setFieldValue, resetForm, submitCount } = props;

          const maxScrollViewHeight = getMaxHeight(proxyUser, suspendedMembers);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (submitCount > 0) {
              setValidationOnChange(true);
            }
          }, [submitCount]);

          return (
            <View
              style={{ width: "100%", display: "flex", flexDirection: "row" }}>
              <View
                style={{
                  maxHeight: maxScrollViewHeight,
                  width: "100%",
                }}>
                <View style={[shapes.sectionBorder, { padding: 0 }]}>
                  <View
                    style={[
                      tw("flex flex-row justify-between"),
                      { padding: 15 },
                    ]}>
                    <Text style={[fonts.sectionHeading]}>
                      {Localise(messages, "SMS")}
                    </Text>
                    <FormFieldSwitch
                      value={smsToggleValue}
                      onValueChange={(val) => {
                        if (enableSMSSectionEditing) {
                          setSmsToggleValue(val);
                          val !== smsToggle
                            ? setIsSubmit(false)
                            : setIsSubmit(true);
                        }
                        if (!enableSMSSectionEditing) {
                          let preferences = [
                            {
                              id: currentNotificationTab,
                              values: [
                                JSON.stringify({
                                  smsToggle: val,
                                  smsBody: smsBody,
                                  emailSubject: emailSubject,
                                  emailToggle: emailToggle,
                                  emailBody: emailBody,
                                }),
                              ],
                            },
                          ];
                          saveSettings({ preferences }, true, false, () => {
                            dispatch(fetchDefaultNotifications(shopCode));
                          });
                        }
                      }}
                      testID="smsToggleStatus"
                    />
                  </View>
                  <View
                    style={{
                      borderBottomColor: colors.light,
                      borderBottomWidth: 1,
                    }}
                  />
                  <View
                    style={{
                      paddingHorizontal: 45,
                      paddingTop: 15,
                      paddingBottom: enableSMSSectionEditing ? 0 : 15,
                    }}>
                    <View
                      style={[
                        tw("flex flex-row justify-between items-center"),
                      ]}>
                      <Text style={[fonts.default]}>
                        {Localise(messages, "Message")}
                      </Text>
                      {enableSMSSectionEditing && (
                        <PopupMenu
                          containerStyle={tw("ml-1 items-end")}
                          optionsContainerStyle={tw("p-1 m-8")}
                          menuOptions={smsTokenMenu}
                          menuOptionsTitle={
                            "Select a Token from the list or add manually with [ ] around the text description"
                          }
                          onSelect={(val) => {
                            if (enableSMSSectionEditing) {
                              handleSelectToken(val, values, setFieldValue);
                            }
                          }}
                          testID="sms_tokens"
                          accessibilityLabel="sms_tokens"
                          style={[
                            tw("flex flex-row justify-between items-center"),
                          ]}>
                          <Tooltip text={Localise(messages, "Add Token")} renderForWebOnly = {true}>
                            <Image
                              style={{
                                width: 20,
                                height: 20,
                              }}
                              source={IMAGES["zoom-in"]}
                            />
                          </Tooltip>
                        </PopupMenu>
                      )}
                    </View>
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      name={`smsMessage`}
                      placeholder="Enter SMS Message"
                      containerStyle={[tw("px-0 pb-2")]}
                      errorStyle={[tw("pb-0 m-0")]}
                      multiline={true}
                      numberOfLines={2}
                      onChange={handleChange}
                      editable={enableSMSSectionEditing}
                      grayedOutOnDisable={!enableSMSSectionEditing}
                      maxLength={120}
                      onBlur={handleOnBlur}
                    />
                  </View>

                  {enableSMSSectionEditing && (
                    <>
                      <View
                        style={[
                          tw("flex flex-row  justify-between"),
                          {
                            paddingHorizontal: 45,
                            paddingBottom: 10,
                            marginLeft: "auto",
                          },
                        ]}
                        testID="remaining_sms_chars"
                        accessibilityLabel="remaining_sms_chars">
                        <Text style={{ ...fonts.default }}>
                          {Localise(
                            messages,
                            `${characterLimit} Characters / 120 Remaining`,
                          )}
                        </Text>
                      </View>
                      <View
                        style={[
                          tw("flex flex-row  justify-between"),
                          { paddingHorizontal: 45, paddingBottom: 15 },
                        ]}>
                        <TouchableOpacity
                          style={{
                            marginRight: "auto",
                          }}
                          onPress={() => {
                            setSmsDefaultBody(defaultNotification?.smsBody);
                            defaultNotification?.smsBody === smsBody
                              ? setIsSubmit(true)
                              : setIsSubmit(false);
                            setCharacterLimit(
                              defaultNotification?.smsBody?.length > 0
                                ? 120 - defaultNotification?.smsBody?.length
                                : 120,
                            );
                          }}
                          testID="sms_reset_to_default"
                          accessibilityLabel="sms_reset_to_default">
                          <Text style={{ ...fonts.link1, fontWeight: "400" }}>
                            {Localise(messages, "Reset to Default")}
                          </Text>
                        </TouchableOpacity>

                        <SaveCancelButtons
                          disableOnDirty={isSubmit}
                          buttonTitle={Localise(messages, "Save")}
                          cancelTestId={"clear_sms"}
                          saveTestId={"save_sms_text"}
                          onCancel={() => {
                            resetForm();
                            setSmsToggleValue(smsToggle);
                            setSmsDefaultBody(smsBody);
                            setIsSubmit(true);
                            setCharacterLimit(defaultSMSCharLimit);
                          }}
                        />
                      </View>
                    </>
                  )}
                </View>
                <View fsClass="fs-unmask"></View>
              </View>
            </View>
          );
        }}
      />
    </View>
  );
};

export default SmsSettings;
