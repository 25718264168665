import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Text } from "react-native-elements";
import { View, TouchableOpacity } from "react-native";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { ToasterHandler } from "components/elements";
import { FormField, Form, SubmitButton } from "components/elements/forms";
import { fonts, colors, backgroundColors } from "styles/theme";
import { getGrandTotalPrice } from "library/utils/createOrder";
import {
  setGiftCardsData,
  fetchGiftCardsData,
  setRedeemedGcData,
} from "library/sagas/views/home/drawer/create-order/slice";
import {
  selectGiftCardsData,
  selectRedeemedGiftCardsData,
} from "library/sagas/views/home/drawer/create-order/selector";

import { redeemGcValidationSchema } from "../config";

const RedeemGiftCards = ({ values, amountToPay }) => {
  const dispatch = useDispatch();
  const { isDesktop } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const [isSubmit, setIsSubmit] = useStateIfMounted(true);
  const [disableGcField, setDisableGcField] = useStateIfMounted(false);
  const [isLoading, setIsLoading] = useStateIfMounted(false);
  const giftCardsResponse = useSelector(selectGiftCardsData);
  const [validationOnChange, setValidationOnChange] = useStateIfMounted(false);
  const redeemedGiftCardsData = useSelector(selectRedeemedGiftCardsData);

  const {
    grandTotal,
    gcRedeemedData = [],
    redeemedGcbalance = [],
  } = getGrandTotalPrice(amountToPay, giftCardsResponse);
  const BALANCE = Localise(messages, "Balance");

  const removeGiftCard = (index) => {
    dispatch(
      setGiftCardsData({ giftCardsResponse, actionName: "delete", index })
    );
  };

  useEffect(() => {
    dispatch(setRedeemedGcData(gcRedeemedData));
  }, [giftCardsResponse, gcRedeemedData?.length, grandTotal]);

  useEffect(() => {
    if (grandTotal === "0.00") {
      setDisableGcField(true);
      setIsSubmit(true);
    } else {
      setDisableGcField(false);
      values?.giftCard?.length > 0 ? setIsSubmit(false) : setIsSubmit(true);
    }
  }, [values?.giftCard, grandTotal]);

  const onValidateGiftCard = (values, formikBag) => {
    setIsLoading(true);
    if (formikBag.isSubmitting) return;
    dispatch(
      fetchGiftCardsData({
        giftCardId: values?.giftCard,
        shopCode: values?.sendingMember,
        resolve: () => {
          ToasterHandler("success", Localise(messages, "Redeemed gift card"));
          setIsLoading(false);
          formikBag.setSubmitting(false);
          formikBag.resetForm({ giftCard: "" });
        },
        reject: (text) => {
          setIsLoading(false);
          formikBag.setSubmitting(false);
          text.includes("Gift")
            ? formikBag.setErrors({ giftCard: text })
            : ToasterHandler("error", Localise(messages, text));
        },
      })
    );
  };

  return (
    <View style={{ marginLeft: 5, marginTop: 5 }}>
      <View
        style={tw("flex flex-row items-center")}
        testID={"validate_gift_card"}
      >
        <Form
          initialValues={{ giftCard: "" }}
          onSubmit={(values, formikBag) => {
            onValidateGiftCard(values, formikBag);
          }}
          validationSchema={redeemGcValidationSchema(
            messages,
            Localise,
            redeemedGiftCardsData,
            values
          )}
          validateOnChange={validationOnChange}
          validateOnBlur={validationOnChange}
          render={({ submitCount }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              if (submitCount > 0) {
                setValidationOnChange(true);
              }
            }, [submitCount]);

            return (
              <View style={[tw("flex flex-row justify-center h-full")]}>
                <View
                  style={[
                    tw("flex flex-row flex-wrap justify-between"),
                    { zIndex: -1 },
                  ]}
                >
                  <View
                    style={{
                      marginTop: isDesktop ? 2 : 5,
                      paddingTop: isDesktop ? 0 : 5,
                    }}
                  >
                    <FormField
                      name="giftCard"
                      placeholder={Localise(messages, "Redeem Gift Card")}
                      containerStyle={{
                        width: isDesktop ? 190 : 110,
                        paddingHorizontal: 0,
                      }}
                      errorStyle={{ paddingBottom: 0 }}
                      autoCapitalize="none"
                      autoCorrect={false}
                      autoComplete="new-password"
                      testID="redeem_gift_card"
                      accessibilityLabel="redeem_gift_card"
                      disabled={disableGcField}
                      editable={!disableGcField}
                    />
                  </View>

                  <TouchableOpacity>
                    <SubmitButton
                      containerStyle={{
                        width: isDesktop ? 78 : 90,
                        height: 35,
                        margin: !isDesktop ? 10 : 2,
                        marginLeft: 15,
                      }}
                      testID={"apply_gift_card"}
                      accessibilityLabel={"apply_gift_card"}
                      disableOnDirty={isSubmit}
                      loading={isLoading}
                      title={Localise(messages, "Apply")}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            );
          }}
        />
      </View>
      {giftCardsResponse?.length > 0 &&
        giftCardsResponse.map((gcData, index) => {
          const { giftCodeId = "" } = gcData;

          return (
            <View
              style={{
                ...tw("flex-row flex-wrap justify-between"),
                flexGrow: 1,
              }}
              key={index}
            >
              <View
                key={index}
                style={{
                  ...tw("flex-row flex-wrap items-center"),
                  width: "40%",
                  paddingRight: 10,
                  flexGrow: 1,
                }}
              >
                <TouchableOpacity
                  key={index}
                  style={{
                    ...tw("flex-row justify-between items-center"),
                    backgroundColor: backgroundColors.navBar,
                    borderColor: "#a9a9a9",
                    borderWidth: 0.5,
                    borderRadius: 3,
                    padding: 10,
                    shadowColor: colors.medium,
                    shadowOffset: {
                      width: 0,
                      height: 0.5,
                    },
                    marginTop: 5,
                    marginBottom: 10,
                    marginRight: 10,
                    shadowRadius: 1,
                    shadowOpacity: 0.5,
                  }}
                  onPress={() => {
                    removeGiftCard(index);
                  }}
                  testID={"redeem_gift_card"}
                  accessibilityLabel={"redeem_gift_card"}
                >
                  <Text
                    style={{
                      color: colors.highlighter,
                      fontWeight: "bold",
                    }}
                  >
                    {`******${giftCodeId.slice(-4)}`}
                  </Text>
                  <Text
                    style={{
                      paddingLeft: 10,
                      paddingRight: 5,
                      color: colors.highlighter,
                      fontWeight: "bold",
                    }}
                  >
                    X
                  </Text>
                </TouchableOpacity>
                <Text
                  style={[fonts.default, tw("pr-4"), { color: colors.primary }]}
                >
                  {`${BALANCE} $${redeemedGcbalance[index]}`}
                </Text>
              </View>
              <Text
                style={[
                  fonts.sectionHeading,
                  tw("pt-5"),
                  { fontWeight: "normal" },
                  { color: colors.primary },
                ]}
              >
                {`$${gcRedeemedData[index]?.gcAmount}`}
              </Text>
            </View>
          );
        })}
    </View>
  );
};

export default RedeemGiftCards;
