import React, { useContext, useEffect, useMemo } from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";
import tw from "tailwind-rn";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";
import Environment from "library/utils/environment";
import { FormField, FormFieldPicker } from "components/elements/forms";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { selectPromoCodeslist } from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { COMMON, CREATE_ORDER } from "library/constants";
import { fonts } from "styles/theme";
import { getOrderSubTotal } from "library/utils/createOrder";
import { formatPrice } from "library/utils/formatter";
import { getPromoCodesList } from "./helper";
import { CrossIcon } from "components/elements";

const PromoCode = ({ index, sendingMemberCode }) => {
  const { isDesktop } = useContext(DeviceContext);
  const { messages, Localise } = useContext(I18NContext);
  const { values, setFieldValue } = useFormikContext();

  const promoCodesList = useSelector(selectPromoCodeslist(sendingMemberCode));
  const promoCodeSwitch = Environment.get("SHOW_PROMO_CODE_FEATURE", false);

  const orderItemsPath = `orderItems.${index}`;
  const order = get(values, orderItemsPath, {});

  const {
    lineItems = [],
    promoCode = "",
    selectedPromoCodeInfo = {},
    price = [],
  } = order;

  const promoCodeOptions = useMemo(
    () => getPromoCodesList(promoCodesList, lineItems),
    [promoCodesList, lineItems.length]
  );

  const isPromoPercentage =
    selectedPromoCodeInfo.discountType === COMMON.PERCENTAGE;
  const isDiscountsApplied = price.some((item) => !!item.discountType);

  const infoText = promoCode
    ? CREATE_ORDER.PROMO_ADDED
    : isDiscountsApplied
    ? CREATE_ORDER.DISCOUNTS_ADDED
    : "";

  useEffect(() => {
    if (isEmpty(lineItems)) setFieldValue(`${orderItemsPath}.promoCode`, "");
  }, [lineItems?.length]);

  useEffect(() => {
    if (!promoCodeSwitch) return;

    if (!promoCode) {
      setFieldValue(`${orderItemsPath}.selectedPromoCodeInfo`, {});
      return;
    }
    const selectedPromoCode =
      promoCodesList?.find((item) => item.promoCode === promoCode) || {};

    const promoValue = parseFloat(
      selectedPromoCode?.discountValue || 0
    ).toFixed(2);

    setFieldValue(`${orderItemsPath}.selectedPromoCodeInfo`, {
      ...selectedPromoCode,
      discountValue: promoValue,
    });
  }, [promoCode]);

  useEffect(() => {
    if (!promoCodeSwitch) return;

    const promoValue = selectedPromoCodeInfo?.discountValue || 0;
    const promoDiscountType = selectedPromoCodeInfo?.discountType || "";

    const productTotal = getOrderSubTotal(order, false);

    price.forEach((item, idx) => {
      let discount = 0;
      if (promoDiscountType === COMMON.PERCENTAGE) {
        discount = promoValue;
      } else if (promoDiscountType === COMMON.DOLLAR) {
        const { quantity = 1 } = lineItems[idx] || {};
        discount = (item.value / productTotal) * promoValue * quantity;

        // To zero out the value in case the discount amount exceeds the product price.
        if (formatPrice(discount) > quantity * item?.value) {
          discount = quantity * item?.value;
        }
      }

      setFieldValue(
        `${orderItemsPath}.price.${idx}.discountType`,
        promoDiscountType
      );
      setFieldValue(
        `${orderItemsPath}.price.${idx}.discount`,
        formatPrice(discount)
      );
    });
  }, [selectedPromoCodeInfo?.discountValue, lineItems]);

  return (
    <View
      style={tw(
        `flex flex-row items-center ${isDesktop ? "pt-4 pb-2" : "py-2"}`
      )}
    >
      {!promoCodeSwitch ? (
        <FormField
          name="promoCode"
          placeholder={Localise(messages, "Promo Code")}
          label={Localise(messages, "Promo Code")}
          labelStyle={tw(`font-normal`)}
          path={orderItemsPath}
          containerStyle={{
            width: isDesktop ? 190 : 110,
            paddingHorizontal: 0,
          }}
          errorStyle={tw(`pb-0`)}
          autoCapitalize="none"
          autoCorrect={false}
          autoComplete="new-password"
          testID="promo_code"
          accessibilityLabel="promo_code"
        />
      ) : (
        <View style={tw(`flex w-full ${isDesktop ? "flex-row" : "flex-col"}`)}>
          <View
            style={[
              tw(`flex flex-row justify-between items-center`),
              {
                width: isDesktop ? (promoCode ? 315 : 190) : "100%",
              },
            ]}
          >
            <FormFieldPicker
              name="promoCode"
              label={Localise(messages, "Promo Code")}
              labelStyle={tw(`font-normal`)}
              placeholder={{
                label: Localise(messages, "Select Code"),
              }}
              path={orderItemsPath}
              containerStyle={{
                width: isDesktop ? 190 : !promoCode ? "100%" : "62%",
                paddingHorizontal: 0,
                paddingBottom: 0,
              }}
              errorStyle={tw(`pb-0`)}
              data={promoCodeOptions}
              disabledFieldTouch={true}
              autoCapitalize="none"
              autoCorrect={false}
              autoComplete="new-password"
              testID="promo_code"
              accessibilityLabel="promo_code"
            />
            {!!promoCode && (
              <>
                <FormField
                  name="discountValue"
                  inputContainerStyle={{
                    padding: 6,
                  }}
                  containerStyle={[
                    tw("mt-5 px-0 ml-2"),
                    {
                      width: isDesktop ? 90 : "25%",
                    },
                  ]}
                  errorStyle={tw(`pb-0`)}
                  renderErrorMessage={false}
                  keyboardType="numeric"
                  iconName={isPromoPercentage ? "percent" : "currency-usd"}
                  iconPosition={!isPromoPercentage}
                  iconSize={isPromoPercentage ? 12 : 14}
                  iconType={
                    isPromoPercentage ? "font-awesome" : "material-community"
                  }
                  leftIconContainerStyle={tw(`pr-0`)}
                  placeholder="0.00"
                  path={`${orderItemsPath}.selectedPromoCodeInfo`}
                  editable={false}
                  grayedOutOnDisable={true}
                  testID="promo_discount_value"
                  accessibilityLabel="promo_discount_value"
                />
                <CrossIcon
                  containerStyle={tw(`mt-5 ml-1`)}
                  onPress={() => {
                    setFieldValue(`${orderItemsPath}.promoCode`, "");
                  }}
                  testID="remove_promo_code"
                  accessibilityLabel="remove_promo_code"
                />
              </>
            )}
          </View>
          {!!infoText && (
            <Text
              style={[
                fonts.style2,
                tw(
                  `flex justify-center items-center ${
                    isDesktop ? "mt-5 ml-2" : "mt-2"
                  }`
                ),
              ]}
            >
              {Localise(messages, infoText)}
            </Text>
          )}
        </View>
      )}
    </View>
  );
};

export default PromoCode;
