const occasion_reminder_settings = {
  smsTokenMenu: [
    {
      key: "recipient",
      label: "[Recipient]",
    },
    {
      key: "occasion",
      label: "[Occasion Name]",
    },
    {
      key: "occasionDate",
      label: "[Occasion Date]",
    },
    {
      key: "shopWebsite",
      label: "[Shop Website]",
    },
    {
      key: "shopPhone",
      label: "[Shop Phone]",
    },
  ],
  emailSubjectTokenMenu: [],
  emailBodyTokenMenu: [
    {
      key: "customerName",
      label: "[Customer Name]",
    },
    {
      key: "occasion",
      label: "[Occasion Name]",
    },
    {
      key: "occasionDate",
      label: "[Occasion Date]",
    },
    {
      key: "logo",
      label: "[Logo]",
    },
    {
      key: "shopName",
      label: "[Shop Name]",
    },
    {
      key: "shopEmail",
      label: "[Shop Email]",
    },
    {
      key: "shopPhone",
      label: "[Shop Phone]",
    },
    {
      key: "shopWebsite",
      label: "[Shop Website]",
    },
    {
      key: "shopAddress",
      label: "[Shop Address]",
    },
  ],
};

export const orderStatusSpecificTokenMenu = [
  {
    order_confirmation_notification_settings: {
      smsTokenMenu: [
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
      ],
      emailSubjectTokenMenu: [
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
      ],
      emailBodyTokenMenu: [
        {
          key: "customerName",
          label: "[Customer Name]",
        },
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "deliveryDetails",
          label: "[Delivery Details]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "logo",
          label: "[Logo]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "orderStatusLink",
          label: "[Order Status Link]",
        },
        {
          key: "priceAndPaymentSummary",
          label: "[Price and Payment Summary]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopEmail",
          label: "[Shop Email]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
        {
          key: "shopWebsite",
          label: "[Shop Website]",
        },
        {
          key: "shopAddress",
          label: "[Shop Address]",
        },
        {
          key: "cardMessage",
          label: "[Card Message]",
        },
      ],
    },
    pickup_confirmation_notification_settings: {
      smsTokenMenu: [
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
      ],
      emailSubjectTokenMenu: [
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
      ],
      emailBodyTokenMenu: [
        {
          key: "customerName",
          label: "[Customer Name]",
        },
        {
          key: "pickupTime",
          label: "[Pickup Time]",
        },
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "deliveryDetails",
          label: "[Delivery Details]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "logo",
          label: "[Logo]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "orderStatusLink",
          label: "[Order Status Link]",
        },
        {
          key: "priceAndPaymentSummary",
          label: "[Price and Payment Summary]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopEmail",
          label: "[Shop Email]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
        {
          key: "shopWebsite",
          label: "[Shop Website]",
        },
        {
          key: "shopAddress",
          label: "[Shop Address]",
        },
        {
          key: "cardMessage",
          label: "[Card Message]",
        },
      ],
    },
    order_started_notification_settings: {
      smsTokenMenu: [
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
      ],
      emailSubjectTokenMenu: [
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
      ],
      emailBodyTokenMenu: [
        {
          key: "customerName",
          label: "[Customer Name]",
        },
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "deliveryDetails",
          label: "[Delivery Details]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "logo",
          label: "[Logo]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "orderStatusLink",
          label: "[Order Status Link]",
        },
        {
          key: "priceAndPaymentSummary",
          label: "[Price and Payment Summary]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopEmail",
          label: "[Shop Email]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
        {
          key: "shopWebsite",
          label: "[Shop Website]",
        },
        {
          key: "shopAddress",
          label: "[Shop Address]",
        },
        {
          key: "cardMessage",
          label: "[Card Message]",
        },
      ],
    },
    order_dd_changed_notification_settings: {
      smsTokenMenu: [
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
      ],
      emailSubjectTokenMenu: [
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
      ],
      emailBodyTokenMenu: [
        {
          key: "customerName",
          label: "[Customer Name]",
        },
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "deliveryDetails",
          label: "[Delivery Details]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "logo",
          label: "[Logo]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "orderStatusLink",
          label: "[Order Status Link]",
        },
        {
          key: "priceAndPaymentSummary",
          label: "[Price and Payment Summary]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopEmail",
          label: "[Shop Email]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
        {
          key: "shopWebsite",
          label: "[Shop Website]",
        },
        {
          key: "shopAddress",
          label: "[Shop Address]",
        },
        {
          key: "cardMessage",
          label: "[Card Message]",
        },
      ],
    },
    pick_up_date_changed_notification_settings: {
      smsTokenMenu: [
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
      ],
      emailSubjectTokenMenu: [
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
      ],
      emailBodyTokenMenu: [
        {
          key: "customerName",
          label: "[Customer Name]",
        },
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "deliveryDetails",
          label: "[Delivery Details]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "logo",
          label: "[Logo]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "orderStatusLink",
          label: "[Order Status Link]",
        },
        {
          key: "priceAndPaymentSummary",
          label: "[Price and Payment Summary]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopEmail",
          label: "[Shop Email]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
        {
          key: "shopWebsite",
          label: "[Shop Website]",
        },
        {
          key: "shopAddress",
          label: "[Shop Address]",
        },
        {
          key: "cardMessage",
          label: "[Card Message]",
        },
      ],
    },
    order_out_for_delivery_notification_settings: {
      smsTokenMenu: [
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
      ],
      emailSubjectTokenMenu: [
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
      ],
      emailBodyTokenMenu: [
        {
          key: "customerName",
          label: "[Customer Name]",
        },
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "deliveryDetails",
          label: "[Delivery Details]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "logo",
          label: "[Logo]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "orderStatusLink",
          label: "[Order Status Link]",
        },
        {
          key: "priceAndPaymentSummary",
          label: "[Price and Payment Summary]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopEmail",
          label: "[Shop Email]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
        {
          key: "shopWebsite",
          label: "[Shop Website]",
        },
        {
          key: "shopAddress",
          label: "[Shop Address]",
        },
        {
          key: "cardMessage",
          label: "[Card Message]",
        },
      ],
    },
    order_delivered_notification_settings: {
      smsTokenMenu: [
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
      ],
      emailSubjectTokenMenu: [
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
      ],
      emailBodyTokenMenu: [
        {
          key: "customerName",
          label: "[Customer Name]",
        },
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "deliveryDetails",
          label: "[Delivery Details]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "logo",
          label: "[Logo]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "orderStatusLink",
          label: "[Order Status Link]",
        },
        {
          key: "priceAndPaymentSummary",
          label: "[Price and Payment Summary]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopEmail",
          label: "[Shop Email]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
        {
          key: "shopWebsite",
          label: "[Shop Website]",
        },
        {
          key: "shopAddress",
          label: "[Shop Address]",
        },
        {
          key: "cardMessage",
          label: "[Card Message]",
        },
      ],
    },
    order_pickedup_notification_settings: {
      smsTokenMenu: [
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
      ],
      emailSubjectTokenMenu: [
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
      ],
      emailBodyTokenMenu: [
        {
          key: "customerName",
          label: "[Customer Name]",
        },
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "deliveryDetails",
          label: "[Delivery Details]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "logo",
          label: "[Logo]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "priceAndPaymentSummary",
          label: "[Price and Payment Summary]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopEmail",
          label: "[Shop Email]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
        {
          key: "shopWebsite",
          label: "[Shop Website]",
        },
        {
          key: "shopAddress",
          label: "[Shop Address]",
        },
        {
          key: "cardMessage",
          label: "[Card Message]",
        },
      ],
    },
    walkin_delivered_notification_settings: {
      smsTokenMenu: [
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
      ],
      emailSubjectTokenMenu: [
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
      ],
      emailBodyTokenMenu: [
        {
          key: "customerName",
          label: "[Customer Name]",
        },
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "deliveryDetails",
          label: "[Delivery Details]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "logo",
          label: "[Logo]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "priceAndPaymentSummary",
          label: "[Price and Payment Summary]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopEmail",
          label: "[Shop Email]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
        {
          key: "shopWebsite",
          label: "[Shop Website]",
        },
        {
          key: "shopAddress",
          label: "[Shop Address]",
        },
        {
          key: "cardMessage",
          label: "[Card Message]",
        },
      ],
    },
    order_not_delivered_notification_settings: {
      smsTokenMenu: [
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
      ],
      emailSubjectTokenMenu: [
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
      ],
      emailBodyTokenMenu: [
        {
          key: "customerName",
          label: "[Customer Name]",
        },
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "deliveryDetails",
          label: "[Delivery Details]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "logo",
          label: "[Logo]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "orderStatusLink",
          label: "[Order Status Link]",
        },
        {
          key: "priceAndPaymentSummary",
          label: "[Price and Payment Summary]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopEmail",
          label: "[Shop Email]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
        {
          key: "shopWebsite",
          label: "[Shop Website]",
        },
        {
          key: "shopAddress",
          label: "[Shop Address]",
        },
        {
          key: "cardMessage",
          label: "[Card Message]",
        },
      ],
    },
    order_ready_for_pickup_notification_settings: {
      smsTokenMenu: [
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
      ],
      emailSubjectTokenMenu: [
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
      ],
      emailBodyTokenMenu: [
        {
          key: "customerName",
          label: "[Customer Name]",
        },
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "deliveryDetails",
          label: "[Delivery Details]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "logo",
          label: "[Logo]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "orderStatusLink",
          label: "[Order Status Link]",
        },
        {
          key: "priceAndPaymentSummary",
          label: "[Price and Payment Summary]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopEmail",
          label: "[Shop Email]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
        {
          key: "shopWebsite",
          label: "[Shop Website]",
        },
        {
          key: "shopAddress",
          label: "[Shop Address]",
        },
        {
          key: "shopHours",
          label: "[Shop Hours]",
        },
        {
          key: "cardMessage",
          label: "[Card Message]",
        },
      ],
    },
    credit_card_saved_notification_settings: {
      smsTokenMenu: [
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
      ],
      emailSubjectTokenMenu: [
        {
          key: "shopName",
          label: "[Shop Name]",
        },
      ],
      emailBodyTokenMenu: [
        {
          key: "customerName",
          label: "[Customer Name]",
        },
        {
          key: "logo",
          label: "[Logo]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopEmail",
          label: "[Shop Email]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
        {
          key: "shopWebsite",
          label: "[Shop Website]",
        },
        {
          key: "shopAddress",
          label: "[Shop Address]",
        },
      ],
    },
    house_account_statement_notification_settings: {
      smsTokenMenu: [
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
        {
          key: "haStatementsMonth",
          label: "[HA Statements Month]",
        },
      ],
      emailSubjectTokenMenu: [
        {
          key: "shopName",
          label: "[Shop Name]",
        },
      ],
      emailBodyTokenMenu: [
        {
          key: "customerName",
          label: "[Customer Name]",
        },
        {
          key: "logo",
          label: "[Logo]",
        },
        {
          key: "priceAndPaymentSummary",
          label: "[Price and Payment Summary]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopEmail",
          label: "[Shop Email]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
        {
          key: "shopWebsite",
          label: "[Shop Website]",
        },
        {
          key: "shopAddress",
          label: "[Shop Address]",
        },
        {
          key: "haStatementsLink",
          label: "[HA Statements Link]",
        },
        {
          key: "haStatementsMonth",
          label: "[HA Statements Month]",
        },
      ],
    },
    pay_later_notification_settings: {
      smsTokenMenu: [
        {
          key: "amountDue",
          label: "[Amount Due]",
        },
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
      ],
      emailSubjectTokenMenu: [
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
      ],
      emailBodyTokenMenu: [
        {
          key: "amountDue",
          label: "[Amount Due]",
        },
        {
          key: "customerName",
          label: "[Customer Name]",
        },
        {
          key: "deliveryDate",
          label: "[Delivery Date]",
        },
        {
          key: "deliveryDetails",
          label: "[Delivery Details]",
        },
        {
          key: "forRecipientName",
          label: "[for Recipient Name]",
        },
        {
          key: "logo",
          label: "[Logo]",
        },
        {
          key: "orderNumber",
          label: "[Order Number]",
        },
        {
          key: "orderStatusLink",
          label: "[Order Status Link]",
        },
        {
          key: "priceAndPaymentSummary",
          label: "[Price and Payment Summary]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopEmail",
          label: "[Shop Email]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
        {
          key: "shopWebsite",
          label: "[Shop Website]",
        },
        {
          key: "shopAddress",
          label: "[Shop Address]",
        },
        {
          key: "cardMessage",
          label: "[Card Message]",
        },
      ],
    },
    gift_cards_notification_settings: {
      smsTokenMenu: [
        {
          key: "recipientName",
          label: "[Recipient Name]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
        {
          key: "giftCode",
          label: "[Gift Code]",
        },
      ],
      emailSubjectTokenMenu: [
        {
          key: "shopName",
          label: "[Shop Name]",
        },
      ],
      emailBodyTokenMenu: [
        {
          key: "recipientName",
          label: "[Recipient Name]",
        },
        {
          key: "logo",
          label: "[Logo]",
        },
        {
          key: "giftCardRibbonLogo",
          label: "[Gift Card Ribbon Logo]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopNow",
          label: "[Shop Now]",
        },
        {
          key: "shopEmail",
          label: "[Shop Email]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
        {
          key: "shopWebsite",
          label: "[Shop Website]",
        },
        {
          key: "shopAddress",
          label: "[Shop Address]",
        },
        {
          key: "cardMessage",
          label: "[Card Message]",
        },
        {
          key: "amount",
          label: "[Amount]",
        },
        {
          key: "giftCode",
          label: "[Gift Code]",
        },
      ],
    },
    event_proposal_notification_settings: {
      smsTokenMenu: [
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
      ],
      emailSubjectTokenMenu: [
        {
          key: "shopName",
          label: "[Shop Name]",
        },
      ],
      emailBodyTokenMenu: [
        {
          key: "eventProposalLink",
          label: "[Event proposal link]",
        },
        {
          key: "logo",
          label: "[Logo]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopEmail",
          label: "[Shop Email]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
        {
          key: "shopWebsite",
          label: "[Shop Website]",
        },
        {
          key: "shopAddress",
          label: "[Shop Address]",
        },
      ],
    },
    onDemand_invoice_notification_settings: {
      emailSubjectTokenMenu: [
        {
          key: "shopName",
          label: "[Shop Name]",
        },
      ],
      emailBodyTokenMenu: [
        {
          key: "logo",
          label: "[Logo]",
        },
        {
          key: "onDemandEmailLink",
          label: "[OnDemand Email Link]",
        },
        {
          key: "shopName",
          label: "[Shop Name]",
        },
        {
          key: "shopEmail",
          label: "[Shop Email]",
        },
        {
          key: "shopPhone",
          label: "[Shop Phone]",
        },
        {
          key: "shopWebsite",
          label: "[Shop Website]",
        },
        {
          key: "shopAddress",
          label: "[Shop Address]",
        },
      ],
    },
    birthday_occasion_notification_settings: occasion_reminder_settings,
    anniversary_occasion_notification_settings: occasion_reminder_settings,
  },
];
