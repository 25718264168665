import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { View } from "react-native";

import {
  FormField,
  FormFieldPicker,
  FormFieldCheckBox,
} from "components/elements/forms";

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import I18NContext from "library/contexts/i18N";
import {
  formatPrice,
  isMasked,
  // getMaskedCardNumber, // commented this as part of this ticket: MSOL-18277
} from "library/utils/formatter";
import { DeviceContext } from "library/contexts/appSettings";

import BillingInfo from "./billing-info";

const paypalOptions = [
  { label: "Email", value: "email" },
  { label: "Phone", value: "phone" },
];

const PAID_ELSEWHERE = ({ isSmallScreen }) => {
  const { values, setFieldValue } = useFormikContext();
  const { messages, Localise } = React.useContext(I18NContext);

  const {
    paymentInfo: { newTotalAmount },
  } = values;

  useEffect(() => {
    setFieldValue(
      `paymentInfo.PAID_ELSEWHERE.amount`,
      formatPrice(newTotalAmount)
    );
  }, []);

  return (
    <>
      <FormField
        name={"amount"}
        path={`paymentInfo.PAID_ELSEWHERE`}
        label={Localise(messages, "Amount")}
        containerStyle={{
          width: isSmallScreen ? "100%" : "50%",
          marginTop: 2,
        }}
        iconType="material-community"
        iconName="currency-usd"
        iconSize={14}
        inputContainerStyle={{
          padding: 6,
        }}
        leftIconContainerStyle={{ paddingRight: 0 }}
        isPrice={true}
        editable={false}
        grayedOutOnDisable={true}
      />
      <FormField
        name={"note"}
        path={`paymentInfo.PAID_ELSEWHERE`}
        label={Localise(messages, "Note")}
        placeholder={Localise(messages, "Enter note as needed")}
        containerStyle={{
          width: isSmallScreen ? "100%" : "50%",
          marginTop: 2,
        }}
        maxLength={50}
      />
    </>
  );
};

const CASH_OR_CHECK = ({ isSmallScreen }) => {
  const { values, setFieldValue } = useFormikContext();
  const { messages, Localise } = React.useContext(I18NContext);
  const tenderedAmount = get(
    values,
    `paymentInfo.CASH_OR_CHECK.tenderedAmount`,
    0
  );

  const {
    paymentInfo: { newTotalAmount },
  } = values;

  useEffect(() => {
    if (isNaN(tenderedAmount)) return;

    const diff = tenderedAmount - newTotalAmount;
    const changeDueAmount = diff >= 0 ? diff : 0;

    setFieldValue(
      `paymentInfo.CASH_OR_CHECK.changeDueAmount`,
      parseFloat(changeDueAmount).toFixed(2)
    );
    setFieldValue(`paymentInfo.CASH_OR_CHECK.orderTotal`, newTotalAmount);
  }, [tenderedAmount]);

  return (
    <>
      <FormField
        name={"tenderedAmount"}
        path={`paymentInfo.CASH_OR_CHECK`}
        label={Localise(messages, "Tendered")}
        placeholder={Localise(messages, "Enter Amount (e.g. $100.00)")}
        containerStyle={{
          width: isSmallScreen ? "100%" : "50%",
          marginTop: 2,
        }}
        iconType="material-community"
        iconName="currency-usd"
        iconSize={14}
        inputContainerStyle={{
          padding: 6,
        }}
        leftIconContainerStyle={{ paddingRight: 0 }}
        isPrice={true}
      />
      <FormField
        name={"changeDueAmount"}
        path={`paymentInfo.CASH_OR_CHECK`}
        label={Localise(messages, "Change Due")}
        placeholder={Localise(messages, "Due Amount")}
        containerStyle={{
          width: isSmallScreen ? "100%" : "50%",
          marginTop: 2,
        }}
        iconType="material-community"
        iconName="currency-usd"
        iconSize={14}
        inputContainerStyle={{
          padding: 6,
        }}
        leftIconContainerStyle={{ paddingRight: 0 }}
        editable={false}
        grayedOutOnDisable={true}
      />
    </>
  );
};

const CREDIT_CARD = ({ isSmallScreen }) => {
  const { values, setFieldValue } = useFormikContext();
  const { messages, Localise } = React.useContext(I18NContext);

  const { customerInfo = {} } = values;
  const {
    firstPaymentInfo: {
      nameOnCard = "",
      lastFourDigits = "",
      expirationMonth = "",
      expirationYear = "",
      billingAddress: {
        addressLine1 = "",
        addressLine2 = "",
        city = "",
        country = "",
        state = "",
        zipcode = "",
      } = {},
    } = {},
  } = customerInfo;
  const {
    paymentInfo: { paymentType = "", saveBillingInfo, CREDIT_CARD = {} },
  } = values;
  const { actualCardNumber = "", isSwipedCard = false } = CREDIT_CARD;

  const isSavedCard = paymentType === "SAVED_CARD";
  const isTerminalPayment = paymentType === "PAYMENT_TERMINAL";

  useEffect(() => {
    const cardInfo = {
      name: isSavedCard ? nameOnCard : "",
      cardNumber: isSavedCard ? "***" + lastFourDigits : "",
      expDate: isSavedCard
        ? expirationMonth + "/" + expirationYear.substring(2)
        : "",
      cvv: "",
      zip: isSavedCard ? zipcode : "",
      billingInfo: {
        addressLine1: isSavedCard ? addressLine1 : "",
        suite: isSavedCard ? addressLine2 : "",
        city: isSavedCard ? city : "",
        state: isSavedCard ? state : "",
        country: isSavedCard ? country : "US",
      },
    };

    setFieldValue(`paymentInfo.CREDIT_CARD`, cardInfo);
  }, [paymentType]);

  return (
    <React.Fragment>
      {!isTerminalPayment && (
        <>
          <FormField
            name={"name"}
            path={`paymentInfo.CREDIT_CARD`}
            label={Localise(messages, "Name on Card")}
            placeholder={Localise(messages, "Enter Name on Card")}
            containerStyle={{
              width: isSmallScreen ? "100%" : "50%",
              marginTop: 2,
            }}
            grayedOutOnDisable={true}
            editable={!isSavedCard}
            autoComplete="new-password" //hack for autoComplete off
          />
          <FormField
            name={"cardNumber"}
            path={`paymentInfo.CREDIT_CARD`}
            label={Localise(messages, "Card Number")}
            placeholder={Localise(messages, "Enter Card Number")}
            editable={!isSavedCard}
            fsClass="fs-exclude"
            containerStyle={{
              width: isSmallScreen ? "100%" : "50%",
              marginTop: 2,
            }}
            grayedOutOnDisable={true}
            iconName="credit-card"
            iconPosition={false}
            keyboardType="numeric"
            maxLength={19}
            transformText={(text = "") => {
              let trimmed = text.split(" ").join("");
              if (trimmed.length < 4 || isNaN(trimmed)) return text;
              let ccnumspaced = trimmed.match(/.{1,4}/g) || [];
              return ccnumspaced.join(" ");
            }}
            handleOnBlur={(cardNumber) => {
              if (isMasked(cardNumber)) return;
              setFieldValue(`paymentInfo.CREDIT_CARD`, {
                ...CREDIT_CARD,
                // cardNumber: getMaskedCardNumber(cardNumber), // commented this as part of this ticket: MSOL-18277
                cardNumber: cardNumber,
                actualCardNumber: cardNumber,
              });
            }}
            autoComplete="new-password" //hack for autoComplete off
            onKeyPress={(e) => {
              if (isSwipedCard) {
                const keyCode = e.which || e.keyCode;
                //For backspace and delete keys
                if (
                  [8, 46, "Backspace"].includes(keyCode) &&
                  actualCardNumber
                ) {
                  setFieldValue(`paymentInfo.CREDIT_CARD`, {
                    ...CREDIT_CARD,
                    cardNumber: "",
                    actualCardNumber: "",
                    expDate: "",
                    cvv: "",
                    name: "",
                    isSwipedCard: false,
                  });
                }
              }
            }}
          />
          <FormField
            name={"expDate"}
            path={`paymentInfo.CREDIT_CARD`}
            label={Localise(messages, "Expiration Date")}
            placeholder="MM/YY"
            containerStyle={{
              width: isSmallScreen ? "100%" : "33.3%",
              marginTop: 2,
            }}
            maxLength={5}
            grayedOutOnDisable={true}
            editable={!isSavedCard}
            transformText={(text) => {
              const length = text.length;
              if (isNaN(text) || text.includes("/") || length !== 4)
                return text;
              return text.slice(0, 2) + "/" + text.slice(2);
            }}
            autoComplete="new-password" //hack for autoComplete off
          />
          <FormField
            name={"cvv"}
            path={`paymentInfo.CREDIT_CARD`}
            label={Localise(messages, "CVV")}
            placeholder={Localise(messages, "Enter Code")}
            containerStyle={{
              width: isSmallScreen ? "100%" : "33.3%",
              marginTop: 2,
            }}
            maxLength={4}
            editable={!isSwipedCard}
            grayedOutOnDisable={true}
            secureTextEntry={true}
            autoComplete="new-password" //hack for autoComplete off
          />
        </>
      )}

      {(!isTerminalPayment || (isTerminalPayment && saveBillingInfo)) && (
        <>
          <FormField
            name={"zip"}
            path={`paymentInfo.CREDIT_CARD`}
            label={Localise(messages, "Billing Zip")}
            placeholder={Localise(messages, "Enter Zip")}
            containerStyle={{
              width: isSmallScreen ? "100%" : "33.3%",
              marginTop: 2,
            }}
            maxLength={10}
            editable={!isSavedCard}
            grayedOutOnDisable={true}
          />
        </>
      )}

      {!isEmpty(customerInfo) && !isSavedCard && (
        <FormFieldCheckBox
          iconRight={false}
          name={"saveBillingInfo"}
          path={`paymentInfo`}
          size={20}
          containerStyle={{ marginLeft: "auto", width: "100%" }}
          title={Localise(messages, "Save Payment (requires customer consent)")}
        />
      )}
      {(saveBillingInfo || isSavedCard) && (
        <BillingInfo isSmallScreen={isSmallScreen} isSavedCard={isSavedCard} />
      )}
    </React.Fragment>
  );
};

const DIGITAL_INVOICE = ({ isSmallScreen }) => {
  return (
    <>
      <FormField
        name="email"
        label="Email"
        placeholder="Enter Email"
        containerStyle={{
          width: isSmallScreen ? "100%" : "40%",
          marginTop: 2,
        }}
        editable={true}
      />
      <FormField
        name="phone"
        label="Phone"
        placeholder="Enter Number"
        containerStyle={{
          width: isSmallScreen ? "100%" : "30%",
          marginTop: 2,
        }}
        editable={true}
      />
    </>
  );
};

const MAIL_INVOICE = () => {
  return <></>;
};

const PhoneField = ({ isSmallScreen }) => {
  return (
    <FormField
      name="phone"
      label="Phone"
      placeholder="Enter Number"
      containerStyle={{
        width: isSmallScreen ? "100%" : "30%",
        marginTop: 2,
      }}
      editable={true}
    />
  );
};

const EmailField = ({ isSmallScreen }) => {
  return (
    <FormField
      name="email"
      label="Email"
      placeholder="Enter Email"
      containerStyle={{
        width: isSmallScreen ? "100%" : "30%",
        marginTop: 2,
      }}
      editable={true}
    />
  );
};

const PAY_PAL = ({ isSmallScreen, paypalMethod = "phone" }) => {
  return (
    <>
      <FormFieldPicker
        containerStyle={{
          width: isSmallScreen ? "50%" : "30%",
        }}
        placeholder={{ label: "Select Method" }}
        data={paypalOptions}
        name="paypalMethod"
        label="Select Method"
      />

      {paypalMethod === "phone" ? (
        <PhoneField isSmallScreen={isSmallScreen} />
      ) : (
        paypalMethod === "email" && <EmailField isSmallScreen={isSmallScreen} />
      )}
    </>
  );
};

const KeysToComponentMap = {
  CASH_OR_CHECK,
  CREDIT_CARD,
  PAID_ELSEWHERE,
  DIGITAL_INVOICE,
  MAIL_INVOICE,
  PAY_PAL,
  SAVED_CARD: CREDIT_CARD,
  PAYMENT_TERMINAL: CREDIT_CARD,
};

const PaymentMethods = () => {
  const { isDesktop } = React.useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  const { values } = useFormikContext();
  const Component = KeysToComponentMap[values?.paymentInfo?.paymentType ?? ""];

  return (
    <View
      style={{
        flexDirection: "row",
        flexWrap: "wrap",
        marginLeft: -5,
        justifyContent: "flex-start",
      }}
    >
      {Component && <Component isSmallScreen={isSmallScreen} />}
    </View>
  );
};

export default PaymentMethods;
