import * as React from "react";
import { Text, Platform } from "react-native";
import { CustomModal } from "components/elements";
import { backgroundColors, colors } from "styles/theme";
import { selectCompleteRouteModalVisible } from "library/sagas/views/home/drawer/delivery/selector";
import { useSelector } from "react-redux";
import tw from "tailwind-rn";

const CompleteRouteModal = ({
  Localise,
  messages,
  primaryhandler,
  secondaryhandler,
}) => {
  const modalVisible = useSelector(selectCompleteRouteModalVisible);
  const modalContent = {
    content: (
      <Text
        style={{
          ...tw("p-4"),
          fontSize: 15,
          color: colors.highlighter,
          textAlign: "center",
        }}
      >
        {Localise(
          messages,
          "All non-delivered orders are now going back into non-routed orders"
        )}
      </Text>
    ),
    buttons: [
      { type: "secondary", title: Localise(messages, "Nevermind") },
      { type: "primary", title: Localise(messages, "Continue") },
    ],
  };

  return (
    <CustomModal
      modalVisible={modalVisible}
      modalContent={modalContent}
      primaryhandler={primaryhandler}
      secondaryhandler={secondaryhandler}
      contentStyle={[
        tw("border border-black p-4"),
        { backgroundColor: backgroundColors.secondary },
      ]}
      modalStyle={
        Platform.OS !== "web"
          ? {
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              backgroundColor: "#00000070",
              color: colors.secondary,
            }
          : { width: "35%" }
      }
    ></CustomModal>
  );
};

export default CompleteRouteModal;
