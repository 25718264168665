import React, { useContext, memo } from "react";
import { View, Platform } from "react-native";
import { Text } from "react-native-elements";
import I18NContext from "library/contexts/i18N";
import { fonts, colors } from "styles/theme";
import tw from "tailwind-rn";
import {
  Form,
  FormFieldDatePicker,
  SubmitButton,
} from "components/elements/forms";
import * as Yup from "yup";

// Validation schema for the form
const validationSchema = Yup.object().shape({
  startDate: Yup.date().required("Please select start date"),
  endDate: Yup.date()
    .required("Please select end date")
    .min(Yup.ref("startDate"), "End date cannot be before start date"),
});

// Extracted PauseForm Component
const PauseForm = ({ initialValues, onSubmit, onCancel }) => {
  const { Localise, messages } = useContext(I18NContext);

  return (
    <View
      style={{
        padding: 5,
        borderWidth: 1,
        borderRadius: 5,
        borderStyle: "solid",
        borderColor: colors.disabled,
      }}
    >
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        render={() => (
          <View style={tw("flex flex-col")}>
            <View
              style={{
                zIndex: 20,
              }}
            >
              <FormFieldDatePicker
                dateFormat={Platform.OS === "web" ? "MM/dd/yyyy" : "MM/DD/YYYY"}
                dateValueFormat="YYYY-MM-DD"
                name="startDate"
                placeholder={Localise(messages, "Start Date")}
                containerStyle={{
                  zIndex: 30,
                  height: Platform.OS !== "web" ? 40 : "auto",
                  marginTop: 0,
                  flex: 1,
                }}
                alignIconRight={true}
                iconName="calendar"
                iconType="font-awesome"
                popperPlacement={"bottom-end"}
              />
            </View>
            <View
              style={{
                zIndex: Platform.OS === "web" ? 10 : 20,
              }}
            >
              <FormFieldDatePicker
                dateFormat={Platform.OS === "web" ? "MM/dd/yyyy" : "MM/DD/YYYY"}
                dateValueFormat="YYYY-MM-DD"
                name="endDate"
                placeholder={Localise(messages, "End Date")}
                containerStyle={{
                  zIndex: 30,
                  height: Platform.OS !== "web" ? 40 : "auto",
                  marginTop: 0,
                  flex: 1,
                }}
                alignIconRight={true}
                iconName="calendar"
                iconType="font-awesome"
                popperPlacement={"bottom-end"}
              />
            </View>
            <View
              style={[
                tw("flex flex-row items-baseline justify-between"),
                Platform.OS !== "web" && tw("pb-12"),
              ]}
            >
              <View>
                <Text
                  style={[fonts.link1, tw("m-2")]}
                  onPress={() => onCancel()}
                >
                  {Localise(messages, "Close")}
                </Text>
              </View>
              <SubmitButton
                title={Localise(messages, "Apply")}
                buttonStyle={{
                  paddingVertical: 4,
                  paddingHorizontal: 10,
                }}
                containerStyle={{ margin: 0, marginRight: 5 }}
              />
            </View>
          </View>
        )}
      />
    </View>
  );
};

export default memo(PauseForm);
