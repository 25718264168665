import React, { useState } from "react";
import { View, Text, Platform, ActivityIndicator } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { DeviceContext } from "library/contexts/appSettings";
import { SaveCancelButtons } from "../../helper";
import { ToasterHandler } from "components/elements";
import { Form, FormFieldSwitch } from "components/elements/forms";
import { Picker } from "components/elements";
import { fonts, colors } from "styles/theme";
import get from "lodash/get";
import AppSettingsContext from "library/contexts/appSettings";
import { Entitlements } from "library/utils/entitlements";

import {
  saveCustomerHouseAccount,
  setAccountingType,
} from "library/sagas/views/home/drawer/customer-directory/slice";
import {
  selectCustomerDetails,
  selectHouseAccountType,
} from "library/sagas/views/home/drawer/customer-directory/selector";
import { selectShopPreferences } from "library/sagas/views/home/drawer/shop-settings/common/selector";

import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import { getValidationSchema } from "./yup";

const HouseAccount = (props) => {
  const {
    UIConfig,
    preferences = {},
    enableHouseAccount = true,
    showAccountingType = true,
    disableOnDirty = true,
    hideSaveCancel,
    formRef,
    setFormSubmitting,
    onSuccess,
    onReject,
    onCreditLimitChange = () => {},
  } = props;
  const { permissions = {} } = React.useContext(AppSettingsContext);
  const dispatch = useDispatch();
  const customerDetails = useSelector(selectCustomerDetails);
  const shopPreferences = useSelector(selectShopPreferences);
  const {
    customerId,
    storeOrigin,
    phone,
    email,
    address: {
      addressLine1 = "",
      city = "",
      state = "",
      country = "",
      zipcode = "",
    } = {},
    houseAccountInfo = {
      houseAccountId: "",
      isActive: false,
    },
  } = customerDetails;
  const { isMobile } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const {
    InputFields: { fields },
  } = UIConfig;
  const [loading, setLoading] = useState(false);

  const selectedShopPermissions = get(permissions, storeOrigin, {});
  const isOpenItemAndBalanceForwardEliglible =
    selectedShopPermissions[Entitlements.HOUSE_ACCOUNT]?.includes(
      Entitlements.HOUSE_ACCOUNT_PERMISSIONS.OPEN_ITEM_ACCOUNTING
    ) &&
    selectedShopPermissions[Entitlements.HOUSE_ACCOUNT]?.includes(
      Entitlements.HOUSE_ACCOUNT_PERMISSIONS.BALANCE_FORWARD_ACCOUNTING
    );

  function onSubmit(values, formikBag) {
    setFormSubmitting && setFormSubmitting(true);
    dispatch(
      saveCustomerHouseAccount({
        params: {
          ...values,
          customerId,
          storeOrigin,
        },
        resolve: () => {
          onSuccess && onSuccess();
          ToasterHandler(
            "success",
            Localise(messages, "House Account updated successfully")
          );
          formikBag.setSubmitting(false);
        },
        reject: (message) => {
          onReject && onReject();
          ToasterHandler(
            "error",
            Localise(
              messages,
              message || "Failed to update House Account. Please try again"
            )
          );
          formikBag.setSubmitting(false);
        },
      })
    );
  }

  const isEligibleForHouseAccount =
    email &&
    phone &&
    //!isNaN(phone) &&
    addressLine1 &&
    city &&
    state &&
    zipcode &&
    country;

  const supportInvoice =
    (preferences["house_account_invoice"] ||
      shopPreferences["house_account_invoice"]) === "true";
  const { isActive, accountType, ...otherProps } = houseAccountInfo;
  const selectedAccountingType = useSelector(selectHouseAccountType);

  return (
    <>
      {!isActive &&
        isOpenItemAndBalanceForwardEliglible &&
        houseAccountInfo?.houseAccountId?.length === 0 &&
        showAccountingType && (
          <View style={tw(`flex flex-row justify-start pb-2 items-center`)}>
            <Text
              style={{
                height: 40,
                width: isMobile ? "25%" : "30%",
                display: "flex",
                alignItems: "center",
                ...fonts.heading5,
              }}
              testID="selectAccountType"
            >
              {Localise(messages, "Accounting Type")}
            </Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: isMobile ? "75%" : "70%",
              }}
            >
              <Picker
                containerStyle={{
                  width: "100%",
                  paddingTop: 7,
                }}
                items={[
                  {
                    label: Localise(messages, "Balance Forward"),
                    value: "Balance_Forward",
                  },
                  {
                    label: Localise(messages, "Open Item Accounting"),
                    value: "Open_Item",
                  },
                ]}
                placeholder={{
                  label: Localise(messages, "Select Accounting Type"),
                  value: "select_accounting_type",
                }}
                value={selectedAccountingType}
                onValueChange={(val) => {
                  dispatch(setAccountingType(val));
                }}
              ></Picker>
            </View>
          </View>
        )}
      {enableHouseAccount && (
        <View style={tw(`flex flex-row justify-start pb-2 items-center`)}>
          <Text
            style={{
              height: 40,
              width: isMobile ? "25%" : "30%",
              display: "flex",
              alignItems: "center",
              ...fonts.heading5,
            }}
            testID="status"
          >
            {Localise(messages, "Status")}
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: 5,
              width: isMobile ? "75%" : "70%",
            }}
          >
            <FormFieldSwitch
              value={isActive}
              onValueChange={() => {
                if (loading || !supportInvoice || !isEligibleForHouseAccount)
                  return;
                setLoading(true);
                dispatch(
                  saveCustomerHouseAccount({
                    params: {
                      customerId,
                      storeOrigin,
                      accountType: accountType || selectedAccountingType,
                      isActive: !isActive,
                      ...(houseAccountInfo?.houseAccountId?.length === 0 &&
                        selectedAccountingType === "Open_Item" && {
                          creditBalance: 0, // passing this field while creating house acc for the first time
                        }),
                      ...otherProps,
                    },
                    resolve: (isCreate) => {
                      setLoading(false);
                      ToasterHandler(
                        "success",
                        Localise(
                          messages,
                          `House Account ${
                            isCreate ? `created` : `updated`
                          } successfully`
                        )
                      );
                    },
                    reject: (message) => {
                      setLoading(false);
                      ToasterHandler(
                        "error",
                        Localise(
                          messages,
                          message ||
                            "Failed to update House Account. Please try again"
                        )
                      );
                    },
                  })
                );
              }}
              testID="toggleStatus"
            />
            <Text style={{ marginLeft: 10 }} testID="statusOfToggle">
              {Localise(messages, `${isActive ? "Active" : "Inactive"}`)}
            </Text>
            {loading && (
              <ActivityIndicator
                style={{ marginLeft: 10 }}
                color={colors.activityIndicator}
                testID={"loader"}
              />
            )}
            {!isEligibleForHouseAccount && (
              <Text style={{ marginLeft: 12 }}>
                {Localise(
                  messages,
                  "Add Customer name, phone, email, and address in Customer Info section before activating House Account"
                )}
              </Text>
            )}
          </View>
        </View>
      )}
      {isActive && (
        <Form
          innerRef={formRef}
          initialValues={houseAccountInfo}
          validationSchema={getValidationSchema(Localise, messages)}
          onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
          validateOnBlur={true}
          render={() => {
            return (
              <>
                {fields.map((fieldInfo, index) => {
                  const {
                    formFieldElement: Component,
                    title,
                    formFieldProps,
                  } = fieldInfo;

                  return (
                    <View
                      key={index}
                      style={tw(
                        `flex flex-row justify-start pb-2 items-${
                          Platform.OS !== "web" ? "center" : "baseline"
                        }`
                      )}
                    >
                      <Text
                        style={{
                          height: 40,
                          width: isMobile ? "25%" : "30%",
                          ...fonts.heading5,
                        }}
                      >
                        {title}
                      </Text>
                      <View style={{ width: isMobile ? "75%" : "70%" }}>
                        <Component
                          {...formFieldProps}
                          handleOnBlur={onCreditLimitChange}
                        />
                      </View>
                    </View>
                  );
                })}
                {
                  <View style={{ display: hideSaveCancel ? "none" : "flex" }}>
                    <SaveCancelButtons disableOnDirty={disableOnDirty} />
                  </View>
                }
              </>
            );
          }}
        />
      )}
    </>
  );
};

export default HouseAccount;
