import React, { useMemo, useState, useEffect } from "react";
import { View } from "react-native";
import { shapes } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import { FieldArray } from "formik";

import ProductsList from "./products-list";
import { TabBar } from "components/elements";
import truncate from "lodash/truncate";

const Products = (props) => {
  const {
    isDesktop,
    values,
    setValues,
    memberCodes,
    currentTabIndex: menuTabIndex,
    setCurrentTabIndex: setMenuTabIndex,
    errors,
  } = props;
  const { messages, Localise } = React.useContext(I18NContext);

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const tabNames = values?.locations?.map((each, index) => {
    return {
      key: index,
      title: truncate(each.name, { length: 15 }) || "Title",
    };
  });

  useEffect(() => {
    if (menuTabIndex !== 2 || !errors.locations) return;
    const errorTabIndex = errors.locations.findIndex((x) => x);
    errorTabIndex >= 0 && setCurrentTabIndex(errorTabIndex);
  }, [errors.locations]);

  return useMemo(() => {
    const props1 = {
      ...props,
      isSmallScreen: !isDesktop,
      title: "Product Search",
      setSideCarOpen: () => {},
      fetchProduct: () => {},
      setProductInfo: () => {},
      currentproduct: {},
      values,
      setValues,
      sendingMemberCode: values.memberCode,
    };

    return (
      <View
        style={[
          { ...shapes.sectionBorder, paddingTop: 0 },
          { marginTop: 10, width: "100%" },
        ]}
      >
        <TabBar
          tabNames={tabNames}
          currentTab={currentTabIndex}
          setCurrentTab={(val) => {
            if (!errors.locations) setCurrentTabIndex(val);
          }}
          scrollTabBar={true}
          showScrollBar={false}
          showsScrollIndicators={true}
          showBorder={false}
        />
        <FieldArray
          name="locations"
          render={() => {
            return (
              <View>
                {values.locations?.map((location, index) => {
                  if (index === currentTabIndex) {
                    return (
                      <ProductsList
                        {...props1}
                        locationInfo={location}
                        messages={messages}
                        Localise={Localise}
                        index={currentTabIndex}
                      />
                    );
                  }
                })}
              </View>
            );
          }}
        />
      </View>
    );
  }, [
    isDesktop,
    currentTabIndex,
    Localise,
    messages,
    setValues,
    tabNames,
    values,
    props,
    memberCodes,
    menuTabIndex,
    setMenuTabIndex,
  ]);
};

export default Products;
