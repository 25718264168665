import React, { forwardRef } from "react";
import { View, TouchableOpacity } from "react-native";
import { Image, Text } from "react-native-elements";

import { Accordion } from "components/elements";
import { SubmitButton, ResetButton } from "components/elements/forms";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";

import IMAGES from "static/assets/images";
import { colors, fonts, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";

export const Header = ({ title, onClose }) => (
  <View
    style={[
      tw("px-3 py-3 flex flex-row items-center justify-between mb-2"),
      { backgroundColor: backgroundColors.primary },
    ]}
  >
    <Text style={{ ...fonts.heading2, ...tw("text-white") }}>{title}</Text>
    {onClose && (
      <View>
        <TouchableOpacity onPress={onClose} testID="close">
          <Image
            style={{ width: 20, height: 20 }}
            resizeMode="cover"
            source={IMAGES["close"]}
          />
        </TouchableOpacity>
      </View>
    )}
  </View>
);

export const Section = ({ title, children }) => {
  const { isMobile } = React.useContext(DeviceContext);
  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: colors.grayScaleLight,
        marginBottom: 8,
        opacity: 1,
      }}
      pointerEvents={"auto"}
    >
      <Accordion
        title={title}
        defaultOpen={true}
        handleOnPress={() => {}}
        labelStyle={{
          marginTop: 0,
          height: 40,
          backgroundColor: backgroundColors.navBar,
          borderBottomWidth: 1,
          borderColor: colors.grayScaleLight,
          paddingHorizontal: 12,
        }}
        titleStyle={{
          color: colors.primary,
          fontWeight: "500",
        }}
        closeStyle={{
          borderBottomWidth: 0,
        }}
        iconStyle={{
          color: colors.primary,
        }}
        testID={title}
      >
        <View
          style={{
            backgroundColor: backgroundColors.secondary,
            padding: isMobile ? 10 : 20,
          }}
        >
          {children}
        </View>
      </Accordion>
    </View>
  );
};

function SubmitButtons({ disableOnDirty = true, title = "Save" }, ref) {
  // const { dirty, resetForm } = useFormikContext();
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop} = React.useContext(DeviceContext);
  return (
    <View style={tw("justify-end flex-row m-2")}>
       
        <ResetButton
        containerStyle={{
          width: isDesktop ? 72 : 80,
          height: 40,
        }}
        title={Localise(messages, "Cancel")}
        disableOnDirty={disableOnDirty}
        testID={"cancel"}
        refFromProp={ref}
      />
      <SubmitButton
        containerStyle={{
          minWidth: isDesktop ? 72 : 80,
          height: 40,
          marginLeft: -1, 
        }}
        title={Localise(messages, title)}
        disableOnDirty={disableOnDirty}
        refFromProp={ref}
      />
    </View>
  );
}

export const SaveCancelButtons = forwardRef(SubmitButtons);
