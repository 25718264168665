export const deliveryMethods = [
  { label: "Florist Delivered", value: "FLORIST_DELIVERED" },
  { label: "Pickup", value: "STORE_PICKUP" },
  { label: "Walk-In", value: "WALK_IN" },
  { label: "Phone Out", value: "PHONE_OUT" },
  { label: "Delivery Service", value: "DELIVERY_SERVICE" },
  {
    label: "Wire Out",
    value: "FLORIST_PARTNER",
  },
  {
    label: "Florist Delivered",
    value: "MOL_FLORIST_DELIVERED",
  },
  {
    label: "Florist Delivered",
    value: "FOL_FLORIST_DELIVERED",
  },
];

export const locationTypes = [
  { label: "Residence", value: "Residence" },
  { label: "Business", value: "Business" },
  { label: "Funeral Home", value: "Funeral Home" },
  { label: "Church", value: "Church" },
  { label: "Hospital", value: "Hospital" },
  { label: "Nursing Home", value: "Nursing Home" },
  { label: "Hotel", value: "Hotel" },
];

export const pickupTimes = [
  { value: "8:00", label: "8:00 AM" },
  { value: "8:30", label: "8:30 AM" },
  { value: "9:00", label: "9:00 AM" },
  { value: "9:30", label: "9:30 AM" },
  { value: "10:00", label: "10:00 AM" },
  { value: "10:30", label: "10:30 AM" },
  { value: "11:00", label: "11:00 AM" },
  { value: "11:30", label: "11:30 AM" },
  { value: "12:00", label: "12:00 PM" },
  { value: "12:30", label: "12:30 PM" },
  { value: "13:00", label: "1:00 PM" },
  { value: "13:30", label: "1:30 PM" },
  { value: "14:00", label: "2:00 PM" },
  { value: "14:30", label: "2:30 PM" },
  { value: "15:00", label: "3:00 PM" },
  { value: "15:30", label: "3:30 PM" },
  { value: "16:00", label: "4:00 PM" },
  { value: "16:30", label: "4:30 PM" },
  { value: "17:00", label: "5:00 PM" },
  { value: "17:30", label: "5:30 PM" },
  { value: "18:00", label: "6:00 PM" },
  { value: "18:30", label: "6:30 PM" },
  { value: "19:00", label: "7:00 PM" },
  { value: "19:30", label: "7:30 PM" },
  { value: "20:00", label: "8:00 PM" },
];

export const undeliverableActions = [
  { label: "Return to Store", value: "Return to Store" },
  { label: "Leave at Location", value: "Leave at Location" },
];
