import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";
import { selectState as selectGlobalState } from "../../../../ongoing/global-data/selector";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */

export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectPageStatus = createSelector(
  selectState,
  ({ status }) => status
);

export const selectIsDrawable = createSelector(
  selectState,
  ({ isDrawable }) => isDrawable
);

export const selectApiResponse = createSelector(
  selectState,
  ({ apiResponse }) => apiResponse
);

export const selectFeesCoverage = createSelector(
  selectApiResponse,
  (apiResponse) => apiResponse.feesCoverage
);

export const selectAutoRoutes = createSelector(
  selectState,
  ({ apiResponse }) => apiResponse?.autoRoutes
);

export const selectCitiesZipcodes = createSelector(
  selectState,
  ({ apiResponse }) => apiResponse?.feesCoverage.citiesZipcodes || []
);

export const selectActions = createSelector(
  selectState,
  ({ actions }) => actions
);

export const selectShopCode = createSelector(
  selectActions,
  selectGlobalState,
  ({ shopCode }, { shopCode: dashboardSelectedShopCode }) => {
    let memberCode = shopCode || dashboardSelectedShopCode;
    return memberCode !== "all" ? memberCode : "";
  }
);

export const selectReloadDeliveryZoneMap = createSelector(
  selectFeesCoverage,
  (apiResponse) => apiResponse.reloadDeliveryZoneMap || false
);

export const selectCreatedRouteZone = createSelector(
  selectState,
  ({ currentlyCreatedRouteZone }) =>
    currentlyCreatedRouteZone?.name != "" ? currentlyCreatedRouteZone : {}
);

export const selectIsCreateZoneEnabled = createSelector(
  selectState,
  ({ isCreateZoneEnabled }) => isCreateZoneEnabled
);

export const selectNewZoneCreationFailed = createSelector(
  selectState,
  ({ newZoneCreationFailed }) => newZoneCreationFailed
);

export const selectIsAutoRoutesLoading = createSelector(
  selectState,
  ({ isLoading }) => isLoading
);

export const selectIsDeleteModalVisible = createSelector(
  selectState,
  ({ isDeleteModalVisible }) => isDeleteModalVisible
);

export const selectDeleteZoneData = createSelector(
  selectState,
  ({ deleteZoneData }) => deleteZoneData
);

export const selectEditZoneIndex = createSelector(
  selectState,
  ({ editZoneIndex }) => editZoneIndex
);

export const selectEditedWaypoints = createSelector(
  selectState,
  ({ editedWaypoints }) => editedWaypoints
);

export const selectIsEditableZone = createSelector(
  selectState,
  ({ isEditableZone }) => isEditableZone
);

export const selectIsEditCancelled = createSelector(
  selectState,
  ({ isEditCancelled }) => isEditCancelled
);

export const selectNonRoutedOrders = createSelector(
  selectApiResponse,
  (apiResponse) => apiResponse.nonRoutedOrders
);

export const selectZoneCapacity = createSelector(
  selectState,
  ({ zoneCapacity }) => zoneCapacity
);

export const selectCoverageAreaCircleVisibilty = createSelector(
  selectState,
  ({ isCoverageAreaCircleVisible }) => isCoverageAreaCircleVisible
);

export const selectActiveRouteId = createSelector(
  selectState,
  ({ activeRouteId }) => activeRouteId
);

export const selectCompleteRouteModalVisible = createSelector(
  selectState,
  ({ completeRouteModalVisible }) => completeRouteModalVisible
);

export const selectRemoveStopAction = createSelector(
  selectState,
  ({ removeStopAction }) => removeStopAction
);

export const selectScreenKey = createSelector(
  selectState,
  ({ screenKey }) => screenKey
);

export const selectNestedScreenKey = createSelector(
  selectState,
  ({ nestedScreenKey }) => nestedScreenKey
);

export const selectDeliveryDashboardData = createSelector(
  selectState,
  ({ deliveryDashboard }) => deliveryDashboard
);

export const selectDashboardCurrentTabKey = createSelector(
  selectState,
  ({ dashboardCurrentTabKey }) => dashboardCurrentTabKey
);

export const selectZonesData = createSelector(
  selectState,
  ({ zonesList }) => zonesList
);

export const selectFocusField = createSelector(
  selectState,
  ({ focusField }) => focusField
);

export const selectQRScanReprintWarning = createSelector(
  selectState,
  ({ qrScanReprintWarning }) => qrScanReprintWarning
);

export const selectEnableLassoTool = createSelector(
  selectState,
  ({ enableLassoTool }) => enableLassoTool
);

export const selectRouteCitiesZipcodes = createSelector(
  selectState,
  ({ zipApiResponse }) => {
    const combinedZipCodes =
      Object.values(zipApiResponse?.feesCoverage.citiesZipcodes || {}).flat() ||
      [];
    return combinedZipCodes;
  }
);

export const selectCitiesPlaceIds = createSelector(
  selectState,
  ({ zipApiResponse }) => zipApiResponse?.feesCoverage.citiesplaceIDs || []
);

export const selectZipCoverageLoading = createSelector(
  selectState,
  ({ zipApiResponse }) => zipApiResponse?.loading || false
);

export const selectCanProgrammaticallyZoom = createSelector(
  selectState,
  ({ canProgrammaticallyZoom }) => canProgrammaticallyZoom
);
