import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Customer from "./customer";

import {
  ListOfActions,
  ListOfSubActions,
} from "components/views/drawer/order-listing/current-orders/config";
import OrderDetails from "components/views/drawer/order-details";

import { DeviceContext } from "library/contexts/appSettings";
import {
  setCurrentCustomer,
  setCustomerOrders,
  setSideCarInitialize,
  fetchCustomerDetails,
} from "library/sagas/views/home/drawer/customer-directory/slice";
import {
  selectShowUpsert,
  selectExtendedScreenData,
  selectCustomerOrders,
  selectStoreOrigin,
  selectCurrentHouseAccountId,
} from "library/sagas/views/home/drawer/customer-directory/selector";
import UserProfileStorage from "library/storage/userProfile";
import { setShopCode as setSelectedShopCode } from "library/sagas/views/home/drawer/shop-settings/common/slice";

import get from "lodash/get";

const UpsertScreen = ({ action = "update", scrollViewRef }) => {
  const dispatch = useDispatch();
  const showUpsert = useSelector(selectShowUpsert);
  const extendedScreenData = useSelector(selectExtendedScreenData);
  const orders = useSelector(selectCustomerOrders);
  const houseAccountId = useSelector(selectCurrentHouseAccountId);
  const storeOrigin = useSelector(selectStoreOrigin);

  const { memberCodes = [] } = UserProfileStorage.getAuthGroup();
  const { isDesktop } = React.useContext(DeviceContext);
  const customerId = get(extendedScreenData, "customerId", "");
  const currentOrderId = get(extendedScreenData, "orderId", "");

  const isCreate = action === "create";

  useEffect(() => {
    if (customerId) {
      dispatch(
        setSideCarInitialize({
          storeOrigin: storeOrigin || memberCodes[0],
          isCreate,
        })
      );
      dispatch(setSelectedShopCode(""));
    }
  }, [customerId]);

  if (!showUpsert && !isCreate) return null;

  if (currentOrderId !== "") {
    const { deliveryMethod = "", sourceMemberCode = "" } =
      orders.length > 0 &&
      orders.find((item) => item.orderItemId === currentOrderId);

    return (
      <OrderDetails
        isSmallScreen={!isDesktop}
        recordId={currentOrderId}
        deliveryMethod={deliveryMethod}
        sourceMemberCode={sourceMemberCode}
        actionsList={ListOfActions}
        subActionsList={ListOfSubActions}
        onAction={(value) => {
          dispatch(
            setCurrentCustomer({
              type: "recordAction",
              value,
            })
          );
          dispatch(setCustomerOrders({ setEmpty: false }));
        }}
        recordAction={get(extendedScreenData, "recordAction", "")}
        onComplete={() => {
          dispatch(fetchCustomerDetails({ storeOrigin }));
          dispatch(setCurrentCustomer({ type: "meetBallAction", value: "" }));
        }}
        menuAction={get(extendedScreenData, "menuAction", "")}
        setMenuAction={(value) => {
          dispatch(
            setCurrentCustomer({
              type: "menuAction",
              value,
            })
          );
          dispatch(setCustomerOrders({ setEmpty: false }));
        }}
        scrollViewRef={scrollViewRef}
      />
    );
  }

  return (
    <Customer
      id={customerId}
      isCreate={isCreate}
      houseAccountId={houseAccountId}
    />
  );
};

export default UpsertScreen;
