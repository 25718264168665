import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text } from "react-native";

import { Section, Header } from "../helper";
import { getUIConfig } from "../ui-config";

import { Spinner } from "components/elements";

import I18NContext from "library/contexts/i18N";
import { Entitlements } from "library/utils/entitlements";
import Environment from "library/utils/environment";
import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import { setCurrentCustomer } from "library/sagas/views/home/drawer/customer-directory/slice";
import { selectExtendedScreenData } from "library/sagas/views/home/drawer/customer-directory/selector";

import get from "lodash/get";
import tw from "tailwind-rn";
import { isEnabledForMember } from "library/utils/featureAvailability";

const Customer = ({ id, isCreate, houseAccountId }) => {
  const dispatch = useDispatch();
  const extendedScreenData = useSelector(selectExtendedScreenData);

  const status = get(extendedScreenData, "status", "");
  const { permissions } = React.useContext(AppSettingsContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const { isDesktop, isTablet, isMobile } = React.useContext(DeviceContext);

  let showSubscriptions = false;

  const isSubscriptionsEnabled = Environment.get(
    "ENABLE_SUBSCRIPTIONS",
    ""
  ).split(",");

  const allShopCodes = [];
  Object.keys(permissions).map((memberCode) => {
    if (Entitlements.CUSTOMER_DIRECTORY in permissions[memberCode])
      allShopCodes.push(memberCode);

    if (!showSubscriptions) {
      showSubscriptions =
        isSubscriptionsEnabled.includes("ALL") ||
        isSubscriptionsEnabled.includes(memberCode)
          ? true
          : false;
    }
  });

  const showEmailMarketingToggle = allShopCodes.every(
    (memberCode) =>
      isEnabledForMember("ENABLE_OCCASION_REMINDER", memberCode) &&
      permissions[memberCode][Entitlements.OCCASION_REMINDERS]?.includes(
        Entitlements.OCCASION_REMINDER_PERMISSIONS.EMAIL_MARKETING_NOTIFICATION
      )
  );

  const UIConfig = getUIConfig(
    Localise,
    messages,
    isDesktop,
    isMobile,
    allShopCodes,
    showEmailMarketingToggle
  );

  const onClose = () => {
    dispatch(setCurrentCustomer());
  };

  return (
    <View style={[isTablet && { padding: 10 }]}>
      {!isCreate && (
        <Header
          title={Localise(messages, "Customer Details")}
          onClose={onClose}
        />
      )}
      {status === "progress" ? (
        <View style={{ minHeight: 150 }}>
          <Spinner size="large" />
        </View>
      ) : status === "fail" ? (
        <View
          style={[{ minHeight: 150 }, tw("items-center justify-center p-8")]}
        >
          <Text style={{ color: "red" }}>
            {Localise(messages, "Something went wrong, please try again")}
          </Text>
        </View>
      ) : (
        UIConfig.sections.map(
          ({ section, component: { name: MainComponent, props } }, index) => {
            const isHidden =
              (section === Localise(messages, "Payment") && !id) ||
              (section === Localise(messages, "Order History") && isCreate) ||
              (section === Localise(messages, "House Account") && !id) ||
              (section === Localise(messages, "Credit/Debit Memo") &&
                !houseAccountId) ||
              (section === Localise(messages, "Statements") &&
                !houseAccountId) ||
              (section === Localise(messages, "Subscriptions") &&
                (isCreate || !showSubscriptions)) ||
              (section === Localise(messages, "Activity") && !id);
            return (
              !isHidden && (
                <Section title={section} key={index}>
                  <MainComponent UIConfig={props} isCreate={isCreate} />
                </Section>
              )
            );
          }
        )
      )}
    </View>
  );
};

export default Customer;
