import { Entitlements, isMHQNonCoreMember } from "library/utils/entitlements";
import Environment from "library/utils/environment";
import has from "lodash/has";
import {
  isIncomingWiredOrder,
  isFloristDelivered,
} from "components/views/drawer/order-details/delivery-info/helper";

export const hasQuickBooksAccess = (permissions, memberCode) => {
  if (memberCode === "") return false;
  if (memberCode === "all") {
    return Object.values(permissions).some((permission) =>
      permission[Entitlements.SHOP_SETTINGS]?.includes(
        Entitlements.SHOP_SETTINGS_PAGE.QUICK_BOOKS
      )
    );
  }
  return (
    permissions[memberCode]?.[Entitlements.SHOP_SETTINGS]?.includes(
      Entitlements.SHOP_SETTINGS_PAGE.QUICK_BOOKS
    ) ?? false
  );
};

export const hasDesignCenterFeature = (permissions, memberCode) => {
  if (memberCode === "") return false;
  if (memberCode === "all") {
    return Object.values(permissions).some((permission) =>
      permission[Entitlements.ORDERS]?.includes(
        Entitlements.ORDERS_PAGE.DESIGNER
      )
    );
  }
  return (
    permissions[memberCode]?.[Entitlements.ORDERS]?.includes(
      Entitlements.ORDERS_PAGE.DESIGNER
    ) ?? false
  );
};

// To get all the member codes in a Shop Group that have a specific feature.
export const getMemberCodesWithFeature = (
  permissions,
  key,
  featureName = null
) => {
  return Object.keys(permissions).filter((memberCode) => {
    const permission = permissions[memberCode];
    if (featureName) {
      const features = permission?.[key];
      return features?.includes(featureName);
    } else {
      return has(permission, key);
    }
  });
};

export const hasQRScanFeature = (permissions, memberCode) => {
  if (!memberCode) {
    return Object.values(permissions).some(
      (permission) => permission[Entitlements.QR_SCAN]
    );
  }

  return has(permissions[memberCode], Entitlements.QR_SCAN);
};

export const isEnabledForMember = (key, memberCode) => {
  if (!key || !memberCode) return false;

  const memberCodes = Environment.get(key, "").split(",");
  return memberCodes.includes("ALL") || memberCodes.includes(memberCode);
};

export const isFloristDeliveryAvailable = (permissions, memberCode) => {
  return (
    permissions[memberCode]?.[Entitlements.CREATE_ORDER]?.includes(
      Entitlements.CREATE_ORDER_PAGE.DM_FLORIST_DELIVERED
    ) ?? false
  );
};

export const isPickupDeliveryAvailable = (permissions, memberCode) => {
  return (
    permissions[memberCode]?.[Entitlements.CREATE_ORDER]?.includes(
      Entitlements.CREATE_ORDER_PAGE.DM_PICKUP
    ) ?? false
  );
};

export const isWalkInDeliveryAvailable = (permissions, memberCode) => {
  return (
    permissions[memberCode]?.[Entitlements.CREATE_ORDER]?.includes(
      Entitlements.CREATE_ORDER_PAGE.DM_WALK_IN
    ) ?? false
  );
};

export const isPhoneOutDeliveryAvailable = (permissions, memberCode) => {
  return (
    permissions[memberCode]?.[Entitlements.CREATE_ORDER]?.includes(
      Entitlements.CREATE_ORDER_PAGE.DM_PHONE_OUT
    ) ?? false
  );
};

export const isPayLaterAvailable = (permissions, memberCode) => {
  return (
    permissions[memberCode]?.[Entitlements.SHOP_SETTINGS]?.includes(
      Entitlements.SHOP_SETTINGS_PAGE.PT_PAY_LATER
    ) ?? false
  );
};

export const isTimedDeliveryAvailable = (permissions, memberCode) => {
  return (
    permissions[memberCode]?.[Entitlements.CREATE_ORDER]?.includes(
      Entitlements.CREATE_ORDER_PAGE.TIMED_DELIVERY
    ) ?? false
  );
};

export const hasDraftOrderFeature = (permissions) => {
  return Object.values(permissions).some(
    (permission) => Entitlements.DRAFT_ORDERS in permission
  );
};

export const hasBirthdayReminderFeature = (permissions, memberCode) => {
  return (
    (isEnabledForMember("ENABLE_OCCASION_REMINDER", memberCode) &&
      permissions[memberCode]?.[Entitlements.OCCASION_REMINDERS]?.includes(
        Entitlements.OCCASION_REMINDER_PERMISSIONS.BIRTHDAY_REMINDER
      )) ??
    false
  );
};

export const hasAnniversaryReminderFeature = (permissions, memberCode) => {
  return (
    (isEnabledForMember("ENABLE_OCCASION_REMINDER", memberCode) &&
      permissions[memberCode]?.[Entitlements.OCCASION_REMINDERS]?.includes(
        Entitlements.OCCASION_REMINDER_PERMISSIONS.ANNIVERSARY_REMINDER
      )) ??
    false
  );
};

export const hasPromoCodeFeature = (permissions = {}, memberCode = "") => {
  if (memberCode === "") return false;
  if (memberCode === "all") {
    return (
      Object.values(permissions)?.some((permission = {}) =>
        has(permission, Entitlements.PROMO_CODE)
      ) ?? false
    );
  }
  return has(permissions[memberCode], Entitlements.PROMO_CODE);
};

export const hasDeliveryZone = ({
  shopCode,
  deliveryMethod,
  direction,
  addressLine1,
}) => {
  return (
    isMHQNonCoreMember(shopCode) &&
    (isFloristDelivered(deliveryMethod) ||
      isIncomingWiredOrder(deliveryMethod ?? "", direction)) &&
    !!addressLine1
  );
};

export default {
  hasQuickBooksAccess,
  hasDesignCenterFeature,
  hasQRScanFeature,
  isEnabledForMember,
  isFloristDeliveryAvailable,
  isPickupDeliveryAvailable,
  isWalkInDeliveryAvailable,
  isPhoneOutDeliveryAvailable,
  isPayLaterAvailable,
  isTimedDeliveryAvailable,
  hasBirthdayReminderFeature,
  hasAnniversaryReminderFeature,
  hasDraftOrderFeature,
  hasPromoCodeFeature,
};
