import React from "react";
import { View } from "react-native";
import { FormField, FormFieldPicker } from "components/elements/forms";
import tw from "tailwind-rn";
import { backgroundColors } from "styles/theme";

const DeliveryActions = ({
  Localise,
  messages,
  isSmallScreen,
  modify,
  values,
  setFieldValue,
}) => {
  const path = "deliveryDetails";
  const undeliverableActions = [
    { label: "Return to Store", value: "Return to Store" },
    { label: "Leave at Location", value: "Leave at Location" },
  ];

  return (
    <>
      <View style={[tw("justify-between flex-row flex-wrap"), { zIndex: 5 }]}>
        <FormField
          name="specialDeliveryInstructions"
          label={`${Localise(messages, "Delivery Instructions")} (${
            values.specialInstructionsCharCount || 0
          }/500)`}
          placeholder={Localise(messages, "Enter Delivery Instructions")}
          path={path}
          autoCapitalize="none"
          autoCorrect={false}
          autoComplete="new-password" //hack for autoComplete off
          containerStyle={{
            width: isSmallScreen ? "100%" : "70%",
            marginLeft: -5,
            paddingRight: 3,
            padding: 0,
            margin: 0,
          }}
          multiline={true}
          numberOfLines={1}
          maxNumberOfLines={5}
          spellCheck={true}
          editable={modify}
          grayedOutOnDisable={true}
          isUpdateOnChange={true}
          transformText={(val) => {
            setFieldValue("specialInstructionsCharCount", val.length);
            return val;
          }}
        />
        <FormFieldPicker
          name="undeliverableAction"
          isRequired
          label={Localise(messages, "Undeliverable Action")}
          placeholder={{
            label: "Select Undeliverable Action",
          }}
          path={path}
          data={undeliverableActions}
          containerStyle={{
            width: isSmallScreen ? "100%" : "30%",
            marginLeft: -5,
            zIndex: 5,
          }}
          innerContainerStyle={{
            backgroundColor: modify
              ? "transparent"
              : backgroundColors.greyColor,
          }}
          disabled={!modify}
        />
      </View>
    </>
  );
};

export default DeliveryActions;
